import { useEffect, useState, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { getQuestions, updateQuestions, deleteQuestions } from '../service/AdminApi';
import { questionModel, optionModel, menuModel, toastModel, tempObj } from '../interface/AdminModel';
import { Select, Space } from 'antd';
import Loader from './Loader';
import DeletePopup from './DeletePopup';
import { popupModel } from '../interface/AssesmentModel';
import { Console } from 'console';
const { Option } = Select;

const AdminQuestion = (props: any) => {



    /**PS_AQ_04-PS_AQ_08
     * Objects are defined here
     */
    const questionTemplate: questionModel = {
        questionId: 0,
        question: '',
        isBranch: 0,
        optionType: '',
        option: [],
        branchingOptionId: 0
    };

    let optionTemplate: optionModel = {
        index: 0,
        optionId: 0,
        option: '',
        recommendation: '',
        score: 0,
        severity: '',
        isActive: true
    };

    let toastData: toastModel = {
        toastType: "",
        toastHeaderMessage: "",
        toastBodyMessage: ""
    }

    let checkBranch: any = {
        questionId: '',
        isBranch: 1,
        options: []
    }

    const popUpParam: popupModel = {
        popupType: '',
        popupHeading: '',
        popupMessage: '',
    };

    /**PS_AQ_09
     * State variables are defined here
     */
    const [responseJson, setResponseJson] = useState<any>([]);
    const [branchingQuestion, setBranchingQuestion] = useState<any>([]);
    const [tempQuestion, setTempQuestion] = useState<any>([]);
    const [hidePopup, setHidePopup] = useState<boolean>(false);
    const [render, setRender] = useState<boolean>(true);
    const [editArr, setEditArr] = useState<any>([]);
    const [recommendationArray, setRecommendationArray] = useState<any>([]);
    const [optionArr, setOptionArr] = useState<any>([]);
    const [currentMenu, setCurrentMenu] = useState(props.name);
    const [questionIndex, setQuestionIndex] = useState<number>(0);
    const [callDelete, setCallDelete] = useState<boolean>(false);
    const [disableAddNew, setDisableAddNew] = useState<boolean>(false);
    const [tempArr, setTempArr] = useState<any>([]);
    const [previousLength, setPreviousLength] = useState<any>({});
    const [noData, setNoData] = useState<boolean>(false)
    const [inputObj, setInputObj] = useState<any>({});
    const [newQuest, setNewQuest] = useState<number>(0);
    const [newOpt, setNewOpt] = useState<number>(0);
    const [edit, setEdit] = useState<boolean>(false);
    const [tArr, setTArr] = useState<any>([]);
    const [branchObj, setBranchObj] = useState<any>({});
    const [questvalidationObj, setQuestValidationObj] = useState<any>({})
    const [optvalidationObj, setOptValidationObj] = useState<any>({})
    const [popupData, setPopupData] = useState<popupModel>(popUpParam)
    const [checkbranchToggler, setCheckbranchToggler] = useState<any>({});
    const [checkRecommendation, setCheckRecommendation] = useState<any>({});
    const [recommendationCount, setRecommendationCount] = useState<any>({});
    const [isActiveCount,setIsActiveCount] = useState<any>({});
    const [hideDeletePopup, setHideDeletePopup] = useState<boolean>(true)
    let branchCheck: any = {};
    const charRegex = /[<>]/;
    const location = useLocation();
    const adminRef: any = useRef([]);
    const [adminScroll, setAdminScroll] = useState<number>(0)
    let hideCancel = 0
    

    //let checkbranchToggler = false

    useEffect(() => {

        if (adminScroll != 0) {

            adminRef.current[adminRef.current.length - 1]?.scrollIntoView({ behavior: "smooth" })
        }

    }, [adminScroll])

    /**PS_AQ_13
     * This Useeffect is used to call the deleteQuestion function
     * call Delete is passed as its dependency
     */
    // useEffect(() => {
    //     if (callDelete) {
    //         deleteQuestion()
    //     }
    // }, [callDelete]);

    /**PS_AQ_15
     * This useEffect is used to call the getData function and set the currentmenu 
     * edit,props.data and the props.name is set as its dependency
     */
    useEffect(() => {
        setCurrentMenu(props.name)
        getData();
    }, [props.name, edit, props.data]);

    /**PS_AQ_14
     * This useEffect is used to call the questionBinding function to bind all the question
     * render and the branchingQuestion is passed as its dependency
     */
    useEffect(() => {
        questionBinding();
    }, [render, branchingQuestion]);

    /**PS_AQ_16-PS_AQ_31
     * This function is used to fetch the data from the getQuestion API
     * TempQuestion is the state variable array used for binding the questions and the branchingQuestion array is used for the branching purpose 
     * props.name is passed as its parameter containign the verticalID,controlAreaId,controlAreaSubMenuId
     */
    const getData = async () => {
        try {
            props.loader.setIsLoading(true)
            const response = await getQuestions(props.name);
            if (response?.status !== 200) {
                 props.loader.setIsLoading(false)
                let toastData = {
                    toastType: 'error',
                    toastHeaderMessage: 'Error',
                    toastBodyMessage: 'API failed'
                }
                props.toast.setToastProp(toastData)
                props.toast.setHideToast(true);
            }
            if (response?.status === 200) {
                 props.loader.setIsLoading(false)
                const data = response?.data;
                setResponseJson(data.questions);
                setTempQuestion(data.questions);
                setBranchingQuestion(data.questions);
                if (data.questions.length === 0) {
                    setNoData(true)
                }
                data.questions.forEach((question: any) => {
                    if (question.branchingOptionId) {
                        setTArr((prev: any) => ([...prev, question.branchingOptionId]))
                    }
                    recommendationCount[question.questionId]=0
                    
                })
                 props.loader.setIsLoading(false)
            }
        }
        catch (error) {
             props.loader.setIsLoading(false)
            let toastData = {
                toastType: 'error',
                toastHeaderMessage: 'Error',
                toastBodyMessage: 'API failed'
            }
            props.toast.setToastProp(toastData)
            props.toast.setHideToast(true);
        }

    };

    /**PS_AQ_32-PS_AQ_40
    * This function is used to bind the questions fetched from the API
    * Checkbox and the Radio button is handled in the seperate binding 
    */
    const questionBinding = () => {
        if (tempQuestion.length === 0) {
            return (
                <div className="tab-pane mb-5" id="v-pills-costopt" role="tabpanel">
                    <div className="row text-center">
                        <p className="font-16 font-medium color-grey-v3 my-5">Sorry, you don't have any questions added. Please click on<span className="font-16 font-semibold color-grey-v1"> "Upload Questionnaire"</span> or <span className="font-16 font-semibold color-grey-v1"> "Add New Question"</span> to add the Questionnaire.</p>
                    </div>
                </div>
            )
        }
        else {
            tempQuestion.forEach((data:any)=>{
                let activeCount = 0
                data.options.forEach((opt:any)=>{
                    if(opt.isActive){
                        isActiveCount[data?.questionId]=++activeCount
                    }
                })
               
            })
            
            return tempQuestion?.map((tempData: any, i: number) => {
                let tempCount=0
               
                let checkEdit = editArr.findIndex((tempEdit: any) => tempEdit == tempData?.questionId)
                let checkOptionDD = optionArr.findIndex((tempOpt: any) => tempOpt == tempData?.questionId)
                let recomendationShow = recommendationArray.findIndex((tempReco: any) => tempReco == tempData?.questionId)
                if (tempData.questionId < 0) {
                    hideCancel++
                }
                let questionIndex, optionIndex
                branchCheck[tempData.questionId] = 0
                tempQuestion.forEach((subTemp: any, indexx: number) => {
                    subTemp?.options?.forEach((mapData: any) => {
                        if (mapData?.optionId == tempData?.branchingOptionId) {
                            questionIndex = indexx + 1
                        }
                    })
                    let index = subTemp?.options?.findIndex((checkData: any) => checkData?.optionId == tempData?.branchingOptionId)
                    if (index != -1) {
                        optionIndex = index + 1
                    }

                    recommendationCount[tempData.questionId]=0

                    //setCheckbranchToggler({...checkbranchToggler,[tempData.questionId]:false})
                })

                return (

                    <>
                        <div ref={el => adminRef.current[i] = el} className="mb-5">
                            <div className="col-lg-12 col-md-12 col-12 mb-3">
                                <div className="d-flex justify-content-between align-items-center">
                                    <p className="font-14 font-semibold color-black mb-0"> {i + 1}- Question </p>
                                    <div className="d-flex justify-content-between align-items-center">
                                        {tempData?.branchingOptionId ?
                                            <>
                                                <span className="font-14 font-medium color-black-v2 me-4 d-flex align-items-center">
                                                    <span className="me-2 d-flex align-items-center">
                                                        <img src="images/tag-icon.svg" alt="tag-icon" />
                                                    </span>
                                                    Branching from{'\u00A0'}
                                                    <span className="blue-700">
                                                        {" "}{questionIndex} <span>{" "}({optionIndex})</span>
                                                    </span>
                                                </span>
                                                |
                                            </> : ""}
                                        <button
                                            disabled={editArr.findIndex((question: any) => question === tempData?.questionId) != -1 ? true : false}
                                            id={tempData?.questionId}
                                            onClick={(e) => {
                                                handleEdit(e, tempData?.questionId);
                                            }}
                                            type="button"
                                            className="font-14 font-medium  ms-3 me-3 link-blue bg-white border-0 shadow-none d-flex align-items-center"

                                        >
                                            <span className="pe-2" hidden={editArr.findIndex((question: any) => question === tempData?.questionId) != -1 ? true : false}>
                                                <img src="images/editIcon.svg" alt="edit-icon" />
                                            </span>
                                            <span className="mt-1" hidden={editArr.findIndex((question: any) => question === tempData?.questionId) != -1 ? true : false}>Edit Question</span>
                                        </button>

                                        <button
                                            id={tempData.questionId}
                                            className="font-14 font-medium red-400  bg-white border-0 shadow-none d-flex align-items-center"
                                            onClick={() => {
                                                setQuestionIndex(tempData.questionId)
                                                let tempObj: popupModel = {
                                                    popupHeading: "Delete",
                                                    popupType: "",
                                                    popupMessage: "Are you sure you want to delete this question ?",
                                                    setCallDelete: (param: boolean) => setCallDelete(param)
                                                }
                                                setPopupData(tempObj)
                                                setHideDeletePopup(false)

                                            }}
                                        >
                                            <span className="pe-2">
                                                <img src="images/delete-icon-sm.svg" alt="delete-icon" />
                                            </span>
                                            <span className="mt-1">Delete Question</span>
                                        </button>
                                    </div>
                                </div>
                            </div>

                            <input
                                type="text"
                                className="form-control theme-form font-regular font-14"
                                value={tempData?.question}
                                id={tempData?.questionId}
                                title={tempData?.question}
                                onChange={(e) => { setEditData(e, tempData?.questionId) }}
                                disabled={checkEdit != -1 || tempData.questionId < 0 ? false : true}
                            />
                            <div>
                                {questvalidationObj[tempData?.questionId] ? <span className="mt-2 mb-0 color-red font-14 font-regular">{questvalidationObj[tempData?.questionId]}</span> : <><span className="mt-2 mb-0 color-red font-14 font-regular">{questvalidationObj[tempData?.questionId]}</span></>}
                            </div>
                            {tempData.options?.map((optArr: any, ii: number) => {
                               
                                if(optArr.isActive){
                                const tempIndexNew: string[] = []
                                tempQuestion.forEach((data: any, index: number) => {
                                    if (data?.branchingOptionId === optArr?.optionId) tempIndexNew.push(index + "")
                                })
                                branchObj[optArr.optionId] = tempIndexNew

                                previousLength[optArr.optionId] = tempIndexNew.length
                                if (tempIndexNew.length > 0) {
                                    checkbranchToggler[tempData.questionId] = true
                                    branchCheck[tempData.questionId]++

                                    //setCheckbranchToggler({...checkbranchToggler,[tempData.questionId]:true})
                                }
                                if (optArr.recommendation) {
                                    checkRecommendation[tempData.questionId] = true
                                    recommendationCount[tempData.questionId] = ++tempCount
                                    //setCheckRecommendation({...checkRecommendation,[tempData.questionId]:true})
                                }

                                return (

                                    <>
                                        {tempData?.questionType === 'Radio' ?
                                            <>
                                                <div className="row">
                                                    <div className="col-12 col-md-3">
                                                        <div className="form-check mt-4 d-flex gap-3">
                                                            <input
                                                                className="form-check-input theme-radio label-bold"
                                                                type="radio"
                                                                name="secondquestion"
                                                                disabled
                                                            />
                                                            <input
                                                                disabled={checkEdit != -1 || tempData.questionId < 0 ? false : true}
                                                                type="text"
                                                                id={tempData?.questionId}
                                                                name={"Description"}
                                                                title={optArr.option}
                                                                value={optArr.option}
                                                                onChange={(e) => onOptionSelect(e, optArr)}
                                                                className="form-control theme-form font-regular font-14"
                                                                placeholder=""
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-2">
                                                        <div className="mt-4">
                                                            <input
                                                                disabled={checkEdit != -1 || tempData.questionId < 0 ? false : true}
                                                                name={"score"}
                                                                id={tempData?.questionId}
                                                                value={optArr?.score}
                                                                onChange={(e: any) => onOptionSelect(e, optArr)}
                                                                type="text"
                                                                className="form-control theme-form font-regular font-14"
                                                                placeholder="Enter score value"

                                                            />
                                                        </div>
                                                    </div>

                                                    {checkbranchToggler[tempData.questionId] || tArr.findIndex((opt: any) => opt === optArr.optionId) != -1 ?
                                                        <div className='d-flex gap-3 align-items-center col-12 col-md-3'>

                                                            <label className="mt-4 font-14 font-regular color-grey text-nowrap">
                                                                Goto-Question
                                                            </label>
                                                            <div className='dropdown w-100 mt-4 theme-form'>
                                                                <Select
                                                                    mode="multiple"
                                                                    allowClear={false}
                                                                    showSearch={false}
                                                                    style={{ width: '100%' }}
                                                                    placeholder="go-to question"
                                                                    value={branchObj[optArr.optionId]}
                                                                    onChange={(e: any) => {
                                                                        handleBranchingDropDown(e, optArr.optionId, tempData?.questionId, branchObj[optArr.optionId])
                                                                    }}
                                                                    optionLabelProp="label"
                                                                    disabled={checkEdit != -1 || tempData.questionId < 0 ? false : true}
                                                                >
                                                                    {branchingQuestion?.map((temp: any, ik: any) => {
                                                                        if (i != ik) {
                                                                            if (temp.isBranch == 0) {

                                                                                return (
                                                                                    <Option value={String(ik)} id={temp.optionId} label={ik + 1} >
                                                                                        <Space>
                                                                                            {ik + 1}
                                                                                        </Space>
                                                                                    </Option>)
                                                                            }
                                                                            else {
                                                                                return (
                                                                                    <Option value={String(ik)} id={temp.optionId} label={ik + 1} style={{ display: "none" }}>
                                                                                        <Space>
                                                                                            {ik + 1}
                                                                                        </Space>
                                                                                    </Option>)
                                                                            }
                                                                        }
                                                                    }
                                                                    )
                                                                    }</Select></div> </div> : ""

                                                    }

                                                    {isActiveCount[tempData?.questionId] > 2 ?
                                                        <div className="col-12 col-md-2">
                                                            <div className="mt-4 pt-1">
                                                                <button className="font-14 font-medium red-400  bg-white border-0 shadow-none " hidden={checkEdit != -1 || tempData.questionId < 0 ? false : true}
                                                                    id={optArr.optionId.toString()} onClick={(e: any) => {
                                                                        deleteOption(e, tempData?.questionId, optArr?.optionId)
                                                                    }}><span className=""><img src="images/delete-icon-sm.svg" alt="delete-icon" /></span></button>
                                                            </div>
                                                        </div>
                                                        : ""}
                                                </div>
                                                <div>
                                                    {optvalidationObj[optArr.optionId] ? <span className="mt-2 mb-0 color-red font-14 font-regular">{optvalidationObj[optArr.optionId]}</span> : <><span className="mt-2 mb-0 color-red font-14 font-regular">{optvalidationObj[optArr.optionId]}</span></>}
                                                </div>
                                                {
                                                    checkRecommendation[tempData.questionId] ?
                                                        <div className="row mt-4">
                                                            <div className="col-12 col-md-8">
                                                                <label htmlFor="recommendation1" className="form-label font-14 font-semibold color-black">Recommendation</label>
                                                                <input
                                                                    disabled={checkEdit != -1 || tempData.questionId < 0 ? false : true}
                                                                    name={"Recommendations"}
                                                                    type="text"
                                                                    id={tempData.questionId}
                                                                    title={optArr.recommendation}
                                                                    value={optArr.recommendation}
                                                                    onChange={(e) => onOptionSelect(e, optArr)}
                                                                    className="form-control theme-form font-regular font-14"
                                                                    placeholder="Specify the recommendations here" />
                                                            </div>
                                                            <div className="col-12 col-md-4">
                                                                <label htmlFor="impact1" className="form-label font-14 font-semibold color-black">Impact</label>
                                                                <select className="form-select theme-form" aria-label="Default select example" disabled={checkEdit != -1 || tempData.questionId < 0 ? false : true}
                                                                    name={"severity"} id={tempData.questionId} value={optArr.severity} onChange={(e) => onOptionSelect(e, optArr)}>
                                                                    <option selected value={''}>select</option>
                                                                    <option value={"High"}>High</option>
                                                                    <option value={"Medium"}>Medium</option>
                                                                    <option value={"Low"}>Low</option>
                                                                </select>
                                                            </div>
                                                        </div> : ""
                                                }


                                            </>
                                            :
                                            <>
                                                <div className="row">
                                                    <div className="col-12 col-md-3">
                                                        <div className="form-check mt-4 d-flex gap-3 align-items-center">
                                                            <input className="theme-check mt-0 form-check-input d-flex align-items-center" type="checkbox" id="checkbox1" disabled />
                                                            <input
                                                                disabled={checkEdit != -1 || tempData.questionId < 0 ? false : true}
                                                                type="text"
                                                                id={tempData.questionId}
                                                                name={"Description"}
                                                                title={optArr.option}
                                                                value={optArr.option}
                                                                onChange={(e) => onOptionSelect(e, optArr)}
                                                                className="form-control theme-form font-regular font-14" />
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-2">
                                                        <div className="mt-4">
                                                            <input
                                                                disabled={checkEdit != -1 || tempData.questionId < 0 ? false : true}
                                                                name={"score"}
                                                                id={tempData.questionId}
                                                                value={optArr.score}
                                                                onChange={(e: any) => onOptionSelect(e, optArr)}
                                                                type="text"
                                                                className="form-control theme-form font-regular font-14"
                                                                placeholder="Enter score value"

                                                            />
                                                        </div>
                                                    </div>
                                                    {checkbranchToggler[tempData.questionId] || tArr.findIndex((opt: any) => opt === optArr.optionId) != -1 ?
                                                        <div className='d-flex gap-3 align-items-center col-12 col-md-3'>
                                                            <label className="mt-4 font-14 font-regular color-grey text-nowrap">
                                                                Goto-Question
                                                            </label>
                                                            <div className='dropdown w-100 mt-4 theme-form'>
                                                                <Select
                                                                    mode="multiple"
                                                                    allowClear={false}
                                                                    showSearch={false}
                                                                    style={{ width: '100%' }}
                                                                    placeholder="go-to question"
                                                                    value={branchObj[optArr.optionId]}
                                                                    onChange={(e: any) => {
                                                                        handleBranchingDropDown(e, optArr.optionId, tempData?.questionId, branchObj[optArr.optionId])
                                                                    }}
                                                                    optionLabelProp="label"
                                                                    disabled={checkEdit != -1 || tempData.questionId < 0 ? false : true}
                                                                >
                                                                    {branchingQuestion?.map((temp: any, ik: any) => {
                                                                        if (i != ik) {
                                                                            if (temp.isBranch == 0) {
                                                                                return (
                                                                                    <Option value={String(ik)} id={temp.optionId} label={ik + 1} >
                                                                                        <Space>
                                                                                            {ik + 1}
                                                                                        </Space>
                                                                                    </Option>)
                                                                            }
                                                                            else {
                                                                                return (
                                                                                    <Option value={String(ik)} id={temp.optionId} label={ik + 1} style={{ display: "none" }}>
                                                                                        <Space>
                                                                                            {ik + 1}
                                                                                        </Space>
                                                                                    </Option>)
                                                                            }
                                                                        }
                                                                    }
                                                                    )
                                                                    }</Select></div></div> : ""

                                                    }
                                                    {isActiveCount[tempData?.questionId] > 2 ?
                                                        <div className="col-12 col-md-2">
                                                            <div className="mt-4 pt-1">
                                                                <button className="font-14 font-medium red-400  bg-white border-0 shadow-none " hidden={checkEdit != -1 || tempData.questionId < 0 ? false : true}
                                                                    id={optArr?.optionId} onClick={(e) => deleteOption(e, tempData?.questionId, optArr?.optionId)}><span className=""><img src="images/delete-icon-sm.svg" alt="delete-icon" /></span></button>
                                                            </div>
                                                        </div>
                                                        : ""}
                                                </div>
                                                <div>
                                                    {optvalidationObj[optArr.optionId] ? <span className="mt-2 mb-0 color-red font-14 font-regular">{optvalidationObj[optArr.optionId]}</span> : <><span className="mt-2 mb-0 color-red font-14 font-regular">{optvalidationObj[optArr.optionId]}</span></>}

                                                </div>
                                                {
                                                    checkRecommendation[tempData.questionId] ?
                                                        <div className="row mt-4">
                                                            <div className="col-12 col-md-8">
                                                                <label htmlFor="recommendation1" className="form-label font-14 font-semibold color-black">Recommendation</label>
                                                                <input
                                                                    disabled={checkEdit != -1 || tempData.questionId < 0 ? false : true}
                                                                    name={"Recommendations"}
                                                                    type="text"
                                                                    id={tempData.questionId}
                                                                    title={optArr.recommendation}
                                                                    value={optArr.recommendation}
                                                                    onChange={(e) => onOptionSelect(e, optArr)}
                                                                    className="form-control theme-form font-regular font-14"
                                                                    placeholder="Specify the recommendations here" />
                                                            </div>
                                                            <div className="col-12 col-md-4">
                                                                <label htmlFor="impact1" className="form-label font-14 font-semibold color-black">Impact</label>
                                                                <select className="form-select theme-form" aria-label="Default select example" disabled={checkEdit != -1 || tempData.questionId < 0 ? false : true}
                                                                    name={"severity"} id={tempData.questionId} value={optArr.severity} onChange={(e) => onOptionSelect(e, optArr)}>
                                                                    <option selected value={''}>select</option>
                                                                    <option value={"High"}>High</option>
                                                                    <option value={"Medium"}>Medium</option>
                                                                    <option value={"Low"}>Low</option>
                                                                </select>
                                                            </div>
                                                        </div> : ""
                                                }
                                            </>
                                        }

                                    </>

                                )
                            }})}
                            <div className=" mt-4 d-grid gap-3 d-md-flex align-items-center justify-content-between">
                                <div className=" d-sm-block d-md-flex align-items-center ">
                                    <div className={checkEdit != -1 || tempData.questionId < 0 ? "d-flex align-items-center my-2 cursor-pointer" : "d-flex align-items-center my-2"} >
                                        <img src="images/plus.svg" alt="plus" />
                                        {

                                            tempData.questionType == null || tempData.questionType == '' ? <select className="cursor-pointer shadow-none theme-form form-select font-regular color-black font-14 me-3 border-0" disabled={checkEdit != -1 || tempData.questionId < 0 ? false : true}
                                                id={tempData.questionId} onChange={(e) => { handleAddOption(e) }}>
                                                <option id={"0"} value={0}>Add Option</option>

                                                <>
                                                    <option id={"1"} value={"Radio"}> Radio</option>
                                                    <option id={"2"} value={"Checkbox"}> Checkbox</option>
                                                </>

                                            </select> : <button className="shadow-none theme-form form-select font-regular color-black font-14 me-3 border-0" value={tempData.questionType} disabled={checkEdit != -1 || tempData.questionId < 0 ? false : true} id={tempData.questionId} onClick={(e) => { handleAddOption(e) }}>Add Option</button>
                                        }

                                    </div>
                                    <div className="form-check form-switch d-flex align-items-center me-4 my-2">
                                        <input className="form-check-input theme-toggle cursor-pointer" type="checkbox" role="switch" disabled={(tempData.questionId < 0) || (tempData.questionId > 0 && checkbranchToggler[tempData.questionId] && branchCheck[tempData.questionId] != 0) || (tempData.questionId > 0 && checkEdit == -1) ? true : false} checked={checkbranchToggler[tempData.questionId] ? true : false} id={tempData?.questionId} onChange={(e) => { handleGotoToggler(e) }} defaultChecked />
                                        <label className="form-check-label font-14 font-regular color-grey ms-2 mt-1" htmlFor="deviceKey">Add Branching</label>
                                    </div>
                                    <div className="form-check form-switch d-flex align-items-center my-2" id={recommendationCount[tempData.questionId]}>
                                        <input className="form-check-input theme-toggle cursor-pointer" type="checkbox" role="switch" checked={checkRecommendation[tempData.questionId] ? true : false} disabled={(tempData.questionId < 0 && tempData.options.length < 2) || (tempData.questionId > 0 && checkEdit == -1 ) || (tempData.questionId > 0 && checkEdit != -1 && recommendationCount[tempData.questionId]>=1)   ? true : false} id={tempData?.questionId} onChange={(e) => { handleRecommendationToggler(e) }} />
                                        <label className="form-check-label font-14 font-regular color-grey ms-2 mt-1" htmlFor="AddRecommendations">Add Recommendations</label>
                                    </div>
                                </div>
                            </div>
                            <div>{inputObj[tempData?.questionId] ? <p className="mt-2 mb-0 color-red font-14 font-regular">{inputObj[tempData?.questionId]}</p> : <></>}</div>
                        </div>
                    </>
                )

            })
        }

    }


    /**PS_AQ_122-PS_AQ_139
        * This function is used to set the changes doen in the options by the user
        * event and the paramOption state variables are the parameters
        */
    const onOptionSelect = (e: any, paramOption: any) => {
        const editQuest = tempQuestion?.map((question: any) => {
            if (question.questionId == e.target.id) {
                question.options?.map((option: any) => {
                    if (option.optionId == paramOption.optionId) {
                        if (e.target.name === "Description") {
                            if (!charRegex.test(e.target.value)) {
                                option.option = e.target.value
                            }
                            if (charRegex.test(e.target.value)) {
                                option.option = option.option
                            }
                        }
                        else if (e.target.name === "severity") {
                            option.severity = e.target.value
                        }
                        else if (e.target.name === "Recommendations") {
                            if (!charRegex.test(e.target.value)) {
                                option.recommendation = e.target.value
                            }
                            if (charRegex.test(e.target.value)) {
                                option.recommendation = option.recommendation
                            }
                        }
                        else if (e.target.name === "score") {
                            if (!isNaN(e.target.value) && e.target.value <= 100) {
                                option.score = Number(e.target.value)
                            }
                            else {
                                option.score = option.score
                            }

                        }
                    }
                })
            }
        });
        setRender(!render);
    }

    /**PS_AQ_63-PS_AQ_86
    * This function is used to set the branchingOptions selected by the user
    * event,optId and the questionId is passed as the parameter
    */
    const handleBranchingDropDown = (event: any, optId: any, questionId: number, branchObj: any) => {
        if (event.length == 0) {
            let tempQues = tempQuestion?.map((question: any) => {
                if (optId == question.branchingOptionId) {
                    return { ...question, branchingOptionId: null }
                }
                else {
                    return question
                }
            })
            setTempQuestion(tempQues)
            let branchQuest = branchingQuestion?.map((question: any) => {
                if (optId == question.branchingOptionId) {
                    return { ...question, isBranch: 0 }
                }
                else {
                    return question
                }
            })
            setBranchingQuestion(branchQuest)
            // tempArr.forEach((temp: any) => {
            //     if (temp.questionId == questionId) {
            //         temp.options = []
            //     }
            // })
        }
        if (event.length > previousLength[optId]) {
            event.forEach((id: any) => {
                let tempBranch = branchingQuestion.map((branchQuest: any, index: number) => {
                    if (Number(id) === index) {
                        return { ...branchQuest, isBranch: 1 }
                    }
                    else {
                        return branchQuest
                    }
                })
                setBranchingQuestion(tempBranch);
                let recentArr = tempArr;
                let tempQuest = tempQuestion?.map((quest: any, index: any) => {
                    if (index == Number(id)) {
                        let tempId = recentArr.findIndex((question: any) => question?.questionId == questionId)
                        let tempObj = recentArr[tempId]
                        if (!tempObj?.options?.includes(quest.questionId)) {
                            tempObj?.options?.push(quest.questionId)
                        }
                        return { ...quest, branchingOptionId: optId }
                    }
                    else {
                        return quest
                    }
                });
                setInputObj({ ...inputObj, [questionId]: "" })
                setTempQuestion(tempQuest)
            })


        }
        else if (event.length < previousLength[optId]) {
            let tempIndex = tArr.findIndex((element: any) => element == optId)
            tArr.splice(tempIndex, 1)
            branchObj?.forEach((temp: any) => {
                if (!event.includes(temp)) {
                    branchObj.splice(branchObj.indexOf(event), 1)
                    let tempQid = branchingQuestion[temp].questionId
                    branchingQuestion[temp].isBranch = 0
                    tempQuestion[temp].branchingOptionId = null
                    tempArr.forEach((temp: any, index: number) => {
                        if (temp.questionId == questionId) {
                            let tIndex = temp.options.findIndex((opt: any) => opt == tempQid)
                            if (tIndex != -1) tempArr[index].options.splice(tIndex, 1)
                        }
                    })
                }
            })
        }
        setRender(!render);
        previousLength[optId] = event.length
    }


    /**PS_AQ_47-PS_AQ_61
     * This function is used to handle the toggler action for the branching options/go-to question toggler
     * event is passed as the parameter
     */
    const handleGotoToggler = (event: any) => {
        if (!event.target.checked) {
            setCheckbranchToggler({ ...checkbranchToggler, [event.target.id]: false })
            setInputObj({ ...inputObj, [event.target.id]: "" })
        }
        else if (event.target.checked) {
            setCheckbranchToggler({ ...checkbranchToggler, [event.target.id]: true })
        }
        const newTempQuestion = tempQuestion?.map((question: any) => {
            if (question.questionId == event.target.id) {
                if (!question.isBranch) {
                    question.options.forEach((opt: any) => {
                        previousLength[opt.optionId] = 0
                    })
                    if(tempArr.findIndex((element:any)=>element.questionId==question.questionId)==-1){
                        tempArr.push({ questionId: question.questionId, isBranch: 1, options: [] })
                    }
                    //setCheckbranchToggler({ ...checkbranchToggler, [event.target.id]: true })
                    return { ...question, isBranch: 1 }
                }
                else if (question.isBranch) {
                    //setCheckbranchToggler({ ...checkbranchToggler, [event.target.id]: false })
                    return { ...question, isBranch: 0 };
                }
            }
            return question;
        });
        setTempQuestion(newTempQuestion);
        setRender(!render);


    }


    /**PS_AQ_111-PS_AQ_119
    * This function is used to handle the toggler action for the recommendations for options/recommendation toggler
    * event,optId and the questionId is passed as the parameter
    */
    const handleRecommendationToggler = (event: any) => {
        if (event.target.checked) {
            setRecommendationArray((prevState: any) => [...prevState, event.target.id])
            setCheckRecommendation({ ...checkRecommendation, [event.target.id]: true })
        }
        else {
            let recoIndex = recommendationArray.findIndex((data: any) => data == event.target.id)
            if (recoIndex != -1) recommendationArray.splice(recoIndex, 1)
            delete checkRecommendation[event.target.id]
            setRender(!render)
        }
    }

  /**PS_AQ_165-PS_AQ_181
     * This function is used to call the updateQuestion API passing the edited question
     * event,optId and the questionId is passed as the parameter
     */
  const editQuestion = async () => {
    props.loader.setIsLoading(true)
   let flag: boolean = true
   tempArr.forEach((question: any) => {
       if (checkbranchToggler[question.questionId] && question.options.length == 0) {
           flag = false
           setInputObj({ ...inputObj, [question.questionId]: "Please select a branching option" })
       }
       else if (checkbranchToggler[question.questionId] && question.options.length != 0) {
           flag = true
           setInputObj({ ...inputObj, [question.questionId]: "" })
       }
   })

        let tempQuestObj: any = {}
        let tempOptObj: any = {}
        tempQuestion.forEach((question: tempObj) => {
            let tempCount: number = 0
            if (!question.question || !question.question.trim() ) {
                tempQuestObj[question.questionId] = "Please Enter the Question"
                flag = false
            }

       if (question.question && question.options.length == 0) {
           tempQuestObj[question.questionId] = "Please Enter the Option"
           flag = false
       }

       let optionFlag = false;
       let recommendationCount=0;
       question.options.forEach((option: optionModel) => {
           if (!option.option || !option.option.trim()) {
               tempOptObj[option.optionId] = "Please Enter the Option"
               flag = false
           }
           if(checkRecommendation[question.questionId] && (option.recommendation) && (option.severity) &&(option.recommendation.trim())){
               recommendationCount++
           }
           if (option.score == null) {
               tempOptObj[option.optionId] = "Please Enter the Score"
               flag = false
           }
           if (checkRecommendation[question.questionId] && ((option.recommendation) && (option.severity) &&(option.recommendation.trim()) ) || ((!option.recommendation) && (!option.severity))) {
               tempCount++
           }
           // else if(checkRecommendation[question.questionId] && ((option.recommendation) && (!option.severity))){
           //     tempCount=0
           // }

       })
       if (checkRecommendation[question.questionId] && tempCount == question.options.length && recommendationCount>=1) optionFlag = true

       if (checkRecommendation[question.questionId] && !optionFlag ) {
           tempQuestObj[question.questionId] = "Please Enter the Recommendation and Severity"
           flag = false
       }

   })
   setQuestValidationObj(tempQuestObj)
   setOptValidationObj(tempOptObj)

    currentMenu.questions = tempQuestion
    if (flag) {
        tempQuestion.forEach((question: any) => {
            question.question=question.question.trim().replace(/\"/g, '”')
            if (question.questionId < 0) {
                question.options.forEach((option: any) => {
                    option.option=option.option.trim().replace(/\"/g, '”')
                    option.recommendation=option.recommendation.trim().replace(/\"/g, '”')
                    option.severity=option.severity.replace(/\"/g, '”')
                    if (option.optionId < 0) {
                        option.optionId = 0
                    }
                })
                question.questionId = 0
            }
            else {
                question.options.forEach((option: any) => {
                    option.option=option.option.trim().replace(/\"/g, '”')
                    option.recommendation=option.recommendation.trim().replace(/\"/g, '”')
                    option.severity=option.severity.replace(/\"/g, '”')
                    if (option.optionId < 0) {
                        option.optionId = 0
                    }
                })
            }
        })
        let response = await updateQuestions(currentMenu)
        if (response?.status !== 200) {
            let toastData = {
                toastType: 'Error',
                toastHeaderMessage: 'Error',
                toastBodyMessage: 'Api Failed !.'
            }
            props.toast.setToastProp(toastData)
            props.toast.setHideToast(true);
        }
        if (response?.status === 200) {
                props.loader.setIsLoading(false)
            setEdit(!edit)
            let toastData = {
                toastType: 'Success',
                toastHeaderMessage: 'Success',
                toastBodyMessage: 'Questionnaires have been updated successfully'
            }
            props.toast.setToastProp(toastData)
            props.toast.setHideToast(true);
        }
        setRender(!render);
        setDisableAddNew(false);
        setEditArr([])
        // window.location.reload()
    }
    else {
        setHidePopup(true)
            props.loader.setIsLoading(false)
    }


}


    /**PS_AQ_42-PS_AQ_45
     * This function is used to handle the edit icon action
     * event and the questionId is passed as the parameter
     * editArr just stores the id of the question for which the edit icon is clicked
     */
    const handleEdit = (event: any, questionId: string) => {
        setEditArr((prevState: any) => [...prevState, questionId]);
        //setRecommendationArray((prevState: any) => [...prevState, questionId])
        setRender(!render)
    }


    /**PS_AQ_90-PS_AQ_109
    * This function is used to add the new optionsvfor both the new and the existing questions
    * event is passed as the parameter
    * In this function a temp object is just created and push into the tempQuestions array 
    */
    const handleAddOption = (event: any) => {
        let tempEdit = tempQuestion.find((question: any) => {
            return question.questionId == event.target.id
        })
        tempEdit.questionType = event.target.value
        if (!tempEdit.options?.length) {
            let tempArr = [{
                optionId: newOpt - 1,
                option: "",
                recommendation: "",
                severity: "",
                score: 0,
                isActive: true
            },
            {
                optionId: newOpt - 2,
                option: "",
                recommendation: "",
                severity: "",
                score: 0,
                isActive: true
            }]
            setNewOpt(newOpt - 2);
            tempEdit.options.push(...tempArr)
        }
        else {
            let tempNo = newOpt - 1
            let tempArr = {
                optionId: tempNo,
                option: "",
                recommendation: "",
                severity: "",
                score: 0,
                isActive: true
            }
            tempEdit.options.push(tempArr)
            setNewOpt(newOpt - 1);
        }
        optionTemplate = {
            optionId: 0,
            option: "",
            recommendation: "",
            severity: "",
            score: 0,
            isActive: true
        }
        setRender(!render);

    }


    /**PS_AQ_188-PS_AQ_194
        * This function is used to delete the option selected by the user
        * event, and the questionId is passed as the parameter
        * In this function the options are deleted from the tempQuestions array
        */
    const deleteOption = (event: any, questId: any, optionId: any) => {
        let questInd = tempQuestion.findIndex((quest: any) => quest.questionId == questId)
        let optInd = tempQuestion[questInd].options.findIndex((opt: any) => opt.optionId == optionId)
        tempQuestion[questInd].options[optInd].isActive=false
        branchObj[optionId]?.forEach((temp: any) => {
            let tempQid = branchingQuestion[temp].questionId
            branchingQuestion[temp].isBranch = 0
            tempQuestion[temp].branchingOptionId = null
            tempArr.forEach((temp: any, index: number) => {
                if (temp.questionId === questId) {
                    let tIndex = temp.options.findIndex((opt: any) => opt === tempQid)
                    if (tIndex != -1) tempArr[index].options.splice(tIndex, 1)
                }
            })
        })
        branchObj[optionId] = []
        setRender(!render)
    }

    /**PS_AQ_142-PS_AQ_148
    * This function is used to set the data edit by the user
    * event and the questionId is passed as the parameter
    */
    const setEditData = (event: any, questionId: string) => {
        const editQuestion = tempQuestion.map((question: any) => {
            if (question.questionId === questionId) {
                if (charRegex.test(event.target.value)) {
                    return question
                }
                if (!charRegex.test(event.target.value)) {

                    return { ...question, question: event.target.value }
                }
            }
            else {
                return question
            }

        })
        setTempQuestion(editQuestion)
    }

    /**PS_AQ_160-PS_AQ_162
     * This function is used to add the question 
     * event is passed as the parameter
     */
    const handleAddQuestion = (event: any) => {
        let newQuestion = {
            questionId: newQuest - 1,
            question: '',
            isBranch: 0,
            questionType: "",
            options: [],
            branchingOptionId: null
        }
        setTempQuestion((prev: any) => ([...prev, newQuestion]))
        editArr.push(newQuest - 1)
        setNewQuest(newQuest - 1)

        setAdminScroll(adminScroll + 1)

        // adminRef?.current[adminRef.current.length - 1]?.scrollIntoView({
        //     behavior: "smooth", block: 'end',
        //     inline: 'nearest'
        // })
    }


    /**PS_AQ_151-PS_AQ_157
    * This function is used to delete the question selected by the user
    * event and the questionId is passed as the parameter
    */
    const deleteQuestion = async () => {
        try {
             props.loader.setIsLoading(true)
            if (questionIndex > 0) {
                let response = await deleteQuestions({ "question_id": questionIndex })
                if (response.status == 200) {
                     props.loader.setIsLoading(false)
                    let toastData = {
                        toastType: 'Success',
                        toastHeaderMessage: 'Success',
                        toastBodyMessage: 'Questionnaires have been deleted successfully'
                    }
                    props.toast.setToastProp(toastData)
                    props.toast.setHideToast(true);
                }
                else if (response.status != 200) {
                     props.loader.setIsLoading(false)
                    let toastData = {
                        toastType: 'error',
                        toastHeaderMessage: 'Error',
                        toastBodyMessage: 'API failed'
                    }
                    props.toast.setToastProp(toastData)
                    props.toast.setHideToast(true);

                }
                setEdit(!edit)

            }
            else {
                let tempIndex = tempQuestion.findIndex((quest: any) => quest.questionId === questionIndex)
                if (tempIndex != -1) tempQuestion.splice(tempIndex, 1)
                let deleteIndex = editArr.findIndex((element: any) => element == questionIndex)
                if (deleteIndex != -1) editArr.splice(deleteIndex, 1)
            }
             props.loader.setIsLoading(false)
            setCallDelete(false)
        }
        catch (error) {
             props.loader.setIsLoading(false)
            let toastData = {
                toastType: 'error',
                toastHeaderMessage: 'Error',
                toastBodyMessage: 'API failed'
            }
            props.toast.setToastProp(toastData)
            props.toast.setHideToast(true);
        }
    }




    return (
        <>
            <div className="tab-content" id="nav-tabContent">
                <div className="tab-pane fade show active pt-5" id="nav-storage" role="tabpanel" >
                    <div className="row">
                        {questionBinding()}
                    </div>
                </div>
                <div className="fixed-bottom py-2 mx-3 Admin-footer bg-white">
                    <div className="d-grid d-md-flex justify-content-md-between mx-3 ">
                        <button type="button" className="btn btn-secondary btn-white font-14 font-medium my-2" style={{ visibility: `${disableAddNew ? 'hidden' : 'visible'}` }} onClick={(e: any) => { handleAddQuestion(e) }}><img src="images/plus.svg" alt="add-icon" className="me-2" />Add New Question</button>
                        <div className="button-container">
                            <button type="button" className="btn theme-link-btn font-14 font-medium text-decoration-underline md-me-2 shadow-none my-2" hidden={editArr.length == 0 ? true : false} onClick={() => {
                                setEditArr([])
                                setTempQuestion(responseJson)
                                setEdit(!edit)
                            }}>Cancel</button>
                            <button type="button" className="btn theme-primary-btn font-14 font-medium my-2" hidden={editArr.length == 0 ? true : false} onClick={() => { editQuestion() }}>Save</button>
                        </div>
                    </div>
                </div>
            </div>
            {hidePopup ? <div
                className="modal fade"
                id="generateReport"
                tabIndex={-1}
            >
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content p-3">
                        <div className="modal-header pb-0 border-0">
                            <h5 className="font-semibold font-18 font-semibold d-flex justify-content-between align-items-center">
                                Alert
                            </h5>
                            <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            />
                        </div>
                        <div className="modal-body">
                            <div>
                                <p className="font-medium font-14">
                                    Oopss it seems like you have forget to fill some data
                                </p>
                                <div className="col-md-12 gap-2 d-grid d-md-flex justify-content-center mt-4 ">
                                    <button
                                        type="button"
                                        className="btn btn-dark theme-primary-btn-sm border-0 font-semibold px-4"
                                        onClick={() => {
                                            setHidePopup(false)
                                        }}
                                    >
                                        I'll Check
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> : ""}
           
            {hideDeletePopup ?
                <></>
                :
                <DeletePopup
                    popupType='Delete'
                    popupHeading={popupData.popupHeading}
                    popupMessage={popupData.popupMessage}
                    setHideDeletePopup={setHideDeletePopup}
                    deleteCall={deleteQuestion}
                />}

        </>
    )
}
export default AdminQuestion
