import React, { useEffect, useState } from 'react';
import { getRoles, getMilestone, postMilestone } from '../service/InitiativeApi';
import { milestoneDetailsModel, milestoneValidationModel, toastModel, responseModel, tempModel, deleteRequest } from '../interface/InitiativeModel';
import moment from 'moment';

const MilestoneForm = (props: any) => {


  //PS_MF_05 - PS_MF_13
  //Declaration and initialization of variables
  const milestoneDetails: milestoneDetailsModel =
  {
    organizationId: '',
    verticalId: '',
    milestoneName: '',
    milestoneId: '',
    initiativeId: props.name.InitiativeId ? props.name.InitiativeId : '',
    startDate: '',
    endDate: '',
    plannedHours: '',
    resourceDet: []
  };

  const milestoneValidation: milestoneValidationModel =
  {
    milestoneName: '',
    milestoneNameRegex: '',
    startDate: '',
    endDate: '',
    plannedHours: '',
    role: '',
    resource: '',
    resourceDet: ''
  };

  const milestoneValidationMsg: milestoneValidationModel =
  {
    milestoneName: "Please enter milestone name",
    milestoneNameRegex: "Please enter a valid milestone name",
    startDate: "Please choose the start date",
    endDate: "Please choose the end date",
    plannedHours: "Please enter planned hours",
    role: "Please choose a role",
    resource: "Please enter the resource count",
    resourceDet: "Please choose the resources"
  };

  const toastData: toastModel =
  {
    toastType: '',
    toastHeaderMessage: '',
    toastBodyMessage: ''
  };

  let tempObj: tempModel = {
    roleId: '',
    roleName: '',
    resources: '',
    edit: false
  }


  /**PS_AG_08-PS_AG_11
      * State variable are defined here
      */
  const [hideToast, setHideToast] = useState<boolean>(true);
  const [toastProp, setToastProp] = useState<toastModel>(toastData)
  const [milestoneDet, setMilestoneDet] = useState<milestoneDetailsModel>(milestoneDetails);
  const [tObj, setTobj] = useState<any>(tempObj);
  const [formError, setFormError] = useState<milestoneValidationModel>(milestoneValidation);
  const [formErrorMsg, setFormErrorMsg] = useState<milestoneValidationModel>(milestoneValidationMsg);
  const [roleDropDown, setRoleDropDown] = useState<responseModel[]>([]);
  const[maxDate,setMaxDate] = useState<any>('');
  const[minDate,setMinDate] = useState<any>('');
  const [queryParam, setQueryParam] = useState<string>(props?.name?.milestoneId ? props?.name?.milestoneId : "");
  const [initiativeId, setInitiativeId] = useState<string>(props?.name?.InitiativeId ? props?.name?.InitiativeId : "");
  const [roleArr, setRoleArr] = useState<string[]>([]);
  const [render, setRender] = useState<boolean>(false)
  const charRegex = /[-!$%^&*()_+|~={}\[\]:""'';<>?,\/]/;
  const numberRegex = /^\d+(\.\d{1,2})?$/
  const emojiRegex = /[\u{1F600}-\u{1F64F}\u{1F300}-\u{1F5FF}\u{1F680}-\u{1F6FF}\u{1F700}-\u{1F77F}\u{1F780}-\u{1F7FF}\u{1F800}-\u{1F8FF}\u{1F900}-\u{1F9FF}\u{1FA00}-\u{1FA6F}\u{2600}-\u{26FF}\u{2700}-\u{27BF}]/u;
  const [backupArr,setBackupArr] = useState<string[]>([]); 
  /**PS_MF_14
      * The useEffect function calls the getData function and 
      * render is passed as the dependency 
      */
  useEffect(() => {
    getMilestoneData();
  }, [render]);


  /**PS_MF_15 - PS_MF_34
      * The getData function calls the getRoles api
      * getMilestone API is called when the query param is present
      */
  const getMilestoneData = async () => {
    try {
      
      props.name.setIsLoading(true)
      if(props.name.minDate){
        setMinDate(moment(props.name.minDate).format('YYYY-MM-DD'))
      }
      if(props.name.maxDate){
        setMaxDate(moment(props.name.maxDate).format('YYYY-MM-DD'))
      }
      const roleResponse = await getRoles();
      if (roleResponse.status !== 200) {
        props.name.setIsLoading(false)
        let toastData: toastModel = {
          toastType: 'error',
          toastHeaderMessage: 'Error',
          toastBodyMessage: 'Api Failed'
        }
        setToastProp(toastData)
        setHideToast(false);
      }
      else {
        props.name.setIsLoading(false)
        setRoleDropDown(roleResponse.data);
      }

      if (queryParam) {
        props.name.setIsLoading(true)
        let obj: deleteRequest = {
          milestoneId: queryParam,
          initiativeId: initiativeId
        }
        const milestoneResponse = await getMilestone(obj);
        if (milestoneResponse.status !== 200) {
          props.name.setIsLoading(false)
          let toastData: toastModel = {
            toastType: 'error',
            toastHeaderMessage: 'Error',
            toastBodyMessage: 'Api Failed'
          }
          setToastProp(toastData)
          setHideToast(false);
        }
        else {
          props.name.setIsLoading(false)
          let milestone = milestoneResponse.data.Milestones[0];
          let responseObj: milestoneDetailsModel = {
            milestoneName: milestone.milestoneName,
            milestoneId: milestone.milestoneId,
            initiativeId: milestone.initativeId,
            startDate: moment(milestone.startDate).format('YYYY-MM-DD'),
            endDate: moment(milestone.endDate).format('YYYY-MM-DD'),
            plannedHours: milestone.plannedHours,
            resourceDet: milestone.ResourceDetails
          }
          milestone.ResourceDetails.forEach((resource: any) => {
            roleArr.push(resource.roleId)
          })
          setMilestoneDet(responseObj);
        }
      }
    }
    catch (error) {
      props.name.setIsLoading(false)
      let toastData: toastModel = {
        toastType: 'error',
        toastHeaderMessage: 'Error',
        toastBodyMessage: 'Api Failed'
      }
      setToastProp(toastData)
      setHideToast(false);

    }
  };

  

  /**PS_MF_42 - PS_MF_48
      * The setFormData function sets the value provided by the user in the
      * milestoneDet state variable while onChange 
      */
  const setFormData = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { id, value } = event.target;
    if (id === "resourceDet") {
      setMilestoneDet((prevState: any) => ({
        ...prevState,
        resourceDet: [...prevState.resourceDet, tObj],
      }));
      setTobj(tempObj)
    }
    else if (id === "plannedHours") {
      setMilestoneDet(prevState => ({
        ...prevState,
        [id]: Number(value)
      }));
    }
    else {
      setMilestoneDet(prevState => ({
        ...prevState,
        [id]: value
      }));
    }
  };

  /**PS_MF_35 - PS_MF_40
      * The bindRoleDropDown function binds the role to the drop down
      */
  const bindRoleDropDown = () => {
    return roleDropDown.map((role: any) => {
      return (
        <option hidden={JSON.stringify(milestoneDet.resourceDet).includes(role.roleId) || backupArr.includes(role.roleId) ? true : false} value={role.roleId + "#" + role.roleName} key={role.roleId} id={role.roleName.toString()}>
          {role.roleName}
        </option>
      );
    });
  };

  /**PS_MF_51 - PS_MF_70
      * The formValidation function checks for the validation for all input fields 
      */
  const formValidation = async () => {

    let flag = true;
    if (!milestoneDet.milestoneName) {
      setFormError((previous: any) => ({ ...previous, ['milestoneName']: formErrorMsg.milestoneName }))
      flag = false;
    }
    if (charRegex.test(milestoneDet.milestoneName)) {
      setFormError((previous: any) => ({ ...previous, ['milestoneNameRegex']: formErrorMsg.milestoneNameRegex }))
      flag = false;
    }
    if (milestoneDet.plannedHours === 0 || !numberRegex.test((milestoneDet.plannedHours).toString())) {
      setFormError((previous: any) => ({ ...previous, ['plannedHours']: formErrorMsg.plannedHours }))
      flag = false;
    }
    if (!milestoneDet.startDate) {
      setFormError((previous: any) => ({ ...previous, ['startDate']: formErrorMsg.startDate }))
      flag = false;
    }
    if (!milestoneDet.endDate) {
      setFormError((previous: any) => ({ ...previous, ['endDate']: formErrorMsg.endDate }))
      flag = false;
    }
    if (milestoneDet.resourceDet.length === 0) {
      setFormError((previous: any) => ({ ...previous, ['resourceDet']: formErrorMsg.resourceDet }))
      flag = false;
    }

    if (flag) {
      props.name.setIsLoading(true)
      postFormData()
    }
    return flag;
  }

  /**PS_MF_71 - PS_MF_83
      * The function is used to post the milestone details
      * postMilestone API is called 
      * milestoneDet is passed as the parameter
      */
  const postFormData = async () => {
    milestoneDet.resourceDet.forEach((resource: any) => {
      resource.resources = Number(resource.resources)
      delete resource["index"]
    })
    milestoneDet.organizationId = props?.name?.organizationId
    milestoneDet.verticalId = props?.name?.verticalId
    milestoneDet.initiativeId = milestoneDet.initiativeId || props?.name?.InitiativeId
    const postMilestoneResponse = await postMilestone(milestoneDet)
    if (postMilestoneResponse.status == 200) {
      props.name.setIsLoading(false)
      props.name.setRender((prev: any) => (!prev))
      props.name.setFlag(false)
    }else props.name.setRender((prev: any) => (!prev))
  }



  return (
    <div className="offcanvas offcanvas-end custom-offcanvas show" tabIndex={-1} id="offcanvasRight" style={{ zIndex: `${props.name.isLoading ? 0 : 1051}`, visibility: `${props.flag ? 'visible' : 'hidden'}` }}>
      <div className="offcanvas-header">
        <h5 id="offcanvasRightLabel" className="font-semibold font-20">{queryParam ? "Edit Milestone" : "Add Milestone"}</h5>
        <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close" onClick={() => { props.name.setFlag(false) }} />
      </div>
      <div className="offcanvas-body">
        <div className="row">
          <div className="col-md-12">
            <div className="mb-md-5 mb-3">
              <label className="form-labelmilestoneName font-14 font-semibold color-black mb-2 ">Milestone Name<span className='color-red'>{" "}*</span></label>
              <input type="text" className="form-control theme-form font-regular font-14" id="milestoneName" 
              maxLength={30}
              value={milestoneDet.milestoneName} 
              placeholder="Enter Milestone Name"
                onChange={(e) => {
                  if (!emojiRegex.test(e.target.value)) {
                    charRegex.test(e.target.value) ?
                      setFormError({ ...formError, ['milestoneNameRegex']: formErrorMsg.milestoneNameRegex }) : setFormError({ ...formError, ['milestoneNameRegex']: "" })
                    setFormData(e)
                  }
                }} />
              {(formError.milestoneName && !milestoneDet.milestoneName) ||
                formError.milestoneNameRegex ? <p
                  className="mt-2 mb-0 color-red font-14 font-regular">
                {formError.milestoneNameRegex ?
                  formError.milestoneNameRegex : formError.milestoneName}
              </p> : <p className="mt-2 mb-0 color-red font-14 font-regular"></p>}            </div>
          </div>
          <div className="col-md-6">
            <div className="mb-md-5 mb-3">
              <label htmlFor="startDate" className="form-label font-14 font-semibold color-black">Start Date<span className='color-red'>{" "}*</span></label>
              <input type="date" className="form-control theme-form font-regular font-14" id="startDate" min={minDate} max={maxDate} value={(milestoneDet?.startDate)?.toString()}
                onChange={(e) => {

                  (e.target.value > milestoneDet.endDate) ? setMilestoneDet({ ...milestoneDet, ['endDate']: '' }) : <></>;
                  setFormData(e)
                }}
                onKeyDown={(e) => {
                  e.preventDefault();
                }} />
              {formError.startDate && milestoneDet.startDate === "" ? <p className="mt-2 mb-0 color-red font-14 font-regular">{formError.startDate}</p> : <p className="mt-2 mb-0 color-red font-14 font-regular"></p>}

            </div>
          </div>
          <div className="col-md-6">
            <div className="mb-md-5 mb-3">
              <label htmlFor="endDate" className="form-label font-14 font-semibold color-black">End Date<span className='color-red'>{" "}*</span></label>
              <input type="date" className="form-control theme-form font-regular font-14" id="endDate" min={milestoneDet.startDate ? milestoneDet.startDate : minDate} max={maxDate} value={(milestoneDet?.endDate)?.toString()}
                onChange={(e) => { setFormData(e) }}
                onKeyDown={(e) => {
                  e.preventDefault();
                }} />
              {formError.endDate && milestoneDet.endDate === "" ? <p className="mt-2 mb-0 color-red font-14 font-regular">{formError.endDate}</p> : <p className="mt-2 mb-0 color-red font-14 font-regular"></p>}
            </div>
          </div>

          <div className="col-md-6">
            <div className="mb-md-5 mb-3">
              <label htmlFor="plannedHours" className="form-label font-14 font-semibold color-black">Planned Hours<span className='color-red'>{" "}*</span></label>
              <input type="text" className="form-control theme-form font-regular font-14" id="plannedHours" value={milestoneDet.plannedHours} placeholder="Enter planned hours" onChange={(e) => {
                if (!emojiRegex.test(e.target.value)) {
                  if (!isNaN(Number(e.target.value)) && Number(e.target.value) <= 1000) {
                    setFormData(e)
                  }
                }
              }} />
              {formError.plannedHours && ((milestoneDet.plannedHours) === 0 || !numberRegex.test(milestoneDet.plannedHours.toString())) ? <p className="mt-2 mb-0 color-red font-14 font-regular">{formError.plannedHours}</p> : <p className="mt-2 mb-0 color-red font-14 font-regular"></p>}
            </div>
          </div>



          <h5 className="font-semibold font-20 mb-4">Resource Details<span className='color-red'>{" "}*</span></h5>
          <div className="table-responsive mb-4">
            <table className="table table-bordered themeTable ">
              <thead className="font-semibold ">
                <tr>
                  <th>Role</th>
                  <th># Resources</th>
                  <th className="text-center">Actions</th>
                </tr>
              </thead>
              <tbody className="font-medium">
                <tr>
                  <td>
                    <span>
                      <select className="theme-form form-select font-regular font-14 offcanvas-select" id={tObj.roleName} disabled={tObj.edit ? true : false} value={tObj.roleId + "#" + tObj.roleName} onChange={(e: any) => {
                        setBackupArr([e.target.value.split("#")[0]])
                        setRoleArr(e.target.value.split("#")[0])
                        setTobj((prev: any) => ({ ...prev, ['roleName']: e.target.value.split("#")[1] }))
                        setTobj((prev: any) => ({ ...prev, ['roleId']: e.target.value.split("#")[0] }))
                      }} >
                        <option>Select</option>
                        {bindRoleDropDown()}
                      </select>
                    </span>
                  </td>
                  <td className=" ">
                    <span>
                      <input type="text" className="form-control theme-form font-regular font-14" id="resources" value={tObj.resources} placeholder="# Resources" onChange={(e) => {
                        if (!isNaN(Number(e.target.value)) && Number(e.target.value) <= 1000) {
                          setTobj((prev: any) => ({ ...prev, ['resources']: e.target.value }))
                        }
                      }} />
                    </span>
                  </td>
                  <td className="text-center"><span className="cursor-pointer"><img src="images/plus.svg" alt="plus" title="Add" id="resourceDet" onClick={(e: any) => {
                     if(Number(tObj.resources)>0){
                    setBackupArr([])
                    setTobj((prev: any) => ({ ...prev, ['edit']: false }))
                    tObj.roleName && tObj.resources ? setFormData(e) :
                      <></>}
                  }} />
                  </span></td>
                </tr>
                {milestoneDet?.resourceDet?.map((role, i) => {
                  role.index = i
                  return (

                    <>
                      <tr>
                        <td>
                          {role.roleName}
                        </td>
                        <td className=" ">
                          {role.resources}
                        </td>
                        <td className="text-center"><span style={tObj.edit ? { cursor: 'not-allowed', pointerEvents: 'none' } : { cursor: 'pointer' }} ><img src="images/editIcon.svg" alt={role.roleId} title="Edit" onClick={() => {
                          let tempRole = milestoneDet?.resourceDet[milestoneDet?.resourceDet?.findIndex((role) => role.index == i)]
                          setTobj((prev: any) => ({ ...prev, ['resources']: tempRole.resources }))
                          setTobj((prev: any) => ({ ...prev, ['roleId']: tempRole.roleId }))
                          setTobj((prev: any) => ({ ...prev, ['roleName']: tempRole.roleName }))
                          setTobj((prev: any) => ({ ...prev, ['edit']: true }))
                          milestoneDet.resourceDet.splice(milestoneDet?.resourceDet?.findIndex((role) => role.index == i), 1)
                        }} /></span><span className="ms-3 cursor-pointer"><img src="images/deleteIcon.svg" alt={role.roleId} title="Delete" onClick={(event: any) => {
                         //
                          // let tempId = roleArr.findIndex((role: any) => role == event?.target.alt)
                          // if (tempId != -1) roleArr.splice(tempId, 1)
                          milestoneDet.resourceDet.splice(milestoneDet?.resourceDet?.findIndex((role) => role.index == i), 1);
                          setMilestoneDet({ ...milestoneDet })
                        }} /></span></td>
                      </tr>
                    </>);
                })}
              </tbody>
            </table>
            {formError.resourceDet && milestoneDet.resourceDet.length === 0 ? <p className="mt-2 mb-0 color-red font-14 font-regular">{formError.resourceDet}</p> : <p className="mt-2 mb-0 color-red font-14 font-regular"></p>}

          </div>
          <div className="d-grid gap-2 d-md-inline-flex justify-content-md-end">
            <button type="button" data-bs-dismiss="offcanvas" aria-label="Close" className="btn btn-dark theme-primary-btn border-0 font-14 font-semibold " onClick={() => {
              formValidation()
            }}>Save</button>
            <a className="theme-link-btn cursor-pointer font-14 font-semibold text-center order-md-first" data-bs-dismiss="offcanvas" aria-label="Close" onClick={() => { props.name.setFlag(false) }} >Cancel</a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MilestoneForm;
