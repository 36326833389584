import { useRef, useState, useEffect } from "react";
import ReportHorizontalBarChart from "./ReportHorizontalChart";
import { useLocation } from "react-router-dom";
import ReportTableComponent from "./RecommendationForReport";
import ReportDonutChart from "./ReportDonutChart";
import ReportAppLineChart from "./ReportGraphChart";
import ReportGaugeChart from "./ReportGaugeChart";
import { floatButtonPrefixCls } from "antd/es/float-button/FloatButton";
import ThreeReportTableComponent from "./ReportRecommendation";
import { GaugeChartData } from "../../interface/GenerateReportModel";

export default function CommonReportTempForServiceNow(props: any) {
  const gaugeValue: GaugeChartData[] = [
    { name: "A", value: 33.3, color: "#ff796b" },
    { name: "B", value: 33.4, color: "#ffd772" },
    { name: "C", value: 33.3, color: "#78e07c" },
  ];
  return (
    <div style={{ WebkitPrintColorAdjust: "exact" }}>
    <div style={{ width: "100%" }}>
      <table style={{ width: "100%" }}>
        <tbody>
          <tr>
            <td>
              {/*Header starts here */}
              <div
                style={{
                  pageBreakInside: "avoid",
                  width: "100%",
                  float: "left",
                }}
              >
                <div
                  style={{
                    width: "98%",
                    justifyContent: "center",
                    padding: "2% 1%",
                    backgroundColor: "#F5F5F5",
                  }}
                >
                  <h5
                    style={{
                      color: "#050505",
                      fontFamily: "SFProText-Medium",
                      fontSize: 22,
                      fontWeight: 600,
                      margin: 0,
                      textAlign: "center",
                      wordSpacing: 8,
                    }}
                  >
                    {props?.controalAreName}
                  </h5>
                </div>
                {/*Header  ends here */}
                {/* about score starts here*/}
                <div
                  style={{
                    width: "100%",
                    float: "left",
                    margin: "3% 0%",
                  }}
                >
                  <h2
                    style={{
                      fontFamily: "SFProText-Medium",
                      color: "#000000",
                      fontWeight: 500,
                      fontSize: 17,
                      marginBottom: 16,
                      // marginTop: 20
                    }}
                  >
                    Know about your score
                  </h2>
                  <div
                    style={{
                      width: "30.3%",
                      marginRight: "1%",
                      padding: "2% 1%",
                      float: "left",
                      backgroundColor: "#F0FAEF",
                      borderRadius: 3,
                    }}
                  >
                    <h5
                      style={{
                        color: "#000000",
                        fontFamily: "SFProText-Medium",
                        fontSize: 15,
                        margin: "2% 0%",
                        fontWeight: 400,
                        float: "left",
                      }}
                    >
                      Overall Proficiency Index
                    </h5>
                    <p
                      style={{
                        color: "#000000",
                        fontFamily: "SFProText-Medium",
                        fontSize: 16,
                        fontWeight: 600,
                        margin: "2% 0%",
                        float: "right",
                      }}
                    >
                      {`${props.overallProficiencyIndex}%`}
                    </p>
                  </div>
                  <div
                    style={{
                      width: "30.3%",
                      marginRight: "1%",
                      padding: "2% 1%",
                      float: "left",
                      backgroundColor: "#FFF3E9",
                      borderRadius: 3,
                    }}
                  >
                    <h5
                      style={{
                        color: "#000000",
                        fontFamily: "SFProText-Medium",
                        fontSize: 15,
                        margin: "2% 0%",
                        fontWeight: 400,
                        float: "left",
                      }}
                    >
                      Peer Score
                    </h5>
                    <p
                      style={{
                        color: "#000000",
                        fontFamily: "SFProText-Medium",
                        fontSize: 16,
                        fontWeight: 600,
                        margin: "2% 0%",
                        float: "right",
                      }}
                    >
                      {`${props.peerScore}%`}
                    </p>
                  </div>
                  <div
                    style={{
                      width: "30.3%",
                      marginRight: "1%",
                      padding: "2% 1%",
                      float: "left",
                      backgroundColor: "#EDFAFE",
                      borderRadius: 3,
                    }}
                  >
                    <h5
                      style={{
                        color: "#000000",
                        fontFamily: "SFProText-Medium",
                        fontSize: 15,
                        margin: "2% 0%",
                        fontWeight: 400,
                        float: "left",
                      }}
                    >
                      {props?.controalAreName}
                    </h5>
                    <p
                      style={{
                        color: "#000000",
                        fontFamily: "SFProText-Medium",
                        fontSize: 16,
                        fontWeight: 600,
                        margin: "2% 0%",
                        float: "right",
                      }}
                    >
                      {`${props.controlAreaProficiencyIndexScore}%`}
                    </p>
                  </div>
                </div>
                {/* about score ends here*/}
                <div
                  style={{
                    width: "100%",
                    float: "left",
                    margin: "1% 0%",
                  }}
                >
                  <div
                    style={{
                      width: "47%",
                      float: "left",
                      marginTop: "2%",
                      paddingRight: "3%",
                    }}
                  >
                    <h2
                      style={{
                        fontFamily: "SFProText-Medium",
                        color: "#000000",
                        fontWeight: 500,
                        fontSize: 17,
                        marginBottom: 16,
                      }}
                    >
                      Control areas that influenced your results
                    </h2>
                    <ReportHorizontalBarChart
                      dataNameValue={props?.ReportHorizontalBarChart}
                    ></ReportHorizontalBarChart>
                  </div>
                  <div
                    style={{
                      width: "47%",
                      float: "left",
                      marginTop: "2%",
                      paddingLeft: "3%",
                      textAlign: "center",
                    }}
                  >
                    <h2
                      style={{
                        fontFamily: "SFProText-Medium",
                        color: "#000000",
                        fontWeight: 500,
                        fontSize: 17,
                        marginBottom: 16,
                        textAlign: "left",
                      }}
                    >
                      Recommendations based on Impacts
                    </h2>
                    <ReportDonutChart
                      dataNameValue2={props.ReportDonutChart}
                    ></ReportDonutChart>
                                 <div
                                    style={{
                                      width: "48%",
                                      float: "left",
                                      paddingRight: "2%",
                                      marginTop: "2%",
                                    }}
                                  >
                                    <div style={{ float: "left" , width:"70%", textAlign:"left" }}>
                                      <span
                                        style={{
                                          display: "inline-block",
                                          width: 16,
                                          height: 16,
                                          backgroundColor: "#FFA79D",
                                          borderRadius: 16,
                                          marginRight: 12,
                                        }}
                                      />
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 14,
                                          color: "#000000",
                                        }}
                                      >
                                        High Impact
                                      </label>
                                    </div>
                                    <div style={{ float: "left", width:"30%" }}>
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 16,
                                          fontWeight: 600,
                                          float: "right",
                                        }}
                                      >
                                        {props?.ReportDonutChart[0]?.value}
                                      </label>
                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      width: "48%",
                                      float: "left",
                                      paddingLeft: "2%",
                                      marginTop: "2%",
                                    }}
                                  >
                                    <div style={{ float: "left", width:"70%", textAlign:"left"  }}>
                                      <span
                                        style={{
                                          display: "inline-block",
                                          width: 16,
                                          height: 16,
                                          backgroundColor: "#FCD269",
                                          borderRadius: 16,
                                          marginRight: 12,
                                        }}
                                      />
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 14,
                                          color: "#000000",
                                        }}
                                      >
                                        Medium Impact
                                      </label>
                                    </div>
                                    <div style={{ float: "left", width:"30%" }}>
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 16,
                                          fontWeight: 600,
                                          float: "right",
                                        }}
                                      >
                                        {props?.ReportDonutChart[1]?.value}
                                      </label>
                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      width: "48%",
                                      float: "left",
                                      paddingRight: "2%",
                                      marginTop: "3%",
                                    }}
                                  >
                                    <div style={{ float: "left", width:"70%", textAlign:"left"  }}>
                                      <span
                                        style={{
                                          display: "inline-block",
                                          width: 16,
                                          height: 16,
                                          backgroundColor: "#9FF5A2",
                                          borderRadius: 16,
                                          marginRight: 12,
                                        }}
                                      />
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 14,
                                          color: "#000000",
                                        }}
                                      >
                                        Low Impact
                                      </label>
                                    </div>
                                    <div style={{ float: "left", width:"30%" }}>
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 16,
                                          fontWeight: 600,
                                          float: "right",
                                        }}
                                      >
                                        {props?.ReportDonutChart[3]?.value}
                                      </label>
                                    </div>
                                  </div>
                  </div>
                </div>
                {/* Code Metrics and Analysis starts here*/}
                {/* Code Metrics and Analysis ends here*/}
                {/* Based on your score starts here*/}
                <div
                                style={{
                                  width: "100%",
                                  float: "left",
                                  margin: "0% 0%",
                                  pageBreakInside: "avoid",
                                  overflow: "auto", // Adding overflow to handle content overflow
                                }}
                              >
                                <h2
                                  style={{
                                    fontFamily: "SFProText-Medium",
                                    color: "#000000",
                                    fontWeight: 500,
                                    fontSize: 17,
                                    marginBottom: 16,
                                    marginTop: 25,
                                  }}
                                >
                                  Based on your Score
                                </h2>
                                <div style={{ width: "100%" }}>
                                  <div
                                    style={{
                                      float: "left",
                                      marginTop: "2%",
                                      textAlign: "center",
                                      margin: "0% 2% 2% 0%",
                                    }}
                                  >
                                    <ReportGaugeChart
                                      dataValue={gaugeValue}
                                      Gaugeval={props.controlAreaProficiencyIndexScore}
                                    ></ReportGaugeChart>
                                  </div>
                                  <div>
                                    {" "}
                                    {/* Adjusted marginTop */}
                                    <ul
                                      style={{ listStyle: "none", padding: 0 }}
                                    >
                                      <li
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 14,
                                          color: "#000000",
                                          marginBottom: 20,
                                          marginTop: 20, // Adjusted marginTop here too
                                          lineHeight: "1.5",
                                        }}
                                      >
                                        {props.overallRecommendations}
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                {/* Based on your score ends here*/}
                {/* Recommendation table starts here */}
                <ThreeReportTableComponent
                  dataForGrid={
                    props?.threeRecomendations
                  }
                  areas={false}
                />
                {/* Recommendation table ends here */}
                {/* Recommendation table starts here */}
                {/* Recommendation table ends here */}
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      {/* footer style starts here */}
      {/* <div style="width: 100%; float: left; position: fixed; bottom: 0px; background-color: white; " class="body-25vh">
<img src="images/footer-design.png" alt="footer design" style="height: 32px; width: 100%; margin-bottom: 10px;">
<div style="text-align: center;">
<img src="images/zeb-logo.svg" alt="zeb-logo" style="width: 88px; height: 32px;">
</div>
</div> */}
      {/* footer style ends here */}
    </div>
  </div>
  );
}
