import { useState, useEffect, useCallback } from "react";
import { postCostBreakdownData } from "../service/ReportApi";
import Loader from "./Loader";
import {
  PricingModel,
  PaymentOption,
  PayloadDetail,
} from "../interface/CostBreakdownModel";

//PS_CB_01 - PS_CB_23
const CostBreakdown = (props: any) => {
  const [sampleData, setSampleData] = useState<PricingModel[]>([]);
  const [activeTab, setActiveTab] = useState<string>("On Demand");
  const [editingRow, setEditingRow] = useState<{
    pricingModel: string;
    paymentOption: string;
    category: string;
  } | null>(null);
  const [tempEdits, setTempEdits] = useState<{
    [key: string]: { [duration: string]: string };
  }>({});
  const [hideSuccessToast, setHideSuccessToast] = useState<boolean>(true);
  const [hideFailedToast, setHideFailedToast] = useState<boolean>(true);
  const [showToasterLoading, setShowToasterLoading] = useState<boolean>(false);
  const [loader, setLoader] = useState<boolean>(false);

  const verticalName = props.verticalName || "";
  const order = [
    "On Demand",
    "Pay as you Go",
    "Saving Plans",
    "Reservation",
    "Reserved Instance",
    "Reserved Instances",
  ];
  const sortPricingModels = (a: PricingModel, b: PricingModel) => {
    return order.indexOf(a.pricingModel) - order.indexOf(b.pricingModel);
  };

  const sortPaymentOptions = (a: PaymentOption, b: PaymentOption) => {
    const order = ["Upfront", "No Upfront", "Partial Upfront"];
    return order.indexOf(a.paymentOption) - order.indexOf(b.paymentOption);
  };
  const VMwarecategories = ["Compute", "Network & Monitoring"];
  const MapCategories = ["Compute", "Storage", "Network and Monitoring"];
  const categoriesToUse = verticalName === "VMware" ? VMwarecategories : MapCategories;
  
  //PS_CB_14
  useEffect(() => {
    setSampleData(props?.costBreakdownDetails || []);
  }, [props?.costBreakdownDetails]);

  //PS_CB_15
  useEffect(() => {
    const sortedData = [...props.costBreakdownDetails].sort(sortPricingModels);

    // Set the sorted data
    setSampleData(sortedData);
    // Set the active tab to the first available pricing model in the order
    const firstAvailableTab = order.find((model) =>
      sortedData.some((item) => item.pricingModel === model)
    );
    if (firstAvailableTab) {
      setActiveTab(firstAvailableTab);
    }
  }, []);

  //PS_CB_16
  const handleEdit = (
    pricingModel: string,
    paymentOption: string,
    category: string
  ) => {
    // If there's a different row being edited, discard those changes
    if (
      editingRow &&
      (editingRow.pricingModel !== pricingModel ||
        editingRow.paymentOption !== paymentOption ||
        editingRow.category !== category)
    ) {
      // Discard changes by resetting tempEdits for the previous row
      setTempEdits((prev) => {
        const newEdits = { ...prev };
        delete newEdits[
          `${editingRow.pricingModel}-${editingRow.paymentOption}-${editingRow.category}`
        ];
        return newEdits;
      });
    }

    // Toggle edit mode for the clicked row
    if (
      editingRow &&
      editingRow.pricingModel === pricingModel &&
      editingRow.paymentOption === paymentOption &&
      editingRow.category === category
    ) {
      setEditingRow(null);
    } else {
      setEditingRow({ pricingModel, paymentOption, category });
    }
  };

  //PS_CB_17
  const handleChange = (
    pricingModel: string,
    paymentOption: string,
    category: string,
    duration: string,
    value: string
  ) => {
    if (value === "" || /^\d*\.?\d*$/.test(value)) {
      // let newVlaue = "0";
      setTempEdits((prev) => ({
        ...prev,
        [`${pricingModel}-${paymentOption}-${category}`]: {
          ...(prev[`${pricingModel}-${paymentOption}-${category}`] || {}),
          [duration]: value,
        },
      }));
    }
  };

  //PS_CB_18
  const handleSave = useCallback(() => {
    if (!editingRow) return;

    const { pricingModel, paymentOption, category } = editingRow;
    const editKey = `${pricingModel}-${paymentOption}-${category}`;
    const edits = tempEdits[editKey];

    if (!edits) return;

    setSampleData((prevData) => {
      const newData = [...prevData];
      let updated = false;

      Object.entries(edits).forEach(([duration, cost]) => {
        const costValue = cost === "" ? "0" : cost;

        // Find or create the PricingModel
        let pricingModelIndex = newData.findIndex(
          (pm) => pm.pricingModel === pricingModel
        );
        if (pricingModelIndex === -1) {
          updated = true;
          pricingModelIndex =
            newData.push({
              pricingModel,
              paymentOptions: [],
            }) - 1;
        }

        // Find or create the PaymentOption
        let paymentOptionIndex = newData[
          pricingModelIndex
        ].paymentOptions.findIndex((po) => po.paymentOption === paymentOption);
        if (paymentOptionIndex === -1) {
          updated = true;
          paymentOptionIndex =
            newData[pricingModelIndex].paymentOptions.push({
              paymentOption,
              categoryDetails: [],
            }) - 1;
        }

        // Find or create the CategoryDetail within the existing Duration
        const existingDetailIndex = newData[pricingModelIndex].paymentOptions[
          paymentOptionIndex
        ].categoryDetails.findIndex(
          (cd) => cd.duration === duration && cd.category === category
        );
        if (existingDetailIndex > -1) {
          // Update existing Cost
          newData[pricingModelIndex].paymentOptions[
            paymentOptionIndex
          ].categoryDetails[existingDetailIndex].cost = costValue;
        } else {
          // Create new CategoryDetail entry
          updated = true;
          newData[pricingModelIndex].paymentOptions[
            paymentOptionIndex
          ].categoryDetails.push({
            category,
            duration,
            cost: costValue,
          });
        }
      });

      // Save to parent's state
      if (updated && props?.setCostBreakdownDetails) {
        props.setCostBreakdownDetails(newData);
      }

      // Prepare data for API call
      // ...
      const details: PayloadDetail[] = Object.entries(edits).map(
        ([duration, cost]) => ({
          organizationId: props?.initialReportData?.organizationId || "",
          verticalId: props?.initialReportData?.verticalId || "",
          projectId: props?.initialReportData?.projectId || "",
          strategyId:
            props?.initialReportData?.vmWareRecommendations?.strategyId || "",
          pricingModel,
          paymentOption,
          category,
          duration,
          cost: cost === "" ? "0" : cost,
        })
      );
      // Call API to update data
      projectReportData({ details });

      // Only if there was an update, return the new data for sampleData state
      if (updated) {
        return newData;
      } else {
        return prevData;
      }
    });

    // Clean up
    setEditingRow(null);
    setTempEdits((prevEdits) => {
      const newEdits = { ...prevEdits };
      delete newEdits[editKey];
      return newEdits;
    });
  }, [
    editingRow,
    tempEdits,
    props?.initialReportData,
    projectReportData,
    props?.setCostBreakdownDetails,
  ]);

  //PS_CB_19
  const handleCancel = () => {
    if (editingRow) {
      const { pricingModel, paymentOption, category } = editingRow;
      const editKey = `${pricingModel}-${paymentOption}-${category}`;

      // Remove the temporary edits for this row
      setTempEdits((prev) => {
        const newEdits = { ...prev };
        delete newEdits[editKey];
        return newEdits;
      });

      // Exit edit mode
      setEditingRow(null);
    }
  };

  //PS_CB_20
  const renderTable = (pricingModel: PricingModel) => {
    return pricingModel.paymentOptions
      .sort(sortPaymentOptions)
      .map((paymentOption) => {
        const durationsSet = new Set(
          paymentOption.categoryDetails.map((detail) => detail.duration)
        );
        const durations = Array.from(durationsSet).sort((a, b) => {
          const aYear = parseInt(a.split(" ")[0], 10);
          const bYear = parseInt(b.split(" ")[0], 10);
          return isNaN(aYear) || isNaN(bYear) ? 0 : aYear - bYear;
        });

        let categories = categoriesToUse;

        const getCategoryDetail = (category: string, duration: string) => {
          return (
            paymentOption.categoryDetails.find(
              (detail) =>
                detail.category === category && detail.duration === duration
            ) || { category, duration, cost: "0.00" }
          );
        };

        const calculateTotal = (duration: string) => {
          return categories.reduce((total, category) => {
            const detail = getCategoryDetail(category, duration);
            return total + parseFloat(detail.cost || "0.00");
          }, 0);
        };

        return (
          <div key={paymentOption.paymentOption}>
            <h3 className="mt-4 font-20 mb-3 font-semibold">
              {pricingModel.pricingModel} {paymentOption.paymentOption ? "- " + paymentOption.paymentOption : ""}
            </h3>
            <div className="table-responsive mb-4 mt-2">
              <table className="table table-bordered themeTable alter-typo custom-table">
                <thead className="font-medium">
                  <tr>
                    <th className="fw-unset">Category</th>
                    {durations.map((duration) => (
                      <th key={duration} className="fw-unset text-end">
                        {duration}
                      </th>
                    ))}
                    <th className="fw-unset text-center">Actions</th>
                  </tr>
                </thead>
                <tbody className="font-regular">
                  {categories.map((category) => (
                    <tr key={category}>
                      <td>{category}</td>
                      {durations.map((duration) => {
                        const detail = getCategoryDetail(category, duration);
                        const isEditing =
                          editingRow?.pricingModel ===
                          pricingModel.pricingModel &&
                          editingRow?.paymentOption ===
                          paymentOption.paymentOption &&
                          editingRow?.category === category;
                        const editValue =
                          tempEdits[
                          `${pricingModel.pricingModel}-${paymentOption.paymentOption}-${category}`
                          ]?.[duration];
                        const displayValue =
                          editValue !== undefined ? editValue : detail.cost;
                        return (
                          <td key={duration} className="text-end">
                            {isEditing ? (
                              <div className="currency-input-container">
                                <div className="currency-symbol">$</div>
                                <input
                                  type="text"
                                  placeholder="Amount"
                                  className="custom-form-control"
                                  // maxLength={10}
                                  // pattern="[0-9]{10}"
                                  maxLength={13} // 10 digits + decimal point + 2 decimal places
                                  pattern="^\d{1,10}(\.\d{0,2})?$"
                                  value={displayValue}
                                  onChange={(e) =>
                                    handleChange(
                                      pricingModel.pricingModel,
                                      paymentOption.paymentOption,
                                      category,
                                      duration,
                                      e.target.value
                                    )
                                  }
                                />
                              </div>
                            ) : (
                              // `$ ${parseFloat(displayValue).toFixed(2)}`

                              `$${parseFloat(displayValue).toLocaleString(
                                "en-US",
                                {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                }
                              )}`
                            )}
                          </td>
                        );
                      })}
                      <td className="text-center">
                        <span
                          className="cursor-pointer"
                          onClick={() =>
                            handleEdit(
                              pricingModel.pricingModel,
                              paymentOption.paymentOption,
                              category
                            )
                          }
                        >
                          {editingRow?.pricingModel ===
                            pricingModel.pricingModel &&
                            editingRow?.paymentOption ===
                            paymentOption.paymentOption &&
                            editingRow?.category === category ? (
                            <div>
                              <span
                                className="cursor-pointer"
                                onClick={handleSave}
                              >
                                <img
                                  src="images/check.svg"
                                  alt="editIcon"
                                  title="Edit"
                                  className="w-20"
                                />
                              </span>
                              <span
                                className="cursor-pointer"
                                onClick={handleCancel}
                              >
                                <img
                                  src="images/x.svg"
                                  alt="editIcon"
                                  title="Edit"
                                  className="ww-25"
                                />
                              </span>
                            </div>
                          ) : (
                            <img
                              src="images/editIcon.svg"
                              alt="editIcon"
                              title="Edit"
                            />
                          )}
                        </span>
                      </td>
                    </tr>
                  ))}
                  <tr>
                    <td>Total</td>
                    {durations.map((duration) => (
                      <td key={duration} className="text-end font-semibold">
                        {/* $ {calculateTotal(duration).toFixed(2)} */}$
                        {calculateTotal(duration).toLocaleString("en-US", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                      </td>
                    ))}
                    <td></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        );
      });
  };

  //PS_CB_21
  const renderContent = () => {
    const activePricingModel = sampleData.find(
      (model) => model.pricingModel === activeTab
    );
    if (!activePricingModel) return null;

    if (
      (activeTab === "On Demand" || activeTab === "Pay as you Go") &&
      verticalName === "VMware"
    ) {
      const onDemandCost =
        activePricingModel.paymentOptions[0].categoryDetails[0].cost;
      return (
        <div className="payment-section mt-4 text-center">
          <p className="font-32 font-bold mb-0">
            {/* $ {parseFloat(onDemandCost).toFixed(2)} */}$
            {parseFloat(onDemandCost).toLocaleString("en-US", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </p>
          <p className="font-14 mt-1 mb-0 color-grey-v8 font-medium">
            Pay as you Go
          </p>
        </div>
      );
    } else {
      return renderTable(activePricingModel);
    }
  };

  //PS_CB_22
  async function projectReportData(body: any) {
    try {
      setShowToasterLoading(true);
      let response = await postCostBreakdownData(body);

      if (response.status == 200) {
        setShowToasterLoading(false);
        setHideSuccessToast(false);
        setTimeout(() => {
          setHideSuccessToast(true);
        }, 4000);
      } else {
        setShowToasterLoading(false);
        setHideFailedToast(false);
        setTimeout(() => {
          setHideFailedToast(true);
        }, 4000);
      }
    } catch (error) {
      setShowToasterLoading(false);
      setHideFailedToast(false);
      setTimeout(() => {
        setHideFailedToast(true);
      }, 4000);
    } finally {
      setLoader(false);
    }
  }

  return (
    <div
      className="tab-pane fade show active"
      id="MigrationStrategystab"
      role="tabpanel"
    >
      <h5 className="font-18 font-semibold color-black">
        Cost Breakdown - {verticalName === "MAP" ? "AWS Cost" : props?.Solution}
      </h5>
      <div className="col-lg-12 col-xl-12 col-md-12 col-12 my-4">
        <ul className="nav nav-tabs cost-nav-tabs" id="myTab" role="tablist">
          {sampleData.sort(sortPricingModels).map((model, index) => (
            <li className="nav-item" role="presentation" key={index}>
              <button
                className={`cost-nav nav-link ${activeTab === model.pricingModel ? "active" : ""
                  }`}
                onClick={() => setActiveTab(model.pricingModel)}
                type="button"
                role="tab"
                aria-selected={activeTab === model.pricingModel}
              >
                {model.pricingModel}
              </button>
            </li>
          ))}
        </ul>
        <div className="tab-content" id="myTabContent">
          <div className="tab-pane fade show active" role="tabpanel">
            {renderContent()}
          </div>
        </div>
      </div>

      {loader && <Loader />}

      {showToasterLoading && (
        <div className="position-fixed bg-white top-110px start-50 translate-middle w-md-100">
          <div className="shadow-sm d-flex rounded-3 custom-toast p-3">
            <div className="toast-body d-flex align-items-center ">
              <div className="d-block me-3">
                <img
                  src="images/loading.gif"
                  alt="toast-success"
                  style={{ height: "15px", width: "15px" }}
                />
              </div>
              <div className="d-block">
                <span className="font-medium font-16 color-grey">
                  Saving...
                </span>
              </div>
            </div>
          </div>
        </div>
      )}

      {!hideSuccessToast && (
        <div className="position-fixed bg-white top-110px start-50 translate-middle w-md-100">
          <div className="shadow-sm d-flex rounded-3 custom-toast p-3">
            <div className="toast-body d-flex align-items-center ">
              <div className="d-block me-3">
                <img src="images/toast-success.svg" alt="toast-success" />
              </div>
              <div className="d-block">
                <span className="font-medium font-16 color-grey">
                  Saved successfully
                </span>
              </div>
            </div>
          </div>
        </div>
      )}

      {!hideFailedToast && (
        <div className="position-fixed bg-white top-110px start-50 translate-middle w-md-100">
          <div className="shadow-sm d-flex rounded-3 custom-toast p-3">
            <div className="toast-body d-flex align-items-center ">
              <div className="d-block me-3">
                <img src="images/fail-icon.svg" alt="toast-fail" />
              </div>
              <div className="d-block">
                <span className="font-medium font-16 color-grey">
                  Api failed.
                </span>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CostBreakdown;
