import { useEffect, useRef, useState } from "react";
import {
  uploadDataModel,
  uploadDataPostModel,
} from "../../interface/questionnaireInterface";
import { getSasToken } from "../../service/ReportApi";
import { read, utils } from "xlsx";
import { encryptStorage, fileNameSlice } from "../../constant/constant";
import { jwtDecode } from "jwt-decode";
import { getConnectorsData } from "../../helpers/encryption";

export default function DataStrategyConnector(props: any) {
  const {
    activeConnector,
    validateConnectorInput,
    setShowConnectorForm,
    connectorFormData,
    setExcelTemplateData,
  } = props;

  const [fileName, setFileName] = useState<string>("");
  const [file, setFile] = useState<any>();
  const [validationMessage, setValidationMessage] = useState<string>("");
  const validationHeadings = [
    "Control Area",
    "Sub Control Area",
    "Data Platform",
    "Metric Name",
    "Recommendation",
    "Impact",
  ];
  const attachment = useRef(null);

  useEffect(() => {
    if (connectorFormData["template"]) {
      let filename = fileNameSlice(connectorFormData["template"]) || "";
      setFileName(filename);
    }
  }, []);

  /**PS_AN_54
   * This function is used to the read the files uploaded by the user
   * event is passed as the parameter
   */

  const readFile = async (event: any) => {
    try {
      attachment.current = null;
      const file = event.target.files?.[0];
      setFile(file);
      let fileName = event.target.files?.[0].name;
      if (file) {
        const typeArr = [
          "vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        ];
        let type = typeArr.includes(file.type.split("/")[1]);
        if (type) {
          setValidationMessage("");
          let reader = new FileReader();
          reader.onload = async (e) => {
            const wb = read(e?.target?.result);
            const sheets = wb.SheetNames;
            const rows: any = utils.sheet_to_json(wb.Sheets[sheets[0]]);
            if (rows.length >= 1) {
              let columnTitle = utils.sheet_to_json(wb.Sheets[sheets[0]], {
                header: 1,
              })[0];
              if (
                JSON.stringify(validationHeadings) ==
                JSON.stringify(columnTitle)
              ) {
                setFileName(fileName);
                let tempArr: any = [];
                rows.forEach((data: uploadDataModel) => {
                  let tempDataObj: uploadDataPostModel = {
                    ControlArea: data["Control Area"],
                    SubControlArea: data["Sub Control Area"],
                    DataPlatform: data["Data Platform"],
                    MetricName: data["Metric Name"],
                    Recommendation: data["Recommendation"],
                    Impact: data["Impact"],
                  };
                  tempArr.push(tempDataObj);
                });
                tempArr.forEach((data: uploadDataModel, index: number) => {
                  if (
                    data["Metric Name"] ==
                    tempArr[index + 1]?.["Metric Name"] &&
                    data.Recommendation == tempArr[index + 1]?.Recommendation
                  ) {
                    tempArr.splice(index + 1, 1);
                  }
                });
                setExcelTemplateData(tempArr);
              } else {
                setFileName("");
                setValidationMessage(
                  "Please upload the file with valid column and data"
                );
              }
            } else {
              setFileName("");
              setValidationMessage("Please upload the file with valid data");
            }
          };
          reader.readAsArrayBuffer(file);
        } else {
          setFileName("");
          setValidationMessage("Please upload a XLSX file");
        }
      }
    } catch (error) {
      // console.log(error);
    }
  };

  /**PS_AN_54
   * This function is used to the remove the files uploaded by the user
   */
  const removeFile = () => {
    setFileName("");
    setValidationMessage("");
    attachment.current = null;
  };

  /**PS_AN_63-PS_AN_68
   * This function is used to download the template file uploaded in the blob
   */
  const downloadTemplate = async () => {
    try {
      let value = encryptStorage.getItem("jwt")
      let parsedJtk: any = ""
      let storageAccountName: any, containerName: any;
      if (value) {
        parsedJtk = jwtDecode(value)
        storageAccountName = getConnectorsData({ key: parsedJtk.ek, encryptedMessage: process.env.REACT_APP_BLOB_ACCOUNT_NAME! });
        containerName = getConnectorsData({ key: parsedJtk.ek, encryptedMessage: process.env.REACT_APP_BLOB_CONTAINER! })
      }
      const sas = await getSasToken("r");
      window.open(`https://${storageAccountName}.blob.core.windows.net/${containerName}/Templates/Solarwinds Orion-Template.xlsx` + `?${sas.data}`);
    } catch (error) {
      console.error("Error occurred while downloading the template.", error);
    }
  };

  const bindConnectorsForm = () => {
    const bindSaveAndCancel = () => {
      return (
        <div className="d-grid gap-2 d-md-inline-flex justify-content-md-end mb-5 w-100">
          <button
            type="button"
            className="btn btn-dark theme-primary-btn border-0 font-14 font-semibold "
            disabled={!fileName ? true : false}
            onClick={async () => {
              // uploadTemplate()
              validateConnectorInput(file);
            }}
          >
            Save
          </button>
          <a
            className="theme-link-btn cursor-pointer  font-semibold text-center order-md-first"
            onClick={() => {
              removeFile();
              setShowConnectorForm(true);
            }}
          >
            Cancel
          </a>
        </div>
      );
    };
    switch (activeConnector) {
      case "Solarwinds Orion":
        return (
          <>
            <div className="col-lg-8 col-md-12 col-12">
              <p className="font-24 font-bold mt-3">
                Solarwinds Configurations
              </p>
              <p className="font-14 font-bold color-black mb-4">Step 1</p>
              <div className="d-flex mb-3">
                <img
                  src="images/download.svg"
                  alt="download"
                  title="download"
                  className="me-2 cursor-pointer"
                />
                <span
                  className="link-blue font-12 font-semibold link-blue cursor-pointer"
                  onClick={() => {
                    downloadTemplate();
                  }}
                >
                  Download Template
                </span>
              </div>
              <p className="form-label font-14 font-regular color-black  mb-4">
                Download the template
              </p>
              <p className="font-14 font-bold color-black mb-4">Step 2</p>
              <p className="form-label font-14 font-regular color-black  mb-4">
                Run the Installer to install the On-Premise Poller by using the
                following device key in the installation wizard.
              </p>
              <p className="font-14 font-bold color-black mb-3">Step 3</p>
              <div className="mb-3 d-md-flex align-items-center">
                <span className="upload-btn-wrapper me-2 d-block ">
                  <button
                    type="button"
                    className="font-14 link-blue font-medium bg-white border-0 shadow-none cursor-pointer"
                  >
                    <img
                      src="images/upload.svg"
                      alt="browse"
                      className="me-2 cursor-pointer"
                    />
                    Upload Template
                  </button>
                  <input
                    type="file"
                    name="myfile"
                    className="cursor-pointer"
                    onChange={(event: any) => {
                      readFile(event);
                    }}
                  />
                </span>
              </div>
              <div>
                {fileName ? (
                  <span className="font-14 font-medium color-grey mt-1 ms-2  d-block ">
                    {fileName}
                    <span className="cursor-pointer ms-3" title="Cancel">
                      <img
                        src="images/upload-close.svg"
                        alt="upload-close"
                        onClick={() => {
                          removeFile();
                        }}
                      />
                    </span>
                  </span>
                ) : (
                  ""
                )}
              </div>
              <div>
                <p className="font-14 font-regular color-red">
                  {validationMessage ? validationMessage : ""}
                </p>
              </div>
              {bindSaveAndCancel()}
            </div>
          </>
        );
    }
  };

  return <>{bindConnectorsForm()}</>;
}
