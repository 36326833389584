import React, { useEffect, useState, useRef } from "react";
import {
  QuestionniareOptionModel,
  QuestionniareQuestionModel,
  questionnaireModel,
  userAnsweredModel
} from "../interface/questionnaireInterface";

export default function Questionnaire(props: any) {
  const {
    questionsData,
    controlAreaSubMenuClick,
    controlAreaSelectedId,
    controlAreaSubMenuSelectedId,
    notAssessedSubMenus,
    setMainQuestions,
    saveQuestionnaire,
  } = props;


  const [questionnaireData, setQuestionnaireData] = useState<questionnaireModel[]>([]);
  const [displayQuestions, setDisplayQuestions] = useState<QuestionniareQuestionModel[]>([]);
  const [notAssessedControlAreaSubMenus, setNotAssessedControlAreaSubMenus] = useState<string[]>(notAssessedSubMenus);

  const ref: any = useRef();
  const nextRefs: any = useRef([]);
  const questionnaireRef: any = useRef([]);
  const [ds, setds] = useState(false);

  useEffect(() => {
    setQuestionnaireData(questionsData);
    initializeDisplayQuestions();
  }, [questionsData]);

  useEffect(() => {
    setNotAssessedControlAreaSubMenus(notAssessedSubMenus);
  }, [notAssessedSubMenus]);

  useEffect(() => {
    if (nextRefs?.current?.length) {
      let offset = Number(ref?.current?.offsetWidth);
      let newOffset = 0;
      for (let i = 0; i < nextRefs?.current?.length; i++) {
        if (nextRefs?.current[i] !== null)
          newOffset += nextRefs?.current[i]?.offsetWidth;
      }
      if (offset - newOffset < -5) setds(true);
      else setds(false);
    }
  }, [displayQuestions]);

  const initializeDisplayQuestions = () => {
    // Filter questions based on selected control area and sub-menu
    const allQuestions = questionsData
      ?.find((qa: questionnaireModel) => qa.controlAreaId === controlAreaSelectedId)
      ?.controlAreaValue
      .find((ca: any) => ca.controlAreaSubMenuId === controlAreaSubMenuSelectedId)
      ?.controlAreaSubMenuValues || [];

    const branchingIds = new Set(
      allQuestions?.flatMap((q: any) => q.options?.flatMap((opt: any) => opt.branchId))
    );

    let initialQuestions = allQuestions?.filter((q: any) => !branchingIds?.has(q.questionId));

    // Set to track added question IDs
    const addedQuestionIds = new Set(initialQuestions.map((q: any) => q.questionId));

    // Function to get branching questions
    const getBranchingQuestions = (questions: any[]) => {
      const branchIds = questions?.flatMap(q =>
        q.userAnswered?.flatMap((answer: any) =>
          q.options?.find((opt: any) => opt?.optionId === answer?.optionId)?.branchId || []
        )
      );

      return allQuestions?.filter((q: any) => branchIds?.includes(q.questionId) && !addedQuestionIds.has(q.questionId));
    };

    // Add branching questions based on initial answers
    let branchingQuestions = getBranchingQuestions(initialQuestions);
    while (branchingQuestions.length > 0) {
      // initialQuestions = [...initialQuestions, ...branchingQuestions];
      // branchingQuestions = getBranchingQuestions(branchingQuestions);
      // Add new branching questions to the initial questions
      initialQuestions.push(...branchingQuestions);

      // Update the set of added question IDs
      branchingQuestions.forEach((q: any) => addedQuestionIds.add(q.questionId));

      // Get the next set of branching questions based on newly added ones
      branchingQuestions = getBranchingQuestions(branchingQuestions);
    }

    // Sort questions: answered first, then unanswered
    // const sortedQuestions = [
    //   ...initialQuestions?.filter((q: any) => q.userAnswered && q.userAnswered.length > 0),
    //   ...initialQuestions?.filter((q: any) => !q.userAnswered || q.userAnswered.length === 0)
    // ];

    setDisplayQuestions(initialQuestions);
  };

  const handleClear = () => {
    setDisplayQuestions(prevQuestions => {
      const clearedQuestions = prevQuestions.map(question => ({
        ...question,
        userAnswered: []
      }));

      // Filter out branching questions
      const nonBranchingQuestions = clearedQuestions?.filter(question => {
        const isNotBranching = !prevQuestions?.some(q =>
          q.options?.some(opt => opt.branchId?.includes(question.questionId))
        );
        return isNotBranching;
      });

      // Sort questions to ensure consistent order
      const sortedQuestions = nonBranchingQuestions?.sort((a, b) => Number(a.questionId) - Number(b.questionId));

      setMainQuestions(sortedQuestions);
      return sortedQuestions;
    });
  };


  const handleOptionSelect = (
    questionId: string,
    option: string,
    selectedOptionId: number,
    branchId: string[],
    branchType: string,
    score: number,
    isChecked: boolean
  ) => {
    const allQuestions = questionnaireData.flatMap((qa: questionnaireModel) =>
      qa.controlAreaValue.flatMap((ca: any) => ca.controlAreaSubMenuValues)
    );

    setDisplayQuestions((prevQuestions) => {
      let updatedQuestions = [...prevQuestions];
      const questionIndex = updatedQuestions.findIndex(
        (q) => q.questionId === questionId
      );
      if (questionIndex === -1) return updatedQuestions;

      const currentQuestion = updatedQuestions[questionIndex];
      const previousAnswers = [...currentQuestion.userAnswered];

      // Check if the selected option is "None of these"
      const isNoneOfThese = option.toLowerCase() === "none of these";

      // Update the current question's answer
      if (currentQuestion.type === "checkbox") {
        if (isNoneOfThese) {
          // If "None of these" is selected, clear all other selections
          currentQuestion.userAnswered = isChecked
            ? [
              {
                optionId: selectedOptionId,
                optionScore: score,
                branchId: branchId,
                branchType: branchType,
              },
            ]
            : [];
        } else {
          // For other options, handle as before
          if (isChecked) {
            // Remove "None of these" if it was previously selected
            currentQuestion.userAnswered = currentQuestion.userAnswered.filter(
              (answer) =>
                currentQuestion.options
                  .find((opt) => opt.optionId === answer.optionId)
                  ?.option.toLowerCase() !== "none of these"
            );
            currentQuestion.userAnswered.push({
              optionId: selectedOptionId,
              optionScore: score,
              branchId: branchId,
              branchType: branchType,
            });
          } else {
            currentQuestion.userAnswered = currentQuestion.userAnswered.filter(
              (answer) => answer.optionId !== selectedOptionId
            );
          }
        }
      } else {
        // For radio buttons, always set to the selected option
        currentQuestion.userAnswered = [
          {
            optionId: selectedOptionId,
            optionScore: score,
            branchId: branchId,
            branchType: branchType,
          },
        ];
      }

      // Helper function to get all branch IDs for given answers
      const getBranchIds = (answers: userAnsweredModel[]) => {
        return answers.flatMap(
          (answer) =>
            currentQuestion.options.find(
              (opt) => opt.optionId === answer.optionId
            )?.branchId || []
        );
      };

      const previousBranchIds = getBranchIds(previousAnswers);
      const currentBranchIds = getBranchIds(currentQuestion.userAnswered);

      // Determine which branch IDs to remove and which to add
      const branchIdsToRemove = previousBranchIds.filter(
        (id) => !currentBranchIds.includes(id)
      );
      const branchIdsToAdd = currentBranchIds.filter(
        (id) => !previousBranchIds.includes(id)
      );

      // Helper function to recursively remove branching questions
      const removeBranchingQuestions = (questionIds: string[]) => {
        const branchingQuestions = updatedQuestions.filter((q) =>
          questionIds.includes(q.questionId)
        );
        updatedQuestions = updatedQuestions.filter(
          (q) => !questionIds.includes(q.questionId)
        );

        const nestedBranchIds = branchingQuestions.flatMap((q) =>
          q.options.flatMap((opt) => opt.branchId)
        );

        if (nestedBranchIds.length > 0) {
          removeBranchingQuestions(nestedBranchIds);
        }
      };

      // Remove branching questions that are no longer relevant
      removeBranchingQuestions(branchIdsToRemove);

      // Add new branching questions
      const addBranchingQuestions = (
        parentIndex: number,
        branchIds: string[]
      ) => {
        let branchingQuestions: QuestionniareQuestionModel[] = [];
        branchIds.forEach((branchId) => {
          const newBranch = allQuestions.find((q) => q.questionId === branchId);
          if (
            newBranch &&
            !updatedQuestions.some((q) => q.questionId === branchId)
          ) {
            branchingQuestions.push(newBranch);
          }
        });

        updatedQuestions.splice(
          parentIndex + 1,
          0,
          ...branchingQuestions.map((q) => ({ ...q, userAnswered: [] }))
        );

        return branchingQuestions.length;
      };

      addBranchingQuestions(questionIndex, branchIdsToAdd);

      // Reorder questions: answered questions first, then unanswered questions in their original order
      // const answeredQuestions = updatedQuestions.filter(
      //   (q) => q.userAnswered.length > 0
      // );
      // const unansweredQuestions = updatedQuestions.filter(
      //   (q) => q.userAnswered.length === 0
      // );

      return [...updatedQuestions];
    });
  };

  const handleSave = () => {
    setMainQuestions(displayQuestions);
    saveQuestionnaire(displayQuestions);
  };

  const renderQuestion = (question: QuestionniareQuestionModel, index: number) => {
    // Check if "None of these" is selected
    const isNoneOfTheseSelected = question.userAnswered.some(
      (ans: userAnsweredModel) =>
        question.options?.find(opt => opt.optionId === ans.optionId)?.option.toLowerCase() === "none of these"
    );

    return (
      <div className="mb-3" key={question.questionId}>
        <p className="font-16 font-regular">
          {index + 1}. {question.question}
          <span
            hidden={
              !(
                notAssessedControlAreaSubMenus.length !== 0 &&
                question.userAnswered.length === 0
              )
            }
            className="action-needed ms-2"
          />
        </p>
        {question.options.map((opt: QuestionniareOptionModel) => {
          const isNoneOfThese = opt.option.toLowerCase() === "none of these";
          const isChecked = question.userAnswered.some(
            (ans: userAnsweredModel) => ans.optionId === opt.optionId
          );
          const isDisabled = isNoneOfTheseSelected && !isNoneOfThese;

          return (
            <div key={opt.optionId} className={question.type === "checkbox" ? "row" : "form-check ms-3"}>
              <div className={question.type === "checkbox" ? "col-md-6" : ""}>
                <div className={question.type === "checkbox" ? "check-card" : ""}>
                  <span className={question.type === "checkbox" ? "form-check align-items-center ms-3" : ""}>
                    <input
                      className={question.type === "checkbox" ? "theme-check form-check-input label-bold" : "form-check-input theme-radio label-bold"}
                      type={question.type === "checkbox" ? "checkbox" : "radio"}
                      id={`${question.questionId}-${opt.optionId}`}
                      name={question.questionId.toString()}
                      value={opt.optionId}
                      onChange={(e) =>
                        handleOptionSelect(
                          question.questionId,
                          opt.option,
                          opt.optionId,
                          opt.branchId,
                          opt.branchType,
                          opt.optionScore,
                          e.target.checked
                        )
                      }
                      checked={isChecked}
                      disabled={isDisabled}
                    />
                    <label
                      className={`form-check-label mb-2 ms-2 font-14 font-regular ${isDisabled ? 'text-muted' : ''}`}
                      htmlFor={`${question.questionId}-${opt.optionId}`}
                    >
                      {opt.option}
                    </label>
                  </span>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  const bindQuestionnaire = () => {
    try {
      if (questionnaireData?.some((obj) => obj.controlAreaId === controlAreaSelectedId) === false) {
        return (
          <div className="font-14 font-medium color-grey-v3 mt-3 d-flex justify-content-center">
            <p className="mb-0">
              Sorry, Selected cloud doesn't have any sub menu in this control area.
            </p>
          </div>
        );
      }
      if (questionnaireData === null) {
        return (
          <div className="font-14 font-medium color-grey-v3 mt-3 d-flex justify-content-center">
            <p className="mb-0">
              Please select a Cloud name to start the assessment.
            </p>
          </div>
        );
      }
      return questionnaireData?.map(
        ({ controlAreaId, controlAreaValue }: any, mainIndex: number) => {
          if (controlAreaSelectedId.trim() === controlAreaId.trim()) {
            return (
              <div
                key={mainIndex}
                className={`tab-pane fade active show`}
                role="tabpanel"
              >
                <div
                  className="row sticky-top bg-white"
                  style={{ zIndex: "1" }}
                >
                  <div className="d-flex nav-tabs">
                    {ds && (
                      <button
                        style={{
                          position: "absolute",
                          zIndex: "1",
                          left: "2px",
                          top: "6px",
                        }}
                        type="button"
                        className="btn border-0 p-0 shadow-none"
                        onClick={() => {
                          ref.current.scrollLeft -= 200;
                        }}
                      >
                        <img
                          src="images/date-left-arrow.svg"
                          alt="date-left-arrow"
                        />
                      </button>
                    )}
                    <div
                      ref={ref}
                      className="nav border-tabs flex-nowrap table-responsive nav-scroll"
                      role="tablist"
                    >
                      {controlAreaValue?.map((tabs: any, tabsIndex: number) => (
                        <button
                          ref={(el: any) => {
                            nextRefs.current[tabsIndex] = el;
                          }}
                          key={tabsIndex}
                          className={`nav-link text-nowrap ${tabs.controlAreaSubMenuId === controlAreaSubMenuSelectedId ? "active" : ""
                            }`}
                          type="button"
                          role="tab"
                          onClick={() => {
                            controlAreaSubMenuClick(
                              tabs.controlAreaSubMenuId,
                              tabs.controlAreaSubMenuName
                            );
                            questionnaireRef.current?.scrollIntoView({
                              behavior: "smooth",
                              inline: "start",
                            });
                          }}
                        >
                          {tabs.controlAreaSubMenuName}
                          <span
                            hidden={!notAssessedControlAreaSubMenus.includes(tabs.controlAreaSubMenuId)}
                            className="action-needed ms-2"
                          ></span>
                        </button>
                      ))}
                    </div>
                    {ds && (
                      <button
                        style={{
                          position: "absolute",
                          right: "0px",
                          top: "6px",
                        }}
                        type="button"
                        className="btn border-0 p-0 shadow-none"
                        onClick={() => {
                          ref.current.scrollLeft += 200;
                        }}
                      >
                        <img
                          src="images/date-right-arrow.svg"
                          alt="date-right-arrow"
                        />
                      </button>
                    )}
                  </div>
                </div>
                <div className="tab-content" id="nav-tabContent">
                  <div
                    className="tab-pane fade pt-4 active show mb-4"
                    role="tabpanel"
                    ref={questionnaireRef}
                  >
                    {controlAreaValue?.map(({ controlAreaSubMenuId }: any) => {
                      if (controlAreaSubMenuId.trim() === controlAreaSubMenuSelectedId.trim()) {
                        if (displayQuestions.length === 0) {
                          return (
                            <div className="font-14 font-medium color-grey-v3 mt-3 d-flex justify-content-center">
                              <p className="mb-0">
                                Sorry, no questions have been configured in the admin. Please upload questions to assess.
                              </p>
                            </div>
                          );
                        } else {
                          return displayQuestions.map((question, index) => renderQuestion(question, index));
                        }
                      }
                      return null;
                    })}
                  </div>
                  <div className="fixed-bottom py-2 mx-3 Admin-footer bg-white">
                    <div className="d-grid d-md-flex justify-content-md-end mx-3 ">
                      <div className="button-container">
                        <a
                          className="theme-link-btn font-16 font-semibold text-center order-md-first cursor-pointer"
                          onClick={handleClear}
                        >
                          Clear
                        </a>
                        <button
                          type="button"
                          className="btn theme-primary-btn font-14 font-medium my-2"
                          onClick={handleSave}
                          disabled={!displayQuestions.some((question) => question.userAnswered.length > 0)}
                        >
                          Save
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          }
          return null;
        }
      );
    } catch (e) {
      console.error(e);
      return (
        <div className="font-14 font-medium color-grey-v3 mt-3 d-flex justify-content-center">
          <p className="mb-0">
            Sorry, This control area menu may be removed or has some issues.
          </p>
        </div>
      );
    }
  };

  return <>{bindQuestionnaire()}</>;
}