import { useMsal, useIsAuthenticated } from '@azure/msal-react'
// import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom'
import { isAuth } from './Auth';



const LoginPage = () => {
    const { instance, accounts } = useMsal();

    const navigate = useNavigate();

    const handleLogin = async () => {
        const request = {
            scopes: ["https://graph.microsoft.com/User.Read"],
            redirectStartPage: window.location.origin + "/homepage",
            redirectUri: window.location.origin + "/homepage", // Set your desired redirect URI here
            onRedirectNavigate: window.location.origin + "/homepage",
        };

        //   const login_response = await instance
        //     .loginPopup(request)
        //     .catch((error: any) => {
        //       console.log(error);
        //     });

        const login_response = await instance
            .loginPopup(request)
            .catch((error: any) => {
                // console.log(error);
            });

        if (isAuth([login_response?.account])){
            navigate("/homepage")
        } 
    };


    return (
        <>
            {/* Login page starts here */}
            <div className="container-fluid">
                <div className="row ">
                    {/* Port left container starts here */}
                    <div className=" col-xl-6 d-xl-block d-none p-0 bg-image">
                        <div className="row justify-content-center">
                            <div className="col-md-8 pt-5 position-relative">
                                <span>
                                    <img
                                        src="images/zeb-pulse-logo.svg"
                                        alt="zeb-logo"
                                        className="logo-sty"
                                    />
                                </span>
                                <h5 className="font-regular font-32 color-white py-4">
                                    <span className="color-theme">The simplest way to </span> manage you
                                    work place.
                                </h5>
                                <span className="pos-login-img">
                                    <img
                                        src="images/login-img.svg"
                                        alt="login-img"
                                        className="login-img-sty"
                                    />
                                </span>
                            </div>
                        </div>
                    </div>
                    {/* Port left container ends here */}
                    {/*Port right container starts here */}
                    <div className="  col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12 align-self-center">
                        <div className="form-signup">
                            <div className="row justify-content-center">
                                <div className="col-md-7 col-sm-12 mb-3 text-center">
                                    <span className="d-xl-none d-block my-5 pt-5">
                                        <img
                                            src="images/zeb-black-logo.svg"
                                            alt="logo"
                                            className="logo-sty"
                                        />
                                    </span>
                                    <h5 className="font-semibold font-32 color-black mb-5">
                                        Get Started Now
                                    </h5>
                                    <button onClick={() => handleLogin()} className="btn w-100 login-btn font-semibold">Login</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* Port right container ends here */}
                </div>
            </div>
            {/* Login page ends here */}


        </>
    );
};

export default LoginPage