import { useEffect, useRef, useState } from "react";
import { InfraApplicationDetails } from "../interface/InfraAssessmentModel";
import { getInfraApplicationDetails, postInfraApplicationDetails } from "../service/InfraAssessmentApi";
import { deleteInfraApplicationInventoryDetails } from "../service/InfraAssessmentApi";
import ToastComponent from "./ToastComponent";
import uploadFileToBlob1 from "../helpers/BlobUpload";
import { useLocation } from "react-router-dom";
import { fileNameSlice } from "../constant/constant";
//PS 12 CREATING CONST VARIABLES
const toastData: any = {
    toastType: '',
    toastHeaderMessage: '',
    toastBodyMessage: '',
};
const applicationData: any = {
    applicationId: "",
    applicationName: "",
    applicationArchitectureBlobUrl: "",
}
export default function PortfolioComponent(props: any) {

    const { setIsLoading, menuId, state } = props;
    const attachment: any = useRef(null);
    //ps-1 Initialize a state variable `applications` of type `InfraApplicationDetails`, with an initial state set to `applicationData`.

    const [applications, setApplications] = useState<InfraApplicationDetails[]>([]);
    //PS-2 Initialize a state variable `data` of type `editApplication`, with `InfraApplicationDetails` as an interface and initially set to `applicationData`.
    const [editApplication, seteditApplication] = useState<InfraApplicationDetails>(applicationData);
    //PS-3Initialize a state variable `validationMessage` using `useState`, initially as an empty string.
    const [validationMessage, setValidationMessage] = useState<string>("");
    //PS-4 Initialize a state variable `fileName` of type string, set initially to an empty string ("").
    const [fileName, setFileName] = useState<string>("");
    //PS-5 Initialize a state variable `file` of type string, set initially to an empty string ("").
    const [file, setFile] = useState<any>("");
    //PS-6 Initialize a state variable `isForm` of type boolean to indicate if the component is in form mode, initially set to `false`.
    const [isForm, setIsForm] = useState<Boolean>();
    //PS-7 Initialize a state variable `toastProp` using `useState`, initially as `false`.
    const [toastProp, setToastProp] = useState<any>(toastData)
    //PS-8 Initialize a state variable `hideToast` using `useState`, initially as `false`.
    const [hideToast, setHideToast] = useState<boolean>(true);
    //PS- 9 Create a state variable `showDeleteModal` to manage modal visibility.
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    //PS-10 Create a state variable `deleteApplicationId` of type string, initialized with an empty string ("").
    const [deleteApplicationId, setDeleteApplicationId] = useState<string>('');
    //PS-5 Create a computed state variable `isDisabled`, which is based on conditions involving `editApplication.applicationName` and `editApplication.applicationSummary`.
    const isDisabled = !editApplication.applicationName.trim();
    const [flag, setFlag] = useState<Boolean>(false);

    //ps-18 An `useEffect` is triggered which calls the `fetchInfraApplicationDetails` function.
    useEffect(() => {
        fetchInfraApplicationDetails();
    }, []);

    //PC_96  The onclick function associated with the 'Cancel' removeFile is called button is invoked to handle the cancellation process.
    const removeFile = () => {
        setFileName("");
        setFile("");
        setValidationMessage("");
        attachment.current.value = null;
    };

    //ps-20 `getInfraApplicationDetails` function is called with a payload containing organization ID, vertical ID, and infra assessment type ID.
    const fetchInfraApplicationDetails = async () => {
        setIsLoading(true)
        try {
            const payload = {
                organizationId: state.organizationId,
                verticalId: state.verticalId,
                projectId: state.projectId,
            }
            //ps-21 Inside `getInfraApplicationDetails`, invokes `apiCall` with URL "/getApplicationDetails", method "GET", and payload.
            const response = await getInfraApplicationDetails(payload);
            if (response.status !== 200) {
                let toastData = {
                    toastType: "error",
                    toastHeaderMessage: "Error",
                    toastBodyMessage: "Api Failed",
                };
                setToastProp(toastData);
                setHideToast(false);
            }

            //ps-26 Upon receiving a 200 status code, if response data has a length greater than 0, `setApplications` is updated with the received data.
            if (Array.isArray(response.data) && response.data.length > 0) {
                setApplications(response.data);
                //ps-27 `setIsForm` is set to `false` after successfully fetching data.
                setIsForm(false);
            } else {
                console.error("Unexpected data structure:", response.data);
                setIsForm(true);
            }
            setIsLoading(false)
        } catch (error) {
            setIsLoading(false)
            let toastData = {
                toastType: "error",
                toastHeaderMessage: "Error",
                toastBodyMessage: "Api Failed",
            };
            setToastProp(toastData);
            setHideToast(false);
        }
    };

    //PS-58 The `handleImageUpload` function is invoked in response to the file selection event.
    const handleImageUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValidationMessage("");
        const files = event.target.files;
        if (!files || files.length === 0) {
            setValidationMessage("Please select an image file");
            return;
        }
        const file = files[0];
        setFile(file)
        if (file) {
            //PS-58 Checks if the extracted file extension is one of the allowed types (e.g., 'png', 'jpg', 'jpeg') and that the file size does not exceed 5MB.
            const AcceptedExtensions = ['png', 'jpg', 'jpeg'];
            const MaxFileSize = 5 * 1024 * 1024; // 5MB

            const fileExtension = file.name.split('.').pop()?.toLowerCase();

            // Validate file extension
            if (!fileExtension || !AcceptedExtensions.includes(fileExtension)) {
                attachment.current.value = null;
                setValidationMessage("Please upload valid image in png, jpg, jpeg");
                return;
            }

            // PS-67 If the file extension is invalid or the file size exceeds the limit, an appropriate validation message is set using `setValidationMessage("...")`.
            if (file.size > MaxFileSize) {
                attachment.current.value = null;
                setValidationMessage("Please upload image less than 5MB");
                return;
            }

            setFileName(file.name)
        } else {
            attachment.current.value = null;
            setValidationMessage("Please select an image file");
            return;
        }
    };


    //PS-45 handleEdit function in invoked
    const handleEdit = (application: any) => {
        seteditApplication(application);
        setFileName(fileNameSlice(application.applicationArchitectureBlobUrl) || '');
        setIsForm(true);
    };
    //PC-47 The Component renders the form with prepopulated values, displaying editable fields to the user in the HTML.


    // ps-38 The user confirms the intent to delete by interacting with the confirmation modal.
    const confirmDelete = async () => {
        setShowDeleteModal(false);
        setFlag(false);
        setIsLoading(true);
        try {
            const payload = {
                organizationId: state.organizationId,
                verticalId: state.verticalId,
                applicationId: deleteApplicationId,
                infraAssessmentMenuId: menuId,
                projectId: state.projectId
            }

            //ps-39-43 Upon user confirmation, a function `handleDeleteInventory` is invoked using `async/await` to carry out the deletion process.
            const response = await deleteInfraApplicationInventoryDetails(payload);

            // Reset the form
            seteditApplication({
                applicationId: "",
                applicationName: "",
                applicationArchitectureBlobUrl: "",
            });
            setFileName("");
            await fetchInfraApplicationDetails();
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
        }
    };

    //ps-36 The `handleDelete` function is invoked following the user's click on the delete button.
    const handleDelete = async (application: InfraApplicationDetails) => {
        //ps-37 Within `handleDelete`, `setShowDeleteModal(true)` is called to display a confirmation popup and `setDeleteApplicationId` is called with the appropriate application ID.
        setDeleteApplicationId(application.applicationId);
        setShowDeleteModal(true);
        setFlag(true)
    };
    const closeModal = () => {
        setShowDeleteModal(false);
        setFlag(false)
    };

    //PS-89:`validateApplicationDetails()` function is invoked
    const validateApplicationDetails = () => {
        if (editApplication.applicationName !== "") {
            return true;
        }
        return false;
    }
    //PS-87 `handleSaveButton()` function is called to initiate saving the application details.
    const handleSaveButton = async () => {
        setIsLoading(true)
        //PS-89 `validateApplicationDetails()` function is invoked to perform input validation checks before saving.
        if (validateApplicationDetails()) {
            let blobUrl = editApplication.applicationArchitectureBlobUrl

            if (file !== "") {
                let path = state.organizationName.toString() + state.organizationId.toString()
                //PS-90 CALLS THE uploadFileToBlob with file and path
                blobUrl = await uploadFileToBlob1(file, path)
                seteditApplication(prevState => ({
                    ...prevState,
                    applicationArchitectureBlobUrl: blobUrl,
                }));
            }
            //PS-91 Invokes `SaveInfraApplicationDetails` with the blob URL resulting from the file upload.
            await SaveInfraApplicationDetails(blobUrl)

            // Reset form and switch to list view
            seteditApplication(applicationData);
            setFileName("");
            setFile("");
            setIsForm(false);

            // Fetch updated application list
            await fetchInfraApplicationDetails();
        }
        setIsLoading(false)
    }
    //PS-91- HANDLES SaveInfraApplicationDetails
    const SaveInfraApplicationDetails = async (blobUrl: any) => {
        setIsLoading(true)

        try {
            const payload = {
                organizationId: state.organizationId,
                verticalId: state.verticalId,
                projectId: state.projectId,
                infraAssessmentMenuId: menuId,
                applicationId: editApplication.applicationId,
                applicationName: editApplication.applicationName,
                applicationArchitectureBlobUrl: blobUrl,
                updateStatus: true
            };
            const response = await postInfraApplicationDetails(payload);
            if (response.status == 200) {
                await fetchInfraApplicationDetails()
                setIsLoading(false)
            }
            else {
                setIsLoading(false)
                let toastData = {
                    toastType: "error",
                    toastHeaderMessage: "Error",
                    toastBodyMessage: "Api Failed",
                };
                setToastProp(toastData);
                setHideToast(false);
            }
        } catch (error) {
            setIsLoading(false)
            let toastData = {
                toastType: "error",
                toastHeaderMessage: "Error",
                toastBodyMessage: "Api Failed",
            };
            setToastProp(toastData);
            setHideToast(false);
        }
    };

    const bindConnectorsForm = () => {
        const bindSaveAndCancel = () => {
            return (
                <div className="d-grid gap-2 d-md-inline-flex justify-content-md-end mb-5 mt-5 w-100">
                    <button
                        type="button"
                        className="btn btn-dark theme-primary-btn border-0 font-14 font-semibold"
                        disabled={isDisabled}
                        onClick={() => {
                            handleSaveButton()
                        }}
                    >
                        Save
                    </button>
                    <a
                        className="theme-link-btn cursor-pointer font-14 font-semibold text-center order-md-first"
                        onClick={() => {
                            attachment.current.value = null;
                            seteditApplication(applicationData)
                            setFileName("")
                            setValidationMessage("")
                            fetchInfraApplicationDetails()
                        }}
                    >
                        Cancel
                    </a>

                </div>

            );
        };
        return (
            <>
                <div className="row">
                    <div className="col-lg-8 col-md-12 col-12">
                        <p className="font-24 font-bold mt-3">Portfolio Discovery</p>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="mb-4 mt-4">
                                    <label
                                        htmlFor="ApplicationName"
                                        className="form-label font-14 font-semibold color-black"
                                    >
                                        Application Name <span className="color-red">*</span>
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control theme-form font-regular font-14"
                                        id="ApplicationName"
                                        placeholder="Enter Application Name"
                                        value={editApplication.applicationName}
                                        onChange={
                                            (e) => {
                                                seteditApplication(prevDetails => ({
                                                    ...prevDetails, applicationName: e.target.value
                                                }));
                                            }} />

                                </div>
                            </div>
                            <div className="col-md-12">

                            </div>
                            <div>
                                <h2 className="font-14 font-semibold color-black mb-2">Upload Application Architecture</h2>
                                <div className="mb-3 d-md-flex align-items-center">
                                    <span className="upload-btn-wrapper me-2 d-block">
                                        <button
                                            type="button"
                                            className="font-14 link-blue font-medium bg-white border-0 shadow-none cursor-pointer"
                                        >
                                            <img src="images/upload.svg" alt="browse" className="me-2" />Upload
                                        </button>
                                        <input type="file" name="myfile" className="cursor-pointer" accept="image/*" ref={attachment} onChange={(e) => handleImageUpload(e)} />
                                    </span>
                                </div>
                                <span className="font-14 font-medium color-grey mt-1 ms-2  d-block ">
                                    {fileName}
                                    <span className="cursor-pointer ms-3" title="Cancel">
                                        {fileName ? (<img src="images/upload-close.svg" alt="upload-close" onClick={() => {
                                            removeFile();
                                        }} />
                                        ) : (
                                            <></>
                                        )}
                                    </span>
                                </span>
                                <p className="mt-2 mb-0 color-red font-14 font-regular">
                                    {validationMessage}
                                </p>
                            </div>
                        </div>
                    </div>
                    {bindSaveAndCancel()}
                </div>
            </>
        );
    };
    const renderDetails = () => {
        return applications.map((application) => (
            <div className="table-responsive mb-4 border border-1 rounded p-2">
                <table className="table table-borderless mb-0">
                    <thead className="font-semibold"></thead>
                    <tbody className="font-regular">
                        <tr className="text-nowrap connector-table">

                            <td className="text-start text-nowrap">
                                <img src="images/application.svg" alt="solarwinds" className="me-2" />

                                <span className="font-14 font-semibold color-black-v2 me-3">{application.applicationName}</span>
                            </td>
                            <td className="text-end">
                                <button type="button" className="btn edit-btn px-4" onClick={() => handleEdit(application)}>
                                    Edit
                                </button>
                                <button type="button" className="btn btn-outline-danger ms-3 px-3" onClick={() => handleDelete(application)}>
                                    Delete
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        ));
    };
    return (
        <>
            {showDeleteModal && (
                <div
                    className="modal fade show"
                    id="delete"
                    tabIndex={-1}
                    aria-hidden="true"
                    data-bs-backdrop="static"
                    data-bs-keyboard="false"
                    style={{ display: 'block' }}
                >
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content p-3">
                            <div className="modal-header pb-0 border-0">
                                <button
                                    type="button"
                                    className="btn-close cursor-pointer"
                                    onClick={() => closeModal()}
                                    aria-label="Close"
                                ></button>
                            </div>

                            <div className="modal-body">
                                <div className="w-100 text-center mb-3">
                                    <img src="images/delete-icon.svg" alt="delete" />
                                </div>

                                <p className="font-semibold font-24 text-center mb-2 red-400">
                                    Delete Confirmation
                                </p>

                                <p className="font-semibold font-16 text-center mb-5">
                                    Do you really want to delete the Application?
                                </p>

                                <div className="d-grid gap-2 d-md-inline-flex justify-content-md-center mb-2 mb-md-0 w-100">
                                    <a
                                        className="theme-link-btn font-14 font-semibold text-center order-md-first cursor-pointer px-4"
                                        onClick={() => confirmDelete()}
                                    >
                                        Yes
                                    </a>

                                    <button
                                        type="button"
                                        className="btn btn-danger red-400 btn-lg px-4 text-white font-14 font-semibold"
                                        onClick={() => closeModal()}
                                    >
                                        No
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {isForm ?
                bindConnectorsForm()
                :
                <>
                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-4">
                        <p className="font-24 font-bold mt-3">Portfolio Discovery </p>
                        {renderDetails()}
                    </div >
                    <div className="align-items-center mb-3">
                        <span className="link-blue font-14 font-regular cursor-pointer" onClick={() => {
                            seteditApplication(applicationData)
                            setFileName("");
                            setIsForm(true);
                        }}>
                            <img src="images/plusBlue.svg" alt="plusBlue" className="me-2" />Add New Application</span>
                    </div>

                </>
            }
            {flag && <div className="modal-backdrop fade show"></div>}</>
    );

}
