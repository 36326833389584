import { useEffect, useState } from "react";
import { MigrationStrategyProps, Question, SubmenuItem } from "../interface/InfraAssessmentModel";
import { getInfraAssessmentApplications, getInfraAssessmentQuestions, getInfraAssessmentSubMenus, postMigrationStrategyAnswers } from "../service/InfraAssessmentApi";
import ToastComponent from "./ToastComponent";

const MigrationStrategy: React.FC<MigrationStrategyProps> = (props) => {

    const toastData: any = {
        toastType: '',
        toastHeaderMessage: '',
        toastBodyMessage: '',
    };

    // PS_MigrationStrategy_01        
    const { setApplicationDropDown, applicationDropDown, setSelectedApplicationId, selectedApplicationId, setIsLoading, state, menuId } = props

    // PS_MigrationStrategy_02-  PS_MigrationStrategy_13
    const [questions, setQuestions] = useState<Question[]>([]);
    const [renderedQuestions, setRenderedQuestions] = useState<Question[]>([]);
    const [answers, setAnswers] = useState<{ [key: string]: string | null }>({});
    const [renderedQuestionIds, setRenderedQuestionIds] = useState<string[]>([]);
    const [showToasterPopup, setShowToasterPopup] = useState(false);
    const [showToasterLoading, setShowToasterLoading] = useState<boolean>(false);
    const [isSaveBtnVisible, setIsSaveBtnVisible] = useState(false);
    const [isNoRecordPageVisible, setIsNoRecordPageVisible] = useState(false);
    const [toastProp, setToastProp] = useState<any>(toastData)
    const [hideToast, setHideToast] = useState<boolean>(false);
    const [subMenus, setSubMenus] = useState<SubmenuItem[]>([]);
    const [apiResponse, setApiResponse] = useState<{ applicationName: string, strategyName: string } | null>(null);
    const [answeredOrder, setAnsweredOrder] = useState<string[]>([]);



    // PS_MigrationStrategy_15
    useEffect(() => {
        getInitialPageData();
    }, [selectedApplicationId]);




    const getSubMenus = async () => {
        try {
            const submenupayload = {
                controlAreaMenuId: menuId,
            };
            const response = await getInfraAssessmentSubMenus(submenupayload);
            if (response.status !== 200) {
                setIsLoading(false);
                let toastData = {
                    toastType: 'error',
                    toastHeaderMessage: 'Error',
                    toastBodyMessage: 'API failed'
                }
                setToastProp(toastData)
                setHideToast(false);
            }
            setSubMenus(response.data[0].submenuId);
            const submenuId = response.data[0].submenuId
            getApplicationQuestions(submenuId)

        } catch (error) {
            console.error("Error fetching submenu data:", error);
        }
    }

    const getApplicationQuestions = async (submenuId: string) => {
        try {
            const questionPayload = {
                organizationId: state.organizationId,
                applicationId: selectedApplicationId,
                verticalId: state.verticalId,
                projectId: state.projectId,
                menuId: menuId,
                submenuId: submenuId
            }
            // PS_MigrationStrategy_38
            const fetchedQuestions = await getInfraAssessmentQuestions(questionPayload);
            if (fetchedQuestions.status !== 200) {
                setIsLoading(false);
                let toastData = {
                    toastType: 'error',
                    toastHeaderMessage: 'Error',
                    toastBodyMessage: 'API failed'
                }
                setToastProp(toastData)
                setHideToast(false);
            }
            if (fetchedQuestions.status == 200) {
                setIsLoading(false)
                setHideToast(true);
                setQuestions(fetchedQuestions.data.questions);

                const userAnswers = fetchedQuestions.data.questions.reduce((acc: any, question: any) => {
                    if (question.answeredOptionId && question.answeredOptionId.length > 0) {
                        acc[question.questionId] = question.answeredOptionId[0];
                    }
                    return acc;
                }, {});
                setAnswers(userAnswers);
                renderInitialQuestion(fetchedQuestions.data.questions, userAnswers);
            }
            // renderInitialQuestion(fetchedQuestions.data.questions);
        } catch (error) {
            console.error("Error fetching question data:", error);
        }
    }

    const getInitialPageData = async () => {
        try {
            setIsLoading(true)
            setIsSaveBtnVisible(false)
            getSubMenus()
        } catch (error) {
            setIsLoading(false)
            console.error("Error fetching initial data:", error);
        }
    };


    const renderInitialQuestion = (questions: Question[], userAnswers: { [key: string]: string }) => {
        let questionsToRender: Question[] = [];
        let currentQuestionIndex = 0; // Start with the first question index

        while (currentQuestionIndex < questions.length) {
            const currentQuestion = questions[currentQuestionIndex];
            questionsToRender.push(currentQuestion);
            const selectedOption = currentQuestion.options.find(
                (option): any => userAnswers[currentQuestion.questionId] === option.optionId
            );

            if (selectedOption && selectedOption.branchId) {
                // Find the index of the branchId question
                const branchIndex = questions.findIndex(q => q.questionId === selectedOption.branchId);
                if (branchIndex === -1) break; // Stop if there's no branching question found

                currentQuestionIndex = branchIndex; // Move to the branching question
            } else {
                break;
            }
        }

        setRenderedQuestions(questionsToRender);
        setIsSaveBtnVisible(Object.keys(userAnswers).length > 0);
    };


    const handleOptionSelect = (questionId: string, optionId: string, questionIndex: number) => {
        // Begin with all questions up to the current one
        let updatedQuestions = renderedQuestions.slice(0, questionIndex + 1);

        // Set answeredOptionId for the current question
        updatedQuestions[updatedQuestions.length - 1] = {
            ...updatedQuestions[updatedQuestions.length - 1],
            answeredOptionId: optionId
        };

        // Find the selected option
        const selectedOption = updatedQuestions[updatedQuestions.length - 1].options.find(o => o.optionId === optionId);

        // Reset answers for all subsequent questions
        const newAnswers = { ...answers };
        Object.keys(newAnswers).forEach(key => {
            if (renderedQuestions.findIndex(q => q.questionId === key) > questionIndex) {
                delete newAnswers[key];
            }
        });
        newAnswers[questionId] = optionId;

        // Add all subsequent questions based on the branching logic
        while (selectedOption && selectedOption.branchId) {
            const nextQuestion = questions.find(q => q.questionId === selectedOption.branchId);
            if (!nextQuestion) break;

            updatedQuestions.push({ ...nextQuestion, answeredOptionId: "" });

            // Stop here as we don't have any pre-selected answers for subsequent questions
            break;
        }

        // Update the state with the new question list and answers
        setRenderedQuestions(updatedQuestions);
        setRenderedQuestionIds(updatedQuestions.map(q => q.questionId));
        setAnswers(newAnswers);

        // Determine if save button should be visible
       setIsSaveBtnVisible(Object.keys(newAnswers).length > 0);
    };


    // Helper function to determine if the last question has been reached
    const checkIsLastQuestion = (renderedQuestions: Question[], allQuestions: Question[]) => {
        if (renderedQuestions.length === 0) return false;
        const lastRenderedQuestion = renderedQuestions[renderedQuestions.length - 1];
        const lastOptionSelected = lastRenderedQuestion.answeredOptionId;
        if (!lastOptionSelected) return false;
        const selectedOption = lastRenderedQuestion.options.find(o => o.optionId === lastOptionSelected);
        // Assuming there's a branchId, check if there's no subsequent question for the selected option
        if (!selectedOption) return false;
        return !allQuestions.some(q => q.questionId === selectedOption.branchId);
    }

    // PS_MigrationStrategy_54 -  PS_MigrationStrategy_78
    const handleSave = async () => {
        const applicationId = selectedApplicationId
        const userAnsweredDetails = Object.entries(answers).map(([questionId, answeredOptionId]) => ({
            questionId,
            answeredOptionId
        }));

        const lastAnsweredQuestion = renderedQuestions[renderedQuestions.length - 1];
        const lastAnsweredOptionId = answers[lastAnsweredQuestion.questionId];
        const lastSelectedOption = lastAnsweredQuestion.options.find(option => option.optionId === lastAnsweredOptionId);
        const strategyId = lastSelectedOption?.branchId || '';

        const payloadData = {
            organizationId: state.organizationId,
            verticalId: state.verticalId,
            projectId: state.projectId,
            menuId: menuId,
            submenuId: subMenus,
            applicationId: applicationId,
            migrationStrategyId: strategyId,
            userAnsweredDetails: userAnsweredDetails
        };

        setShowToasterLoading(true);

        try {
            const submitApiResponse = await postMigrationStrategyAnswers(payloadData);

            if (submitApiResponse.status === 200) {
                setApiResponse(submitApiResponse.data);
                setShowToasterPopup(true);
            } else {
                setShowToasterPopup(true);
                resetForm();
            }
        } catch (error) {
            console.error("Error submitting form data", error);
        } finally {
            setShowToasterLoading(false);
        }
    };

    const resetForm = async () => {
        setAnswers({});
        await getInitialPageData();
    };


    const handleClosePopup = () => {
        setShowToasterPopup(false);
        setApiResponse(null);
        resetForm();
    };
    const handleClear = () => {
        setAnswers({});
        setRenderedQuestions(questions.slice(0, 1)); // Show only the first question
        setIsSaveBtnVisible(false);
      };

    return (
        <>
            {applicationDropDown.length !== 0 && (
                <div className="tab-pane fade active show" id="CAFtab" role="tabpanel">
                    <h2 className="font-22 font-semibold color-black mb-0">
                        Migration Modernization recommendation Strategy
                    </h2>
                    <div className="row">
                        <div className="tab-content" id="nav-UserExperience">
                            <div className="tab-pane fade show active pt-5" id="Businesstab" role="tabpanel">
                                {renderedQuestions.map((question, questionIndex) => (
                                    <div className="mb-4" key={questionIndex}>
                                        <p className="font-16 font-medium">{questionIndex + 1 + "." + question.question}</p>
                                        {question.options.map((option, optionIndex) => (
                                            <div className="form-check ms-3" key={"option" + optionIndex}>
                                                <input
                                                    className="form-check-input theme-radio label-bold"
                                                    type={question.questionType}
                                                    name={"question" + questionIndex}
                                                    id={`question${questionIndex}_option${optionIndex}`}
                                                    checked={answers[question.questionId] === option.optionId}
                                                    onChange={() => handleOptionSelect(question.questionId, option.optionId, questionIndex)}
                                                />
                                                <label
                                                    className="form-check-label mb-2 ms-2 font-14 font-regular"
                                                    htmlFor={`question${questionIndex}_option${optionIndex}`}
                                                >
                                                    {option.option}
                                                </label>
                                            </div>
                                        ))}
                                    </div>
                                ))}
                            </div>

                            {renderedQuestions.length > 0 && (
                                <div className="d-grid gap-2 d-md-inline-flex justify-content-md-end mb-5 w-100">
                                    <a
                                        className="theme-link-btn font-16 font-semibold text-center order-md-first cursor-pointer"
                                        onClick={handleClear}
                                    >
                                        Clear
                                    </a>
                                    <button
                                        type="button"
                                        className="btn btn-dark theme-primary-btn border-0 font-14 font-semibold"
                                        onClick={handleSave}
                                        disabled={Object.keys(answers).length === 0}
                                    >
                                        Save
                                    </button>
                                </div>
                            )}
                        </div>
                    </div>
                </div>)}

            {applicationDropDown.length == 0 && (
                <div className="row h-100 align-items-center text-center justify-content-center">
                    <div className="col-lg-6 col-md-12 col-sm-12 text-center">
                        <img src="images/assessment-norec-img.svg" alt="assessment-norec-img" className="ass-norec-img" />
                        <p className="font-16 font-medium color-black mt-4">Please add an application to the portfolio discovery to move forward with the migration strategy - 7Rs</p>
                    </div>
                </div>
            )}

            {showToasterLoading ? (
                <div className="position-fixed bg-white top-110px start-50 translate-middle w-md-100">
                    <div className="shadow-sm d-flex rounded-3 custom-toast p-3">
                        <div className="toast-body d-flex align-items-center ">
                            <div className="d-block me-3">
                                <img
                                    src="images/loading.gif"
                                    alt="toast-success"
                                    style={{ height: "15px", width: "15px" }}
                                />
                            </div>
                            <div className="d-block">
                                <span className="font-medium font-16 color-grey">
                                    Saving...
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            ) : null}

            {showToasterPopup && apiResponse && apiResponse.strategyName &&(
                <>
                    <div className="modal-backdrop fade show"></div>
                    <div className="modal fade show" style={{ display: "block" }}>
                        <div className="modal-dialog modal-dialog-centered">
                            <div className="modal-content p-3">
                                <div className="modal-header pb-0 border-0"><button type="button" className="btn-close cursor-pointer" aria-label="Close" onClick={handleClosePopup} /></div>
                                <div className="modal-body">
                                    <div className="w-100 text-center mb-3"><img src="images/circled-check.svg" alt="delete" /></div>
                                    <p className="font-semibold font-24 text-center mb-2">Recommended Strategy</p>
                                    <p className="font-medium font-16 text-center mb-4 color-grey-v1">Based on the assessment, the
                                        determined Migration strategy for  {apiResponse.applicationName} is <span className="font-semibold font-16 color-black"> {apiResponse.strategyName}</span></p>
                                    <div className="d-grid gap-2 d-md-inline-flex justify-content-md-center mb-2 mb-md-0 w-100">
                                        <button type="button" className="btn btn-dark theme-primary-btn border-0 font-14 font-semibold" onClick={handleClosePopup}>Ok</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )}

            {
                toastProp.visible &&
                <ToastComponent name={toastProp} />
            }
        </>
    )
}

export default MigrationStrategy