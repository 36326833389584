import {apiCall} from "../client/apiCall";
import { baseUrl, initiaitveEndpoints } from "../constant/constant";

/**
 * PS_RMPA_02 - PS_RMPA_04
 * Inside the getRoadMapData function the apiCall function is called by passing the url, requestMethod and body
 */
//parameters URL, data and requestType as POST
export const getRoadMapData = async (organizationDet: any) => {
    const response = await apiCall(baseUrl + initiaitveEndpoints.getRoadMapData, 'POST', {organizationInfo:organizationDet});


    // const response: any = {
    //     status: 200,
    //     data: {
    //         "roadMapDetails": null,
    //         "transformationScore": "50%",
    //         "rteScore" : "20%"
    //     }
    // }
    return response

}

/**
 * PS_SQA_02 - PS_SQA_04
 * Inside the getInitiativeData function the client function is called by passing the url, requestMethod and body
 */
//parameters URL, data and requestType as POST
export const getInitiativeData = async (organizationDet : any) =>{
    const response = await apiCall(baseUrl + initiaitveEndpoints.getInitiativeData, 'POST', {organizationInfo:organizationDet});


    // const response : any = {
    //     status : 200,
    //     data : {
    //         "initiativeDetails" : [
    //             {
    //                 "initiativeName": "CRM Implementation",
    //                 "initiativeId": "000000000000000000000000000000",
    //                 "riskSeverity": "high",
    //                 "currentTimeline": {
    //                     "milestones": [
    //                         {
    //                             "milestoneId": "111111111111",
    //                             "milestoneName": "de1sign",
    //                             "milestoneStart": "2023-06-05",
    //                             "milestoneEnd": "2023-08-15",
    //                             "plannedHours": 600,
    //                             "developmentCapacity": 500,
    //                             "riskSeverity": "high"
    //                         },
    //                         {
    //                             "milestoneId": "333333333333333",
    //                             "milestoneName": "de2sign",
    //                             "milestoneStart": "2023-06-07",
    //                             "milestoneEnd": "2023-07-28",
    //                             "plannedHours": 600,
    //                             "developmentCapacity": 500,
    //                             "riskSeverity": "low"
    //                         }
    //                     ],
    //                     "resourceDetails": [
    //                         {
    //                             "roleName": "Software Engineer",
    //                             "resourceCount": 12
    //                         }
    //                     ]
    //                 },
    //                 "resourceRecommendations": {
    //                     "milestones": [
    //                         {
    //                             "milestoneId": "00000000000000000000000000000",
    //                             "milestoneName": "design",
    //                             "milestoneStart": "2023-11-11",
    //                             "milestoneEnd": "2023-12-05",
    //                             "plannedHours": 600,
    //                             "developmentCapacity": 500,
    //                             "riskSeverity": "low"
    //                         },
    //                         {
    //                             "milestoneId": "00000000000000000000000000000",
    //                             "milestoneName": "designqqqqqqqqqqqwwwwwwwwwwwwwwweeeeeeeeeeeerrrrrrrrrrrrrtttttttttttyyyyyyyyyyyuuuuuuuuuuuuiiiiiiiiiiiiooooooooooooppppppp",
    //                             "milestoneStart": "2023-08-07",
    //                             "milestoneEnd": "2023-10-30",
    //                             "plannedHours": 600,
    //                             "developmentCapacity": 500,
    //                             "riskSeverity": "high"
    //                         }
    //                     ],
    //                     "resourceDetails": [
    //                         {
    //                             "roleName": "Software Engineer",
    //                             "resourceCount": 12
    //                         }
    //                     ]
    //                 },
    //                 "timelineRecommendations": {
    //                     "milestones": [
    //                         {
    //                             "milestoneId": "00000000000000000000000000000",
    //                             "milestoneName": "design",
    //                             "milestoneStart": "2023-06-06",
    //                             "milestoneEnd": "2024-09-05",
    //                             "plannedHours": 600,
    //                             "developmentCapacity": 500,
    //                             "riskSeverity": "high"
    //                         },
    //                         {
    //                             "milestoneId": "00000000000000000000000000000",
    //                             "milestoneName": "design",
    //                             "milestoneStart": "2023-08-07",
    //                             "milestoneEnd": "2023-10-30",
    //                             "plannedHours": 600,
    //                             "developmentCapacity": 500,
    //                             "riskSeverity": "high"
    //                         }
    //                     ],
    //                     "resourceDetails": [
    //                         {
    //                             "roleName": "Software Engineer",
    //                             "resourceCount": 12
    //                         }
    //                     ]
    //                 }
    //             }
    //         ]
    //     }
    // }

    return response
}