import react, { useState, useEffect, useRef } from "react"
import { employeeRangeModel, industryModel, organizationDataModel, organizationFilterDataModel, orgFormValidationMsgModel } from "../interface/OrganizationGridModel"
import { formValidationModel } from "../interface/InitiativeModel";
import { getConnectorsData } from "../helpers/encryption";
import { encryptStorage, fileNameSlice } from '../constant/constant';
import { jwtDecode } from 'jwt-decode';
import uploadFileToBlob1 from "../helpers/BlobUpload";

const OrganizationForm = (props: any) => {
    const charRegex = /^(?=.*[A-Za-z])[A-Za-z0-9\s]*$/;
    const webUrlRegex = /^(https?:\/\/)?(www\.)?[a-zA-Z0-9-]+(\.[a-zA-Z]{2,})+([\/\w-]*)*\/?(\?\S*)?(,\s*(https?:\/\/)?(www\.)?[a-zA-Z0-9-]+(\.[a-zA-Z]{2,})+([\/\w-]*)*\/?(\?\S*)?)*$/;
    const numberRegex = /^\d{1,5}(\.\d{1,2})?$/
    const fileNameRegex = /[!$%^&*+|~={}\[\]:"";<>?,@\/]/
    const typeArr = ['jpg', 'jpeg', 'png'];
    //Initial filter data
    const initialFilterData: organizationFilterDataModel = {
        search: "",
        industryTypeId: "",
        employeeRangeId: "",
        revenue: "",
    }
    const initialFormValidationMsg: orgFormValidationMsgModel = {
        organizationName: "",
        employeeRangeId: "",
        industryTypeId: "",
        industryName: "",
        revenue: "",
        organizationLogo: "",
        websiteUrl: "",
    }


    const [organizationData, setOrgainzationData] = useState<organizationDataModel>({ ...props.editedData })
    const [formValidationMsg, setFormValidationMsg] = useState<orgFormValidationMsgModel>(initialFormValidationMsg)
    const [fileName, setFileName] = useState<string>(organizationData?.organizationLogo?.split("/")?.at(0) || ""); const [file, setFile] = useState<any>()
    const attachments = useRef(null);
    useEffect(() => {
        let tempName = fileNameSlice(organizationData?.organizationLogo)
        tempName == "default_org_logo.svg" ? setFileName('') : tempName ? setFileName(tempName) : setFileName('')
    }, [])

    //Function to handle the on change event
    const onChangeValue = (id: string, value: string) => {
        setFormValidationMsg({ ...formValidationMsg, [id]: "" })
        setOrgainzationData({ ...organizationData, [id]: value })
        if (id === "organizationName") {
            if (!charRegex.test(value)) {
                if (value === "") {
                    setFormValidationMsg({ ...formValidationMsg, organizationName: "Enter the organization name" })
                }
                else {
                    setFormValidationMsg({ ...formValidationMsg, organizationName: "Enter a valid organization name" })
                }
            }
        }
        if (id === 'industryName') {
            if (value.trim() === '') {
                setFormValidationMsg({ ...formValidationMsg, industryName: "Enter the industry type" })
            } else if (!charRegex.test(value)) {
                setFormValidationMsg({ ...formValidationMsg, industryName: "Enter the valid industry type" })
            } else {
                setFormValidationMsg({ ...formValidationMsg, industryName: "" })
            }
        }
        if (id === 'industryTypeId') {
            setOrgainzationData({ ...organizationData, [id]: value, industryName: "" })
        }
        if (id === "websiteUrl") {
            const urls = value.split(',').map(url => url.trim());
            const invalidUrls = urls.filter(url => url !== "" && !webUrlRegex.test(url));

            if (invalidUrls.length > 0) {
                setFormValidationMsg({ ...formValidationMsg, websiteUrl: "Enter a valid Url" });
            } else {
                setFormValidationMsg({ ...formValidationMsg, websiteUrl: "" });
            }
            setOrgainzationData({ ...organizationData, [id]: value });
        }
        else if (id === "revenue") {
            if (!numberRegex.test(value)) {
                setFormValidationMsg({ ...formValidationMsg, revenue: "Enter a valid revenue" })
            }
        }
    }
    //Function to valid organization logo
    const logoValidation = (event: any) => {
        setOrgainzationData({ ...organizationData, organizationLogo: "" })
        setFormValidationMsg({ ...formValidationMsg, organizationLogo: "" })
        if (event.target.files[0]) {
            setFile(event.target.files[0])
            if (fileNameRegex.test(event.target.files[0].name)) {
                setFileName('')
                setFormValidationMsg({ ...formValidationMsg, organizationLogo: "Please upload file with valid name" })
                return
            }
            if (!typeArr?.includes(event?.target?.files[0]?.type.split('/')[1])) {
                setFileName('')
                setFormValidationMsg({ ...formValidationMsg, organizationLogo: "Please upload in png/jpg/jpeg format" })
                return
            }
            if ((event.target.files[0].size / 1024) > 1024) {
                setFileName('')
                setFormValidationMsg({ ...formValidationMsg, organizationLogo: "Please upload the file within 1 MB" })
                return
            }
            else if (typeArr?.includes(event?.target?.files[0]?.type.split('/')[1])) {
                setFileName(() => (event?.target?.files[0]?.name))
                return
            }
        }
    }
    //Function to handle the on submit
    const onSubmitForm = async () => {
        const validationMsg: orgFormValidationMsgModel = { ...formValidationMsg }
        let flag = true
        if (organizationData.organizationName?.length === 0) {
            validationMsg.organizationName = "Enter the organization name"
            flag = true
        }
        if (organizationData.industryTypeId?.length === 0) {
            validationMsg.industryTypeId = "Select the industry type"
            flag = true
        }
        if (organizationData.employeeRangeId?.length === 0) {
            validationMsg.employeeRangeId = "Select the employee range"
            flag = true
        }
        if (organizationData.revenue?.length === 0) {
            validationMsg.revenue = "Enter the revenue"
            flag = true
        }
        if (organizationData.websiteUrl?.length === 0) {
            validationMsg.websiteUrl = "Enter the website URL"
            flag = true
        }
        if (organizationData.industryTypeId === props.industry.find((data: industryModel) => data.industryName === "Other")?.industryTypeId) {
            if (organizationData?.industryName?.trim() === '') {
                validationMsg.industryName = "Enter the industry type";
                flag = true;
            }
        }
        setFormValidationMsg(validationMsg)
        flag = [validationMsg.organizationName?.length === 0, validationMsg.websiteUrl?.length === 0, validationMsg.revenue?.length === 0, validationMsg.organizationLogo?.length === 0, validationMsg.industryName?.length === 0, validationMsg.industryTypeId?.length === 0, validationMsg.employeeRangeId?.length === 0].every(Boolean);
        if (flag) {
            try {
                let organizationLogo: string = organizationData?.organizationLogo
                if (organizationLogo?.length === 0 || fileName === "default_org_logo.svg") {
                    let value = encryptStorage.getItem("jwt")
                    if (value) {
                        let parsedJtk: any = ""
                        parsedJtk = jwtDecode(value)
                        const storageAccountName = getConnectorsData({ key: parsedJtk.ek, encryptedMessage: process.env.REACT_APP_BLOB_ACCOUNT_NAME! });
                        const containerName = getConnectorsData({ key: parsedJtk.ek, encryptedMessage: process.env.REACT_APP_BLOB_CONTAINER! })
                        if (file) {
                            const upload = await uploadFileToBlob1(file, 'organizationlogo')
                            if (upload) {
                                organizationLogo = upload.toString()
                            }
                        }
                        else if (!fileName) {
                            organizationLogo = `https://${storageAccountName}.blob.core.windows.net/${containerName}/default_org_logo.svg`
                        }
                    }
                }
                const selectedIndustry = props.industry.find((data: industryModel) => data.industryTypeId === organizationData.industryTypeId);
                props.postOrganization({ ...organizationData, organizationLogo: organizationLogo })
            } catch (error) {
                // setIsLoading(false)
                let toastData = {
                    toastType: 'error',
                    toastHeaderMessage: 'Error',
                    toastBodyMessage: 'API failed'
                }
                // setToastProp(toastData)
                // setHideToast(true);
            }
        }
    }
    //Function to bind the industry
    const bindIndustry = () => {
        return (
            props.industry?.map((data: industryModel) => {
                return (
                    <option value={data.industryTypeId} key={data.industryTypeId}>{data.industryName}</option>
                )
            })
        )
    }

    //Function to bind the employee range
    const bindEmployeeRange = () => {
        return (
            props.employeeRange?.map((data: employeeRangeModel) => {
                return (
                    <option value={data.employeeRangeId} key={data.employeeRangeId}>{data.employeeRange}</option>
                )
            })
        )
    }

    return (


        <div className="modal fade show" style={{ display: "block" }}>
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content custom-popup w-650">
                    <div className="modal-header border-0 justify-content-between align-items-center position-relative px-4 pt-4 pb-2">
                        <h2 className="modal-title mb-0 font-20 font-semibold primary-textcolor" id="accept-promptLabel">
                            {`${organizationData.organizationId.length === 0 ? 'Add New' : 'Edit'} Organization`}</h2>
                        <button type="button" className="btn" data-bs-dismiss="modal" aria-label="Close" onClick={() => { props.setHideForm(true) }}><img src="images/popup-close.svg" alt="Close" /></button>
                    </div>
                    <div className="modal-body border-0 px-4">
                        <div className="row">
                            <div className="col-md-6 mb-4">
                                <label className="form-label font-14 font-semibold" htmlFor="orgName">Organization Name <span className='color-red'>{" "}*</span></label>
                                <input type="text" id="orgName" className="form-control custom-form" placeholder="Enter organization name" value={organizationData.organizationName} onChange={(event: any) => { onChangeValue("organizationName", event.target.value) }} maxLength={30} />
                                <span className='color-red'>{formValidationMsg.organizationName}</span>
                            </div>
                            <div className="col-md-6 px-md-4">
                                <div className="mb-5">
                                    <label className="form-label font-14 font-semibold color-black">
                                        Organization’s Logo
                                    </label>
                                    <div className="mb-3 d-md-flex align-items-center">
                                        {fileName.length === 0 ?
                                            <span className="upload-btn-wrapper me-2 d-block ">
                                                <button type="button"
                                                    className="font-14 link-blue font-medium bg-white border-0 shadow-none cursor-pointer">
                                                    <img className="me-2" src="images/uploadcloud.svg" alt="uploadcloud" />
                                                    Browse
                                                </button>
                                                <input type="file" name="myfile" className="cursor-pointer" id='organizationLogo' ref={attachments} onChange={(
                                                    event: any) => {
                                                    logoValidation(event)
                                                }} />
                                            </span> :
                                            <></>
                                        }
                                        {fileName != "" ? <span className="font-14 font-medium color-grey mt-1 ms-2  d-block ">{fileName}<span
                                            className="cursor-pointer ms-3" title="Cancel"><img src="images/upload-close.svg"
                                                onClick={() => {
                                                    attachments.current = null;
                                                    setFileName('')
                                                    setOrgainzationData({ ...organizationData, organizationLogo: "" })
                                                }} /></span></span> : ""}

                                    </div>
                                    <span className='color-red'>{formValidationMsg.organizationLogo}</span>
                                </div>
                            </div>
                            <div className="col-md-6 mb-4">
                                <label className="form-label font-14 font-semibold" htmlFor="selectDomain">Select Type of Industry <span className='color-red'>{" "}*</span></label>
                                <select name="selectDomain" id="selectDomain" className="form-select form-control custom-form"
                                    value={organizationData.industryTypeId}
                                    onChange={(event: any) => {
                                        onChangeValue("industryTypeId", event.target.value);
                                    }}
                                >
                                    <option value={""} disabled>Select</option>
                                    {bindIndustry()}
                                </select>
                                <span className='color-red'>{formValidationMsg.industryTypeId}</span>
                            </div>
                            {
                                props.industry.find((data: industryModel) => data.industryTypeId === organizationData.industryTypeId)?.industryName === 'Other' && (
                                    <div className="col-md-6 mb-4">
                                        <label className="form-label font-14 font-semibold" htmlFor="orgUrl">Industry Type<span className='color-red'>{" "}*</span></label>
                                        <input type="text" id="industryName" className="form-control custom-form" placeholder="Enter your Industry Type" value={organizationData.industryName}
                                            onChange={(event: any) => onChangeValue('industryName', event.target.value)}
                                        />
                                        {formValidationMsg.industryName && (
                                            <div className="mt-1">
                                                <span className='color-red font-16'>{formValidationMsg.industryName}</span>
                                            </div>
                                        )}
                                    </div>
                                )
                            }
                            <div className="col-md-6 mb-4">
                                <label className="form-label font-14 font-semibold" htmlFor="noEmployees">Number of Employees <span className='color-red'>{" "}*</span></label>
                                <select name="noEmployees" id="noEmployees" className="form-select form-control custom-form" value={organizationData.employeeRangeId} onChange={(event: any) => { onChangeValue("employeeRangeId", event.target.value) }}>
                                    <option value={""} disabled>Select</option>
                                    {bindEmployeeRange()}
                                </select>
                                <span className='color-red'>{formValidationMsg.employeeRangeId}</span>
                            </div>
                            <div className="col-md-6 mb-4">
                                <label className="form-label font-14 font-semibold" htmlFor="revenue">
                                    Revenue (In million) <span className='color-red'>{" "}*</span>
                                </label>
                                <div className="input-group">
                                    <span className="input-group-text font-semibold font-16 py-1 px-3 form-dollor">$</span>
                                    <input type="text" className="form-control custom-form font-14 font-regular text-end" id="revenue" maxLength={10} placeholder="0.00" value={organizationData.revenue} onChange={(event: any) => { onChangeValue("revenue", event.target.value) }} />
                                </div>
                                {formValidationMsg.revenue && (
                                    <div className="mt-1">
                                        <span className='color-red font-16'>{formValidationMsg.revenue}</span>
                                    </div>
                                )}
                            </div>
                            <div className="col-md-6 mb-4">
                                <label className="form-label font-14 font-semibold" htmlFor="orgUrl">Website Url <span className='color-red'>{" "}*</span></label>
                                <input type="text" id="orgUrl" className="form-control custom-form" placeholder="Enter Website Url" value={organizationData.websiteUrl} onChange={(event: any) => { onChangeValue("websiteUrl", event.target.value) }} />
                                <span className='color-red'>{formValidationMsg.websiteUrl}</span>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer px-4 border-0 justify-content-end pb-4 gap-1">
                        <a className="theme-link-btn font-14 font-semibold text-center order-md-first cursor-pointer" data-bs-dismiss="modal" onClick={() => { props.setHideForm(true) }}>Cancel</a>
                        <button type="button" className="btn btn-dark theme-primary-btn border-0 font-semibold " onClick={() => { onSubmitForm() }}>Save</button>
                    </div>
                </div>
            </div>
        </div>

    )


}

export default OrganizationForm

