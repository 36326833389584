import { encryptStorage } from "../constant/constant";
import { logoutApi } from "../service/CommonApi";

const SessionTimeOut = () => {

    const handleLogin = () => {
        logOut();
        document.body.classList.remove('overflow-hidden');
    };

    const logOut = async () => {
        let access_token = encryptStorage.getItem('jwt')
        if (access_token) {
            await logoutApi(access_token)
        }
        window.sessionStorage.clear();
        window.localStorage.clear();
        window.location.href = "/";
    };
    
    return (
        <>
            <div className="modal-backdrop fade show"></div>
            <div
                className="modal fade show" style={{ display: "block" }}
                id="error-login"
                data-bs-backdrop="static"
                data-bs-keyboard="false"
                tabIndex={-1}
                aria-labelledby="accept-promptLabel"
                aria-modal="true"
                role="dialog"
            >
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content custom-popup">
                        <div className="modal-header border-0 flex-column justify-content-center align-items-center position-relative px-4 pt-4 pb-2">
                            <img className="warning-icon" src="./images/warning.svg" alt="warning" width="50px" height="50px" />
                            <h2 className="modal-title font-semibold primary-textcolor" id="accept-promptLabel">
                                Session Expired
                            </h2>
                        </div>
                        <div className="modal-body border-0 px-4 py-0">
                            <div className="row">
                                <div className="col-md-12">
                                    <p className="font-14 font-medium color-black text-center mb-0">
                                        Your Session has been expired
                                    </p>
                                    <p className="font-14 font-medium color-black text-center mb-0">
                                        You will be redirected to the login page.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer px-4 border-0 justify-content-center pb-4 pt-4 gap-1">
                            <button
                                type="button"
                                className="btn btn-dark theme-primary-btn border-0 font-semibold"
                                onClick={handleLogin}
                            >
                                Login
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default SessionTimeOut;