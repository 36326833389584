/**
 * PS_HBC_1.0 - PS_HBC_1.1
 * import the necessary packages
 */
import React, { useEffect } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  ResponsiveContainer,
  LabelList,
  Tooltip
} from "recharts";
import * as _ from "lodash";
import {
  DataItem,
  CustomYAxisLabelInterface,
} from "../../interface/HorizontalBarChartModel";

/**
 * PS_HBC_1.2 - PS_HBC_1.9
 * this function renders the horizontal bar chart
 * @param props 
 * @returns Html
 */

const HorizontalBarChart: React.FC<any> = (props) => {
  /**
   * PS_HBC_1.2
   * this variable removes the duplicated values
   */
  let unique: any = _.uniqWith(props.dataNameValue, _.isEqual);


  /**
   * PS_HBC_1.3 - PS_HBC_1.6
   * this function returns html to customize the yaxis label
   * @param label 
   * @returns html
   */
  const CustomYAxisLabel: any = (label: any) => {
    const { x, y, payload } = label;
    const truncatedValue =
      payload.value.length > 18
        ? payload.value.slice(0, 18) + "..."
        : payload.value;

    return (
      <g transform={`translate(${x},${y})`}>
        <text x={-170} y={0} dy={6} textAnchor="start">
          {truncatedValue}
        </text>
      </g>
    );
  };

  // const CustomYAxisLabel: any = (label: any) => {
  //   const { x, y, payload } = label;
  //   const textContent = payload.value;

  //   return (
  //     <g transform={`translate(${x},${y})`}>
  //       <foreignObject x={-140} y={-8} dy={6} width={170} height={40}>
  //         <div
  //           style={{
  //             overflowWrap: "break-word",
  //             wordWrap: "break-word",
  //             width: "75%",
  //             height: "100%",
  //             fontSize: 10,
  //           }}
  //         >
  //           {textContent}
  //         </div>
  //       </foreignObject>
  //     </g>
  //   );
  // };


  /**
   * this return the html to render the horizontal bar chart
   */
  return (
    <>
      <div>
        <ResponsiveContainer width="100%" height={370}>
          <BarChart
            data={unique}
            layout="vertical"
            margin={{ top: 10, right: 40, left: 120, bottom: 10 }}
          >
            <CartesianGrid horizontal={false} vertical={false} />
            <XAxis
              type="number"
              tickLine={false}
              domain={[0, 100]}
              ticks={[0, 20, 40, 60, 80, 100]}
            />
            <YAxis
              type="category"
              dataKey="name"
              tickLine={false}
              tick={CustomYAxisLabel}
            />
            <Tooltip />
            <Bar
              dataKey="value"
              fill="#8caaff"
              barSize={
                props?.dataNameValue?.length <= 3
                  ? 40
                  : props?.dataNameValue?.length <= 5
                  ? 30
                  : props?.dataNameValue?.length <= 8
                  ? 20
                  : 15
              }
            >
              <LabelList dataKey="value" position="right" />
            </Bar>
          </BarChart>
        </ResponsiveContainer>
      </div>
    </>
  );
};

export default HorizontalBarChart;
