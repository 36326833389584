import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  postInitiative,
  deleteMilestone,
  getMilestone,
  removeInitiative,
  getInitiative,
} from "../service/InitiativeApi";
import {
  initiativeDetailsModel,
  formValidationModel,
  toastModel,
  deleteRequest,
} from "../interface/InitiativeModel";
import MilestoneForm from "./MilestoneForm";
import ToastComponent from "./ToastComponent";
import moment from "moment";
import { filterModel, popupModel } from "../interface/AssesmentModel";
import DeletePopup from "./DeletePopup";
import BreadCrumbs from "./breadCrumb";
import Loader from "./Loader";
import { useMsal } from "@azure/msal-react";
import { isAuth } from "./Login/Auth";

const InitiativeForm = () => {
  /**PS_IF_04-PS_IF_07
   * Object are defined here
   */
  const initiativeDetails: initiativeDetailsModel = {
    organizationId: "",
    initiativeId: "",
    initiativeName: "",
    initiativeType: "",
    startDate: "",
    endDate: "",
    budget: "",
  };

  const formValidation: formValidationModel = {
    initiativeName: "",
    startDate: "",
    endDate: "",
    budget: "",
    initiativeType: "",
    initiativeNameRegex: "",
    budgetRegex: "",
  };

  let formValidationMsg: formValidationModel = {
    initiativeName: "Please enter initiative name",
    budget: "Please enter budget (In millions)",
    startDate: "Please choose the start date",
    endDate: "Please choose the end date",
    initiativeType: "Please select any Type",
    initiativeNameRegex: "Please enter a valid name",
    budgetRegex: "Please enter a valid budget",
  };

  const toastData: toastModel = {
    toastType: "",
    toastHeaderMessage: "",
    toastBodyMessage: "",
  };

  const filterData: filterModel = {
    initiativeId: "",
    milestoneId: "",
  };

  const popUpData: popupModel = {
    popupType: "",
    popupHeading: "",
    popupMessage: "",
  };

  /**PS_IF_08-PS_IF_11
   * state variables are defined here
   */

  const { state } = useLocation();
  const navigator = useNavigate();

  const [organizationId, setOrganizationId] = useState<string>(
    state.organizationId ? state.organizationId : ""
  );
  const [verticalId, setVerticalId] = useState<string>("");
  const [initiativeId, setInitiativeId] = useState<string>("");
  const [milestoneId, setMilestoneId] = useState<string>("");
  const [initiativeDet, setInitiativeDet] = useState(initiativeDetails);
  const [milestoneDet, setMilestoneDet] = useState<any>([]);
  const [formError, setFormError] =
    useState<formValidationModel>(formValidation);
  const [formErrorMsg, setFormErrorMsg] =
    useState<formValidationModel>(formValidationMsg);
  const [filterParams, setFilterParams] = useState<filterModel>(filterData);
  const [wizardFirst, setWizardFirst] = useState<string>("active");
  const [wizardSecond, setWizardSecond] = useState<string>("");
  const [hidePgOne, setHidePgOne] = useState<boolean>(false);
  const [hidePgTwo, setHidePgTwo] = useState<boolean>(true);
  const [callDelete, setCallDelete] = useState<boolean>(false);
  const [render, setRender] = useState<boolean>(false);
  const [hideToast, setHideToast] = useState<boolean>(true);
  const [popupParams, setPopupParams] = useState<popupModel>(popUpData);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [flag, setFlag] = useState<Boolean>(false);
  const [rerender, setRerender] = useState<boolean>(false);
  const [toastProp, setToastProp] = useState<toastModel>(toastData);
  const [canDelete, setCanDelete] = useState<boolean>(true);
  const [breadCrumbData, setBreadCrumbData] = useState([
    {
      title: state?.initiativeId ? "Roadmap Planning" : "Assessments",
      path: state?.initiativeId ? "/roadmapplanning" : "/homepage",
      isActive: true,
      state: state?.initiativeId
        ? {
            state: {
              organizationId: organizationId,
              organizationName: state?.organizationName,
              verticalId: state?.verticalId,
              verticalName: state?.verticalName,
              controlArea: "Initiative Estimation",
            },
          }
        : "",
    },

    { title: state?.organizationName, path: "", isActive: true },

    {
      title: state?.initiativeId ? "Edit Initiative" : "New Initiative",
      path: "",
      isActive: false,
    },
  ]);
  const [sortable, setsortable] = useState({
    milestoneName: "asc",
    startDate: "asc",
    endDate: "asc",
    plannedHours: "asc",
    resources: "asc",
  });
  const charRegex = /[-!$%^&*()_+|~={}\[\]:""'';<>?,\/]/;
  const numberRegex = /^\d{1,5}(\.\d{1,2})?$/;
  const emojiRegex =
    /[\u{1F600}-\u{1F64F}\u{1F300}-\u{1F5FF}\u{1F680}-\u{1F6FF}\u{1F700}-\u{1F77F}\u{1F780}-\u{1F7FF}\u{1F800}-\u{1F8FF}\u{1F900}-\u{1F9FF}\u{1FA00}-\u{1FA6F}\u{2600}-\u{26FF}\u{2700}-\u{27BF}]/u;
  const todayDate = moment().format("YYYY-MM-DD");
  const { accounts } = useMsal();

  /**PS_IF_13
   * This useEffect is used to call the getInitiativeDetails function
   */
  useEffect(() => {
    if (state.initiativeId) {
      getInitiativeDetails();
    }
  }, []);

  /**PS_IF_13
   * This useEffect is used to set the organization id and the vertical id
   * state in passed as its dependency
   */
  useEffect(() => {
    if (state) {
      setOrganizationId(state.organizationId);
      setVerticalId(state.verticalId);
    }
  }, [state]);

  /**PS_IF_13
   * This useEffect is used to call the getMilestoneData function
   * render in passed as its dependency
   */
  useEffect(() => {
    if (initiativeId != "") {
      getMilestoneData();
    }
  }, [render]);

  /**PS_IF_14
   * This function is used to call the removeMilestone function
   *  callDelete is passed as its parameter
   */
  useEffect(() => {
    if (callDelete) {
      removeMilestone();
    }
  }, [callDelete]);

  /**PS_IF_14
   * This function is used to call the removeMilestone function
   *  rerender is passed as its parameter
   */
  useEffect(() => {
    bindMilestoneGrid();
  }, [rerender]);

  /**PS_IF_14
   * Use Effect function which is used to handle the authorization
   */
  useEffect(() => {
    if (!isAuth(accounts)) navigator("/");
  }, []);

  /**PS_IF_14
   * Use Effect function which is used to handle the toast message hide toast is set as dependency
   */
  useEffect(() => {
    setTimeout(() => {
      setHideToast(true);
    }, 2500);
  }, [hideToast]);

  /**PS_IF_08-PS_IF_11
   * This function is used to call the getInitiative API
   */
  const getInitiativeDetails = async () => {
    try {
      setIsLoading(true);
      let requestObj = {
        organizationInfo: {
          organizationId: state.organizationId,
          initiativeId: state.initiativeId,
        },
      };
      let response = await getInitiative(requestObj);
      if (response.status != 200) {
        setIsLoading(false);
        let toastData = {
          toastType: "error",
          toastHeaderMessage: "Error",
          toastBodyMessage: "Api Failed",
        };
        setToastProp(toastData);
        setHideToast(false);
        setMilestoneId("");
      } else {
        setIsLoading(false);
        setInitiativeDet(response.data[0]);
        setInitiativeId(response.data[0].initiativeId);
      }
    } catch (error) {
      setIsLoading(false);
      let toastData = {
        toastType: "error",
        toastHeaderMessage: "Error",
        toastBodyMessage: "Api Failed",
      };
      setToastProp(toastData);
      setHideToast(false);
      setMilestoneId("");
    }
  };

  /**PS_IF_08-PS_IF_11
   * This function is used to call the getMilestone API
   */
  const getMilestoneData = async () => {
    try {
      setIsLoading(true);
      const response = await getMilestone(filterParams);
      if (response.status !== 200) {
        setIsLoading(false);
        let toastData = {
          toastType: "error",
          toastHeaderMessage: "Error",
          toastBodyMessage: "Api Failed",
        };
        setToastProp(toastData);
        setHideToast(false);
        setMilestoneId("");
      } else {
        setIsLoading(false);
        response.data?.Milestones?.forEach((data: any) => {
          let tempCount = 0;
          data?.ResourceDetails?.forEach((resource: any) => {
            tempCount = tempCount + resource.resources;
          });
          data.resources = tempCount;
        });
        setMilestoneDet(
          response.data.Milestones ? response.data.Milestones : []
        );
        setRerender(!rerender);
        setMilestoneId("");
      }
    } catch (error) {
      setIsLoading(false);
      let toastData = {
        toastType: "error",
        toastHeaderMessage: "Error",
        toastBodyMessage: "Api Failed",
      };
      setToastProp(toastData);
      setHideToast(false);
      setMilestoneId("");
    }
  };

  /**PS_IF_08-PS_IF_11
   * This function is used to call the postInitiative API
   */
  const postInitiativeDetails = async () => {
    try {
      setIsLoading(true);
      initiativeDet.organizationId = organizationId;
      if (initiativeId) {
        initiativeDet.initiativeId = initiativeId;
      }
      let response = await postInitiative({ basicInfo: initiativeDet });
      if (response.status !== 200) {
        setIsLoading(false);
        let toastData = {
          toastType: "error",
          toastHeaderMessage: "Error",
          toastBodyMessage: "Api Failed",
        };
        setToastProp(toastData);
        setHideToast(false);
      } else {
        setIsLoading(false);
        setInitiativeId(response.data.initiativeId);
        setFilterParams({
          ...filterParams,
          ["initiativeId"]: response.data.initiativeId,
        });
        setRender(!render);
      }
    } catch (error) {
      setIsLoading(false);
      let toastData = {
        toastType: "error",
        toastHeaderMessage: "Error",
        toastBodyMessage: "Api Failed",
      };
      setToastProp(toastData);
      setHideToast(false);
    }
  };

  /**PS_IF_91-PS_IF_96
   * This function is used to delete the milestone
   * deleteMilestone API is called
   * tempObj is passed as the parameter
   */
  const removeMilestone = async () => {
    try {
      setIsLoading(true);
      let tempObj: deleteRequest = {
        organizationId: organizationId,
        verticalId: verticalId,
        initiativeId: initiativeId,
        milestoneId: milestoneId,
      };
      const response = await deleteMilestone(tempObj);
      if (response.status !== 200) {
        let toastData: toastModel = {
          toastType: "error",
          toastHeaderMessage: "Error",
          toastBodyMessage: "Api Failed",
        };
        setToastProp(toastData);
        setHideToast(false);
        setCallDelete(false);
      } else {
        setIsLoading(false);
        setRender(!render);
        setCallDelete(false);
      }
    } catch (error) {
      setIsLoading(false);
      let toastData: toastModel = {
        toastType: "error",
        toastHeaderMessage: "Error",
        toastBodyMessage: "Api Failed",
      };
      setToastProp(toastData);
      setHideToast(false);
    }
  };

  /**PS_IF_91-PS_IF_96
   * This function is used to remove the initiatives and its milestones
   * RempveInitiative API is called
   * tempObj is passed as the parameter
   */
  const cancelInitiative = async () => {
    try {
      setIsLoading(true);
      let tempObj: deleteRequest = {
        organizationId: organizationId,
        verticalId: verticalId,
        initiativeId: initiativeId,
      };
      const response = await removeInitiative(tempObj);
      if (response.status !== 200) {
        setIsLoading(false);
        let toastData: toastModel = {
          toastType: "error",
          toastHeaderMessage: "Error",
          toastBodyMessage: "Api Failed",
        };
        setToastProp(toastData);
        setHideToast(false);
      } else {
        setIsLoading(false);
        navigator("/homepage");
      }
    } catch (error) {
      setIsLoading(false);
      let toastData: toastModel = {
        toastType: "error",
        toastHeaderMessage: "Error",
        toastBodyMessage: "Api Failed",
      };
      setToastProp(toastData);
      setHideToast(false);
    }
  };

  /**PS_IF_08-PS_IF_11
   * This function is used to set the values entered by the user
   * event is accepted as the parameter
   */
  const setFormData = (event: any) => {
    if (event?.target?.id?.split("#")[0] === "initiativeType") {
      setInitiativeDet({
        ...initiativeDet,
        [event.target.id.split("#")[0]]: event.target.value,
      });
    } else {
      setInitiativeDet({
        ...initiativeDet,
        [event.target.id]: event.target.value,
      });
    }
  };

  /**PS_IF_08-PS_IF_11
   * This function is used to validate the form
   */
  const validateForm = async () => {
    let flag = true;
    if (!initiativeDet.initiativeName) {
      setFormError((previous: any) => ({
        ...previous,
        ["initiativeName"]: formValidationMsg.initiativeName,
      }));
      flag = false;
    }
    if (charRegex.test(initiativeDet.initiativeName)) {
      setFormError((previous: any) => ({
        ...previous,
        ["initiativeNameRegex"]: formValidationMsg.initiativeNameRegex,
      }));
      flag = false;
    }
    if (!initiativeDet.budget) {
      setFormError((previous: any) => ({
        ...previous,
        ["budget"]: formValidationMsg.budget,
      }));
      flag = false;
    }
    if (
      !numberRegex.test(initiativeDet.budget.toString()) ||
      Number(initiativeDet.budget) <= 0
    ) {
      setFormError((previous: any) => ({
        ...previous,
        ["budgetRegex"]: formValidationMsg.budgetRegex,
      }));
      flag = false;
    }
    if (!initiativeDet.initiativeType) {
      setFormError((previous: any) => ({
        ...previous,
        ["initiativeType"]: formValidationMsg.initiativeType,
      }));
      flag = false;
    }
    if (!initiativeDet.startDate) {
      setFormError((previous: any) => ({
        ...previous,
        ["startDate"]: formValidationMsg.startDate,
      }));
      flag = false;
    }
    if (!initiativeDet.endDate) {
      setFormError((previous: any) => ({
        ...previous,
        ["endDate"]: formValidationMsg.endDate,
      }));
      flag = false;
    }

    if (flag) {
      setInitiativeDet((prev: any) => ({
        ...prev,
        ["organizationId"]: organizationId,
      }));
      postInitiativeDetails();
      setHidePgOne(true);
      setHidePgTwo(false);
      setWizardFirst("active completed");
      setWizardSecond("active");
    }
    return flag;
  };

  window.onbeforeunload = function (event) {
    if (
      initiativeId &&
      canDelete &&
      state.path != "RoadmapPlanning" &&
      window.location.href?.split("//")?.toString()?.split("/")[1] ==
        "newinitiative"
    ) {
      cancelInitiative();
    }
  };

  /**PS_IF_08-PS_IF_11
   * This function is used to bind the milestone grid
   */
  const bindMilestoneGrid = () => {
    if (!milestoneDet || milestoneDet.length == 0) {
      return (
        <div>
          <div hidden={hidePgTwo}>
            <div className="container-fluid">
              <div className="row">
                <div className="d-flex justify-content-center">
                  <div className="col-11 col-sm-11 col-md-10 col-lg-8 col-xl-8">
                    <h3 className="font-18 font-semibold color-black mb-4 mt-4">
                      {initiativeDet?.initiativeName}
                    </h3>
                    <div className="d-md-flex justify-content-between align-items-center mb-4">
                      <h6 className="font-18  font-semibold color-black mb-2 mb-md-0">
                        Milestones
                      </h6>
                      <div className="d-grid gap-2 d-md-inline-flex justify-content-md-end">
                        <button
                          type="button"
                          className="btn btn-light btn-white font-14 font-semibold"
                          onClick={() => {
                            setMilestoneId("");
                            setFlag(true);
                          }}
                        >
                          <span className="me-2">
                            <img src="images/plus.svg" alt="plus" />
                          </span>
                          Create New Milestone
                        </button>
                      </div>
                    </div>
                    <div
                      className="tab-pane mb-5"
                      id="v-pills-costopt"
                      role="tabpanel"
                    >
                      <div className="row text-center">
                        <p className="font-16 font-medium color-grey-v3 my-5">
                          Sorry you don't have any milestones added. Please
                          click on
                          <span className="font-16 font-semibold color-grey-v1">
                            {" "}
                            "Create New Milestone"
                          </span>{" "}
                          to add Milestones.
                        </p>
                      </div>
                    </div>
                    <div className="d-md-flex justify-content-between mb-5 ">
                      <div className="d-grid gap-2 d-md-inline-flex justify-content-md-start mb-2 mb-md-0">
                        <button
                          type="button"
                          className="btn btn-secondary theme-secondary-btn font-14 font-semibold "
                          onClick={() => {
                            setHidePgOne(false);
                            setHidePgTwo(true);
                            setWizardFirst("active");
                            setWizardSecond("");
                          }}
                        >
                          Previous
                        </button>
                      </div>
                      <div className="d-grid gap-2 d-md-inline-flex justify-content-md-end">
                        <a
                          className="theme-link-btn font-14 font-semibold text-center cursor-pointer order-md-first"
                          onClick={() => {
                            if (state.path == "RoadmapPlanning") {
                              setCanDelete(false);
                              navigator("/roadmapplanning", {
                                state: {
                                  organizationId: organizationId,
                                  organizationName: state?.organizationName,
                                  verticalId: state?.verticalId,
                                  verticalName: state?.verticalName,
                                  controlArea: "Initiative Estimation",
                                },
                              });
                            } else {
                              cancelInitiative();
                            }
                          }}
                        >
                          Cancel
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {flag && (
              <MilestoneForm
                name={{
                  InitiativeId: initiativeId,
                  organizationId: organizationId,
                  verticalId: verticalId,
                  setRender: (param: boolean) => setRender(param),
                  minDate: initiativeDet.startDate,
                  maxDate: initiativeDet.endDate,
                  milestoneId: milestoneId,
                  setFlag: (param: boolean) => setFlag(param),
                  setIsLoading: (param: boolean) => setIsLoading(param),
                  isLoading: isLoading,
                }}
                flag={flag}
              />
            )}
          </div>
        </div>
      );
    } else {
      return (
        <div>
          <div hidden={hidePgTwo}>
            <div className="container-fluid">
              <div className="row">
                <div className="d-flex justify-content-center">
                  <div className="col-11 col-sm-11 col-md-10 col-lg-8 col-xl-8">
                    <h3 className="font-18 font-semibold color-black mb-4 mt-4">
                      {initiativeDet.initiativeName}
                    </h3>
                    <div className="d-md-flex justify-content-between align-items-center mb-4">
                      <h6 className="font-18  font-semibold color-black mb-2 mb-md-0">
                        Milestones
                      </h6>
                      <div className="d-grid gap-2 d-md-inline-flex justify-content-md-end">
                        <button
                          type="button"
                          className="btn btn-light btn-white font-14 font-semibold"
                          onClick={() => {
                            setMilestoneId("");
                            setFlag(true);
                          }}
                        >
                          <span className="me-2">
                            <img src="images/plus.svg" alt="plus" />
                          </span>
                          Create New Milestone
                        </button>
                      </div>
                    </div>
                    <div className="table-responsive mb-4">
                      <table className="table table-bordered themeTable ">
                        <thead className="font-semibold ">
                          <tr>
                            <th>
                              Milestone Name
                              <span className="ms-2 cursor-pointer">
                                <img
                                  onClick={() => {
                                    milestoneDet.sort((a: any, b: any) => {
                                      const sortOrder =
                                        sortable.milestoneName === "asc"
                                          ? 1
                                          : -1;
                                      return (
                                        sortOrder *
                                        a.milestoneName?.localeCompare(
                                          b.milestoneName
                                        )
                                      );
                                    });
                                    setsortable((prev: any) => ({
                                      ...prev,
                                      milestoneName: `${
                                        prev.milestoneName === "asc"
                                          ? "desc"
                                          : "asc"
                                      }`,
                                    }));
                                  }}
                                  src={`${
                                    sortable.milestoneName === "asc"
                                      ? "/images/sortarrow.svg"
                                      : "/images/sortarrowup.svg"
                                  }`}
                                  alt="sortarrow"
                                  title="Sort"
                                />
                              </span>
                            </th>
                            <th>
                              Start Date
                              <span className="ms-2 cursor-pointer">
                                <img
                                  onClick={() => {
                                    function sort(arr: any, orderBy: string) {
                                      arr.sort((a: any, b: any) => {
                                        if (orderBy == "asc")
                                          return moment(a.startDate).format(
                                            "MM-DD-YYYY"
                                          ) <
                                            moment(b.startDate).format(
                                              "MM-DD-YYYY"
                                            )
                                            ? 1
                                            : -1;
                                        else
                                          return moment(b.startDate).format(
                                            "MM-DD-YYYY"
                                          ) <
                                            moment(a.startDate).format(
                                              "MM-DD-YYYY"
                                            )
                                            ? 1
                                            : -1;
                                      });
                                      setsortable((prev: any) => ({
                                        ...prev,
                                        startDate: `${
                                          prev.startDate === "asc"
                                            ? "desc"
                                            : "asc"
                                        }`,
                                      }));
                                    }
                                    sort(
                                      milestoneDet,
                                      sortable.startDate === "asc"
                                        ? "desc"
                                        : "asc"
                                    );
                                  }}
                                  src={`${
                                    sortable.startDate === "asc"
                                      ? "/images/sortarrow.svg"
                                      : "/images/sortarrowup.svg"
                                  }`}
                                  alt="sortarrow"
                                  title="Sort"
                                />
                              </span>
                            </th>
                            <th>
                              End Date
                              <span className="ms-2 cursor-pointer">
                                <img
                                  onClick={() => {
                                    function sort(arr: any, orderBy: string) {
                                      arr.sort((a: any, b: any) => {
                                        if (orderBy == "asc")
                                          return moment(a.endDate).format(
                                            "MM-DD-YYYY"
                                          ) <
                                            moment(b.endDate).format(
                                              "MM-DD-YYYY"
                                            )
                                            ? 1
                                            : -1;
                                        else
                                          return moment(b.endDate).format(
                                            "MM-DD-YYYY"
                                          ) <
                                            moment(a.endDate).format(
                                              "MM-DD-YYYY"
                                            )
                                            ? 1
                                            : -1;
                                      });
                                      setsortable((prev: any) => ({
                                        ...prev,
                                        endDate: `${
                                          prev.endDate === "asc"
                                            ? "desc"
                                            : "asc"
                                        }`,
                                      }));
                                    }
                                    sort(
                                      milestoneDet,
                                      sortable.endDate === "asc"
                                        ? "desc"
                                        : "asc"
                                    );
                                  }}
                                  src={`${
                                    sortable.endDate === "asc"
                                      ? "/images/sortarrow.svg"
                                      : "/images/sortarrowup.svg"
                                  }`}
                                  alt="sortarrow"
                                  title="Sort"
                                />
                              </span>
                            </th>
                            <th>
                              Planned Hours
                              <span className="ms-2 cursor-pointer">
                                <img
                                  onClick={() => {
                                    function sort(arr: any, orderBy: string) {
                                      arr.sort((a: any, b: any) => {
                                        const aStart = Number(a.plannedHours);
                                        const bStart = Number(b.plannedHours);
                                        return orderBy == "asc"
                                          ? aStart - bStart
                                          : bStart - aStart;
                                      });
                                      setsortable((prev: any) => ({
                                        ...prev,
                                        plannedHours: `${
                                          prev.plannedHours === "asc"
                                            ? "desc"
                                            : "asc"
                                        }`,
                                      }));
                                    }
                                    sort(
                                      milestoneDet,
                                      sortable.plannedHours === "asc"
                                        ? "desc"
                                        : "asc"
                                    );
                                  }}
                                  src={`${
                                    sortable.plannedHours === "asc"
                                      ? "/images/sortarrow.svg"
                                      : "/images/sortarrowup.svg"
                                  }`}
                                  alt="sortarrow"
                                  title="Sort"
                                />
                              </span>
                            </th>
                            <th className="text-center">
                              # Resources
                              <span className="ms-2 cursor-pointer">
                                <img
                                  onClick={() => {
                                    function sort(arr: any, orderBy: string) {
                                      arr.sort((a: any, b: any) => {
                                        const aStart = Number(a.resources);
                                        const bStart = Number(b.resources);
                                        return orderBy == "asc"
                                          ? aStart - bStart
                                          : bStart - aStart;
                                      });
                                      setsortable((prev: any) => ({
                                        ...prev,
                                        resources: `${
                                          prev.resources === "asc"
                                            ? "desc"
                                            : "asc"
                                        }`,
                                      }));
                                    }
                                    sort(
                                      milestoneDet,
                                      sortable.resources === "asc"
                                        ? "desc"
                                        : "asc"
                                    );
                                  }}
                                  src={`${
                                    sortable.resources === "asc"
                                      ? "/images/sortarrow.svg"
                                      : "/images/sortarrowup.svg"
                                  }`}
                                  alt="sortarrow"
                                  title="Sort"
                                />
                              </span>
                            </th>
                            <th className="text-center">Actions</th>
                          </tr>
                        </thead>
                        <tbody className="font-medium">
                          {milestoneDet?.map((milestone: any) => {
                            let tempCount = 0;
                            return (
                              <tr>
                                <td>{milestone?.milestoneName}</td>
                                <td className=" ">
                                  {moment(milestone?.startDate).format(
                                    "MM-DD-YYYY"
                                  )}
                                </td>
                                <td className=" ">
                                  {moment(milestone?.endDate).format(
                                    "MM-DD-YYYY"
                                  )}
                                </td>
                                <td className=" ">
                                  {milestone?.plannedHours}hrs
                                </td>
                                {milestone?.ResourceDetails?.forEach(
                                  (res: any) => {
                                    tempCount += Number(res.resources);
                                  }
                                )}
                                <td className="text-end">{tempCount}</td>
                                <td className="text-center">
                                  <span className="cursor-pointer">
                                    <img
                                      src="images/editIcon.svg"
                                      alt={milestone.milestoneId}
                                      title="Edit"
                                      onClick={(event: any) => {
                                        setMilestoneId(event.target.alt);
                                        setFlag(true);
                                      }}
                                    />
                                  </span>
                                  <span className={milestoneDet.length==1 && state?.initiativeId ? 'px-2' :'px-2 cursor-pointer'}>
                                    {milestoneDet.length==1 && state?.initiativeId ?<img
                                      src="images/deleteIcon.svg"
                                      alt={milestone.milestoneId}
                                      title="Delete"
                                    />:
                                    <img
                                    src="images/deleteIcon.svg"
                                    data-bs-toggle="modal"
                                    data-bs-target="#delete"
                                    alt={milestone.milestoneId}
                                    title="Delete"
                                    onClick={(event: any) => {
                                      
                                      setMilestoneId(event.target.alt);
                                      let tempObj: popupModel = {
                                        popupHeading: "Delete",
                                        popupType: "",
                                        popupMessage:
                                          "Are you sure you want to delete this milestone ?",
                                        setCallDelete: (param: boolean) =>
                                          setCallDelete(param),
                                      };
                                      setPopupParams(tempObj);
                                    }}
                                  /> }
                                  </span>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                    <div className="d-md-flex justify-content-between mb-5 ">
                      <div className="d-grid gap-2 d-md-inline-flex justify-content-md-start mb-2 mb-md-0">
                        <button
                          type="button"
                          className="btn btn-secondary theme-secondary-btn font-14 font-semibold "
                          onClick={() => {
                            setHidePgOne(false);
                            setHidePgTwo(true);
                            setWizardFirst("active");
                            setWizardSecond("");
                          }}
                        >
                          Previous
                        </button>
                      </div>
                      <div className="d-grid gap-2 d-md-inline-flex justify-content-md-end">
                        <button
                          type="button"
                          className="btn btn-dark theme-primary-btn border-0 font-14 font-semibold "
                          onClick={() => {
                            setCanDelete(false);
                            navigator("/roadmapplanning", {
                              state: {
                                organizationId: organizationId,
                                organizationName: state?.organizationName,
                                verticalId: state?.verticalId,
                                verticalName: state?.verticalName,
                                controlArea: "Initiative Estimation",
                              },
                            });
                          }}
                        >
                          Save
                        </button>
                        <a
                          className="theme-link-btn font-14 font-semibold text-center cursor-pointer order-md-first"
                          onClick={() => {
                            if (state.path == "RoadmapPlanning") {
                              setCanDelete(false);
                              navigator("/roadmapplanning", {
                                state: {
                                  organizationId: organizationId,
                                  organizationName: state?.organizationName,
                                  verticalId: state?.verticalId,
                                  verticalName: state?.verticalName,
                                  controlArea: "Initiative Estimation",
                                },
                              });
                            } else {
                              cancelInitiative();
                            }
                          }}
                        >
                          Cancel
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {flag && (
              <MilestoneForm
                name={{
                  InitiativeId: initiativeId,
                  organizationId: organizationId,
                  verticalId: verticalId,
                  setRender: (param: boolean) => setRender(param),
                  minDate: initiativeDet.startDate,
                  maxDate: initiativeDet.endDate,
                  milestoneId: milestoneId,
                  setFlag: (param: boolean) => setFlag(param),
                  setIsLoading: (param: boolean) => setIsLoading(param),
                  isLoading: isLoading,
                }}
                flag={flag}
              />
            )}
          </div>
        </div>
      );
    }
  };

  return (
    <>
      <Loader isLoading={isLoading} />
      <>
        <div>
          <div className="container-fluid">
            <div className="row">
              {/* breadcrumb starts here */}
              <div className="px-4">
                <div className="mt-5 pt-5 font-12 font-semibold">
                  <BreadCrumbs data={breadCrumbData} />
                </div>
                <h2 className="font-22 font-semibold mt-4 color-black  mb-5">
                  <a className="me-3 cursor-pointer">
                    <img
                      src="images/backarrow.svg"
                      alt="backarrow"
                      title="Back"
                      onClick={() => {
                        if (state.path == "RoadmapPlanning") {
                          setCanDelete(false);
                          navigator("/roadmapplanning", {
                            state: {
                              organizationId: organizationId,
                              organizationName: state?.organizationName,
                              verticalId: state?.verticalId,
                              verticalName: state?.verticalName,
                              controlArea: "Initiative Estimation",
                            },
                          });
                        } else {
                          cancelInitiative();
                        }
                      }}
                    />
                  </a>
                  {state.initiativeId ? "Edit Initiative" : "New Initiative"}
                </h2>
              </div>
              <div className="d-flex justify-content-center w-100 mb-4 pb-3 border-bottom">
                <div className="wizard-conatiner d-flex justify-content-between">
                  <div className={`wiz-step ${wizardFirst}`}>
                    <span className="wiz-count font-semibold font-14 me-2 ">
                      1
                    </span>
                    <span className="font-semibold d-none d-sm-inline-block">
                      Basic Info
                    </span>
                  </div>
                  <div className={`wiz-step ${wizardSecond}`}>
                    <span className="wiz-count font-semibold font-14 me-2">
                      2
                    </span>
                    <span className="font-semibold d-none d-sm-inline-block">
                      Milestones
                    </span>
                  </div>
                </div>
              </div>
              <div hidden={hidePgOne}>
                <div className="d-flex justify-content-center">
                  <div className="col-sm-11 col-md-10 col-lg-8 col-xl-5">
                    <h3 className="font-18 font-semibold color-black mt-5 mb-5">
                      Basic Info
                    </h3>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="mb-5">
                          <label
                            htmlFor="initiativeName"
                            className="form-label font-14 font-semibold color-black"
                          >
                            Initiative Name<span className="color-red"> *</span>
                          </label>
                          <input
                            type="text"
                            className="form-control theme-form font-regular font-14"
                            id="initiativeName"
                            maxLength={30}
                            placeholder="Enter Initiative Name"
                            value={initiativeDet.initiativeName}
                            onChange={(e) => {
                              if (!emojiRegex.test(e.target.value)) {
                                charRegex.test(e.target.value)
                                  ? setFormError({
                                      ...formError,
                                      ["initiativeNameRegex"]:
                                        formErrorMsg.initiativeNameRegex,
                                    })
                                  : setFormError({
                                      ...formError,
                                      ["initiativeNameRegex"]: "",
                                    });
                                setFormData(e);
                              }
                            }}
                          />
                          {(formError.initiativeName &&
                            !initiativeDet.initiativeName) ||
                          formError.initiativeNameRegex ? (
                            <p className="mt-2 mb-0 color-red font-14 font-regular">
                              {formError.initiativeNameRegex
                                ? formError.initiativeNameRegex
                                : formError.initiativeName}
                            </p>
                          ) : (
                            <p className="mt-2 mb-0 color-red font-14 font-regular"></p>
                          )}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="mb-5">
                          <label className="form-label font-14 font-semibold color-black d-flex">
                            Type<span className="color-red"> *</span>
                          </label>
                          <div className="form-check form-check-inline mt-2 me-4">
                            <input
                              className="form-check-input theme-radio me-2"
                              type="radio"
                              name="initiativeType"
                              id="initiativeType#0"
                              value={"Transformation"}
                              checked={
                                initiativeDet.initiativeType == "Transformation"
                                  ? true
                                  : false
                              }
                              onChange={(e) => {
                                setFormData(e);
                              }}
                            />
                            <label
                              className="form-check-label font-regular font-14"
                              htmlFor="initiativeType#0"
                            >
                              Transformation
                            </label>
                          </div>
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input theme-radio me-2"
                              type="radio"
                              name="initiativeType"
                              id="initiativeType#1"
                              value={"RTE"}
                              checked={
                                initiativeDet.initiativeType == "RTE"
                                  ? true
                                  : false
                              }
                              defaultValue={2}
                              onChange={(e) => {
                                setFormData(e);
                              }}
                            />
                            <label
                              className="form-check-label font-regular font-14"
                              htmlFor="initiativeType#1"
                            >
                              RTE
                            </label>
                          </div>
                          {formError.initiativeType &&
                          initiativeDet.initiativeType == "" ? (
                            <p className="mt-2 mb-0 color-red font-14 font-regular">
                              {formError.initiativeType}
                            </p>
                          ) : (
                            <p className="mt-2 mb-0 color-red font-14 font-regular"></p>
                          )}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="mb-5">
                          <label
                            htmlFor="startDate"
                            className="form-label font-14 font-semibold color-black"
                          >
                            Start Date<span className="color-red"> *</span>
                          </label>
                          <input
                            type="date"
                            className="form-control theme-form font-regular font-14"
                            id="startDate"
                            value={moment(initiativeDet?.startDate).format(
                              "YYYY-MM-DD"
                            )}
                            min={todayDate}
                            max={moment(initiativeDet?.endDate).format(
                              "YYYY-MM-DD"
                            )}
                            onChange={(e) => {
                              setInitiativeDet(() => ({
                                ...initiativeDet,
                                ["endDate"]: "",
                              }));
                              setFormData(e);
                            }}
                            onKeyDown={(e) => {
                              e.preventDefault();
                            }}
                          />
                          {formError.startDate &&
                          initiativeDet.startDate === "" ? (
                            <p className="mt-2 mb-0 color-red font-14 font-regular">
                              {formError.startDate}
                            </p>
                          ) : (
                            <p className="mt-2 mb-0 color-red font-14 font-regular"></p>
                          )}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="mb-5">
                          <label
                            htmlFor="endDate"
                            className="form-label font-14 font-semibold color-black"
                          >
                            End Date<span className="color-red"> *</span>
                          </label>
                          <input
                            type="date"
                            className="form-control theme-form font-regular font-14"
                            id="endDate"
                            min={moment(initiativeDet?.startDate)
                              .add(1, "days")
                              .format("YYYY-MM-DD")}
                            value={moment(initiativeDet?.endDate).format(
                              "YYYY-MM-DD"
                            )}
                            onChange={(e) => {
                              setFormData(e);
                            }}
                            onKeyDown={(e) => {
                              e.preventDefault();
                            }}
                          />
                          {formError.endDate && initiativeDet.endDate === "" ? (
                            <p className="mt-2 mb-0 color-red font-14 font-regular">
                              {formError.endDate}
                            </p>
                          ) : (
                            <p className="mt-2 mb-0 color-red font-14 font-regular"></p>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="row mb-4">
                      <div className="col-md-6">
                        <div className="mb-5">
                          <label
                            htmlFor="Budget"
                            className="form-label font-14 font-semibold color-black"
                          >
                            Budget (In millions)
                            <span className="color-red"> *</span>
                          </label>
                          <div className="input-group theme-group-input mb-3">
                            <span
                              className="input-group-text font-regular font-16 color-grey"
                              id="basic-addon1"
                            >
                              $
                            </span>
                            <input
                              type="text"
                              className="form-control theme-form  text-end font-regular font-14"
                              aria-label="Budget"
                              aria-describedby="basic-addon1"
                              maxLength={10}
                              placeholder="0.00"
                              id="budget"
                              value={initiativeDet?.budget}
                              onChange={(e) => {
                                if (!emojiRegex.test(e.target.value)) {
                                  e.target.value
                                    ? setFormError({
                                        ...formError,
                                        ["budget"]: "",
                                      })
                                    : setFormError({
                                        ...formError,
                                        ["budget"]: formErrorMsg.budget,
                                      });
                                  !numberRegex.test(e.target.value)
                                    ? setFormError({
                                        ...formError,
                                        ["budgetRegex"]:
                                          formErrorMsg.budgetRegex,
                                      })
                                    : setFormError({
                                        ...formError,
                                        ["budgetRegex"]: "",
                                      });
                                  setFormData(e);
                                }
                              }}
                            />
                          </div>
                          {(formError.budget && !initiativeDet.budget) ||
                          formError.budgetRegex ? (
                            <p className="mt-2 mb-0 color-red font-14 font-regular">
                              {formError.budget
                                ? formError.budget
                                : formError.budgetRegex}
                            </p>
                          ) : (
                            <p className="mt-2 mb-0 color-red font-14 font-regular"></p>
                          )}
                        </div>
                      </div>
                    </div>
                    {/* <div class="row justify-content-end mb-5"> */}
                    <div className="d-grid gap-2 d-md-inline-flex justify-content-md-end mb-5 w-100">
                      <button
                        type="button"
                        className="btn btn-dark theme-primary-btn border-0 font-14 font-semibold "
                        onClick={() => {
                          if (state.path == "RoadmapPlanning") {
                            setCanDelete(false);
                          }
                          validateForm();
                        }}
                      >
                        Next
                      </button>
                      <a
                        className="theme-link-btn font-14 font-semibold text-center order-md-first cursor-pointer"
                        onClick={() => {
                          if (state.path == "RoadmapPlanning") {
                            setCanDelete(false);
                            navigator("/roadmapplanning", {
                              state: {
                                organizationId: organizationId,
                                organizationName: state?.organizationName,
                                verticalId: state?.verticalId,
                                verticalName: state?.verticalName,
                                controlArea: "Initiative Estimation",
                              },
                            });
                          } else {
                            if (initiativeId) {
                              cancelInitiative();
                            } else {
                              navigator("/homepage");
                            }
                          }
                        }}
                      >
                        Cancel
                      </a>
                    </div>
                    {/* </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {bindMilestoneGrid()}
        {flag && <div className="modal-backdrop fade show"></div>}
        <DeletePopup name={popupParams} />
        {hideToast ? <></> : <ToastComponent name={toastProp} />}
      </>
    </>
  );
};

export default InitiativeForm;
