import { useState } from "react";
import { AppDevConnectorPropsModel } from "../../interface/questionnaireInterface";

export default function IAMConnector(props: AppDevConnectorPropsModel) {
  const {
    activeConnector,
    validateConnectorInput,
    connectorsOnChange,
    setShowConnectorForm,
    connectorFormData,
    setConnectorFormData,
    connectorFormValidationMsg,
    connectorApiValidationMsg,
    resetValidationMessages,
  } = props;

  const bindConnectorsForm = () => {
    const bindSaveAndCancel = () => {
      return (
        <div className="d-grid gap-2 d-md-inline-flex justify-content-md-end mb-5 w-100">
          <button
            type="button"
            className="btn btn-dark theme-primary-btn border-0 font-14 font-semibold "
            onClick={() => {
              validateConnectorInput(null);
            }}
          >
            Save
          </button>
          <a
            className="theme-link-btn cursor-pointer  font-semibold text-center order-md-first"
            onClick={() => {
              setConnectorFormData({});
              resetValidationMessages(activeConnector);
              setShowConnectorForm(true);
            }}
          >
            Cancel
          </a>
        </div>
      );
    };
    switch (activeConnector) {
      case "Azure AD":
        return (
          <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-4">
            <h3 className="font-18 font-semibold color-black mt-4 mb-4">
              Add Azure AD
            </h3>
            <div className="col-lg-8 col-md-12 col-12 mb-4">
              <div className="row">
                <div className="col-12 col-md-12 col-lg-6">
                  <div className="mb-5">
                    <label
                      htmlFor="Microsoft Primary Domain"
                      className="form-label font-14 font-semibold color-black"
                    >
                      Microsoft Primary Domain
                    </label>{" "}
                    <span className="color-red"> *</span>
                    <input
                      type="text"
                      className="form-control theme-form font-regular font-14"
                      id="Microsoft Primary Domain"
                      name="Microsoft Primary Domain"
                      placeholder="Enter Microsoft Primary Domain"
                      value={connectorFormData["Microsoft Primary Domain"]}
                      onChange={(e) => {
                        connectorsOnChange(e);
                      }}
                      disabled
                    />
                    <p className="mt-2 mb-0 color-red font-14 font-regular">
                      {connectorFormValidationMsg["Microsoft Primary Domain"]}
                    </p>
                  </div>
                </div>
                <div className="col-12 col-md-12 col-lg-6">
                  <div className="mb-5">
                    <label
                      htmlFor="Enterprise Application ID"
                      className="form-label font-14 font-semibold color-black"
                    >
                      Enterprise Application ID
                    </label>
                    <span className="color-red"> *</span>
                    <input
                      type="text"
                      className="form-control theme-form font-regular font-14"
                      id="Enterprise Application ID"
                      name="Enterprise Application ID"
                      placeholder="Enter Enterprise Application ID"
                      value={connectorFormData["Enterprise Application ID"]}
                      onChange={(e) => {
                        connectorsOnChange(e);
                      }}
                    />
                    <p className="mt-2 mb-0 color-red font-14 font-regular">
                      {connectorFormValidationMsg["Enterprise Application ID"]}
                    </p>
                  </div>
                </div>
                <div className="col-12 col-md-12 col-lg-6">
                  <div className="mb-5">
                    <label
                      htmlFor="Client Secret"
                      className="form-label font-14 font-semibold color-black"
                    >
                      Client Secret
                    </label>
                    <span className="color-red"> *</span>
                    <input
                      type="password"
                      className="form-control theme-form font-regular font-14"
                      id="Client Secret"
                      name="Client Secret"
                      placeholder="Enter Client Secret"
                      value={connectorFormData["Client Secret"]}
                      onChange={(e) => {
                        connectorsOnChange(e);
                      }}
                    />
                    <p className="mt-2 mb-0 color-red font-14 font-regular">
                      {connectorFormValidationMsg["Client Secret"]}
                    </p>
                  </div>
                </div>
                <div className="col-12 col-md-12 col-lg-6">
                  <div className="mb-5">
                    <label
                      htmlFor="Tenant ID"
                      className="form-label font-14 font-semibold color-black"
                    >
                      Tenant ID
                    </label>
                    <span className="color-red"> *</span>
                    <input
                      type="text"
                      className="form-control theme-form font-regular font-14"
                      id="Tenant ID"
                      name="Tenant ID"
                      placeholder="Enter Tenant ID"
                      value={connectorFormData["Tenant ID"]}
                      onChange={(e) => {
                        connectorsOnChange(e);
                      }}
                    />
                    <p className="mt-2 mb-0 color-red font-14 font-regular">
                      {connectorFormValidationMsg["Tenant ID"]}
                    </p>
                  </div>
                </div>
                <p className="mt-1 mb-0 color-red font-14 font-regular">
                  {connectorApiValidationMsg}
                </p>
                {bindSaveAndCancel()}
              </div>
            </div>
          </div>
        );

      case "Okta":
        return (
          <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-4">
            <h3 className="font-18 font-semibold color-black mt-4 mb-4">
              Add Okta
            </h3>
            <div className="col-lg-8 col-md-12 col-12 mb-4">
              <div className="row">
                <div className="col-12 col-md-12 col-lg-6">
                  <div className="mb-5">
                    <label
                      htmlFor="Okta Primary Domain"
                      className="form-label font-14 font-semibold color-black"
                    >
                      Okta Primary Domain
                    </label><span className="color-red"> *</span>
                    <input
                      type="text"
                      className="form-control theme-form font-regular font-14"
                      id="Okta Primary Domain"
                      name="Okta Primary Domain"
                      placeholder="Enter Okta Primary Domain"
                      value={connectorFormData["Okta Primary Domain"]}
                      onChange={(e) => {
                        connectorsOnChange(e);
                      }}
                    />
                    <p className="mt-2 mb-0 color-red font-14 font-regular">
                      {connectorFormValidationMsg["Okta Primary Domain"]}
                    </p>
                  </div>
                </div>
                <div className="col-12 col-md-12 col-lg-6">
                  <div className="mb-5">
                    <label
                      htmlFor="Okta Token Endpoint"
                      className="form-label font-14 font-semibold color-black"
                    >
                      Okta Token Endpoint
                    </label><span className="color-red"> *</span>
                    <input
                      type="text"
                      className="form-control theme-form font-regular font-14"
                      id="Okta Token Endpoint"
                      name="Okta Token Endpoint"
                      placeholder="Enter Okta Token Endpoint"
                      value={connectorFormData["Okta Token Endpoint"]}
                      onChange={(e) => {
                        connectorsOnChange(e);
                      }}
                    />
                    <p className="mt-2 mb-0 color-red font-14 font-regular">
                      {connectorFormValidationMsg["Okta Token Endpoint"]}
                    </p>
                  </div>
                </div>
                <div className="col-12 col-md-12 col-lg-6">
                  <div className="mb-5">
                    <label
                      htmlFor="Client ID"
                      className="form-label font-14 font-semibold color-black"
                    >
                      Client ID
                    </label><span className="color-red"> *</span>
                    <input
                      type="text"
                      className="form-control theme-form font-regular font-14"
                      id="Client ID"
                      name="Client ID"
                      placeholder="Enter Client ID"
                      value={connectorFormData["Client ID"]}
                      onChange={(e) => {
                        connectorsOnChange(e);
                      }}
                    />
                    <p className="mt-2 mb-0 color-red font-14 font-regular">
                      {connectorFormValidationMsg["Client ID"]}
                    </p>
                  </div>
                </div>
                <div className="col-12 col-md-12 col-lg-6">
                  <div className="mb-5">
                    <label
                      htmlFor="Client Secret"
                      className="form-label font-14 font-semibold color-black"
                    >
                      Client Secret
                    </label><span className="color-red"> *</span>
                    <input
                      type="password"
                      className="form-control theme-form font-regular font-14"
                      id="Client Secret"
                      name="Client Secret"
                      placeholder="Enter Client Secret"
                      value={connectorFormData["Client Secret"]}
                      onChange={(e) => {
                        connectorsOnChange(e);
                      }}
                    />
                    <p className="mt-2 mb-0 color-red font-14 font-regular">
                      {connectorFormValidationMsg["Client Secret"]}
                    </p>
                  </div>
                </div>
                <p className="mt-1 mb-0 color-red font-14 font-regular">
                  {connectorApiValidationMsg}
                </p>
                {bindSaveAndCancel()}
              </div>
            </div>
          </div>
        );
    }
  };

  return <>{bindConnectorsForm()}</>;
}
