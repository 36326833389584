import { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from "react";
import Inventory from "./Inventory";
import Questionnaire from "./InfraQuestionnaire";
import Portfolio from "./PortfolioDiscovery";
import PortfolioComponent from "./PortfolioDiscovery";
import { getInfraAssessmentApplications, getInfraAssessmentMenus, reportGenerationButton } from "../service/InfraAssessmentApi";
import { MenuItem, ApplicationModel } from "../interface/InfraAssessmentModel";
import MigrationStrategy from "./MigrationStrategy";
import MigrationPlan from "./MigrationPlan";
import Loader from "./Loader";
import { useLocation, useNavigate } from "react-router-dom";
import BreadCrumbs from "./breadCrumb";
import { stat } from "fs";
import { getLeftNavigation } from "../service/QuestionnaireApi";
import Connectors from "./Connectors";


const InfraAssessment = () => {

    const { state } = useLocation();
    const navigate = useNavigate();
    const [showToasterPopup, setShowToasterPopup] = useState(false);
    const [showToasterLoading, setShowToasterLoading] = useState(false);
    const [showToasterFailedPopup, setShowToasterFailedPopup] = useState(false);
    const [showToaster,setShowToaster] = useState(false)
    const [menus, setMenus] = useState<MenuItem[]>([]);
    const [activeTab, setActiveTab] = useState<string>("");
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [selectedMenuId, setSelectedMenuId] = useState<string>("");
    const [showDropdown, setShowDropdown] = useState(false);
    const [applicationDropDown, setApplicationDropDown] = useState<ApplicationModel[]>([])
    const [selectedApplicationId, setSelectedApplicationId] = useState('');
    const [showGenerateButton, setShowGenerateButton] = useState(false);
    const [leftNavigationData, setLeftNavigationData] = useState<any[]>([]);
    const [activeLeftNavigation, setActiveLeftNavigation] = useState({
        id: "",
        name: "",
        type: "connector",
    });
    const [connectedConnectors, setConnectedConnectors] = useState<any[]>([]);
    const [snControlAreaSelectedValues, setSnControlAreaSelectedValues] = useState<string[]>([]);
    let [isConnectorProvided, setIsConnectorProvided] = useState<boolean>(false);
    const [snSubMenuSelectedValues, setSnSubMenuSelectedValues] = useState<string[]>([]);
    const setIsLoadingCallback: Dispatch<SetStateAction<boolean>> = useCallback((value) => {
        setIsLoading(value);
    }, []);

    const setGenerateButtonCallback: Dispatch<SetStateAction<boolean>> = useCallback((value) => {
        setShowGenerateButton(value);
    }, []);

    const setConnectedConnectorsCallback: Dispatch<SetStateAction<any[]>> = useCallback((connectors) => {
        setConnectedConnectors(connectors);
    }, []);

    const activeIds = useMemo(() => ({
        organizationId: state.organizationId,
        verticalId: state.verticalId,
        projectId: state.projectId,
        organizationName: state.organizationName,
        verticalName: "MAP",
        mode: state.mode || ""
    }), [state]);


    const [breadCrumbData, setBreadCrumbData] = useState([
        { title: "Organization", path: "/homepage", isActive: true },
        { title: state.organizationName, path: "", isActive: true },
        { title: state.verticalName, path: "", isActive: false },
    ]);

    useEffect(() => {
        getInitialPageData();
    }, []);

    useEffect(() => {
        getReportGenerationButtonFlag()
    })

    useEffect(() => {
        const handleBeforeUnload = (e: any) => {
            e.preventDefault(); // Prevent the page from reloading
            e.returnValue =
                "You may have unsaved changes. Are you sure you want to reload or leave?"; // Some browsers require a return value
        };

        window.onbeforeunload = function () {
            return "You may have unsaved changes. Are you sure you want to reload or leave?";
        };
        window.addEventListener("beforeunload", handleBeforeUnload);
    }, []);

    const getReportGenerationButtonFlag = async () => {
        const generateButtonPayload = {
            organizationId: state.organizationId,
            verticalId: state.verticalId,
            projectId: state.projectId
        }
        const reportGenerationButtonRes = await reportGenerationButton(generateButtonPayload);
        if (reportGenerationButtonRes.status === 200) {
            setShowGenerateButton(reportGenerationButtonRes.data.enableInfraReportGenerationButton)
        }
    }


    const getInitialPageData = async () => {
        try {
            const menuResponse = await getLeftNavigation(state.verticalId);
            if (menuResponse.length > 0) {
                setLeftNavigationData(menuResponse);
                const firstItem = menuResponse[0];
                if (firstItem.connectors && firstItem.connectors.length > 0) {
                    setActiveLeftNavigation({
                        id: firstItem.connectors[0].connectorId,
                        name: firstItem.connectors[0].connector,
                        type: "connector",
                    });
                } else if (firstItem.controlAreas && firstItem.controlAreas.length > 0) {
                    setActiveLeftNavigation({
                        id: firstItem.controlAreas[0].controlAreaId,
                        name: firstItem.controlAreas[0].controlArea,
                        type: "controlArea",
                    });
                }
            }
        } catch (error) {
            // console.error("Error fetching initial data:", error);
        }
    };

    const handleLeftNavigationClick = (id: string, name: string, type: string) => {
        setActiveLeftNavigation({ id, name, type });
        if (name === "Migration Strategy - 7R’s") {
            getApplicationDetails();
            setShowDropdown(true);
        } else {
            setShowDropdown(false);
        }
    };

    const handleTabChange = async (tabName: string, menuId: string) => {
        setActiveTab(tabName);
        setSelectedMenuId(menuId);
        if (tabName === menus[3]?.menuName) {
            await getApplicationDetails();
            setShowDropdown(true);
        } else {
            setShowDropdown(false);
        }
    };

    const getApplicationDetails = async () => {
        const applicationPayload = {
            organizationId: state.organizationId,
            verticalId: state.verticalId,
            projectId: state.projectId,
        };
        // PS_MigrationStrategy_16
        const applicationResponse = await getInfraAssessmentApplications(applicationPayload); // Replace with your actual API call
        if (applicationResponse.status !== 200) {
            //TODO: add api failed toast
            return;
        }
        if (applicationResponse.data && applicationResponse.data.length > 0) {
            setApplicationDropDown(applicationResponse.data);
            setSelectedApplicationId(applicationResponse.data[0].applicationId);
        } else {
            setApplicationDropDown([]);
            setSelectedApplicationId("");
        }
    }


    const bindApplicationDropdown = () => {
        return applicationDropDown.map((data) => {
            return <option key={data.applicationId} value={data.applicationId}> {data.applicationName}</option>
        })
    }

    const onApplicationChange = (event: any) => {
        setSelectedApplicationId(event.target.value);
    };

    const navigateReportGrid = () => {
        navigate("/reports", {
            state: {
                organizationId: state.organizationId,
                organizationName: state.organizationName,
                verticalId: state.verticalId,
                verticalName: state.verticalName,
                // verticalAssessmentTypeId: state.infraAssessmentTypeId,
                // verticalAssessmentTypeName: state.infraAssessmentTypeName,
                projectId: state.projectId,
                projectName: "",
                projectStatus: "",
                IsInitialReportGeneration: true,
            }
        }
        )
    }

    const bindLeftNavigation = () => {
        return leftNavigationData.map((mainPanel: any, index: number) => (
            <div key={index} className="col-12 col-md-12 col-lg-3 col-xl-2 pe-0">
                <div className="d-lg-flex d-none align-items-start v-tab-border left-nav-brd">
                    <div className="nav flex-column nav-pills custom-v-pills w-100" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                        {mainPanel.connectors && mainPanel.connectors.length > 0 && (
                            <>
                                <p className="font-16 font-semibold mb-lg-3 my-2 ps-0 ps-lg-2">Connectors</p>
                                {mainPanel.connectors.map((connector: any) => (
                                    <button
                                        key={connector.connectorId}
                                        className={`nav-link text-start rounded-0 font-regular font-14 w-100 adjust-height ${connector.connectorId === activeLeftNavigation.id ? "active" : ""}`}
                                        onClick={() => handleLeftNavigationClick(connector.connectorId, connector.connector, "connector")}
                                    >
                                        {connector.connector}
                                    </button>
                                ))}
                            </>
                        )}
                        <p className="font-16 font-semibold mb-lg-3 my-2 ps-0 ps-lg-2">Control Areas</p>
                        {mainPanel.controlAreas.map((area: any) => (
                            <button
                                key={area.controlAreaId}
                                className={`nav-link text-start rounded-0 font-regular font-14 w-100 adjust-height ${area.controlAreaId === activeLeftNavigation.id ? "active" : ""}`}
                                onClick={() => handleLeftNavigationClick(area.controlAreaId, area.controlArea, "controlArea")}
                            >
                                {area.controlArea}
                            </button>
                        ))}
                    </div>
                </div>
            </div>
        ));
    };


    return (
        <>
            <div className="container-fluid" style={{ marginTop: "105px" }}>
                <div className="row justify-content-center">
                    <div className="px-4">
                        {/* breadcrumb starts here */}
                        <BreadCrumbs data={breadCrumbData} />
                        {/* breadcrumb ends here */}
                        {/* heading content starts here */}
                        <div className="d-block d-md-flex flex-wrap justify-content-between align-items-center mb-2">
                            <div className="d-flex align-items-center mb-3 mb-md-0">
                                <a href="" className="cursor-pointer">
                                    <img
                                        src="images/backarrow.svg"
                                        alt="back-arrow"
                                        title="Back"
                                        onClick={(event: any) => {
                                            event.preventDefault();
                                            navigate("/manageassessment", {
                                                state: {
                                                    organizationId: state.organizationId,
                                                    organizationName: state.organizationName,
                                                },
                                            });
                                        }}
                                    />
                                </a>
                                <h2 className="font-22 font-semibold color-black mb-0 ms-3 ">
                                    MAP Assessment
                                </h2>
                            </div>

                            <div className="d-grid gap-3 d-md-inline-flex justify-content-md-end">
                                {(showDropdown && applicationDropDown.length != 0) && (
                                    // <div className="btn-group" role="group">
                                    <select className="form-select form-control custom-form width-200 rounded-2"
                                        value={selectedApplicationId} onChange={(e) => onApplicationChange(e)}
                                    >
                                        {bindApplicationDropdown()}
                                    </select>
                                    // </div>
                                )}
                                {showGenerateButton && (<button
                                    type="button"
                                    className="btn btn-dark theme-primary-btn border-0 font-14 font-semibold pt-2 pb-2 cursor-pointer text-nowrap"
                                    onClick={() => {
                                        navigateReportGrid();
                                    }}
                                >
                                    Generate Report
                                </button>)}
                            </div>
                        </div>
                        {/* heading content ends here */}

                        {/* <div className="row">
                        {bindLeftNavigation()}
                            <div className="col-12 col-md-12 col-lg-3 col-xl-2 ">
                                <div className="row">
                                    <div className="d-lg-flex d-none pe-0 align-items-start v-tab-border left-nav-brd">
                                        <div className="nav flex-column nav-pills custom-v-pills w-100" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                                        <p className="font-16 font-semibold my-lg-4 my-2 ps-0 ps-lg-3">Connectors</p>
                                            {menus.map((menu) => (
                                                <button
                                                    key={menu.menuId}
                                                    className={`nav-link text-start rounded-0 font-medium font-14 ${activeTab === menu.menuName ? "active" : ""}`}
                                                    data-bs-toggle="pill"
                                                    type="button"
                                                    role="tab"
                                                    aria-controls={`${menu.menuName.toLowerCase().replace(/\s/g, "")}`}
                                                    aria-selected="true"
                                                    onClick={() => handleTabChange(menu.menuName, menu.menuId)}>
                                                    {menu.menuName}
                                                </button>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md-12 col-lg-9 col-xl-10 Nav-page-scrl">
                                <div className="row">
                                    <div className="tab-content px-4" id="v-pills-tabContent">
                                        
                                        {(activeTab == "On Premise") && <Inventory setIsLoading={setIsLoading} infraAssessmentMenuId={selectedMenuId} />}
                                        {(activeTab == "Azure") && <Inventory setIsLoading={setIsLoading} infraAssessmentMenuId={selectedMenuId} />}
                                        {(activeTab == "Portfolio Discovery") && <PortfolioComponent setIsLoading={setIsLoading} state={state} menuId={selectedMenuId} />}
                                        {(activeTab == "CAF") && <Questionnaire menuId={selectedMenuId} state={state} setIsLoading={setIsLoading} />}
                                        {(activeTab == menus[4]?.menuName) && <MigrationStrategy
                                            setApplicationDropDown={setApplicationDropDown}
                                            applicationDropDown={applicationDropDown}
                                            setSelectedApplicationId={setSelectedApplicationId}
                                            selectedApplicationId={selectedApplicationId}
                                            setIsLoading={setIsLoading}
                                            state={state}
                                            menuId={selectedMenuId}
                                        />
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */
                        }
                        <div className="row">
                            {bindLeftNavigation()}
                            <div className="col-12 col-md-12 col-lg-9 col-xl-10 Nav-page-scrl">
                                <div className="row">
                                    <div className="tab-content px-4" id="v-pills-tabContent">
                                        {activeLeftNavigation.type === "connector" ? (
                                            <Connectors
                                                activeConnector={activeLeftNavigation.name}
                                                activeConnectorId={activeLeftNavigation.id}
                                                activeIds={activeIds}
                                                isLoading={setIsLoadingCallback}
                                                setGenerateButton={setGenerateButtonCallback}
                                                setConnectedConnectors={setConnectedConnectorsCallback}
                                                snControlAreaSelectedValues={snControlAreaSelectedValues}
                                                setSnControlAreaSelectedValues={setSnControlAreaSelectedValues}
                                                snSubMenuSelectedValues={snSubMenuSelectedValues}
                                                setSnSubMenuSelectedValues={setSnSubMenuSelectedValues}
                                                verticalName={activeIds.verticalName}
                                                connectors={leftNavigationData[0]?.connectors}
                                                setIsConnectorProvided={setIsConnectorProvided}
                                                setShowToasterPopup={setShowToasterPopup}
                                                setShowToasterLoading={setShowToasterLoading}
                                                setShowToasterFailedPopup={setShowToasterFailedPopup}
                                                setShowToaster={setShowToaster}
                                                showToaster={showToaster}
                                            />
                                        ) : (
                                            activeLeftNavigation.type === "controlArea" && (
                                                <>
                                                    <>
                                                        {activeLeftNavigation.name === "CAF" && (
                                                            <Questionnaire
                                                                setIsLoading={setIsLoading}
                                                                state={state}
                                                                menuId={activeLeftNavigation.id}
                                                            />
                                                        )}

                                                        {activeLeftNavigation.name === "Portfolio Discovery" && (
                                                            <PortfolioComponent
                                                                setIsLoading={setIsLoading}
                                                                state={state}
                                                                menuId={activeLeftNavigation.id}
                                                            />
                                                        )}
                                                        {activeLeftNavigation.name === "Migration Strategy - 7R’s" && (
                                                            <MigrationStrategy
                                                                setApplicationDropDown={setApplicationDropDown}
                                                                applicationDropDown={applicationDropDown}
                                                                setSelectedApplicationId={setSelectedApplicationId}
                                                                selectedApplicationId={selectedApplicationId}
                                                                setIsLoading={setIsLoading}
                                                                state={state}
                                                                menuId={activeLeftNavigation.id}
                                                            />
                                                        )}
                                                    </>

                                                </>
                                            ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Loader isLoading={isLoading} />
        </>
    )
}

export default InfraAssessment;