/** PS_RG_1.0 - PS_RG_1.1
 * importing the necessary packages
 */
import React, { useEffect, useState } from "react";
import PopupComponent from "./RecommendationPopup";

/**
 * PS_RG_1.2 - PS_RG_1.12
 * this functions returns a HTML to render the Table component
 */

const TableComponent: React.FC<any> = ({ dataForGrid, areas }) => {
  /**
   * PS_RG_1.3 - PS_RG_1.4
   * State variable creations
   */
  const [data, setData] = useState<any>(dataForGrid);
  const [showPopup, setShowPopup] = useState<boolean>(false);
  const [impactAreaSortOrderCa, setImpactAreaSortOrderCa] =
    useState<string>("asc");
  const [impactAreaSortOrderIa, setImpactAreaSortOrderIa] =
    useState<string>("asc");
  const [impactSortOrder, setImpactSortOrder] = useState<string>("asc");

  /**
   * PS_RG_1.5 - To close & show the popup
   */
  const togglePopup = () => {
    setShowPopup(!showPopup);
  };

  /**
   * PS_RG_1.6
   * This function bindsd the data on the grid
   */
  const bindData = (data: any) => {
    if (
      data.filter(
        (item: any) =>
          item.description !== null &&
          item.description !== undefined &&
          item.description !== ""
      ).length == 0
    ) {
      return (
        <tr style={{ textAlign: "center", color: "grey" }}>
          <td colSpan={3} className="font-14 font-semibold pt-4 color-grey">
            There are no recommendations to display
          </td>
        </tr>
      );
    } else {
      return data
        .filter(
          (item: any) =>
            item.description !== null &&
            item.description !== undefined &&
            item.description !== ""
        )
        .slice(0, 3)
        .map((item: any, index: number) => (
          <tr
            style={{
              borderBottom: "1px solid #E7E7E7",
              wordWrap: "break-word",
              whiteSpace: "normal",
              overflowWrap: "break-word",
              hyphens: "auto",
              pageBreakAfter: "auto",
              pageBreakInside: "avoid",
              pageBreakBefore: "auto",
            }}
            key={index}
          >
            <td
              style={{
                width: "38%",
                padding: "1%",
                textAlign: "left",
                verticalAlign: "top",
                fontSize: "12px",
                //fontFamily: '"SFProText-Medium"',
                color: "#242424",
                wordWrap: "break-word",
                whiteSpace: "normal",
                overflowWrap: "break-word",
                hyphens: "auto",
                pageBreakInside: "avoid",
              }}
            >
              {item.description}
            </td>

            {areas ? (
              <td
                style={{
                  textAlign: "left",
                  width: "18%",
                  padding: "1%",
                  verticalAlign: "top",
                  fontSize: "12px",
                  //fontFamily: '"SFProText-Medium"',
                  color: "#242424",
                  wordWrap: "break-word",
                  whiteSpace: "normal",
                  overflowWrap: "break-word",
                  pageBreakInside: "avoid",
                }}
              >
                {item.impactResources}
              </td>
            ) : (
              <td
                style={{
                  textAlign: "left",
                  width: "18%",
                  padding: "1%",
                  verticalAlign: "top",
                  fontSize: "12px",
                  //fontFamily: '"SFProText-Medium"',
                  color: "#242424",
                  wordWrap: "break-word",
                  whiteSpace: "normal",
                  overflowWrap: "break-word",
                  pageBreakInside: "avoid",
                }}
              >
                {item.controlAreas}
              </td>
            )}
            <td
              style={{
                textAlign: "center",
                width: "18%",
                padding: "1%",
                verticalAlign: "top",
                fontSize: "12px",
                //fontFamily: '"SFProText-Medium"',
                wordWrap: "break-word",
                whiteSpace: "normal",
                overflowWrap: "break-word",
                pageBreakInside: "avoid",
              }}
            >
              {item.impact === "High" ? (
                <span className="d-inline-block">
                  <span className="severity severity-high-bg">
                    <span className="severity-point severity-high me-2" />
                    {item.impact}
                  </span>
                </span>
              ) : item.impact === "Medium" ? (
                <span className="d-inline-block">
                  <span className="severity severity-medium-bg">
                    <span className="severity-point severity-medium me-2" />
                    {item.impact}
                  </span>
                </span>
              ) : (
                <span className="d-inline-block">
                  <span className="severity severity-low-bg">
                    <span className="severity-point severity-low me-2" />
                    {item.impact}
                  </span>
                </span>
              )}
            </td>
          </tr>
        ));
    }
  };

  /**
   * PS_RG_1.7 - PS_RG_1.10
   * These functions sort the columns with "asc" and "desc"
   */

  /**
   * to sort Impacts
   */
  let sortImpactData = () => {
    const impactOrder: any = { High: 3, Medium: 2, Low: 1 };
    const sortedData = [...data].sort((a: any, b: any) => {
      const sortOrder = impactSortOrder == "asc" ? 1 : -1;
      return sortOrder * (impactOrder[b.impact] - impactOrder[a.impact]);
    });

    setData(sortedData);
    setImpactSortOrder(impactSortOrder == "asc" ? "desc" : "asc");
  };

  /**
   * to sort control Area
   */
  let sortControlArea = () => {
    const sortedData = [...data].sort((a: any, b: any) => {
      const sortOrder = impactAreaSortOrderCa === "asc" ? 1 : -1;
      return sortOrder * a.controlAreas.localeCompare(b.controlAreas);
    });

    setData(sortedData);
    setImpactAreaSortOrderCa(impactAreaSortOrderCa === "asc" ? "desc" : "asc");
  };

  /**
   * to sort Impact Area
   */
  let sortImpactArea = () => {
    const sortedData = [...data].sort((a: any, b: any) => {
      const sortOrder = impactAreaSortOrderIa == "asc" ? 1 : -1;
      return sortOrder * a.impactResources.localeCompare(b.impactResources);
    });

    setData(sortedData);
    setImpactAreaSortOrderIa(impactAreaSortOrderIa == "asc" ? "desc" : "asc");
  };

  let dataCount = data.filter(
    (item: any) =>
      item.description !== null &&
      item.description !== undefined &&
      item.description !== ""
  ).length;

  /**
   * PS_RG_1.11
   * This returns HTML to be renderd as grid
   */
  return (
    <div className="p-4 border rounded-3">
      <p className="font-semibold font-18 font-semibold d-flex justify-content-between align-items-center">
        Recommendations ({dataCount})
        <img
          hidden={data.filter(
            (item: any) =>
              item.description !== null &&
              item.description !== undefined &&
              item.description !== ""
          ).length == 0 ? true : false}
          src="images/expand-icon.svg"
          alt="expand-icon"
          className="cursor-pointer"
          data-bs-toggle="modal"
          data-bs-target="#recommendationsExapn"
          onClick={() => {
            togglePopup();
          }}
        />
      </p>
      <div className="table-responsive mb-4">
        <table className="table table-borderless themeTable mb-0">
          <thead className="font-semibold sticky-top">
            <tr>
              <th>Description</th>
              {areas ? (
                <th>
                  Impacted Areas
                  <span
                    className="ms-2 cursor-pointer"
                    onClick={sortImpactArea}
                  >
                    {data.filter(
                      (item: any) =>
                        item.description !== null &&
                        item.description !== undefined &&
                        item.description !== ""
                    ).length == 0
                      ? null
                      : impactAreaSortOrderIa == "asc"
                      ? " ↑"
                      : " ↓"}
                  </span>
                </th>
              ) : (
                <th>
                  Control Areas
                  <span
                    className="ms-2 cursor-pointer"
                    onClick={sortControlArea}
                  >
                    {data.filter(
                      (item: any) =>
                        item.description !== null &&
                        item.description !== undefined &&
                        item.description !== ""
                    ).length == 0
                      ? null
                      : impactAreaSortOrderCa == "asc"
                      ? " ↑"
                      : " ↓"}
                  </span>
                </th>
              )}
              <th
                style={{
                  textAlign: "center",
                }}
              >
                Impact
                <span className="ms-2 cursor-pointer" onClick={sortImpactData}>
                  {data.filter(
                    (item: any) =>
                      item.description !== null &&
                      item.description !== undefined &&
                      item.description !== ""
                  ).length == 0
                    ? null
                    : impactSortOrder == "asc"
                    ? " ↑"
                    : " ↓"}
                </span>
              </th>
            </tr>
          </thead>
          <tbody>{bindData(data)}</tbody>
        </table>
      </div>
      <PopupComponent data={data} area={areas}></PopupComponent>
    </div>
  );
};

export default TableComponent;
