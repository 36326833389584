import { progressModel } from "../../interface/questionnaireInterface";

//PS_59 - PS_67 A toaster component that indicates the completion percentage of AI Auto questionnaire
const ProgressToaster = ({ controlAreas, error, onRetry, onClose }: {
  controlAreas: progressModel[],
  error: boolean,
  onRetry: () => void,
  onClose: () => void
}) => {
  if (error) {
    return (
      <div className="position-fixed bg-white top-150px start-50 translate-middle w-md-100">
        <div className="text-end">

        </div>
        <div className="shadow-sm d-flex rounded-3 custom-toast p-3">

          <div className="toast-body d-flex flex-column">
            <div className="d-flex align-items-center mb-2">
              <div className="d-block me-3">
                <img src="images/fail-icon.svg" alt="Error" />
              </div>
              <div className="d-block">
                <span className="font-medium font-16 color-grey">Oops! AI Couldn't fetch your answer. Please try again</span>
              </div>
              <div className="d-block">
                <button className="btn border-0" onClick={onClose}><img src="images/close-icon-black.svg" width={"15"} height={"15px"} /></button>
              </div>
            </div>
            <div className="d-flex justify-content-end mt-2">
              <button className="btn me-2 color-black" onClick={onRetry}>Retry</button>

            </div>
          </div>
        </div>
      </div>
    );
  }
  if (!controlAreas) {
    return null;
  }

  const totalAreas = controlAreas.length;
  const completedAreas = controlAreas.filter((area) => area.progress === 'Completed').length;
  const progress = totalAreas > 0 ? Math.round((completedAreas / totalAreas) * 100) : 0;
  const isCompleted = progress === 100 ? true : false;

  return (
    <>
      {isCompleted ? (
        <div className="position-fixed bg-white top-150px start-50 translate-middle w-md-100">
          <div className="text-end"></div>
          <div className="shadow-sm d-flex rounded-3 custom-toast p-3">
            <div className="toast-body d-flex flex-column">
              <div className="d-flex align-items-center mb-2">
                <div className="d-block me-3">
                  <img src="images/toast-success.svg" alt="Success" />
                </div>
                <div className="d-block">
                  <span className="font-medium font-16 color-grey">
                    Success! AI has completed answering your questions.
                  </span>
                </div>
                <div className="d-block">
                  <button
                    className="btn border-0"
                    onClick={onClose}
                  >
                    <img src="images/close-icon-black.svg" width="15" height="15" alt="Close" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="position-fixed start-50 translate-middle-x toast-width" style={{ top: '60px' }}>
          <div
            id="taskToast"
            className="toast task-created-toast show"
            role="alert"
            aria-live="assertive"
            aria-atomic="true"
          >
            <div className="toast-body p-4">
              <div className="d-flex justify-content-between align-items-center gap-5">
                <div className="d-flex gap-3 align-items-center">
                  <img src="images/time-icon.svg" alt="Time Icon" />
                  <p className="font-regular font-14 mb-0 color-grey-v6">
                    Please wait while AI prepares your answers!
                  </p>
                </div>
                <div className="text-center">
                  <p className="font-16 font-semibold mb-0">{progress}%</p>
                  <p className="font-14 font-regular color-grey-v6 mb-0">Completed</p>
                </div>
              </div>
            </div>
            <span
              className="progress-blue"
              style={{
                width: `${progress}%`,
                height: '4px',
                position: 'absolute',
                bottom: 0,
                left: 0,
                backgroundColor: '#007bff',
              }}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default ProgressToaster;