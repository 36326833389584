import react, { useState, useEffect, ChangeEvent } from "react"
import { controlAreasImpactImgModel, controlAreasImpactModel, controlAreasModel, inventoryDetailsModel, isEditModel, postSummaryModel, summaryEditModel, uploadTranscriptModel, wafrFileInfoModel, wafrReportFileModel, wafrReportSortOrderModel } from "../interface/WafrReportModel"
import UploadTranscript from "./reportComponents/UploadTranscript"
import CircularPieChart from "./reportComponents/CircularPieChart"
import DonutChart from "./reportComponents/DonutChart"
import SectionVerticalBarChart from "./reportComponents/SectionVerticalBarChart"
import GaugeChart from "./reportComponents/GaugeChart"
import { toastModel } from "../interface/InitiativeModel"
import { getSasToken, postSelectedRecommendationsData, postSummaryData, postUpdatedRecommendationsData, postUploadedTranscriptData } from "../service/ReportApi"
import Loader from "./Loader"
import ExecutiveSummary from "./reportComponents/ExecutiveSummary"
import { jwtDecode } from "jwt-decode"
import { getConnectorsData } from "../helpers/encryption"
import { encryptStorage } from "../constant/constant";
import { useNavigate } from "react-router-dom"
import WafrRecommendationsGrid from "./reportComponents/WafrRecommendationsGrid"
import { report } from "process"
import { includes, over } from "lodash"
import BreadCrumbs from "./breadCrumb"

const WafrReport = (props: any) => {
    const initialFileInfo: wafrFileInfoModel = {
        fileData: "",
        fileName: "",
        fileType: "",
        base64Transcript: "",
    }

    const initialSummaryData = {
        executiveSummary: props.reportData.executiveSummary,
        businessOverview: props.reportData.businessOverview,
        finalReportWalkthrough: props.reportData.finalReportWalkthrough,
    }

    const initialSortData: wafrReportSortOrderModel = {
        checkName: "asc",
        impact: "asc",
        controlAreaName: "asc"
    }

    const toastData: toastModel = {
        toastType: '',
        toastHeaderMessage: '',
        toastBodyMessage: '',
    }

    const controlAreaOrder = ["Security", "Cost Optimization", "Reliability", "Operational Excellence", "Performance Efficiency", "Sustainability"]

    const [reportData, setReportData] = useState<any>(JSON.parse(JSON.stringify(props.reportData)))
    const [summaryData, setSummaryData] = useState<any>(initialSummaryData)
    const [selectedControlArea, setSelectedControlArea] = useState<any>()
    const [selectedTab, setSelectedTab] = useState<string>("uploadTranscript")
    const [summaryFile, setSummaryFile] = useState<wafrFileInfoModel>(initialFileInfo)
    const [finalWalkthroughFile, setFinalWalkthroughFile] = useState<wafrFileInfoModel>(initialFileInfo)
    const [sortOrder, setSortOrder] = useState<any>(initialSortData)
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [saveSuccess, setSaveSuccess] = useState(false);
    const [hideEditRecom, setHideEditRecom] = useState<boolean>(true)
    const [hideProceedAnyway, setHideProceedAnyway] = useState<boolean>(true)
    const [emptyAreas, setEmptyAreas] = useState<string[]>([])
    const [overallAwsRecommendations, setOverallAwsRecommendations] = useState<any>(reportData?.controlAreaDetails?.reduce((acc: any, data: any) => {
        return data?.awsRecommendations?.concat(acc) || acc
    }, []))
    const [selectedRecommendations, setSelectedRecommendations] = useState<any>(overallAwsRecommendations)
    const navigate = useNavigate()
    const [showToasterLoading, setShowToasterLoading] = useState(false);
    const [showToasterPopup, setShowToasterPopup] = useState(false);
    const [showToasterFailedPopup, setShowToasterFailedPopup] = useState(false);
    const [showDeletePopup, setShowDeletePopup] = useState(false);
    const [hideToast, setHideToast] = useState(true);
    const [toastProp, setToastProp] = useState({
        toastType: '',
        toastHeaderMessage: '',
        toastBodyMessage: '',
    });


    const breadCrumbs =
        [
            { title: "Organization", path: "/homepage", isActive: true },
            { title: reportData.organizationName, path: "/manageassessment", isActive: true, state: { organizationId: reportData.organizationId, organizationName: reportData.organizationName } },
            { title: reportData.verticalName, path: "", isActive: true },
            { title: "Report", path: "", isActive: false },
        ]


    const controlAreaImapctImg: controlAreasImpactImgModel = {
        "Security": <span className="legends-level-1 legend-cat-1 me-2 cursor-pointer" />,
        "Cost Optimization": <span className="legends-level-1 legend-cat-2 me-2 cursor-pointer" />,
        "Reliability": <span className="legends-level-1 legend-cat-3 me-2 cursor-pointer" />,
        "Operational Excellence": <span className="legends-level-1 legend-cat-4 me-2 cursor-pointer" />,
        "Performance Efficiency": <span className="legends-level-1 legend-cat-5 me-2 cursor-pointer" />,
        "Sustainability": <span className="legends-level-1 legend-cat-6 me-2 cursor-pointer" />
    }

    const pieChartData = reportData.controlAreasImpact?.map((data: controlAreasImpactModel) => {
        return {
            name: data.controlAreaName,
            value: data.impacts.highImpactCount
        }
    })

    const pieChartColorCode = ['#C4D7EE', '#A992D3', '#F3C6E7', '#D7A8E5', ' #A4D4D6', '#96BDF4'];

    const barChartData = reportData.controlAreasImpact?.map((data: controlAreasImpactModel) => {
        return {
            name: data.controlAreaName,
            highImpactCount: data.impacts.highImpactCount,
            mediumImpactCount: data.impacts.mediumImpactCount,
            lowImpactCount: data.impacts.lowImpactCount,
        }
    })

    useEffect(() => {
        if (selectedTab === "overallSummary") {
            // const sortedData = sortRecommendations(overallAwsRecommendations, "impact");
            // setOverallAwsRecommendations(sortedData)
            const impacts = ["High", "Medium", "Low"]
            let filteredRecommendations :any=[]
            let sortedRecommendations: any=[]
            impacts?.forEach((impact:string)=>{
                filteredRecommendations= overallAwsRecommendations?.filter((recommendation:any)=> recommendation?.impact === impact)||[]
                controlAreaOrder?.forEach((order:string)=>{
                   sortedRecommendations=[...sortedRecommendations, ...filteredRecommendations?.filter((recommendation:any)=> recommendation?.controlAreaName===order)] 
                })
            })
            setOverallAwsRecommendations(sortedRecommendations)
            setSelectedRecommendations(sortedRecommendations)
            setSortOrder({checkName: "asc",
                impact: "desc",
                controlAreaName: "asc"})
        }
    }, [selectedTab])

    useEffect(() => {
        const handleBeforeUnload = (e: any) => {
            e.preventDefault(); // Prevent the page from reloading
            e.returnValue =
                "You may have unsaved changes. Are you sure you want to reload or leave?"; // Some browsers require a return value
        };

        window.onbeforeunload = function () {
            return "You may have unsaved changes. Are you sure you want to reload or leave?";
        };
        window.addEventListener("beforeunload", handleBeforeUnload);
    }, []);

    useEffect(() => {
        setTimeout(() => {
            setReportData({ ...reportData, updateError: false })
        }, 4000);
    }, [])

    const regex2 = /"|[*]/g;

    const escapeJsonString = (str: string) => {
        return str.replace(/\\/g, '\\\\')
            .replace(/"/g, '\\"')
            .replace(/\n/g, '\\n')
            .replace(/\r/g, '\\r')
            .replace(/\t/g, '\\t')
            .replace(/\f/g, '\\f');
    }

    function sanitizeString(str: string): string {
        return str.replace(/\*/g, '')
            .replace(/["'\\]/g, '')
            .replace(/[^\x20-\x7E]/g, '')
            .trim();
    }

    function sanitizeRecommendation(data: any): any {
        if (Array.isArray(data)) {
            return data.map(item => sanitizeRecommendation(item));
        }
        if (typeof data !== 'object' || data === null) {
            return data;
        }
        const sanitizedData: { [key: string]: any } = {};
        for (const [key, value] of Object.entries(data)) {
            const sanitizedKey = sanitizeString(key);
            if (typeof value === 'string') {
                sanitizedData[sanitizedKey] = sanitizeString(value);
            } else {
                sanitizedData[sanitizedKey] = sanitizeRecommendation(value);
            }
        }
        return sanitizedData;
    }

    const findSelectedRecommendations = () => {
        let finalRecommendations: any = []
        reportData?.controlAreaDetails?.forEach((data: any) => {
            let selectedRecommendations = data?.awsRecommendations?.filter((recommendation: any) => {
                return (reportData?.selectedAWSRecommendations?.includes(recommendation?.checkId))
            })
            if (selectedRecommendations?.length === 0 || !selectedRecommendations?.length) {
                selectedRecommendations = data?.awsRecommendations?.filter((recommendation: any) => {
                    return (recommendation?.impact === "High")
                })
            }
            selectedRecommendations = selectedRecommendations?.map((checkDetails: any) => {
                return {
                    ...checkDetails,
                    description: sanitizeString(checkDetails?.
                        description)
                }
            })
            finalRecommendations = [...finalRecommendations, ...selectedRecommendations || []]
        })
        return finalRecommendations
    }

    const postUploadedTranscript = async (key: string) => {
        let payload: uploadTranscriptModel = {
            organizationId: reportData?.organizationId,
            projectId: reportData?.projectId,
            verticalId: reportData?.verticalId,
            verticalName: "WAFR",
            url: reportData?.organizationUrl?.split(",").map(sanitizeString) || [],
            isSummary: key === "executiveSummary",
            recommendations: key === "executiveSummary" ? overallAwsRecommendations : [],
            fileName: sanitizeString(key === "executiveSummary" ? summaryFile.fileName : finalWalkthroughFile.fileName),
            fileType: '.docx',
            fileData: key === "executiveSummary" ? summaryFile.base64Transcript : finalWalkthroughFile.base64Transcript,
        }

        setShowToasterLoading(true);
        try {
            const sanitizedPayload = sanitizeRecommendation(payload);

            // Ensure recommendations is always an array of objects
            if (!Array.isArray(sanitizedPayload.recommendations)) {
                sanitizedPayload.recommendations = [];
            }

            const jsonPayload = JSON.stringify(sanitizedPayload);

            const response = await postUploadedTranscriptData(sanitizedPayload);

            if (response.status === 200) {
                setShowToasterPopup(true);
                if (key === "executiveSummary") {
                    setReportData({
                        ...reportData, [key]: {
                            attributeId: summaryData[key]?.attributeId, attributeSummary: response.data.executiveSummary,
                        },
                        businessOverview: { attributeId: summaryData[key]?.attributeId, attributeSummary: response.data.businessOverview }
                    })
                    setSummaryData({ ...summaryData, [key]: { attributeId: summaryData[key]?.attributeId, attributeSummary: response.data.executiveSummary }, businessOverview: { attributeId: summaryData[key]?.attributeId, attributeSummary: response.data.businessOverview } })
                    setEmptyAreas([])
                }
                else {
                    setSummaryData({ ...summaryData, [key]: { attributeId: summaryData[key]?.attributeId, attributeSummary: response.data.summary } })
                    setReportData({
                        ...reportData, [key]: {
                            attributeId: summaryData[key]?.attributeId, attributeSummary: response.data.summary
                        }
                    })
                }
            }
            else {
                setIsLoading(false)
                let toastData = {
                    toastType: 'error',
                    toastHeaderMessage: 'Error',
                    toastBodyMessage: 'Api Failed'
                }

                setToastProp(toastData)
                setHideToast(false);
            }
        }
        catch (error) {
            setShowToasterFailedPopup(true);
            setIsLoading(false);
        } finally {
            setIsLoading(false);
            setShowToasterLoading(false);
            setTimeout(() => {
                setShowToasterPopup(false);
                // setSaveSuccess(false);
                setShowToasterFailedPopup(false);
            }, 4000);
        }
    }

    const postSummary = async (key: string, value: string) => {
        value = value ? escapeJsonString(value?.trim().replace(regex2, "")) : ""
        let payload: postSummaryModel = {
            organizationId: reportData.organizationId,
            verticalId: reportData.verticalId,
            projectId: reportData.projectId,
            attributeId: summaryData[key]?.attributeId,
            attributeValue: value?.trim(),
            attributeName: key === "executiveSummary" ? "Executive Summary" : key === "businessOverview" ? "Business Overview" : "Final Report Walkthrough"
        }
        setShowToasterLoading(true);

        try {
            const response = await postSummaryData(payload)
            if (response.status === 200) {
                setIsLoading(true)
                setShowToasterPopup(true);
                setReportData({
                    ...reportData, [key]: {
                        attributeId: summaryData[key]?.attributeId, attributeSummary: value?.trim()
                    }
                })
                setSummaryData({ ...summaryData, [key]: { attributeId: summaryData[key]?.attributeId, attributeSummary: value?.trim() } })
                setEmptyAreas(emptyAreas?.filter((areaName: string) => areaName !== key))
            }
            else {
                setIsLoading(false);
                let toastData = {
                    toastType: 'error',
                    toastHeaderMessage: 'Error',
                    toastBodyMessage: 'API failed'
                }
                setToastProp(toastData)
                setHideToast(false);
            }
        }
        catch (error) {
            setShowToasterFailedPopup(true);
            setIsLoading(false);
        } finally {
            setIsLoading(false)
            setShowToasterLoading(false);
            setTimeout(() => {
                setShowToasterPopup(false);
                setShowToasterFailedPopup(false);
            }, 4000);
        }
    }

    const postUpdatedRecommendations = async (selectedControlArea: any, value: string) => {
        value = value ? escapeJsonString(value?.trim().replace(regex2, "")) : ""
        const payload = {
            organizationId: reportData.organizationId,
            verticalId: reportData.verticalId,
            projectId: reportData.projectId,
            controlAreaId: selectedControlArea.controlAreaId,
            overallRecommendation: value?.trim().replace(/\\n/g, '')
        }
        setShowToasterLoading(true);
        try {
            const response = await postUpdatedRecommendationsData(payload)
            if (response.status === 200) {
                setIsLoading(true);
                setSaveSuccess(true);
                setReportData({
                    ...reportData, controlAreaDetails: reportData?.controlAreaDetails?.map((data: any) => {
                        if (data?.controlAreaId !== selectedControlArea?.controlAreaId)
                            return data
                        return ({ ...data, impacts: { ...data?.impacts, overAllSummary: value?.trim().replace(/\\n/g, '') } })
                    })
                })
                setSelectedControlArea({ ...selectedControlArea, impacts: { ...selectedControlArea?.impacts, overAllSummary: value?.trim().replace(/\\n/g, '') } })
            }
            else {
                setIsLoading(false);
                let toastData = {
                    toastType: 'error',
                    toastHeaderMessage: 'Error',
                    toastBodyMessage: 'API failed'
                }
                setToastProp(toastData)
                setHideToast(false);
            }
        }
        catch (error) {
            setShowToasterFailedPopup(true);
            setIsLoading(false);
        } finally {
            setIsLoading(false)
            setShowToasterLoading(false);
            setTimeout(() => {
                setSaveSuccess(false);
                setShowToasterFailedPopup(false);
            }, 4000);
        }
    }

    const postSelectedRecommendations = async () => {
        const payload: any = {
            organizationId: reportData.organizationId,
            verticalId: reportData.verticalId,
            projectId: reportData.projectId,
            checkIds: reportData?.selectedAWSRecommendations,
            recommendations: findSelectedRecommendations() || []
        }
        try {
            setIsLoading(true)
            const sanitizedPayload = sanitizeRecommendation(payload);
            const response = await postSelectedRecommendationsData(sanitizedPayload)
            if (response.status === 200) {
                setSaveSuccess(true);
                navigate("/previewpage", { state: { data: { ...reportData, updatedRecommendations: response?.data?.controlAreasRecommendations } } })
            }
            else {
                setIsLoading(false);
                let toastData = {
                    toastType: 'error',
                    toastHeaderMessage: 'Error',
                    toastBodyMessage: 'API failed'
                }
                setToastProp(toastData)
                setHideToast(false);
            }

        }
        catch (error) {
            setShowToasterFailedPopup(true);
            setIsLoading(false);
        } finally {
            setIsLoading(false)
            setTimeout(() => {
                setSaveSuccess(false);
                setShowToasterFailedPopup(false);
            }, 4000);
        }

    }

    //Generate sas token
    const generateSasToken = async () => {
        try {
            let value = encryptStorage.getItem("jwt")
            let parsedJtk: any = ""
            let storageAccountName: any, containerName: any;
            if (value) {
                parsedJtk = jwtDecode(value)
                const storageAccountName = getConnectorsData({ key: parsedJtk.ek, encryptedMessage: process.env.REACT_APP_BLOB_ACCOUNT_NAME! });
                containerName = getConnectorsData({ key: parsedJtk.ek, encryptedMessage: process.env.REACT_APP_BLOB_CONTAINER! })
            }
            const sas = await getSasToken("r");
            return (sas.data)

        } catch (error) {
            console.error("Error occurred while downloading the template.", error);
        }

    }

    function sortRecommendations(sortedData: any, id: string) {
        sortedData?.sort((a: any, b: any) => {
            const order = sortOrder[id] === "asc" ? 1 : -1
            if (sortOrder[id] === "asc")
                setSortOrder({ ...sortOrder, [id]: "desc" })
            else
                setSortOrder({ ...sortOrder, [id]: "asc" })
            return order * a[id].localeCompare(b[id])
        })
        return sortedData
    }

    const onSaveFile = async () => {
        const sas = await generateSasToken()
        const response = await fetch(reportData?.inventoryUrl + `?${sas}`);
        const blob = await response.blob();
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `${reportData?.organizationName}-inventory details.xlsx`;
        a.click();
    }

    const bindControlAreas = () => {
        return (
            reportData.controlAreas?.map((data: controlAreasModel) => {
                return <button className={`nav-link text-start rounded-0 font-medium font-14 ${selectedTab === data.controlAreaName ? "active" : ""}`} key={data.controlAreaId} data-bs-toggle="pill" id={data.controlAreaName} type="button" role="tab" aria-selected="true" onClick={() => {
                    setSelectedTab((prev) => "controlAreas")
                    setSortOrder(initialSortData);
                    setHideEditRecom(true)
                    setSelectedControlArea(reportData?.controlAreaDetails?.find((controlAreaData: any) => {
                        if (controlAreaData?.controlAreaName === data.controlAreaName) {
                            setSelectedRecommendations(controlAreaData?.awsRecommendations)
                            return true
                        }
                    }))
                }}>{data.controlAreaName}</button>

            })
        )
    }

    const bindResourseDetails = (columnName: string[], resourceDetails: any) => {
        if (!columnName) {
            return (
                <div className="rounded-1 shadow-sm p-3">
                    <p className="font-11 color-black mb-0 text-center">No Resourse Details</p>
                </div>
            )
        }
        return (
            resourceDetails?.map((resourse: any) => {
                return <tr className="border-bottom">
                    {columnName?.map((key: string) => {
                        return <td>
                            {resourse[key] || "-"}
                        </td>
                    })
                    }
                </tr>
            })
        )
    }

    const bindRecommendationGrid = (recommendations: any, limit: number, type: string = "") => {
        return (
            recommendations?.map((data: any, index: number) => {
                if (index >= limit)
                    return
                return (
                    <>
                        <tr className="border-bottom">

                            <td className=" text-wrap ps-3 d-flex"><span><img src="images/accordion-down-arrow.svg"
                                alt="accordion-arrow" className="cursor-pointer accordion-arrow collapsed me-2"
                                data-bs-toggle="collapse" data-bs-target={`#Table${index}`}
                                aria-expanded="false" /></span><span>
                                    {type === "controlAreas" && <input className="theme-check form-check-input label-bold me-2 mt-0" type="checkbox" id="firstquesopt21" checked={reportData?.selectedAWSRecommendations?.includes(data?.checkId)}
                                        onChange={() => {
                                            reportData?.selectedAWSRecommendations?.includes(data?.checkId) ? setReportData({ ...reportData, selectedAWSRecommendations: reportData.selectedAWSRecommendations?.filter((id: string) => id !== data?.checkId) }) :
                                                setReportData({ ...reportData, selectedAWSRecommendations: [...reportData?.selectedAWSRecommendations, data?.checkId] })
                                        }} />}

                                </span><span>{data.checkName || "-"}</span></td>
                            {type === "controlAreas" ? <></> : <td className=" text-wrap">{data.controlAreaName || "-"}</td>}
                            <td className="text-center"><span className="d-inline-block"><span className={`severity severity-${data?.impact?.toLowerCase() || "high"}-bg`}><span className={`severity-point severity-${data?.impact?.toLowerCase() || "high"} me-2`} />{data.impact}</span></span></td>
                        </tr>
                        <tr className="accordion-container ">
                            <td colSpan={3} className="collapse  p-0 border-0" id={`Table${index}`}>
                                {/* first level accordion starts  */}
                                <div className="mb-4 px-2">
                                    <div className="table-responsive custom-shadow rounded-3">
                                        <table className="table table-bordered themeTable mb-0">
                                            <thead className="font-semibold ">
                                                <tr>
                                                    {data?.columnName?.map((columnName: string) => {

                                                        return <th>{columnName}</th>
                                                    })}
                                                </tr>
                                            </thead>
                                            <tbody className="font-medium">
                                                {bindResourseDetails(data?.columnName, data?.resourceDetails)}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                {/* first level accordion starts ends */}
                            </td>
                        </tr>
                    </>
                )
            })
        )
    }

    const bindInventoryDetails = () => {
        return (
            reportData.inventoryDetails?.map((data: inventoryDetailsModel) => {
                return (
                    <div className="col-md-4 pb-3 pe-3 ps-0">
                        <div className="aws-card d-flex justify-content-between align-items-center">
                            <span className="d-flex align-items-center gap-3">
                                <img src={`images/aws-${data.serviceName?.replaceAll(" ", "")?.toLowerCase()}.svg`} alt="aws-ebs-icon" />
                                <span className="font-14 font-semibold">{data.serviceName}</span>
                            </span>
                            <span className="font-24 font-semibold">{data.count}</span>
                        </div>
                    </div>
                )
            })
        )
    }

    const bindLegends = () => {

        return reportData?.controlAreasImpact?.map((data: controlAreasImpactModel, index: number) => {
            return (
                <div className="d-flex align-items-center mt-3 justify-content-between cursor-pointer me-4">
                    <div className="align-items-center d-flex align-items-baseline">
                        {controlAreaImapctImg[data.controlAreaName as keyof controlAreasImpactImgModel]}
                        <span className="font-16 grey-light text-decoration-none cursor-pointer">{data.controlAreaName}</span>
                    </div>
                    <div>
                        <label className="font-16 secondary-textcolor font-bold cursor-pointer">{data.impacts.highImpactCount % 10 < 0 ?
                            '0' + data.impacts.highImpactCount.toString() :
                            data.impacts.highImpactCount.toString()}</label>
                    </div>
                </div>)
        })
    }

    const AwsWafrFindings = () => {
        return (
            <div className="row">
                <div className="col-lg-12 col-md-12 col-12">
                    <div className="ps-lg-4">
                        <h3 className="font-18 font-semibold color-black mb-5">
                            AWS Inventory Count - Findings
                        </h3>
                        <div className="row">
                            {bindInventoryDetails()}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    const OverallSummary = () => {
        return (
            <div className="row">
                <div className="col-lg-7">
                    <div className="summary-card summary-card-l1">
                        <h3 className="font-18 font-semibold">High Risk count against each WAFR pillar</h3>
                        <div className="d-flex justify-content-center mt-4">
                            <div className="d-flex align-items-center half-pie-chart">
                                <CircularPieChart data={pieChartData} colorCode={pieChartColorCode} chartHeight={400} chartXaxis={120} chartYaxis={240} countXaxis={120} countYaxis={225} />
                            </div>
                        </div>
                        <div className="row px-5 mt-3">
                            <div className="col-md-6">
                                {bindLegends()?.slice(0, 3)}
                            </div>
                            <div className="col-md-6">
                                {bindLegends()?.slice(3)}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-5">
                    <div className="summary-card summary-card-l1">
                        <h3 className="font-18 font-semibold">Recommendations based on Impacts </h3>
                        <div className="d-flex justify-content-center align-items-center" style={{ height: 'calc(100% - 30px)' }}>
                            <DonutChart dataNameValue2={[
                                { name: "High", value: overallAwsRecommendations?.reduce((acc: number, data: any) => acc + (data.impact === "High" ? 1 : 0), 0) },
                                { name: "Medium", value: overallAwsRecommendations?.reduce((acc: number, data: any) => acc + (data.impact === "Medium" ? 1 : 0), 0) },
                                { name: "Low", value: overallAwsRecommendations?.reduce((acc: number, data: any) => acc + (data.impact === "Low" ? 1 : 0), 0) }
                            ]} />
                        </div>
                    </div>
                </div>
                <div className="col-lg-12 mt-3">
                    <div className="summary-card">
                        <div className="d-flex justify-content-between">
                            <h3 className="font-18 font-semibold">Overall Findings that impacted your results</h3>
                            <div className="d-flex align-items-center">
                                <div className="d-flex align-items-center me-3">
                                    <span className="legends-level-2 legend-low me-2" />
                                    <span className="font-medium font-12">Low</span>
                                </div>
                                <div className="d-flex align-items-center me-3">
                                    <span className="legends-level-2 legend-med me-2" />
                                    <span className="font-medium font-12">Medium</span>
                                </div>
                                <div className="d-flex align-items-center">
                                    <span className="legends-level-2 legend-high me-2" />
                                    <span className="font-medium font-12">High</span>
                                </div>
                            </div>
                        </div>
                        <div className="bar-chart mt-4 w-100 d-flex justify-content-center align-items-center">
                            <SectionVerticalBarChart data={barChartData} />
                        </div>
                    </div>
                </div>
                <div className="col-lg-12 col-xl-12 col-md-12 col-12 mb-4 mt-3">
                    <div className="p-4 border rounded-3">
                        <p className="font-semibold font-18 font-semibold d-flex justify-content-between align-items-center">
                            Recommendations
                            {overallAwsRecommendations?.length ? <img src="images/expand-icon.svg" alt="expand-icon" className="cursor-pointer" data-bs-toggle="modal" data-bs-target="#recommendationsExapnMigrationStrategy" /> : <></>}</p>
                        <div className=" w-100">
                            <div className="table-responsive mb-4">
                                <table className="table table-borderless themeTable mb-0">
                                    <thead className="font-semibold sticky-top">
                                        <tr>
                                            <th> Description<span className="ms-2 cursor-pointer">
                                                <img src={sortOrder.checkName === "asc" ? "images/sortarrowup.svg":"images/sortarrow.svg" } alt="sortarrow" title={sortOrder.checkName === "asc" ? "Sort Descending" : "Sort Ascending"} onClick={() => {
                                                    const sortedData = sortRecommendations([...overallAwsRecommendations], "checkName");
                                                    setOverallAwsRecommendations(sortedData);
                                                }} /> </span>
                                            </th>
                                            <th> Control Areas <span className="ms-2 cursor-pointer">
                                                <img src={sortOrder.controlAreaName === "asc" ? "images/sortarrowup.svg": "images/sortarrow.svg"} alt="sortarrow" title={sortOrder.controlAreaName === "asc" ? "Sort Descending" : "Sort Ascending"} onClick={() => {
                                                    const sortedData = sortRecommendations([...overallAwsRecommendations], "controlAreaName");
                                                    setOverallAwsRecommendations(sortedData);
                                                }} /> </span>
                                            </th>
                                            <th className="text-center"> Impact <span className="ms-2 cursor-pointer">
                                                <img src={sortOrder.impact === "asc" ? "images/sortarrowup.svg":"images/sortarrow.svg"} alt="sortarrow" title={sortOrder.impact === "asc" ? "Sort Descending" : "Sort Ascending"} onClick={() => {
                                                    const sortedData = sortRecommendations([...overallAwsRecommendations], "impact");
                                                    setOverallAwsRecommendations(sortedData);
                                                }} /> </span>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody className="font-medium">
                                        {overallAwsRecommendations && overallAwsRecommendations?.length ? (
                                            bindRecommendationGrid(overallAwsRecommendations, 5)
                                        ) : (
                                            <tr>
                                                <td colSpan={3}>
                                                    <div className="row text-center">
                                                        <img src="images/no-records-found.svg" width={"300"} height={"300"} alt="No records found" />
                                                        <p className="font-16 font-medium color-grey-v3 my-5">
                                                            No Records Found
                                                        </p>
                                                    </div>
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    const ControlAreas = () => {
        const [selectedRecommendation, setSelectedRecommendation] = useState<string>(selectedControlArea?.impacts?.overAllSummary)
        return (
            <>
                <h3 className="font-semibold font-18">{selectedControlArea.controlAreaName}</h3>
                <div className="col-lg-12 col-xl-12 col-md-12 col-12 mb-4 mt-4">

                    <div className="p-4 border rounded-3">
                        <>
                            <div className="d-flex align-items-center justify-content-between mb-4">
                                <p className="font-semibold font-18 mb-0">Based on your Score</p>
                                <div className="d-flex align-items-center">
                                    {selectedControlArea?.awsRecommendations ?
                                        !hideEditRecom ? (
                                            <div className="d-flex gap-2 me-3">
                                                <button
                                                    className="btn btn-link p-0"
                                                    onClick={() => {
                                                        postUpdatedRecommendations(selectedControlArea, selectedRecommendation);
                                                        setHideEditRecom(true);
                                                    }}
                                                >
                                                    <img src="images/check.svg" alt="check" />
                                                </button>
                                                <button
                                                    className="btn btn-link p-0"
                                                    onClick={() => {
                                                        setSelectedRecommendation(selectedControlArea?.impacts?.overAllSummary);
                                                        setHideEditRecom(true);
                                                    }}
                                                >
                                                    <img src="images/x.svg" alt="close" />
                                                </button>
                                            </div>
                                        ) : (
                                            // <button className="btn btn-link p-0" onClick={() => setHideEditRecom(false)}>
                                            //     <img
                                            //         src="images/edit-icon-grey.svg"
                                            //         alt="edit-icon"
                                            //     />
                                            // </button>
                                            <button type="button" className="btn edit-btn custom-btn font-medium px-4" onClick={() => setHideEditRecom(false)}>Edit</button>
                                        )
                                        : <></>}

                                </div>
                            </div>
                            <div className="h-280 row d-flex justify-content-center align-items-center">
                                <div className="col-12 col-lg-4 d-flex justify-content-center">
                                    {selectedControlArea?.awsRecommendations ?
                                        <GaugeChart dataValue={[
                                            { name: "A", value: 33.3, color: "#ff796b" },
                                            { name: "B", value: 33.4, color: "#ffd772" },
                                            { name: "C", value: 33.3, color: "#78e07c" },
                                        ]} Gaugeval={selectedControlArea?.impacts?.impactPercentage || 0} />
                                        :
                                        <GaugeChart dataValue={[
                                            { name: "A", value: 100, color: "#777" },
                                            { name: "B", value: 0, color: "#777" },
                                            { name: "C", value: 0, color: "#777" },
                                        ]} Gaugeval={0} />
                                    }
                                </div>
                                {selectedControlArea?.awsRecommendations ?
                                    <div className="col-12 col-lg-8 h-280 d-none d-xl-block">

                                        {
                                            !hideEditRecom ?
                                                <textarea className="border rounded-1 custom-form  py-4 ps-4 pe-5" style={{ height: "290px" }} value={selectedRecommendation} onChange={(event: any) => {
                                                    if (!hideEditRecom)
                                                        setSelectedRecommendation(event.target.value);
                                                }
                                                } /> :
                                                (
                                                    <div className="border rounded-1 custom-form py-4 ps-4 pe-5 scrollable-textarea" style={{ height: "290px", overflowY: "auto" }}>
                                                      <p>{selectedControlArea?.impacts?.overAllSummary || ""}</p>
                                                    </div>
                                                  )}
                                    </div>
                                    :
                                    <div className="col-12 col-lg-8 h-280 slim-scroll d-flex justify-content-center align-items-center h-200" style={{ textAlign: "center" }}>
                                        <p className="font-16 font-medium color-grey-v3" >
                                            No Records Found
                                        </p>
                                    </div>
                                }


                            </div>
                        </>

                    </div>
                </div>
                <div className="col-lg-12 col-xl-12 col-md-12 col-12 mb-4 mt-3">
                    <div className="p-4 border rounded-3">
                        <p className="font-semibold font-18 font-semibold d-flex justify-content-between align-items-center">
                            Recommendations
                            {selectedControlArea?.awsRecommendations ?
                                <img src="images/expand-icon.svg" alt="expand-icon" className="cursor-pointer" data-bs-toggle="modal" data-bs-target="#recommendationsExapnMigrationStrategy" /> :
                                <></>} </p>

                        <div className=" w-100">
                            <div className="table-responsive mb-4">
                                <table className="table table-borderless themeTable mb-0">
                                    <thead className="font-semibold sticky-top">
                                        <tr>
                                            <th className="align-items-center d-flex">
                                                {selectedControlArea?.awsRecommendations ? <><span><input className="theme-check form-check-input label-bold me-2 mt-0" type="checkbox" id="firstquesopt21" checked={selectedControlArea?.awsRecommendations?.every((data: any) => reportData?.selectedAWSRecommendations?.includes(data?.checkId))}
                                                    onChange={(event: any) => {
                                                        const checkIds = selectedControlArea?.awsRecommendations?.map((data: any) => data?.checkId)
                                                        if (event.target.checked) {
                                                            setReportData({ ...reportData, selectedAWSRecommendations: [...reportData?.selectedAWSRecommendations, ...checkIds] })
                                                        }
                                                        else {
                                                            setReportData({ ...reportData, selectedAWSRecommendations: reportData?.selectedAWSRecommendations?.filter((data: any) => !checkIds?.includes(data)) })
                                                        }
                                                    }} /></span><span></span></> :
                                                    <></>
                                                }
                                                Description <span
                                                    className="ms-2 cursor-pointer"
                                                    onClick={() => {
                                                        const sortedData = sortRecommendations(selectedControlArea?.awsRecommendations, "checkName");
                                                        setSelectedControlArea({ ...selectedControlArea, awsRecommendations: sortedData })
                                                    }}
                                                >
                                                    {sortOrder?.checkName === "asc" ? " ↑" : " ↓"}
                                                </span></th>
                                            <th className="  text-center">Impact <span
                                                className="ms-2 cursor-pointer"
                                                onClick={() => {
                                                    const sortedData = sortRecommendations(selectedControlArea?.awsRecommendations, "impact");
                                                    setSelectedControlArea({ ...selectedControlArea, awsRecommendations: sortedData })
                                                }}
                                            >
                                                {sortOrder?.impact === "asc" ? " ↑" : " ↓"}
                                            </span></th>
                                        </tr>
                                    </thead>

                                    <tbody className="font-medium">
                                        {selectedControlArea?.awsRecommendations ?
                                            bindRecommendationGrid(selectedControlArea?.awsRecommendations, 5, "controlAreas")
                                            :
                                            <tr>
                                                <td colSpan={2}>
                                                    <div className="row text-center">
                                                        <img src="images/no-records-found.svg" width={"300"} height={"300"} />
                                                        <p className="font-16 font-medium color-grey-v3 my-5">
                                                            No Records Found
                                                        </p>
                                                    </div>
                                                </td>
                                            </tr>
                                        }
                                    </tbody>

                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }



    return (
        <>
            <div className="container-fluid" style={{ marginTop: "90px" }}>
                <div className="row justify-content-center">
                    <div className="px-4">
                        {/* breadcrumb starts here */}
                        <BreadCrumbs data={breadCrumbs}></BreadCrumbs>
                        {/* breadcrumb ends here */}
                        {/* heading content starts here */}
                        <div className="d-block d-md-flex flex-wrap justify-content-between align-items-center  mb-4">
                            <div className="d-flex align-items-center mb-3 mb-md-0">
                                <a href="#" onClick={(event: any) => {
                                    event.preventDefault()
                                    if (window.confirm("Are you sure you want to leave this page?")) {
                                        navigate("/manageassessment", { state: { organizationId: reportData.organizationId, organizationName: reportData.organizationName } })
                                    }
                                }}><img src="images/backarrow.svg" alt="back-arrow" title="Back" /></a>
                                <h2 className="font-22 font-semibold color-black mb-0 ms-3 ">
                                    WAFR Assessment
                                </h2>
                            </div>
                            <div className="d-grid gap-3 d-md-inline-flex justify-content-md-end">
                                {selectedTab === "awsFindings" && reportData?.inventoryUrl &&
                                    <button type="button" className="btn btn-dark theme-outline-btn d-flex gap-2 align-items-center font-14 font-semibold" onClick={() => {
                                        onSaveFile()
                                    }}><img src="images/dwnld-icon-black.svg" alt="download-icon" />Download Inventory</button>
                                }
                                <button type="button" className="btn btn-dark theme-primary-btn-md d-flex gap-2 align-items-center border-0 font-14 font-semibold" onClick={() => {
                                    setEmptyAreas([])
                                    if (reportData?.executiveSummary?.attributeSummary?.length === 0 || reportData?.businessOverview?.attributeSummary?.length === 0) {
                                        setHideProceedAnyway(false)
                                    }
                                    else {
                                        postSelectedRecommendations()
                                    }
                                }
                                }>
                                    <img src="images/dwnld-icon-white.svg" alt="download-icon" />Download Report</button>
                            </div>
                        </div>
                        {/* heading content ends here */}
                        <div className="row">
                            <div className="col-12 col-md-12 col-lg-3 col-xl-2 ">
                                <div className="row">
                                    <div className="d-lg-flex d-none pe-0 align-items-start v-tab-border left-nav-brd">
                                        <div className="nav flex-column nav-pills custom-v-pills w-100" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                                            <p className="font-16 font-semibold mb-lg-4 mb-2 ps-0 ps-lg-3">WAFR</p>
                                            <button className={`nav-link text-start rounded-0 font-medium font-14 ${selectedTab === "uploadTranscript" ? "active" : ""}`} data-bs-toggle="pill" id="uploadTranscript" type="button" role="tab" aria-selected="true" onClick={() => { setSelectedTab("uploadTranscript") }}>Upload Transcript  </button>
                                            <button className={`nav-link text-start rounded-0 font-medium font-14 ${selectedTab === "executiveSummary" ? "active" : ""}`} data-bs-toggle="pill" id="executiveSummary" type="button" role="tab" aria-selected="true" onClick={() => { setSelectedTab("executiveSummary") }}>Executive Summary <span hidden={!emptyAreas?.includes("executiveSummary")} className="action-needed ms-2" /></button>
                                            <button className={`nav-link text-start rounded-0 font-medium font-14 ${selectedTab === "businessOverview" ? "active" : ""}`} data-bs-toggle="pill" id="businessOverview" type="button" role="tab" aria-selected="true" onClick={() => { setSelectedTab("businessOverview") }}>Business Overview <span hidden={!emptyAreas?.includes("businessOverview")} className="action-needed ms-2" /></button>
                                            <button className={`nav-link text-start rounded-0 font-medium font-14 text-truncate menu-list text-ellipsis-230 ${selectedTab === "awsFindings" ? "active" : ""}`} data-bs-toggle="pill" id="awsFindings" type="button" role="tab" aria-selected="true" onClick={() => { setSelectedTab("awsFindings") }} title="AWS Well-Architected Findings">AWS Well-Architected Findings</button>
                                            <button className={`nav-link text-start rounded-0 font-medium font-14 ${selectedTab === "overallSummary" ? "active" : ""}`} data-bs-toggle="pill" id="overallSummary" type="button" role="tab" aria-selected="true" onClick={() => {
                                                setSortOrder(initialSortData)
                                                setSelectedRecommendations(overallAwsRecommendations)
                                                setSelectedTab("overallSummary")
                                            }}>Overall Summary</button>
                                            <p className="font-16 font-semibold my-lg-4  my-2 ps-0 ps-lg-3">Control Areas</p>
                                            {bindControlAreas()}
                                            <button className={`nav-link text-start rounded-0 font-medium font-14 text-ellipsis-230 ${selectedTab === "finalWalkthrough" ? "active" : ""}`} data-bs-toggle="pill" id="finalWalkthrough" type="button" role="tab" aria-selected="true" onClick={() => { setSelectedTab("finalWalkthrough") }} title="WAFR Final Report Walkthrough">WAFR Final Report Walkthrough</button>
                                        </div>
                                    </div>
                                    <select className="theme-form form-select font-regular font-14 d-lg-none d-block mb-3">
                                        <option selected>Upload Transcript</option>
                                        <option>Executive Summary</option>
                                        <option>Business Overview</option>
                                        <option>AWS Well-Architected Findings</option>
                                        <option>Overall Summary</option>
                                        <option>Reliability</option>
                                        <option>Operational Excellence</option>
                                        <option>Performance Efficiency</option>
                                    </select>
                                </div>
                            </div>
                            <div className="col-12 col-md-12 col-lg-9 col-xl-10 Nav-page-scrl">
                                <div className="row">
                                    <div className="tab-content" id="v-pills-tabContent">
                                        {selectedTab === "uploadTranscript" ? isLoading === true ? <Loader isLoading={true} /> : <UploadTranscript setFileInfo={setSummaryFile} fileInfo={summaryFile} postTranscript={postUploadedTranscript.bind(null, "executiveSummary")}
                                            showToasterPopup={showToasterPopup}
                                            showToasterFailedPopup={showToasterFailedPopup}
                                            hideToast={hideToast}
                                            toastProp={toastProp}
                                            showToasterLoading={showToasterLoading} /> : <></>}
                                        {selectedTab === "executiveSummary" ? <ExecutiveSummary
                                            showToasterPopup={showToasterPopup}
                                            showToasterFailedPopup={showToasterFailedPopup}
                                            hideToast={hideToast}
                                            toastProp={toastProp}
                                            summaryName={"Executive Summary"}
                                            verticalName={"WAFR"}
                                            summary={summaryData?.executiveSummary?.attributeSummary}
                                            postSummary={postSummary.bind(null, "executiveSummary")} />
                                            :
                                            <></>}
                                        {selectedTab === "businessOverview" ? <ExecutiveSummary
                                            showToasterPopup={showToasterPopup}
                                            showToasterFailedPopup={showToasterFailedPopup}
                                            hideToast={hideToast}
                                            toastProp={toastProp}
                                            summaryName={"Business Overview"}
                                            verticalName={"WAFR"}
                                            summary={summaryData?.businessOverview?.attributeSummary}
                                            postSummary={postSummary.bind(null, "businessOverview")} />
                                            :
                                            <></>}
                                        {selectedTab === "awsFindings" ? <AwsWafrFindings /> : <></>}
                                        {selectedTab === "overallSummary" ? <OverallSummary /> : <></>}
                                        {selectedTab === "controlAreas" ? <ControlAreas /> : <></>}
                                        {selectedTab === "finalWalkthrough" ? <ExecutiveSummary
                                            showToasterPopup={showToasterPopup}
                                            showToasterFailedPopup={showToasterFailedPopup}
                                            hideToast={hideToast}
                                            toastProp={toastProp}
                                            summaryName={"WAFR Final Report Walkthrough"}
                                            verticalName={"WAFR"}
                                            summary={summaryData?.finalReportWalkthrough?.attributeSummary}
                                            showToasterLoading={showToasterLoading}
                                            postSummary={postSummary.bind(null, "finalReportWalkthrough")}
                                            setFileInfo={setFinalWalkthroughFile} fileInfo={finalWalkthroughFile} postTranscript={postUploadedTranscript.bind(null, "finalReportWalkthrough")} />
                                            :
                                            <></>}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {saveSuccess && (
                <div className="position-fixed bg-white top-110px start-50 translate-middle w-md-100">
                    <div className="shadow-sm d-flex rounded-3 custom-toast p-3">
                        <div className="toast-body d-flex align-items-center ">
                            <div className="d-block me-3">
                                <img src="images/toast-success.svg" alt="toast-success" />
                            </div>
                            <div className="d-block">
                                <span className="font-medium font-16 color-grey">
                                    saved successfully
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {showToasterFailedPopup && (
                <div className="position-fixed bg-white top-110px start-50 translate-middle w-md-100">
                    <div className="shadow-sm d-flex rounded-3 custom-toast p-3">
                        <div className="toast-body d-flex align-items-center ">
                            <div className="d-block me-3">
                                <img src="images/fail-icon.svg" alt="toast-fail" />
                            </div>
                            <div className="d-block">
                                <span className="font-medium font-16 color-grey">
                                    Api failed.
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {reportData?.updateError && (
                <div className="position-fixed bg-white top-110px start-50 translate-middle w-md-100">
                    <div className="shadow-sm d-flex rounded-3 custom-toast p-3">
                        <div className="toast-body d-flex align-items-center ">
                            <div className="d-block me-3">
                                <img src="images/alert-circle.svg" alt="delete" />
                            </div>
                            <div className="d-block">
                                <span className="font-medium font-16 color-grey">
                                    {reportData?.updateErrorMessage}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            )}


            {<WafrRecommendationsGrid recommendations={selectedRecommendations} type={selectedTab} reportData={reportData} setReportData={setReportData} />}
            {hideProceedAnyway ? <></> :
                <div style={{ "position": "absolute", "top": "30%", "left": "35%", "zIndex": "1500" }}>
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content p-3">
                            <div className="modal-header pb-0 border-0 position-relative">
                                <h5 className="font-semibold font-18 w-100 text-center mb-0">
                                    Alert
                                </h5>
                                <button
                                    type="button"
                                    className="btn-close cursor-pointer position-absolute top-0 end-0 mt-2 me-2"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                    onClick={() => {
                                        setHideProceedAnyway(true)
                                    }}
                                />
                            </div>
                            <div className="modal-body">
                                <div>
                                    <p className="font-medium font-14">
                                        There are mandatory areas left incomplete. You are trying to download pdf without completing those areas.
                                    </p>
                                    <div className="col-md-12 gap-2 d-grid d-md-flex mt-4 " style={{ "justifyContent": "right" }}>


                                        <button
                                            data-bs-dismiss="modal"
                                            type="button"
                                            className="btn btn-dark theme-primary-btn-sm border-0 font-semibold px-4 cursor-pointer"
                                            onClick={() => {
                                                setEmptyAreas([reportData?.executiveSummary?.attributeSummary?.length === 0 ? "executiveSummary" : "", reportData?.businessOverview?.attributeSummary?.length === 0 ? "businessOverview" : ""])
                                                setHideProceedAnyway(true)
                                            }}
                                        >
                                            Review What's Left
                                        </button>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
            {hideProceedAnyway || <div className="modal-backdrop fade show"></div>}
            {isLoading && <Loader isLoading={isLoading} />}
        </>
    )
}

export default WafrReport