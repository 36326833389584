import {apiCall} from "../client/apiCall";
import { baseUrl,authEndpoints } from "../constant/constant";


// PC_API_1.2
export async function generateAccessToken(user: string, email: string, role:string) {
     const response = await apiCall(
          baseUrl + authEndpoints.generateToken,
          "post",
          {
               userName: user,
               emailId: email,
               role:role
          }
     );
     return response.data;
}

export async function logoutApi(access: string | undefined) {
     const response = await apiCall(
          baseUrl+ authEndpoints.logoutApi,
          "post",
          {
               access_token: access,
          }
     );
     return response.data;
}