/*PS_DC_1.0
 * import the necessary packages
 */
import React from "react";
import { PieChart, Pie, Cell, ResponsiveContainer, Label } from "recharts";
import { DonutChartProps } from "../../interface/DonuChartModel";
import styled from 'styled-components';

const StyledResponsiveContainer = styled(ResponsiveContainer)`
.recharts-surface{
pointer-events:none;
}
.recharts-sector{
outline:none;
pointer-events:none;
}
`;
/**
 * PS_DC_1.2 - PS_DC_1.24
 * This function returns the html to render the donut chart
 * @param props
 * @returns html
 */
const DonutChart: React.FC<DonutChartProps> = (props: DonutChartProps) => {
  /**
   * PS_DC_1.3 - PS_DC_1.10
   * this set of code determines the colors of the donut with
   * the length of the data
   */

  const renderCustomizedLabel = (props: any) => {
    const { cx, cy, value } = props;
    return (
      <g>
        <text x={cx} y={cy - 10} textAnchor="middle" dominantBaseline="central" style={{ fontSize: '16px', fontWeight: 'bold' }}>
          Total Recommendations
        </text>
        <text x={cx} y={cy + 10} textAnchor="middle" dominantBaseline="central" style={{ fontSize: '16px', fontWeight: 'bold' }}>
          {totValue().toLocaleString()}
        </text>
      </g>
    );
  };

  const height = props?.height || 400
  let COLORS: string[];
  if (
    props.dataNameValue2.length === 3 &&
    !(
      props.dataNameValue2[0].value == 0 &&
      props.dataNameValue2[1].value == 0 &&
      props.dataNameValue2[2].value == 0
    )
  ) {
    COLORS = ["#ff796b", "#ffd772", "#78e07c"];
  } else if (props.dataNameValue2.length === 2) {
    COLORS = ["#76e3f2", "#8aa2f9"];
  } else if (props.dataNameValue2.length === 6) {
    COLORS = ["#a4e29e", "#86e5f2", "#fac383", "#e3a1fb", "#8bb8fb", "#ff9090"];
  } else {
    COLORS = ["grey"];
  }


  /**
   * PS_DC_1.11
   * if no value is given render this chart
   */
  const donutChart: { name: string; value: number }[] = [
    { name: "Group A", value: 100 },
  ];

  /**
   * PS_DC_1.12 - PS_DC_1.16
   * @returns total value to show the total recomendations
   */
  const totValue = (): number => {
    let totalValue = 0;
    for (let i = 0; i < props.dataNameValue2.length; i++) {
      totalValue += props.dataNameValue2[i].value;
    }
    return totalValue;
  };

  /**
   * PS_DC_1.17 - PS_DC_1.21
   * @returns Cell tag to render the cells on the donut chart
   */
  function bindChartData(): any {
    if (props.dataNameValue2.every(data => data.value === 0)) {
      return donutChart.map((_, index) => (
        <Cell key={`cell-${index}`} fill={"grey"} />
      ));
    }
    if (props.dataNameValue2.length == 2 && (props.dataNameValue2[0]?.value != 0 && props.dataNameValue2[1]?.value == 0)) {
      return props.dataNameValue2.map((_, index) => (
        <Cell key={`cell-${index}`} fill={COLORS[0]} />
      ));
    }
    else if (props.dataNameValue2.length == 2 && (props.dataNameValue2[0]?.value == 0 && props.dataNameValue2[1]?.value != 0)) {
      return props.dataNameValue2.map((_, index) => (
        <Cell key={`cell-${index}`} fill={COLORS[1]} />
      ));
    }
    else if (props.dataNameValue2.length == 2) {
      return props.dataNameValue2.map((_, index) => (
        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
      ));
    }
    else if (props.dataNameValue2[0]?.value == 0 && props.dataNameValue2[1]?.value == 0 && props.dataNameValue2[2]?.value != 0) {
      return props.dataNameValue2.map((_, index) => (
        <Cell key={`cell-${index}`} fill={COLORS[2]} />
      ));
    }
    else if (props.dataNameValue2[0]?.value == 0 && props.dataNameValue2[1]?.value != 0 && props.dataNameValue2[2]?.value == 0) {
      return props.dataNameValue2.map((_, index) => (
        <Cell key={`cell-${index}`} fill={COLORS[1]} />
      ));
    }
    else if (props.dataNameValue2[0]?.value != 0 && props.dataNameValue2[1]?.value == 0 && props.dataNameValue2[2]?.value == 0) {
      return props.dataNameValue2.map((_, index) => (
        <Cell key={`cell-${index}`} fill={COLORS[0]} />
      ));
    }
    else if (props.dataNameValue2.length != 0) {
      return props.dataNameValue2.map((_, index) => (
        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
      ));
    }
    else {
      return donutChart.map((_, index) => (
        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
      ));
    }
  }

  /**
   * PS_DC_1.21
   * @returns the html to bind the data inside the chart
   */
  function bindDonut() {
    if (props.dataNameValue2.length == 2 && props?.active == true) {
      return (
        <>
          <Label
            width={170}
            fill="black"
            style={{ fontWeight: "bold" }}
            position="centerBottom"
          >
            Active Users
          </Label>
          <Label
            width={160}
            fill="black"
            style={{ fontWeight: "bold" }}
            position="centerTop"
            dy={10}
          >
            {` ${totValue().toLocaleString()}`}
          </Label>
        </>
      );
    }
    if (props.dataNameValue2.length == 2 && props?.active == false) {
      return (
        <>
          <Label
            width={170}
            fill="black"
            style={{ fontWeight: "bold" }}
            position="centerBottom"
          >
            InActive Users
          </Label>
          <Label
            width={160}
            fill="black"
            style={{ fontWeight: "bold" }}
            position="centerTop"
            dy={10}
          >
            {` ${totValue().toLocaleString()}`}
          </Label>
        </>
      );
    }
    if (props.dataNameValue2.length == 0 && props?.active == true) {
      return (
        <>
          <Label
            width={170}
            fill="black"
            style={{ fontWeight: "bold" }}
            position="centerBottom"
          >
            Active Users
          </Label>
          <Label
            width={160}
            fill="black"
            style={{ fontWeight: "bold" }}
            position="centerTop"
            dy={10}
          >
            {` ${totValue().toLocaleString()}`}
          </Label>
        </>
      );
    }
    if (props.dataNameValue2.length == 0 && props?.active == false) {
      return (
        <>
          <Label
            width={170}
            fill="black"
            style={{ fontWeight: "bold" }}
            position="centerBottom"
          >
            InActive Users
          </Label>
          <Label
            width={160}
            fill="black"
            style={{ fontWeight: "bold" }}
            position="centerTop"
            dy={10}
          >
            {` ${totValue().toLocaleString()}`}
          </Label>
        </>
      );
    }
    if (props.dataNameValue2.length == 3 && props?.active == true) {
      return (
        <>
          <Label
            width={170}
            fill="black"
            style={{ fontWeight: "bold" }}
            position="centerBottom"
          >
            InActive Users
          </Label>
          <Label
            width={160}
            fill="black"
            style={{ fontWeight: "bold" }}
            position="centerTop"
            dy={10}
          >
            {` ${totValue().toLocaleString()}`}
          </Label>
        </>
      );
    }
    if (props.dataNameValue2.length == 3 && props?.totalUsers) {
      return (
        <>
          <Label
            width={170}
            fill="black"
            style={{ fontWeight: "bold" }}
            position="centerBottom"
          >
            Total Users
          </Label>
          <Label
            width={160}
            fill="black"
            style={{ fontWeight: "bold" }}
            position="centerTop"
            dy={10}
          >
            {` ${(props.dataNameValue2[0]?.value + props.dataNameValue2[1]?.value + props.dataNameValue2[2]?.value).toLocaleString()}`}
          </Label>
        </>
      );
    }
    if (props.dataNameValue2.length == 6) {
      return (
        <>
          <Label
            width={170}
            fill="black"
            style={{ fontWeight: "bold" }}
            position="centerBottom"
          >
            Total Lines
          </Label>
          <Label
            width={160}
            fill="black"
            style={{ fontWeight: "bold" }}
            position="centerTop"
            dy={10}
          >
            {` ${props.dataNameValue2[0].value.toLocaleString()}`}
          </Label>
        </>
      );
    } else {
      return (
        <>
          <Label
            width={170}
            fill="black"
            style={{ fontWeight: "bold" }}
            position="centerBottom"
          >
            Total Recommendations
          </Label>
          <Label
            width={160}
            fill="black"
            style={{ fontWeight: "bold" }}
            position="centerTop"
            dy={10}
          >
            {` ${totValue().toLocaleString()}`}
          </Label>
        </>
      );
    }
  }

  /**
   * PS_DC_1.22 - PS_DC_1.24
   * it returns the html to render the donut chart
   */
  return (
    <StyledResponsiveContainer width="70%" height={height}>
      <PieChart>
        <Pie
          data={
            props.dataNameValue2.length === 0 ||
              (props?.dataNameValue2[0]?.value == 0 &&
                props?.dataNameValue2[1]?.value == 0) ||
              (props?.dataNameValue2[0]?.value == 0 &&
                props?.dataNameValue2[1]?.value == 0 &&
                props?.dataNameValue2[2]?.value == 0)
              ? donutChart
              : props.dataNameValue2
          }
          cx="50%"
          cy="50%"
          innerRadius={"80%"}
          outerRadius={"100%"}
          fill="#8884d8"
          dataKey="value"
          stroke="none"
          labelLine={false}
          label={renderCustomizedLabel}
        >
          {bindChartData()}
        </Pie>
      </PieChart>
    </StyledResponsiveContainer>
  );
};

export default DonutChart;
