import { useState, useEffect } from "react";
import {
  postSummaryModel,
  uploadTranscriptModel,
  wafrFileInfoModel,
} from "../interface/WafrReportModel";
import UploadTranscript from "./reportComponents/UploadTranscript";
import { toastModel } from "../interface/InitiativeModel";
import {
  getProjectReportData,
  getSasToken,
  postSummaryData,
  postUploadedTranscriptData,
} from "../service/ReportApi";
import Loader from "./Loader";
import ExecutiveSummary from "./reportComponents/ExecutiveSummary";
import { useNavigate } from "react-router-dom";
import MigrationPlan from "./MigrationPlan";
import CostBreakdown from "./costBreakdown";
import BreadCrumbs from "./breadCrumb";
import DiscoveryGraphComponent from "./DiscoveryChart";
import { encryptStorage } from "../constant/constant";
import { jwtDecode } from "jwt-decode";
import { getConnectorsData } from "../helpers/encryption";

//PS_VMR_01 - PS_VMR_59 VMware report component
const VMwareReport = ({ initialReportData }: any) => {
  const initialFileInfo: wafrFileInfoModel = {
    fileData: "",
    fileName: "",
    fileType: "",
    base64Transcript: "",
  };

  const [initialSummaryData, setinitialSummaryData] = useState({
    executiveSummary: "",
    businessOverview: "",
    recommendationBreakdown: "",
  });
  const [strategyData, setstrategyData] = useState(
    initialReportData?.vmWareRecommendations
  );

  const toastData: toastModel = {
    toastType: "",
    toastHeaderMessage: "",
    toastBodyMessage: "",
  };

  let breadCrumbs = [
    { title: "Organization", path: "/homepage", isActive: true },
    {
      title: initialReportData?.organizationName,
      path: "/manageassessment",
      isActive: true,
      state: {
        organizationId: initialReportData?.organizationId,
        organizationName: initialReportData?.organizationName,
      },
    },
    { title: initialReportData?.verticalName, path: "", isActive: true },
    { title: "Report", path: "", isActive: false },
  ];

  const regex = /<[^>]*>|"/g;

  const [selectedTab, setSelectedTab] = useState<string>("Upload Transcript");
  const [summaryFile, setSummaryFile] =
    useState<wafrFileInfoModel>(initialFileInfo);
  const [hideToast, setHideToast] = useState<boolean>(true);
  const [toastProp, setToastProp] = useState<toastModel>(toastData);

  const navigate = useNavigate();

  const [reportData, setReportData] = useState<any>(initialReportData);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showToasterLoading, setShowToasterLoading] = useState(false);
  const [showToasterPopup, setShowToasterPopup] = useState(false);
  const [showToasterFailedPopup, setShowToasterFailedPopup] = useState(false);
  const [costBreakdownDetails, setCostBreakdownDetails] = useState(
    initialReportData?.costBreakdownDetails
  );

  //PS_VMR_14
  useEffect(() => {
    initialReportData?.infraSummary?.forEach((attributes: any) => {
      switch (attributes.attributeName) {
        case "Executive Summary":
          setinitialSummaryData((prev) => ({
            ...prev,
            executiveSummary: attributes?.attributeValue,
          }));
          break;

        case "Business Overview":
          setinitialSummaryData((prev) => ({
            ...prev,
            businessOverview: attributes?.attributeValue,
          }));
          break;

        case "Recommendation Breakdown":
          setinitialSummaryData((prev) => ({
            ...prev,
            recommendationBreakdown: attributes?.attributeValue,
          }));
          break;

        default:
          setinitialSummaryData((prev) => ({
            ...prev,
            recommendationBreakdown:
              initialReportData?.vmWareRecommendations?.strategySummary,
          }));
          break;
      }
    });
  }, [initialReportData]);

  function escapeJsonString(str: string): string {
    return str
      .replace(/\\/g, "\\\\")
      .replace(/"/g, '\\"')
      .replace(/\n/g, "\\n")
      .replace(/\r/g, "\\r")
      .replace(/\t/g, "\\t")
      .replace(/\f/g, "\\f");
  }

  //PS_VMR_17 - PS_VMR_28
  const postUploadedTranscript = async (key: string) => {
    let payload: uploadTranscriptModel = {
      organizationId: reportData?.organizationId,
      projectId: reportData?.projectId,
      verticalName: "VMware",
      verticalId: reportData?.verticalId,
      isSummary: true,
      recommendations: [],
      url: [],
      fileName: summaryFile.fileName,
      fileType: ".docx",
      fileData: summaryFile.base64Transcript,
    };
    setShowToasterLoading(true);

    try {
      const response = await postUploadedTranscriptData(payload);
      if (response.status === 200) {

        setShowToasterPopup(true);
        await new Promise((resolve) => setTimeout(resolve, 1000));
        setinitialSummaryData({
          ...initialSummaryData,
          executiveSummary: response?.data?.executiveSummary,
          businessOverview: response?.data?.businessOverview,
        });
      } else {
        let toastData = {
          toastType: "error",
          toastHeaderMessage: "Error",
          toastBodyMessage: "API failed",
        };
        setToastProp(toastData);
        setHideToast(false);
      }
    } catch (error) {
      setShowToasterFailedPopup(true);
    } finally {
      setShowToasterLoading(false);
      setTimeout(() => {
        setShowToasterPopup(false);
        setShowToasterFailedPopup(false);
      }, 4000);
    }
  };

  //PS_VMR_29 - PS_VMR_40
  const postSummary = async (key: string, value: string) => {
    setShowToasterLoading(true);
    let payload: postSummaryModel = {
      organizationId: reportData?.organizationId,
      verticalId: reportData?.verticalId,
      projectId: reportData?.projectId,
      attributeName:
        key === "executiveSummary"
          ? "Executive Summary"
          : key === "businessOverview"
            ? "Business Overview"
            : "Recommendation Breakdown",
      attributeValue: escapeJsonString(value?.trim().replace(regex, "")),
      attributeId: "",
    };

    try {
      const response = await postSummaryData(payload);
      if (response.status === 200) {
        setShowToasterPopup(true);
        await new Promise((resolve) => setTimeout(resolve, 1000));
        if (key === "executiveSummary")
          setinitialSummaryData((prev) => ({
            ...prev,
            executiveSummary: value?.trim(),
          }));
        else if (key === "businessOverview")
          setinitialSummaryData((prev) => ({
            ...prev,
            businessOverview: value?.trim(),
          }));
        else
          setinitialSummaryData((prev) => ({
            ...prev,
            recommendationBreakdown: value?.trim(),
          }));
      } else {
        let toastData = {
          toastType: "error",
          toastHeaderMessage: "Error",
          toastBodyMessage: "API failed",
        };
        setToastProp(toastData);
        setHideToast(false);
      }
    } catch (error) {
      setShowToasterFailedPopup(true);
    } finally {
      setShowToasterLoading(false);
      setTimeout(() => {
        setShowToasterPopup(false);
        setShowToasterFailedPopup(false);
      }, 4000);
    }
  };

  //PS_VMR_41 - PS_VMR_42
  const BindMenus = () => {
    return initialReportData?.menus?.map((data: any) => {
      return (
        <button
          className={`nav-link text-start rounded-0 font-medium font-14 text-truncate  ${selectedTab === data?.menuName ? "active" : ""
            }`}
          key={data?.menuId}
          id={data?.menuName}
          type="button"
          role="tab"
          title={
            data?.menuName && data.menuName.length > 18 ? data.menuName : ""
          }
          onClick={() => {
            setSelectedTab((prev) => data?.menuName);
          }}
        >
          {data?.menuName &&
            (data.menuName.length > 18
              ? data.menuName.slice(0, 18) + "..."
              : data.menuName)}
        </button>
      );
    });
  };

  //Define async function to generate SAS token for blob access
  //PS_VMR_43 - PS_VMR_46
  const generateSasToken = async () => {
    try {
      let value = encryptStorage.getItem("jwt")
      let parsedJtk: any = ""
      let storageAccountName: any, containerName: any;
      if (value) {
        parsedJtk = jwtDecode(value)
        const storageAccountName = getConnectorsData({ key: parsedJtk.ek, encryptedMessage: process.env.REACT_APP_BLOB_ACCOUNT_NAME! });
        containerName = getConnectorsData({ key: parsedJtk.ek, encryptedMessage: process.env.REACT_APP_BLOB_CONTAINER! })
      }
      const sas = await getSasToken("r");
      return (sas.data)

    } catch (error) {
      console.error("Error occurred while downloading the template.", error);
    }

  }

  //Define async function to save file by fetching it with the SAS token and triggering the browser's download functionality
  //PS_VMR_47 - PS_VMR_50
  const downloadCostInfoExcel = async () => {
    const sas = await generateSasToken()
    const response = await fetch(reportData?.excelBlobUrl + `?${sas}`);
    const blob = await response.blob();
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `${reportData?.organizationName}-${strategyData?.strategyName}-inventory details.xlsx`;
    a.click();
  }

  //PS_VMR_51 - PS_VMR_59
  const handleDownloadReport = async () => {
    setIsLoading(true)
    let body = {
      organizationId: reportData.organizationId,
      projectId: reportData.projectId,
      projectName: reportData.projectName,
      verticalId: reportData.verticalId,
      verticalName: reportData.verticalName,
      isViewReport: true
    };

    try {
      let response = await getProjectReportData(body);

      if (response.status === 200) {
        navigate("/previewpage", {
          state: { data: response.data },
        });
      } else {
        // Handle error
        console.error('API call failed');
      }
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setIsLoading(false)
    }
  };


  return (
    <>
      <div>
        {/* top header starts here */}

        {/* top header ends here */}
        <div className="container-fluid" style={{ marginTop: "105px" }}>
          <div className="row justify-content-center">
            <div className="px-4">
              {/* breadcrumb starts here */}
              <BreadCrumbs data={breadCrumbs}></BreadCrumbs>
              {/* breadcrumb ends here */}
              {/* heading content starts here */}
              <div className="d-block d-md-flex flex-wrap justify-content-between align-items-center  mb-4">
                <div className="d-flex align-items-center mb-3 mb-md-0">
                  <a href="#">
                    <img
                      src="images/backarrow.svg"
                      alt="back-arrow"
                      title="Back"
                      onClick={(event: any) => {
                        event.preventDefault();
                        navigate("/manageassessment", {
                          state: {
                            organizationId: initialReportData.organizationId,
                            organizationName:
                              initialReportData.organizationName,
                          },
                        });
                      }}
                    />
                  </a>
                  <h2 className="font-22 font-semibold color-black mb-0 ms-3 ">
                    VMware Assessment
                  </h2>
                </div>
                <div className="d-grid gap-3 d-md-inline-flex justify-content-md-end">
                  {(selectedTab === "Cost Breakdown") && (
                    <button
                      type="button"
                      className="btn btn-dark theme-outline-btn d-flex gap-2 align-items-center font-14 font-semibold"
                      onClick={downloadCostInfoExcel}
                    >
                      <img src="images/ms-excel-icon.svg" alt="download-icon" />
                    </button>
                  )}
                  <button
                    type="button"
                    className="btn btn-dark theme-primary-btn-md d-flex gap-2 align-items-center border-0 font-14 font-semibold"
                    onClick={handleDownloadReport}
                  >
                    <img
                      src="images/dwnld-icon-white.svg"
                      alt="download-icon"
                    />
                    Download Report
                  </button>
                </div>
              </div>
              {/* heading content ends here */}
              <div className="row">
                <div className="col-12 col-md-12 col-lg-3 col-xl-2">
                  <div className="row">
                    <div className="d-lg-flex d-none pe-0 align-items-start v-tab-border left-nav-brd">
                      <div
                        className="nav flex-column nav-pills custom-v-pills w-100"
                        id="v-pills-tab"
                        role="tablist"
                        aria-orientation="vertical"
                      >
                        {BindMenus()}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-12 col-lg-9 col-xl-10 Nav-page-scrl">
                  <div className="row">
                    <div className="tab-content" id="v-pills-tabContent">
                      {selectedTab === "Upload Transcript" && (
                        <UploadTranscript
                          fileInfo={summaryFile}
                          verticalName={"VMware"}
                          setFileInfo={setSummaryFile}
                          postTranscript={postUploadedTranscript.bind(
                            null,
                            "executiveSummary"
                          )}
                          showToasterLoading={showToasterLoading}
                          showToasterPopup={showToasterPopup}
                          showToasterFailedPopup={showToasterFailedPopup}
                          hideToast={hideToast}
                          toastProp={toastProp}
                        />
                      )}
                      {selectedTab === "Executive Summary" && (
                        <ExecutiveSummary
                          summaryName={"Executive Summary"}
                          summary={initialSummaryData?.executiveSummary}
                          verticalName={"VMware"}
                          postSummary={postSummary.bind(
                            null,
                            "executiveSummary"
                          )}
                          showToasterLoading={showToasterLoading}
                          showToasterPopup={showToasterPopup}
                          showToasterFailedPopup={showToasterFailedPopup}
                          hideToast={hideToast}
                          toastProp={toastProp}
                        />
                      )}
                      {selectedTab === "Business Overview" && (
                        <ExecutiveSummary
                          summaryName={"Business Overview"}
                          summary={initialSummaryData.businessOverview}
                          verticalName={"VMware"}
                          postSummary={postSummary.bind(
                            null,
                            "businessOverview"
                          )}
                          showToasterLoading={showToasterLoading}
                          showToasterPopup={showToasterPopup}
                          showToasterFailedPopup={showToasterFailedPopup}
                          hideToast={hideToast}
                          toastProp={toastProp}
                        />
                      )}
                      {selectedTab === "Recommendation Breakdown" ? (
                        <>
                          <ExecutiveSummary
                            verticalName={"VMware"}
                            summaryName={"Recommendation Breakdown"}
                            summary={
                              initialSummaryData?.recommendationBreakdown
                            }
                            summaryTitle={strategyData?.strategyName}
                            VMwareRecommendation={true}
                            postSummary={postSummary.bind(
                              null,
                              "recommendationBreakdown"
                            )}
                            showToasterLoading={showToasterLoading}
                            showToasterPopup={showToasterPopup}
                            showToasterFailedPopup={showToasterFailedPopup}
                            hideToast={hideToast}
                            toastProp={toastProp}
                          />
                        </>
                      ) : (
                        <></>
                      )}

                      {selectedTab === "Discovery - Graph" ? (
                        <DiscoveryGraphComponent
                          vmwareStats={initialReportData?.vMwareStats}
                        />
                      ) : (
                        <></>
                      )}
                      {selectedTab === "Cost Breakdown" ||
                        selectedTab === "Cost" ? (
                        <CostBreakdown
                          initialReportData={initialReportData}
                          setCostBreakdownDetails={setCostBreakdownDetails}
                          costBreakdownDetails={costBreakdownDetails}
                          verticalName={"VMware"}
                          Solution={strategyData?.strategyName}
                          showToasterPopup={showToasterPopup}
                          showToasterFailedPopup={showToasterFailedPopup}
                          hideToast={hideToast}
                          toastProp={toastProp}
                        />
                      ) : (
                        <></>
                      )}
                      {selectedTab === "Timeline" && (
                        <MigrationPlan
                          menuId=""
                          state={{
                            organizationId: reportData?.organizationId,
                            verticalId: reportData?.verticalId,
                            verticalName: reportData?.verticalName,
                            projectId: reportData?.projectId,
                            projectName: reportData?.projectName,
                          }}
                          setIsLoading={setIsLoading}
                        ></MigrationPlan>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="modal fade"
          id="alert"
          aria-hidden="true"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabIndex={-1}
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content p-3">
              <div className="modal-header pb-0 border-0">
                <h5 className="font-semibold font-18 font-semibold d-flex justify-content-between align-items-center">
                  Alert
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                />
              </div>
              <div className="modal-body">
                <div>
                  <p className="font-medium font-14">
                    Lorem ipsum dolor sit amet consectetur, adipisicing elit.
                    Ratione quae consequuntur aperiam tempora est. Harum!
                  </p>
                  <div className="col-md-12 gap-2 d-grid d-md-flex justify-content-center mt-4 ">
                    <button
                      data-bs-toggle="modal"
                      data-bs-target="#progress"
                      data-bs-dismiss="modal"
                      type="button"
                      className="btn btn-secondary  theme-secondary-btn-sm border-0 font-semibold px-4"
                    >
                      Review What's Left
                    </button>
                    <button
                      data-bs-toggle="modal"
                      data-bs-target="#progress"
                      data-bs-dismiss="modal"
                      type="button"
                      className="btn btn-dark theme-primary-btn-sm border-0 font-semibold px-4"
                    >
                      Proceed Anyway
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Loader isLoading={isLoading} />
      </div>
    </>
  );
};

export default VMwareReport;
