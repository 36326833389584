import HorizontalBarChart from "./HorizontalBarChart";
import DonutChart from "./DonutChart";
import GaugeChart from "./GaugeChart";
import AppLineChart from "./GraphLineChart";
import TableComponent from "./RecommendationGrid";
import VerticalChart from "./VerticalBarChart";
import { Bar, BarChart, CartesianGrid, XAxis, YAxis } from "recharts";

export default function CommonReportTemp(props: any) {
  return (
    <div className="col-12 col-md-12 col-lg-9 col-xl-10 Nav-page-scrl">
      <div className="tab-content" id="v-pills-tabContent">
        {/* overall summary content starts here */}
        <div
          className="tab-pane fade show active mb-5"
          id="v-pills-overallSumm"
          role="tabpanel"
        >
          <div className="row">
            <div className="col-lg-12 col-xl-4 col-md-12 col-12 mb-4">
              <div className="green-50 p-4 d-flex align-items-center justify-content-between rounded-3">
                <span className="font-semibold font-14">
                  Overall Proficiency Index
                </span>
                <span className="font-semibold font-24">
                  {`${props.overallProficiencyIndex}%`}
                </span>
              </div>
            </div>
            <div className="col-lg-12 col-xl-4 col-md-12 col-12 mb-4">
              <div className="orange-50 p-4 d-flex align-items-center justify-content-between rounded-3">
                <span className="font-semibold font-14">Peer Score</span>
                <span className="font-semibold font-24">
                  {`${props.peerScore}%`}
                </span>
              </div>
            </div>
            <div className="col-lg-12 col-xl-4 col-md-12 col-12 mb-4">
              <div className="blue-bg-100 p-4 d-flex align-items-center justify-content-between rounded-3">
                <span className="font-semibold font-14">
                  {props.controlArea ? props.controlArea : "Control Areas"}
                </span>
                <span className="font-semibold font-24">
                  {props.controlArea
                    ? `${props.controlAreaScore}%`
                    : `${props.controlAreaScore}`}
                </span>
              </div>
            </div>
            <div className="col-lg-12 col-xl-7 col-md-12 col-12 mb-4">
              <div className="p-4  border rounded-3">
                <p className="font-semibold font-18 font-semibold mb-4">
                  Control areas that influenced your results
                </p>
                <div className="h-400 w-100">
                  <HorizontalBarChart
                    dataNameValue={props.overallHorizonatalBarChart}
                  ></HorizontalBarChart>
                </div>
              </div>
            </div>
            <div className="col-lg-12 col-xl-5 col-md-12 col-12 mb-4">
              <div className="p-4  border rounded-3">
                <p className="font-semibold font-18 font-semibold mb-4">
                  Recommendations based on Impacts
                </p>
                <div className="h-400 w-100 d-flex justify-content-center align-items-center">
                  <DonutChart dataNameValue2={props.donutChart}></DonutChart>
                </div>
              </div>
            </div>

            {props?.controlArea == "Endpoint Security" &&
              props?.isAzureADConnected == 1 && (
                <div className="col-lg-12 col-xl-12 col-md-12 col-12 mb-4">
                  <div className="p-4  border rounded-3">
                    {/* <div class=" row d-flex justify-content-center align-items-center"> */}
                    <div className="row ">
                      {/* line graphs starts  */}

                      {/* line graphs starts  ends*/}
                      <p className="font-semibold font-18 font-semibold mb-5">
                        Device Registration
                      </p>
                      <div className="col-12 col-md-12 col-lg-6 col-xl-3 mb-5">
                        <p className="font-14 font-medium h-42px mb-1">
                          Total Devices
                        </p>
                        <p className="font-32 font-bold">
                          {props.endPointSecurityMetric.totalDevices?.toLocaleString()}
                        </p>
                      </div>
                      <div className="col-12 col-md-12 col-lg-6 col-xl-3 mb-5">
                        <p className="font-14 font-medium h-42px mb-1">
                          Managed Corporate Devices
                        </p>
                        <p className="font-32 font-bold">
                          {props.endPointSecurityMetric.managedCorporateDevice?.toLocaleString()}
                        </p>
                      </div>
                      {/* two col layout for graph and KPI starts*/}
                      <div className="col-md-12">
                        <div className="row">
                          <div className="col-md-6">
                            <div className="row">
                              <div className="col-12 col-md-12 col-lg-6 col-xl-6 mb-5">
                                <p className="font-14 font-medium h-42px mb-1">
                                  Managed BYOD Device
                                </p>
                                <p className="font-32 font-bold">
                                  {
                                    props.endPointSecurityMetric
                                      ?.managedByodDevice
                                  }
                                </p>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-12 col-md-12 col-lg-6 col-xl-6 mb-5">
                                <p className="font-14 font-medium h-42px mb-1">
                                  Non - Compliant Device
                                </p>
                                <p className="font-32 font-bold">
                                  {props.endPointSecurityMetric.nonCompliantDevice.toLocaleString()}
                                </p>
                              </div>
                              <p className="font-semibold font-18  mb-5">
                                Disk Encryption
                              </p>
                              <div className="col-12 col-md-12 col-lg-6 col-xl-6 mb-5">
                                <p className="font-14 font-medium h-42px mb-1">
                                  Disk not Encrypted Device Counts
                                </p>
                                <p className="font-32 font-bold">
                                  {props.endPointSecurityMetric.nonEncryptedDevice.toLocaleString()}
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <p className="font-semibold font-18 font-semibold mb-5">
                              Device Protection
                            </p>
                            <div className=" mb-5 d-flex align-items-center justify-content-center">
                              {/* <VerticalChart
                                          verticalChartDataCorprate={
                                            props.endPointSecurityMetric?.corporate
                                          }
                                          verticalChartDataBYOD={
                                            props.endPointSecurityMetric?.byod
                                          }
                                        /> */}

                              <BarChart
                                width={500}
                                height={300}
                                data={[
                                  {
                                    name: "Corporate",
                                    value:
                                      props.endPointSecurityMetric?.corporate.toLocaleString(),
                                  },
                                  {
                                    name: "BYOD",
                                    value:
                                      props.endPointSecurityMetric?.byod.toLocaleString(),
                                  },
                                ]}
                                margin={{
                                  top: 20,
                                  right: 30,
                                  left: 20,
                                  bottom: 5,
                                }}
                              >
                                <CartesianGrid
                                  horizontal={false}
                                  vertical={false}
                                />
                                <XAxis
                                  type="category"
                                  dataKey="name"
                                  tickLine={false}
                                />
                                <YAxis />
                                <Bar
                                  dataKey="value"
                                  fill="#8caaff"
                                  barSize={40}
                                >
                                  {/* <LabelList
                          dataKey="value"
                          position="top"
                          offset={5}
                          formatter={formatBarLabel}
                        /> */}
                                </Bar>
                              </BarChart>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* two col layout for graph and KPI ends*/}
                    </div>
                  </div>
                </div>
              )}
            {props?.controlArea == "Email Security" &&
              props?.isAzureADConnected == 1 && (
                <div className="col-lg-12 col-xl-12 col-md-12 col-12 mb-4">
                  <div className="p-4  border rounded-3">
                    {/* <div class=" row d-flex justify-content-center align-items-center"> */}
                    <div className="row ">
                      <p className="font-semibold font-18 font-semibold mb-5">
                        Email Discovery
                      </p>
                      <div className="col-12 col-md-12 col-lg-6 col-xl-3 mb-5">
                        <p className="font-14 font-medium h-42px mb-1">
                          Total Emails (Inbound)
                        </p>
                        <p className="font-32 font-bold">
                          {(props?.emailSecurityMetric?.inboundEmails).toLocaleString()}
                        </p>
                      </div>
                      <div className="col-12 col-md-12 col-lg-6 col-xl-3 mb-5">
                        <p className="font-14 font-medium h-42px mb-1">
                          Total Emails (Outbound)
                        </p>
                        <p className="font-32 font-bold">
                          {(props?.emailSecurityMetric?.outboundEmails).toLocaleString()}
                        </p>
                      </div>
                      <p className="font-semibold font-18 font-semibold mb-5">
                        Incoming Emails - CompAuth Failures
                      </p>
                      <div className="col-12 col-md-12 col-lg-6 col-xl-3 mb-5">
                        <p className="font-14 font-medium h-42px mb-1">DKIM</p>
                        <p className="font-32 font-bold">
                          {(props?.emailSecurityMetric?.DKIM).toLocaleString()}
                        </p>
                      </div>
                      <div className="col-12 col-md-12 col-lg-6 col-xl-3 mb-5">
                        <p className="font-14 font-medium h-42px mb-1">DMARC</p>
                        <p className="font-32 font-bold">
                          {(props?.emailSecurityMetric?.DMARC).toLocaleString()}
                        </p>
                      </div>
                      <div className="col-12 col-md-12 col-lg-6 col-xl-3 mb-5">
                        <p className="font-14 font-medium h-42px mb-1">
                          SPF Failure
                        </p>
                        <p className="font-32 font-bold">
                          {(props?.emailSecurityMetric?.SPFFailure).toLocaleString()}
                        </p>
                      </div>
                      {/* line graphs starts  */}
                      <div className="col-xl-6 col-12 mb-5">
                        <div className="d-flex justify-content-between align-items-center">
                          <span className="font-18 font-semibold">
                            Malware Threats - Last 7 Days
                          </span>
                          <span className="blue-bg-100 p-2 rounded-2 link-blue font-16 font-semibold">
                            <span className="me-3">#Total</span>{" "}
                            <span>
                              {(
                                props?.emailSecurityMetric?.malwareThreats[0]
                                  .uv +
                                props?.emailSecurityMetric?.malwareThreats[1]
                                  .uv +
                                props?.emailSecurityMetric?.malwareThreats[2]
                                  .uv +
                                props?.emailSecurityMetric?.malwareThreats[3]
                                  .uv +
                                props?.emailSecurityMetric?.malwareThreats[4]
                                  .uv +
                                props?.emailSecurityMetric?.malwareThreats[5]
                                  .uv +
                                props?.emailSecurityMetric?.malwareThreats[6].uv
                              ).toLocaleString()}
                            </span>
                          </span>
                        </div>
                        <div className="h-400 w-100 d-flex justify-content-center align-items-center">
                          <AppLineChart
                            dataForLine={
                              props?.emailSecurityMetric?.malwareThreats
                            }
                          ></AppLineChart>
                        </div>
                      </div>
                      <div className="col-xl-6 col-12 mb-5">
                        <div className="d-flex justify-content-between align-items-center">
                          <span className="font-18 font-semibold">
                            Phish Threats - Last 7 Days
                          </span>
                          <span className="blue-bg-100 p-2 rounded-2 link-blue font-16 font-semibold">
                            <span className="me-3">#Total</span>{" "}
                            <span>
                              {(
                                props?.emailSecurityMetric?.phishThreats[0].uv +
                                props?.emailSecurityMetric?.phishThreats[1].uv +
                                props?.emailSecurityMetric?.phishThreats[2].uv +
                                props?.emailSecurityMetric?.phishThreats[3].uv +
                                props?.emailSecurityMetric?.phishThreats[4].uv +
                                props?.emailSecurityMetric?.phishThreats[5].uv +
                                props?.emailSecurityMetric?.phishThreats[6].uv
                              ).toLocaleString()}
                            </span>
                          </span>
                        </div>
                        <div className="h-400 w-100 d-flex justify-content-center align-items-center">
                          <AppLineChart
                            dataForLine={
                              props?.emailSecurityMetric?.phishThreats
                            }
                          ></AppLineChart>
                        </div>
                      </div>
                      <div className="col-xl-6 col-12 mb-5">
                        <div className="d-flex justify-content-between align-items-center">
                          <span className="font-18 font-semibold">
                            Spam Threats - Last 7 Days
                          </span>
                          <span className="blue-bg-100 p-2 rounded-2 link-blue font-16 font-semibold">
                            <span className="me-3">#Total</span>{" "}
                            <span>
                              {(
                                props?.emailSecurityMetric?.spamThreats[0].uv +
                                props?.emailSecurityMetric?.spamThreats[1].uv +
                                props?.emailSecurityMetric?.spamThreats[2].uv +
                                props?.emailSecurityMetric?.spamThreats[3].uv +
                                props?.emailSecurityMetric?.spamThreats[4].uv +
                                props?.emailSecurityMetric?.spamThreats[5].uv +
                                props?.emailSecurityMetric?.spamThreats[6].uv
                              ).toLocaleString()}
                            </span>
                          </span>
                        </div>
                        <div className="h-400 w-100 d-flex justify-content-center align-items-center">
                          <AppLineChart
                            dataForLine={
                              props?.emailSecurityMetric?.spamThreats
                            }
                          ></AppLineChart>
                        </div>
                      </div>
                      <div className="col-xl-6 col-12 mb-5">
                        <div className="d-flex justify-content-between align-items-center">
                          <span className="font-18 font-semibold">
                            Spoof Threats - Last 7 Days
                          </span>
                          <span className="blue-bg-100 p-2 rounded-2 link-blue font-16 font-semibold">
                            <span className="me-3">#Total</span>{" "}
                            <span>
                              {(
                                props?.emailSecurityMetric?.spoofThreats[0].uv +
                                props?.emailSecurityMetric?.spoofThreats[1].uv +
                                props?.emailSecurityMetric?.spoofThreats[2].uv +
                                props?.emailSecurityMetric?.spoofThreats[3].uv +
                                props?.emailSecurityMetric?.spoofThreats[4].uv +
                                props?.emailSecurityMetric?.spoofThreats[5].uv +
                                props?.emailSecurityMetric?.spoofThreats[6].uv
                              ).toLocaleString()}
                            </span>
                          </span>
                        </div>
                        <div className="h-400 w-100 d-flex justify-content-center align-items-center">
                          <AppLineChart
                            dataForLine={
                              props?.emailSecurityMetric?.spoofThreats
                            }
                          ></AppLineChart>
                        </div>
                      </div>
                      {/* line graphs starts  ends*/}
                      {/* two col layout for graph and KPI starts*/}
                      {/* two col layout for graph and KPI ends*/}
                    </div>
                  </div>
                </div>
              )}
            {props?.controlArea == "Identity & Access Management" &&
              props?.isAzureADConnected != -1 && (
                <div className="col-lg-12 col-xl-12 col-md-12 col-12 mb-4">
                  <div className="p-4  border rounded-3">
                    {/* <div class=" row d-flex justify-content-center align-items-center"> */}
                    <div className="row ">
                      {/* line graphs starts  */}
                      <div className="col-xl-6 col-12 mb-5">
                        <div className="d-flex justify-content-between align-items-center">
                          <span className="font-18 font-semibold">
                            Users And Roles
                          </span>
                        </div>
                        <div className="h-400 w-80 d-flex justify-content-center align-items-center">
                          <DonutChart
                            dataNameValue2={props?.activeUsersDonutChartData}
                            active={true}
                          ></DonutChart>
                        </div>
                        {props?.isAzureADConnected == 1 && (
                          <>
                            <div
                              style={{
                                width: "40%",
                                float: "left",
                                paddingRight: "10%",
                                marginTop: "2%",
                              }}
                            >
                              <div style={{ float: "left" }}>
                                <span
                                  style={{
                                    display: "inline-block",
                                    width: 16,
                                    height: 16,
                                    backgroundColor: "#76E3F2",
                                    borderRadius: 16,
                                    marginRight: 12,
                                  }}
                                />
                                <label
                                  style={{
                                    fontFamily: "SFProText-Medium",
                                    fontSize: 14,
                                    color: "#000000",
                                  }}
                                >
                                  Guests
                                </label>
                              </div>
                              <div style={{ float: "right" }}>
                                <label
                                  style={{
                                    fontFamily: "SFProText-Medium",
                                    fontSize: 16,
                                    fontWeight: 600,
                                    float: "right",
                                  }}
                                >
                                  {(props?.identityAndAccessManagementMetric?.activeGuestsUser).toLocaleString()}
                                </label>
                              </div>
                            </div>
                            <div
                              style={{
                                width: "40%",
                                float: "left",
                                paddingLeft: "10%",
                                marginTop: "2%",
                              }}
                            >
                              <div style={{ float: "left" }}>
                                <span
                                  style={{
                                    display: "inline-block",
                                    width: 16,
                                    height: 16,
                                    backgroundColor: "#8AA2F9",
                                    borderRadius: 16,
                                    marginRight: 12,
                                  }}
                                />
                                <label
                                  style={{
                                    fontFamily: "SFProText-Medium",
                                    fontSize: 14,
                                    color: "#000000",
                                  }}
                                >
                                  Internal
                                </label>
                              </div>
                              <div style={{ float: "right" }}>
                                <label
                                  style={{
                                    fontFamily: "SFProText-Medium",
                                    fontSize: 16,
                                    fontWeight: 600,
                                    float: "right",
                                  }}
                                >
                                  {(props?.identityAndAccessManagementMetric?.activeInternalUser).toLocaleString()}
                                </label>
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                      <div
                        className="col-xl-6 col-12 mb-5"
                        style={{ marginTop: "2.2%" }}
                      >
                        <div className="d-flex justify-content-between align-items-center"></div>
                        <div className="h-400 w-80 d-flex justify-content-center align-items-center">
                          <DonutChart
                            dataNameValue2={props?.inActiveUsersDonutChartData}
                            active={false}
                          ></DonutChart>
                        </div>
                        {props?.isAzureADConnected == 1 && (
                          <>
                            <div
                              style={{
                                width: "40%",
                                float: "left",
                                paddingLeft: "10%",
                                marginTop: "2%",
                              }}
                            >
                              <div style={{ float: "left" }}>
                                <span
                                  style={{
                                    display: "inline-block",
                                    width: 16,
                                    height: 16,
                                    backgroundColor: "#76E3F2",
                                    borderRadius: 16,
                                    marginRight: 12,
                                  }}
                                />
                                <label
                                  style={{
                                    fontFamily: "SFProText-Medium",
                                    fontSize: 14,
                                    color: "#000000",
                                  }}
                                >
                                  Guests
                                </label>
                              </div>
                              <div style={{ float: "right" }}>
                                <label
                                  style={{
                                    fontFamily: "SFProText-Medium",
                                    fontSize: 16,
                                    fontWeight: 600,
                                    float: "right",
                                  }}
                                >
                                  {(props?.identityAndAccessManagementMetric?.inActiveGuestsUser).toLocaleString()}
                                </label>
                              </div>
                            </div>
                            <div
                              style={{
                                width: "40%",
                                float: "left",
                                paddingLeft: "10%",
                                marginTop: "2%",
                              }}
                            >
                              <div style={{ float: "left" }}>
                                <span
                                  style={{
                                    display: "inline-block",
                                    width: 16,
                                    height: 16,
                                    backgroundColor: "#8AA2F9",
                                    borderRadius: 16,
                                    marginRight: 12,
                                  }}
                                />
                                <label
                                  style={{
                                    fontFamily: "SFProText-Medium",
                                    fontSize: 14,
                                    color: "#000000",
                                  }}
                                >
                                  Internal
                                </label>
                              </div>
                              <div style={{ float: "right" }}>
                                <label
                                  style={{
                                    fontFamily: "SFProText-Medium",
                                    fontSize: 16,
                                    fontWeight: 600,
                                    float: "right",
                                  }}
                                >
                                  {(props?.identityAndAccessManagementMetric?.inActiveInternalUser).toLocaleString()}
                                </label>
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                      {/* line graphs starts  ends*/}
                      <p className="font-semibold font-18 font-semibold mb-5">
                        Authentication and Authorization Mechanisms
                      </p>
                      <div className="col-12 col-md-12 col-lg-6 col-xl-3 mb-5">
                        <p className="font-14 font-medium h-42px mb-1">
                          # of Applications
                        </p>
                        <p className="font-32 font-bold">
                          {(props?.identityAndAccessManagementMetric?.noOfApplications).toLocaleString()}
                        </p>
                      </div>
                      <div className="col-12 col-md-12 col-lg-6 col-xl-3 mb-5">
                        <p className="font-14 font-medium h-42px mb-1">
                          # of Groups
                        </p>
                        <p className="font-32 font-bold">
                          {(props?.identityAndAccessManagementMetric?.noOfGroups).toLocaleString()}
                        </p>
                      </div>
                      <div className="col-12 col-md-12 col-lg-6 col-xl-3 mb-5">
                        <p className="font-14 font-medium h-42px mb-1">
                          MFA Disabled Users
                        </p>
                        <p className="font-32 font-bold">
                          {(props?.identityAndAccessManagementMetric?.mfaDisabledUsers).toLocaleString()}
                        </p>
                      </div>
                      <p className="font-semibold font-18 font-semibold mb-5">
                        Identity Protection
                      </p>
                      <div className="row">
                        <div className="col-12 col-md-12 col-lg-6 col-xl-6 mb-5">
                          <p className="font-14 font-medium h-42px mb-1">
                            Password Policy
                          </p>
                          <p className="font-32 font-bold">
                            {
                              props?.identityAndAccessManagementMetric
                                ?.passwordPolicy
                            }
                          </p>
                        </div>
                      </div>
                      {/* two col layout for graph and KPI starts*/}
                      <div className="col-md-12">
                        <div className="row">
                          {props?.isAzureADConnected == 1 && (
                            <div className="col-md-6">
                              <p className="font-semibold font-18 font-semibold mb-5">
                                License Utilization
                              </p>
                              <div className="row">
                                <div className="col-12 col-md-12 col-lg-6 col-xl-6 mb-5">
                                  <p className="font-14 font-medium h-42px mb-1">
                                    License assigned for Active users
                                  </p>
                                  <p className="font-32 font-bold">
                                    {props?.identityAndAccessManagementMetric?.licenseAssignedForActiveUsers?.toLocaleString()}
                                  </p>
                                </div>
                                <div className="col-12 col-md-12 col-lg-6 col-xl-6 mb-5">
                                  <p className="font-14 font-medium h-42px mb-1">
                                    License assigned for Inactive users
                                  </p>
                                  <p className="font-32 font-bold">
                                    {props?.identityAndAccessManagementMetric?.licenseAssignedForInactiveUsers?.toLocaleString()}
                                  </p>
                                </div>
                                <div className="col-12 col-md-12 col-lg-6 col-xl-6 mb-5">
                                  <p className="font-14 font-medium h-42px mb-1">
                                    Un assigned license count
                                  </p>
                                  <p className="font-32 font-bold">
                                    {props?.identityAndAccessManagementMetric?.unAssignedLicenseCount?.toLocaleString()}
                                  </p>
                                </div>
                              </div>
                            </div>
                          )}
                          <div className="col-md-6">
                            <p className="font-semibold font-18 font-semibold mb-5">
                              Identity Protection - Risky users
                            </p>
                            <div className=" mb-5 d-flex align-items-center justify-content-center w-80">
                              <DonutChart
                                dataNameValue2={props?.riskyUsersDonuChartData}
                                totalUsers={true}
                              ></DonutChart>
                            </div>
                            <div className="row justify-content-between">
                              <div className="col-xl-5 col-12 mb-4 ">
                                <p className="d-flex justify-content-between align-items-center">
                                  <span className="font-16 font-medium d-flex align-items-center">
                                    <span className="pie-legend-rounded pie-bg-red me-2 " />
                                    High Impact
                                  </span>{" "}
                                  <span className="font-20 font-bold">
                                    {(props?.identityAndAccessManagementMetric?.highRiskyUsers).toLocaleString()}
                                  </span>
                                </p>
                              </div>
                              <div className="col-xl-5 col-12 mb-4">
                                <p className="d-flex justify-content-between align-items-center">
                                  <span className="font-16 font-medium d-flex align-items-center">
                                    <span className="pie-legend-rounded pie-bg-orange me-2 " />
                                    Medium Impact
                                  </span>{" "}
                                  <span className="font-20 font-bold">
                                    {(props?.identityAndAccessManagementMetric?.mediumRiskyUsers).toLocaleString()}
                                  </span>
                                </p>
                              </div>
                              <div className="col-xl-5 col-12 mb-3">
                                <p className="d-flex justify-content-between align-items-center">
                                  <span className="font-16 font-medium d-flex align-items-center">
                                    <span className="pie-legend-rounded pie-bg-green me-2 " />
                                    Low Impact
                                  </span>{" "}
                                  <span className="font-20 font-bold">
                                    {(props?.identityAndAccessManagementMetric?.lowRiskyUsers).toLocaleString()}
                                  </span>
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* two col layout for graph and KPI ends*/}
                    </div>
                  </div>
                </div>
              )}

            {props?.controlArea == "Modularity" && (
              <div className="col-lg-12 col-xl-12 col-md-12 col-12 mb-4">
                <div className="p-4  border rounded-3">
                  <p className="font-semibold font-18 font-semibold mb-4">
                    Code Metrics and Analysis
                  </p>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="h-400 w-80 d-flex justify-content-center align-items-center">
                        <DonutChart
                          dataNameValue2={props.modularitySonarCloudChart}
                        ></DonutChart>
                      </div>
                    </div>
                    <div className="col-md-6 align-items-center justify-content-center d-flex">
                      <div className="w-75 w-md-100">
                        <p className="d-flex justify-content-between align-items-center">
                          <span className="font-16 font-medium d-flex align-items-center">
                            <span className="pie-legend pie-bg-green me-2 " />
                            Lines of Code
                          </span>
                          <span className="font-20 font-bold">
                            {(props?.codeAnalysisData?.size?.linesOfCode).toLocaleString()}
                          </span>
                        </p>
                        <p className="d-flex justify-content-between align-items-center">
                          <span className="font-16 font-medium d-flex align-items-center">
                            <span className="pie-legend pie-bg-blue me-2 " />
                            Statements
                          </span>
                          <span className="font-20 font-bold">
                            {(props?.codeAnalysisData?.size?.statements).toLocaleString()}
                          </span>
                        </p>
                        <p className="d-flex justify-content-between align-items-center">
                          <span className="font-16 font-medium d-flex align-items-center">
                            <span className="pie-legend pie-bg-orange me-2 " />
                            Functions
                          </span>
                          <span className="font-20 font-bold">
                            {(props?.codeAnalysisData?.size?.functions).toLocaleString()}
                          </span>
                        </p>
                        <p className="d-flex justify-content-between align-items-center">
                          <span className="font-16 font-medium d-flex align-items-center">
                            <span className="pie-legend pie-bg-indigo me-2 " />
                            Classes
                          </span>
                          <span className="font-20 font-bold">
                            {(props?.codeAnalysisData?.size?.classes).toLocaleString()}
                          </span>
                        </p>
                        <p className="d-flex justify-content-between align-items-center">
                          <span className="font-16 font-medium d-flex align-items-center">
                            <span className="pie-legend pie-bg-mildblue me-2 " />
                            Files
                          </span>
                          <span className="font-20 font-bold">
                            {(props?.codeAnalysisData?.size?.files).toLocaleString()}
                          </span>
                        </p>
                        <p className="d-flex justify-content-between align-items-center">
                          <span className="font-16 font-medium d-flex align-items-center">
                            <span className="pie-legend pie-bg-red me-2 " />
                            Comment Lines
                          </span>
                          <span className="font-20 font-bold">
                            {(props?.codeAnalysisData?.size?.commentLines).toLocaleString()}
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            <div className="col-lg-12 col-xl-12 col-md-12 col-12 mb-4">
              <div className="p-4  border rounded-3">
                <p className="font-semibold font-18 font-semibold mb-4">
                  Based on your Score
                </p>
                <div className="h-280 row d-lg-flex d-grid justify-content-center align-items-center">
                  <div className="col-12 col-lg-4">
                    <GaugeChart
                      dataValue={props.gaugeChart}
                      Gaugeval={props.gaugeChartValue}
                    ></GaugeChart>
                  </div>
                  <div className="col-12 col-md-12 col-lg-12 col-xl-8 h-280 slim-scroll ">
                    <p className="font-16 font-regular ">
                      <span className="mb-3 d-block">{props.overallRecom}</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            {props?.vertical == "Salesforce" &&
              !(
                props?.cloudPerformanceInsights?.salesServiceCloud == -1 &&
                props?.cloudPerformanceInsights?.marketingCloud == -1 &&
                props?.cloudPerformanceInsights?.commerceCloud == -1
              ) && (
                <div className="col-lg-12 col-xl-12 col-md-12 col-12 mb-4">
                  <div className="p-4  border rounded-3">
                    <div className="row ">
                      <p className="font-semibold font-18 font-semibold mb-5">
                        Cloud Performance Insights
                      </p>
                      {props?.cloudPerformanceInsights?.salesServiceCloud ==
                      -1 ? (
                        ""
                      ) : (
                        <div className="col-12 col-md-12 col-lg-6 col-xl-3 mb-5">
                          <p className="font-14 font-medium h-42px mb-1">
                            Sales/Service Cloud
                          </p>
                          <p className="font-32 font-bold">{`${props?.cloudPerformanceInsights?.salesServiceCloud}%`}</p>
                        </div>
                      )}
                      {props?.cloudPerformanceInsights?.marketingCloud == -1 ? (
                        ""
                      ) : (
                        <div className="col-12 col-md-12 col-lg-6 col-xl-3 mb-5">
                          <p className="font-14 font-medium h-42px mb-1">
                            Marketing Cloud
                          </p>
                          <p className="font-32 font-bold">{`${props?.cloudPerformanceInsights?.marketingCloud}%`}</p>
                        </div>
                      )}
                      {props?.cloudPerformanceInsights?.commerceCloud == -1 ? (
                        ""
                      ) : (
                        <div className="col-12 col-md-12 col-lg-6 col-xl-3 mb-5">
                          <p className="font-14 font-medium h-42px mb-1">
                            Commerce Cloud
                          </p>
                          <p className="font-32 font-bold">{`${props?.cloudPerformanceInsights?.commerceCloud}%`}</p>
                        </div>
                      )}

                      {/* two col layout for graph and KPI starts*/}
                      {/* two col layout for graph and KPI ends*/}
                    </div>
                  </div>
                </div>
              )}
            {props.controlArea == "Modularity" && (
              <div className="col-lg-12 col-xl-12 col-md-12 col-12 mb-4">
                <div className="p-4  border rounded-3">
                  <p className="font-semibold font-18 font-semibold mb-4">
                    Based on the Code Analysis
                  </p>
                  <div className=" row d-flex justify-content-center align-items-center">
                    <div className="px-5">
                      {/* issues code starts here  */}
                      <p className="mb-4 font-semibold font-14">
                        <span>
                          <img
                            src="images/accordion-arrow.svg"
                            alt="accordion-arrow"
                            className="cursor-pointer accordion-arrow me-2"
                            data-bs-toggle="collapse"
                            data-bs-target="#issues"
                            aria-expanded="true"
                          />
                        </span>
                        Issues
                      </p>
                      <div className="row collapse show" id="issues">
                        <div className="col-12 col-md-12 col-lg-6 col-xl-3 mb-2">
                          <p className="font-14 font-medium h-42px mb-1">
                            Issues
                          </p>
                          <p className="font-32 font-bold">
                            {props.codeAnalysisData.issues.issues.toLocaleString()}
                          </p>
                        </div>
                        <div className="col-12 col-md-12 col-lg-6 col-xl-3 mb-5">
                          <p className="font-14 font-medium h-42px mb-1">
                            Open Issues
                          </p>
                          <p className="font-32 font-bold">
                            {props.codeAnalysisData.issues.openIssues.toLocaleString()}
                          </p>
                        </div>
                        <div className="col-12 col-md-12 col-lg-6 col-xl-3 mb-2">
                          <p className="font-14 font-medium h-42px mb-1">
                            Confirmed Issues
                          </p>
                          <p className="font-32 font-bold">
                            {props.codeAnalysisData.issues.confirmedIssues.toLocaleString()}
                          </p>
                        </div>
                        <div className="col-12 col-md-12 col-lg-6 col-xl-3 mb-2">
                          <p className="font-14 font-medium h-42px mb-1">
                            False Positive Issues
                          </p>
                          <p className="font-32 font-bold">
                            {props.codeAnalysisData.issues.falsePositiveIssues.toLocaleString()}
                          </p>
                        </div>
                        <div className="col-12 col-md-12 col-lg-6 col-xl-3 mb-2">
                          <p className="font-14 font-medium h-42px mb-1">
                            Won’t Fix Issues
                          </p>
                          <p className="font-32 font-bold">
                            {props.codeAnalysisData.issues.wontFixIssues.toLocaleString()}
                          </p>
                        </div>
                      </div>
                      {/* issues code ends here  */}
                      {/* issues code starts here  */}
                      <p className="mb-4 font-semibold font-14">
                        <span>
                          <img
                            src="images/accordion-arrow.svg"
                            alt="accordion-arrow"
                            className="cursor-pointer accordion-arrow me-2"
                            data-bs-toggle="collapse"
                            data-bs-target="#Complexity"
                            aria-expanded="true"
                          />
                        </span>
                        Complexity
                      </p>
                      <div className="row collapse show" id="Complexity">
                        <div className="col-12 col-md-12 col-lg-6 col-xl-3 mb-2">
                          <p className="font-14 font-medium h-42px mb-1">
                            Cyclomatic Complexity
                          </p>
                          <p className="font-32 font-bold">
                            {props.codeAnalysisData.complexity.cyclomaticComplexity.toLocaleString()}
                          </p>
                        </div>
                        <div className="col-12 col-md-12 col-lg-6 col-xl-3 mb-2">
                          <p className="font-14 font-medium h-42px mb-1">
                            Cognitive Complexity
                          </p>
                          <p className="font-32 font-bold">
                            {props.codeAnalysisData.complexity.cognitiveComplexity.toLocaleString()}
                          </p>
                        </div>
                      </div>
                      {/* issues code ends here  */}
                      {/* issues code starts here  */}
                      <p className="mb-4 font-semibold font-14">
                        <span>
                          <img
                            src="images/accordion-arrow.svg"
                            alt="accordion-arrow"
                            className="cursor-pointer accordion-arrow me-2"
                            data-bs-toggle="collapse"
                            data-bs-target="#Size"
                            aria-expanded="true"
                          />
                        </span>
                        Size
                      </p>
                      <div className="row collapse show" id="Size">
                        <div className="col-12 col-md-12 col-lg-6 col-xl-3 mb-2">
                          <p className="font-14 font-medium h-42px mb-1">
                            Lines of Code
                          </p>
                          <p className="font-32 font-bold">
                            {props.codeAnalysisData.size.linesOfCode.toLocaleString()}
                          </p>
                        </div>
                        <div className="col-12 col-md-12 col-lg-6 col-xl-3 mb-2">
                          <p className="font-14 font-medium h-42px mb-1">
                            Statements
                          </p>
                          <p className="font-32 font-bold">
                            {props.codeAnalysisData.size.statements.toLocaleString()}
                          </p>
                        </div>
                        <div className="col-12 col-md-12 col-lg-6 col-xl-3 mb-2">
                          <p className="font-14 font-medium h-42px mb-1">
                            Functions
                          </p>
                          <p className="font-32 font-bold">
                            {props.codeAnalysisData.size.functions.toLocaleString()}
                          </p>
                        </div>
                        <div className="col-12 col-md-12 col-lg-6 col-xl-3 mb-2">
                          <p className="font-14 font-medium h-42px mb-1">
                            Classes
                          </p>
                          <p className="font-32 font-bold">
                            {props.codeAnalysisData.size.classes.toLocaleString()}
                          </p>
                        </div>
                        <div className="col-12 col-md-12 col-lg-6 col-xl-3 mb-2">
                          <p className="font-14 font-medium h-42px mb-1">
                            Files
                          </p>
                          <p className="font-32 font-bold">
                            {props.codeAnalysisData.size.files.toLocaleString()}
                          </p>
                        </div>
                        <div className="col-12 col-md-12 col-lg-6 col-xl-3 mb-2">
                          <p className="font-14 font-medium h-42px mb-1">
                            Comment Lines
                          </p>
                          <p className="font-32 font-bold">
                            {props.codeAnalysisData.size.commentLines.toLocaleString()}
                          </p>
                        </div>
                        <div className="col-12 col-md-12 col-lg-6 col-xl-3 mb-2">
                          <p className="font-14 font-medium h-42px mb-1">
                            {`Comments (%)`}
                          </p>
                          <p className="font-32 font-bold">
                            {`${props.codeAnalysisData.size.comments.toFixed(
                              2
                            )}%`}
                          </p>
                        </div>
                      </div>
                      {/* issues code ends here  */}
                      {/* issues code starts here  */}
                      <p className="mb-4 font-semibold font-14">
                        <span>
                          <img
                            src="images/accordion-arrow.svg"
                            alt="accordion-arrow"
                            className="cursor-pointer accordion-arrow me-2"
                            data-bs-toggle="collapse"
                            data-bs-target="#Duplications"
                            aria-expanded="true"
                          />
                        </span>
                        Duplications
                      </p>
                      <div className="row collapse show" id="Duplications">
                        <div className="col-12 col-md-12 col-lg-6 col-xl-3 mb-2">
                          <p className="font-14 font-medium h-42px mb-1">
                            Density
                          </p>
                          <p className="font-32 font-bold">
                            {`${props.codeAnalysisData.duplications.density.toFixed(
                              2
                            )}%`}
                          </p>
                        </div>
                        <div className="col-12 col-md-12 col-lg-6 col-xl-3 mb-2">
                          <p className="font-14 font-medium h-42px mb-1">
                            Duplicated Lines
                          </p>
                          <p className="font-32 font-bold">
                            {props.codeAnalysisData.duplications.DuplicatedLines.toLocaleString()}
                          </p>
                        </div>
                        <div className="col-12 col-md-12 col-lg-6 col-xl-3 mb-2">
                          <p className="font-14 font-medium h-42px mb-1">
                            Duplicated Blocks
                          </p>
                          <p className="font-32 font-bold">
                            {props.codeAnalysisData.duplications.duplicatedBlocks.toLocaleString()}
                          </p>
                        </div>
                        <div className="col-12 col-md-12 col-lg-6 col-xl-3 mb-2">
                          <p className="font-14 font-medium h-42px mb-1">
                            Duplicated Files
                          </p>
                          <p className="font-32 font-bold">
                            {props.codeAnalysisData.duplications.duplicatedFiles.toLocaleString()}
                          </p>
                        </div>
                      </div>
                      {/* issues code ends here  */}
                      {/* issues code starts here  */}
                      <p className="mb-4 font-semibold font-14">
                        <span>
                          <img
                            src="images/accordion-arrow.svg"
                            alt="accordion-arrow"
                            className="cursor-pointer accordion-arrow me-2"
                            data-bs-toggle="collapse"
                            data-bs-target="#Maintainability"
                            aria-expanded="true"
                          />
                        </span>
                        Maintainability
                      </p>
                      <div className="row collapse show" id="Maintainability">
                        <div className="col-12 col-md-12 col-lg-6 col-xl-3 mb-2">
                          <p className="font-14 font-medium h-42px mb-1">
                            Code Smells
                          </p>
                          <p className="font-32 font-bold ">
                            {props.codeAnalysisData.maintainability.codeSmells.toLocaleString()}
                          </p>
                        </div>
                        <div className="col-12 col-md-12 col-lg-6 col-xl-3 mb-2">
                          <p className="font-14 font-medium h-42px mb-1">
                            Debt
                          </p>
                          <p className="font-32 font-bold">
                            {props.codeAnalysisData.maintainability.debt.toLocaleString()}
                          </p>
                        </div>
                        <div className="col-12 col-md-12 col-lg-6 col-xl-3 mb-2">
                          <p className="font-14 font-medium h-42px mb-1">
                            Debt Ratio
                          </p>
                          <p className="font-32 font-bold">
                            {`${props.codeAnalysisData.maintainability.debtRatio.toFixed(
                              2
                            )}%`}
                          </p>
                        </div>
                        <div className="col-12 col-md-12 col-lg-6 col-xl-3 mb-2">
                          <p className="font-14 font-medium h-42px mb-1">
                            Rating{" "}
                            <span className="tooltip-btn position-relative">
                              <img
                                src="images/info-icon.svg"
                                alt="info-icon"
                                className="info-icon-sty"
                              />
                              <span className="tooltip-sty">
                                <span className="font-10 font-regular color-white text-start">
                                  Rating given to your project related to the
                                  value of your technical debt ratio
                                </span>
                                <span className="font-10 font-regular color-white mb-2 text-start d-block my-2">
                                  <span className="tooltip-pointrer severity-low me-2 " />{" "}
                                  =5% the rating is A
                                </span>
                                <span className="font-10 font-regular color-white mb-2 text-start my-2 d-block">
                                  <span className="tooltip-pointrer severity-average me-2" />
                                  6 to 10% the rating is a B
                                </span>
                                <span className="font-10 font-regular color-white mb-2 text-start my-2 d-block">
                                  <span className="tooltip-pointrer severity-medium me-2" />
                                  11 to 20% the rating is a C
                                </span>
                                <span className="font-10 font-regular color-white mb-2 text-start my-2 d-block">
                                  <span className="tooltip-pointrer severity-seviour me-2" />
                                  21 to 50% the rating is a D
                                </span>
                                <span className="font-10 font-regular color-white mb-2 text-start my-2 d-block">
                                  <span className="tooltip-pointrer    severity-high me-2" />
                                  Over 50% is an E
                                </span>
                              </span>
                            </span>
                          </p>
                          <p
                            className="font-32 font-bold green-300"
                            style={
                              props?.codeAnalysisData?.maintainability
                                ?.rating == "A"
                                ? { color: "#57C269" }
                                : props?.codeAnalysisData?.maintainability
                                    ?.rating == "B"
                                ? { color: "#7891FF" }
                                : props?.codeAnalysisData?.maintainability
                                    ?.rating == "C"
                                ? { color: "#FFD772" }
                                : props?.codeAnalysisData?.maintainability
                                    ?.rating == "D"
                                ? { color: "#EF9C5F" }
                                : props?.codeAnalysisData?.maintainability
                                    ?.rating == ""
                                ? { color: "black" }
                                : { color: "#E05656" }
                            }
                          >
                            {props?.codeAnalysisData?.maintainability?.rating ==
                            ""
                              ? "-"
                              : props?.codeAnalysisData?.maintainability
                                  ?.rating}
                          </p>
                        </div>
                        <div className="col-12 col-md-12 col-lg-6 col-xl-3 mb-2">
                          <p className="font-14 font-medium h-42px mb-1">
                            Effort to Reach A
                          </p>
                          <p className="font-32 font-bold">
                            {(props?.codeAnalysisData?.maintainability?.effortToReachA).toFixed(
                              2
                            )}
                          </p>
                        </div>
                      </div>
                      {/* issues code ends here  */}
                      {/* issues code starts here  */}
                      <p className="mb-4 font-semibold font-14">
                        <span>
                          <img
                            src="images/accordion-arrow.svg"
                            alt="accordion-arrow"
                            className="cursor-pointer accordion-arrow me-2"
                            data-bs-toggle="collapse"
                            data-bs-target="#Security"
                            aria-expanded="true"
                          />
                        </span>
                        Security
                      </p>
                      <div className="row collapse show" id="Security">
                        <div className="col-12 col-md-12 col-lg-6 col-xl-3 mb-2">
                          <p className="font-14 font-medium h-42px mb-1">
                            Vulnerabilities
                          </p>
                          <p className="font-32 font-bold">
                            {props?.codeAnalysisData?.security?.vulnerabilities}
                          </p>
                        </div>
                        <div className="col-12 col-md-12 col-lg-6 col-xl-3 mb-2">
                          <p className="font-14 font-medium h-42px mb-1">
                            Rating{" "}
                            <span className="tooltip-btn position-relative">
                              <img
                                src="images/info-icon.svg"
                                alt="info-icon"
                                className="info-icon-sty"
                              />
                              <span className="tooltip-sty">
                                <span className="font-10 font-regular color-white text-start">
                                  Rating given to your project related to the
                                  value of your vulnerability issues
                                </span>
                                <span className="font-10 font-regular color-white mb-2 text-start d-block my-2">
                                  <span className="tooltip-pointrer severity-low me-2 " />
                                  A = 0 Vulnerabilities
                                </span>
                                <span className="font-10 font-regular color-white mb-2 text-start my-2 d-block">
                                  <span className="tooltip-pointrer severity-average me-2" />
                                  B = at least 1 Minor Vulnerability
                                </span>
                                <span className="font-10 font-regular color-white mb-2 text-start my-2 d-block">
                                  <span className="tooltip-pointrer severity-medium me-2" />
                                  C = at least 1 Major Vulnerability
                                </span>
                                <span className="font-10 font-regular color-white mb-2 text-start my-2 d-block">
                                  <span className="tooltip-pointrer severity-seviour me-2" />
                                  D = at least 1 Critical Vulnerability
                                </span>
                                <span className="font-10 font-regular color-white mb-2 text-start my-2 d-block">
                                  <span className="tooltip-pointrer    severity-high me-2" />
                                  E = at least 1 Blocker Vulnerability
                                </span>
                              </span>
                            </span>
                          </p>
                          <p
                            className="font-32 font-bold"
                            style={
                              props?.codeAnalysisData?.security?.rating == "A"
                                ? { color: "#57C269" }
                                : props?.codeAnalysisData?.security?.rating ==
                                  "B"
                                ? { color: "#7891FF" }
                                : props?.codeAnalysisData?.security?.rating ==
                                  "C"
                                ? { color: "#FFD772" }
                                : props?.codeAnalysisData?.security?.rating ==
                                  "D"
                                ? { color: "#EF9C5F" }
                                : props?.codeAnalysisData?.security?.rating ==
                                  ""
                                ? { color: "black" }
                                : { color: "#E05656" }
                            }
                          >
                            {props?.codeAnalysisData?.security?.rating == ""
                              ? "-"
                              : props?.codeAnalysisData?.security?.rating}
                          </p>
                        </div>
                        <div className="col-12 col-md-12 col-lg-6 col-xl-3 mb-2">
                          <p className="font-14 font-medium h-42px mb-1">
                            Remediation Efforts
                          </p>
                          <p className="font-32 font-bold">
                            {`${props?.codeAnalysisData?.security?.remediationEffort}h`}
                          </p>
                        </div>
                      </div>
                      {/* issues code ends here  */}
                      {/* issues code starts here  */}
                      <p className="mb-4 font-semibold font-14">
                        <span>
                          <img
                            src="images/accordion-arrow.svg"
                            alt="accordion-arrow"
                            className="cursor-pointer accordion-arrow me-2"
                            data-bs-toggle="collapse"
                            data-bs-target="#SecurityR"
                            aria-expanded="true"
                          />
                        </span>
                        Security Review
                      </p>
                      <div className="row collapse show" id="SecurityR">
                        <div className="col-12 col-md-12 col-lg-6 col-xl-3 mb-2">
                          <p className="font-14 font-medium h-42px mb-1">
                            Security Hotspots
                          </p>
                          <p className="font-32 font-bold ">
                            {
                              props?.codeAnalysisData?.securityReview
                                ?.securityHotspots
                            }
                          </p>
                        </div>
                        <div className="col-12 col-md-12 col-lg-6 col-xl-3 mb-2">
                          <p className="font-14 font-medium h-42px mb-1">
                            Rating{" "}
                            <span className="tooltip-btn position-relative">
                              <img
                                src="images/info-icon.svg"
                                alt="info-icon"
                                className="info-icon-sty"
                              />
                              <span className="tooltip-sty">
                                <span className="font-10 font-regular color-white text-start">
                                  The Security Review Rating is a letter grade
                                  based on the percentage of reviewed (Fixed or
                                  Safe) security hotspots.
                                </span>
                                <span className="font-10 font-regular color-white mb-2 text-start d-block my-2">
                                  <span className="tooltip-pointrer severity-low me-2 " />
                                  {`A = >= 80%`}
                                </span>
                                <span className="font-10 font-regular color-white mb-2 text-start my-2 d-block">
                                  <span className="tooltip-pointrer severity-average me-2" />
                                  {`B = >= 70% and <80%`}
                                </span>
                                <span className="font-10 font-regular color-white mb-2 text-start my-2 d-block">
                                  <span className="tooltip-pointrer severity-medium me-2" />
                                  {`C = >= 50% and <70%`}
                                </span>
                                <span className="font-10 font-regular color-white mb-2 text-start my-2 d-block">
                                  <span className="tooltip-pointrer severity-seviour me-2" />
                                  {`D = >= 30% and <50%`}
                                </span>
                                <span className="font-10 font-regular color-white mb-2 text-start my-2 d-block">
                                  <span className="tooltip-pointrer    severity-high me-2" />
                                  {`E = < 30%`}
                                </span>
                              </span>
                            </span>
                          </p>
                          <p
                            className="font-32 font-bold"
                            style={
                              props?.codeAnalysisData?.securityReview?.rating ==
                              "A"
                                ? { color: "#57C269" }
                                : props?.codeAnalysisData?.securityReview
                                    ?.rating == "B"
                                ? { color: "#7891FF" }
                                : props?.codeAnalysisData?.securityReview
                                    ?.rating == "C"
                                ? { color: "#FFD772" }
                                : props?.codeAnalysisData?.securityReview
                                    ?.rating == "D"
                                ? { color: "#EF9C5F" }
                                : props?.codeAnalysisData?.securityReview
                                    ?.rating == ""
                                ? { color: "black" }
                                : { color: "#E05656" }
                            }
                          >
                            {props?.codeAnalysisData?.securityReview?.rating ==
                            ""
                              ? "-"
                              : props?.codeAnalysisData?.securityReview?.rating}
                          </p>
                        </div>
                        <div className="col-12 col-md-12 col-lg-6 col-xl-3 mb-2">
                          <p className="font-14 font-medium h-42px mb-1">
                            Reviewed
                          </p>
                          <p className="font-32 font-bold">
                            {props?.codeAnalysisData?.securityReview?.reviewed}
                          </p>
                        </div>
                      </div>
                      {/* issues code ends here  */}
                      {/* security code starts here */}
                      <p className="mb-4 font-semibold font-14">
                        <span>
                          <img
                            src="images/accordion-arrow.svg"
                            alt="accordion-arrow"
                            className="cursor-pointer accordion-arrow me-2"
                            data-bs-toggle="collapse"
                            data-bs-target="#Reliability"
                            aria-expanded="true"
                          />
                        </span>
                        Reliability
                      </p>
                      <div className="row collapse show" id="Reliability">
                        <div className="col-12 col-md-12 col-lg-6 col-xl-3 mb-2">
                          <p className="font-14 font-medium h-42px mb-1">
                            Bugs
                          </p>
                          <p className="font-32 font-bold ">
                            {props?.codeAnalysisData?.reliability?.bugs}
                          </p>
                        </div>
                        <div className="col-12 col-md-12 col-lg-6 col-xl-3 mb-2">
                          <p className="font-14 font-medium h-42px mb-1">
                            Rating{" "}
                            <span className="tooltip-btn position-relative">
                              <img
                                src="images/info-icon.svg"
                                alt="info-icon"
                                className="info-icon-sty"
                              />
                              <span className="tooltip-sty">
                                <span className="font-10 font-regular color-white text-start">
                                  Rating given to your project related to the
                                  value of your bug issues
                                </span>
                                <span className="font-10 font-regular color-white mb-2 text-start d-block my-2">
                                  <span className="tooltip-pointrer severity-low me-2 " />
                                  A = 0 Bugs
                                </span>
                                <span className="font-10 font-regular color-white mb-2 text-start my-2 d-block">
                                  <span className="tooltip-pointrer severity-average me-2" />
                                  B = at least 1 Minor Bug
                                </span>
                                <span className="font-10 font-regular color-white mb-2 text-start my-2 d-block">
                                  <span className="tooltip-pointrer severity-medium me-2" />
                                  C = at least 1 Major Bug
                                </span>
                                <span className="font-10 font-regular color-white mb-2 text-start my-2 d-block">
                                  <span className="tooltip-pointrer severity-seviour me-2" />
                                  D = at least 1 Critical Bug
                                </span>
                                <span className="font-10 font-regular color-white mb-2 text-start my-2 d-block">
                                  <span className="tooltip-pointrer    severity-high me-2" />
                                  E = at least 1 Blocker Bug
                                </span>
                              </span>
                            </span>
                          </p>
                          <p
                            className="font-32 font-bold red-400"
                            style={
                              props?.codeAnalysisData?.reliability?.rating ==
                              "A"
                                ? { color: "#57C269" }
                                : props?.codeAnalysisData?.reliability
                                    ?.rating == "B"
                                ? { color: "#7891FF" }
                                : props?.codeAnalysisData?.reliability
                                    ?.rating == "C"
                                ? { color: "#FFD772" }
                                : props?.codeAnalysisData?.reliability
                                    ?.rating == "D"
                                ? { color: "#EF9C5F" }
                                : props?.codeAnalysisData?.reliability
                                    ?.rating == ""
                                ? { color: "black" }
                                : { color: "#E05656" }
                            }
                          >
                            {props?.codeAnalysisData?.reliability?.rating == ""
                              ? "-"
                              : props?.codeAnalysisData?.reliability?.rating}
                          </p>
                        </div>
                        <div className="col-12 col-md-12 col-lg-6 col-xl-3 mb-2">
                          <p className="font-14 font-medium h-42px mb-1">
                            Remediation Efforts
                          </p>
                          <p className="font-32 font-bold">
                            {`${props?.codeAnalysisData?.reliability?.remediationEffort}`}
                          </p>
                        </div>
                      </div>
                      {/* security code ends here */}
                    </div>
                  </div>
                </div>
              </div>
            )}
            <div className="col-lg-12 col-xl-12 col-md-12 col-12 mb-4">
              <TableComponent
                dataForGrid={props.gridData}
                areas={props.gridArea}
              ></TableComponent>
            </div>
          </div>
        </div>
        {/* overall summary content ends here */}
        <div className="tab-pane fade" id="v-pills-security" role="tabpanel">
          <div className="row">
            <div className="col-md-12">
              <div className="row">
                <div className="col-lg-12 col-xl-4 col-md-12 col-12 mb-4">
                  <div className="green-50 p-4 d-flex align-items-center justify-content-between rounded-3">
                    <span className="font-semibold font-14">
                      Overall Proficiency Index
                    </span>
                    <span className="font-semibold font-24">
                      {`${props.overallProficiencyIndex}%`}
                    </span>
                  </div>
                </div>
                <div className="col-lg-12 col-xl-4 col-md-12 col-12 mb-4">
                  <div className="orange-50 p-4 d-flex align-items-center justify-content-between rounded-3">
                    <span className="font-semibold font-14">Peer Score</span>
                    <span className="font-semibold font-24">
                      {`${props.peerScore}%`}
                    </span>
                  </div>
                </div>
                <div className="col-lg-12 col-xl-4 col-md-12 col-12 mb-4">
                  <div className="blue-bg-100 p-4 d-flex align-items-center justify-content-between rounded-3">
                    <span className="font-semibold font-14">
                      {props.controlArea ? props.controlArea : "Control Areas"}
                    </span>
                    <span className="font-semibold font-24">
                      {`${props.controlAreaScore}`}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
