// import { useState } from 'react';

import { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { UserDetails, encryptStorage } from "../constant/constant";
import { logoutApi } from "../service/CommonApi";

interface propsType {
  toDisplay?: boolean;
}

const NavBar = (props: propsType) => {
  const { pathname } = useLocation();
  let [linkView, setLinkView] = useState("");
  useEffect(() => {
    setLinkView(pathname);
  }, [pathname]);
  const logOut = async() => {
    
    let access_token = encryptStorage.getItem('jwt')
    if(access_token){
      let logOutRes = await logoutApi(access_token)
    }
    window.sessionStorage.clear();
    window.localStorage.clear();
    window.location.href = "/";
  };
  const navigate = useNavigate();
  const { userData }: any = useContext(UserDetails);
  // if (window.location.pathname == "/") return null;
  return (
    <>
      {/* top header starts here */}
      <nav
        className="navbar navbar-expand-lg navbar-dark bg-dark theme-bg fixed-top"
        hidden={pathname === "/" || pathname === "/previewpage"}
      >
        <div className="container-fluid">
          <a
            className="navbar-brand d-none d-sm-block ms-lg-3"
            href="/homepage"
          >
            <img src="images/logo2.svg" alt="Logo" />
          </a>
          <a className="navbar-brand d-block d-sm-none" href="/homepage">
            <img src="images/logomobile.svg" alt="Logo" />
          </a>
          <div className="d-flex align-items-center">
            {/* mobile profile and notification starts */}
            <div className="dropdown me-lg-3 d-block d-lg-none me-3">
              <img
                className="profile-img cursor-pointer"
                src={userData.profileImage}
                alt="profile-img"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              />
              <ul className="dropdown-menu dropdown-menu-end">
                <li>
                  <a className="dropdown-item color-black font-16 font-semibold d-flex align-items-center" onClick={() => {
                    logOut();
                  }}>
                    <span className="me-2">
                      <img
                        src="images/logout.svg"
                        alt="profile-icon"

                      />
                    </span>
                    Logout
                  </a>
                </li>
              </ul>
            </div>
            {/* mobile profile and notification ends*/}
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarText"
              aria-controls="navbarText"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon" />
            </button>
          </div>
          <div className="collapse navbar-collapse" id="navbarText">
            <ul className="navbar-nav me-auto mb-2 mb-lg-0 ms-lg-5">
              <li className="nav-item" style={{ "cursor": "pointer" }}>
                <a
                  className={`custom-nav-link font-semibold font-14 nav-link ${!window.location.pathname.includes('admin') ? 'active' : ''}`}
                  aria-current="page"
                  onClick={() => {
                    navigate('/homepage')
                  }}
                >
                  Assessment
                </a>
              </li>
              <li className={`nav-item ms-lg-4`} style={{ "cursor": "pointer" }}>
                {userData.role == "Admin" &&
                  <a
                    className={`custom-nav-link font-semibold font-14 nav-link ${window.location.pathname.includes('admin') ? 'active' : ''}`}
                    onClick={() => {

                      navigate('/adminmodule')

                    }}
                  >
                    Admin
                  </a>
                }
              </li>
            </ul>
            <div className="navbar-text align-items-center d-none d-lg-flex ">
              {/* desktop profile and notification starts  */}
              {/* <div className="dropdown me-4 ">
                                <span
                                    className="position-relative cursor-pointer"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                    role="button"
                                >
                                    <img
                                        className="notif-icon"
                                        src="images/bellicon.svg"
                                        alt="profile-img"
                                    />
                                    <span className="notif-dot position-absolute top-0 end-0" />
                                </span>
                                <div className="dropdown-menu dropdown-menu-end p-3">
                                    <div className="d-flex justify-content-between align-items-center mb-3">
                                        <h5 className="font-18 font-semibold color-black">
                                            Notifications
                                        </h5>
                                        <button
                                            type="button"
                                            className="btn-close"
                                            data-bs-dismiss="modal"
                                            aria-label="Close"
                                        />
                                    </div>
                                    <ul className="list-unstyled notification-container">
                                        <li className=" d-flex p-2 rounded-3 mb-3 cursor-pointer">
                                            <span className="d-block me-2 blue-bg notify-sty">
                                                <img
                                                    src="images/Acme_Markets_logo.svg"
                                                    alt="ACME logo"
                                                    className="notifi-logo-sty"
                                                />
                                            </span>
                                            <span className="d-block">
                                                <span className="font-regular font-14 d-block">
                                                    Reports generated successfully for Infrastructure and
                                                    <span>
                                                        <a href="#" className="link-blue">
                                                            3 other
                                                        </a>
                                                        <span>domain verticals.</span>
                                                    </span>
                                                </span>
                                            </span>
                                        </li>
                                        <li className=" d-flex p-2 rounded-3 mb-3 cursor-pointer">
                                            <span className="d-block me-2 blue-bg notify-sty">
                                                <img
                                                    src="images/Acme_Markets_logo.svg"
                                                    alt="ACME logo"
                                                    className="notifi-logo-sty"
                                                />
                                            </span>
                                            <span className="d-block">
                                                <span className="font-regular font-14 d-block">
                                                    Reports generated successfully for Infrastructure and
                                                    <span>
                                                        <a href="#" className="link-blue">
                                                            3 other
                                                        </a>
                                                        <span>domain verticals.</span>
                                                    </span>
                                                </span>
                                            </span>
                                        </li>
                                        <li className=" d-flex p-2 rounded-3 mb-3 cursor-pointer">
                                            <span className="d-block me-2 blue-bg notify-sty">
                                                <img
                                                    src="images/Acme_Markets_logo.svg"
                                                    alt="ACME logo"
                                                    className="notifi-logo-sty"
                                                />
                                            </span>
                                            <span className="d-block">
                                                <span className="font-regular font-14 d-block">
                                                    Reports generated successfully for Infrastructure and
                                                    <span>
                                                        <a href="#" className="link-blue">
                                                            3 other
                                                        </a>
                                                        <span>domain verticals.</span>
                                                    </span>
                                                </span>
                                            </span>
                                        </li>
                                        <li className=" d-flex p-2 rounded-3 mb-3 cursor-pointer">
                                            <span className="d-block me-2 blue-bg notify-sty">
                                                <img
                                                    src="images/Acme_Markets_logo.svg"
                                                    alt="ACME logo"
                                                    className="notifi-logo-sty"
                                                />
                                            </span>
                                            <span className="d-block">
                                                <span className="font-regular font-14 d-block">
                                                    Reports generated successfully for Infrastructure and
                                                    <span>
                                                        <a href="#" className="link-blue">
                                                            3 other
                                                        </a>
                                                        <span>domain verticals.</span>
                                                    </span>
                                                </span>
                                            </span>
                                        </li>
                                    </ul>
                                </div>
                            </div> */}
              <div className="dropdown me-lg-3 ">
                <img
                  className="profile-img cursor-pointer"
                  src={userData.profileImage}
                  alt="profile-img"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                />
                <ul className="dropdown-menu dropdown-menu-end">
                  <li style={{ "cursor": "pointer" }}>
                    <a
                      className="dropdown-item color-black font-16 font-semibold d-flex align-items-center"
                      onClick={() => {
                        logOut();
                      }}
                    >
                      <span className="me-2">
                        <img
                          src="images/logout.svg"
                          alt="profile-icon"

                        />
                      </span>
                      Logout
                    </a>
                  </li>
                </ul>
              </div>
              {/* desktop profile and notification ends  */}
            </div>
          </div>
        </div>
      </nav>
      {/* top header ends here */}
    </>
  );
};

export default NavBar;
