import React from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  ResponsiveContainer,
} from "recharts";
import {
  DataPoint,
  AppLineChartProps,
} from "../../interface/GraphLineChartModel";
import moment from "moment";

const customYAxisFormatter = (value: string, index: number) => {
  if (index >= 1) {
    return `${value}`;
  } else {
    return "";
  }
};

const CustomizedLabel: React.FC<{
  x?: number;
  y?: number;
  stroke?: string;
  value?: number;
}> = (props) => {
  const { x, y, stroke, value } = props;
  return (
    <text x={x} y={y} fill={stroke} fontSize={10} textAnchor="middle">
      {value}
    </text>
  );
};

const ReportAppLineChart: React.FC<AppLineChartProps> = (props) => {
  const totValue = (): string => {
    let totalValue = "";
    for (let i = 0; i < props.dataForLine.length; i++) {
      const inputDate = props.dataForLine[i].name;
      const formattedDate = moment(inputDate).format("MMM D");
      totalValue += formattedDate + " ";
    }
    return totalValue.trim();
  };

  let arr: any = [{}];
  props.dataForLine.forEach((el: any, index: number, ar: any) => {

    index != ar.length - 1 &&
      arr.push({ name: moment(el.name).format("MMM D"), uv: el.uv });
  });


  return (
    <div style={{ width: "128%" }}>
      <ResponsiveContainer id={"chart_container"} width="65%" height={400}>
        <LineChart width={400} height={400} data={arr}>
          <CartesianGrid strokeDasharray="0 100" />
          <XAxis dataKey={"name"} fontSize={'12px'} tickLine={false} />
          <YAxis tickLine={false} tickFormatter={customYAxisFormatter} />
          <Line
            type="linear"
            dataKey="uv"
            stroke="#8caaff"
            fill="#8caaff"
            label={<CustomizedLabel />}
            dot={true}
          />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};

export default ReportAppLineChart;

// import React from "react";
// import {
//   LineChart,
//   Line,
//   XAxis,
//   YAxis,
//   CartesianGrid,
//   ResponsiveContainer,
// } from "recharts";
// import {
//   DataPoint,
//   AppLineChartProps,
// } from "../../interface/GraphLineChartModel";
// import moment from 'moment';

// const customYAxisFormatter = (value: string, index: number) => {
//   if (index >= 1) {
//     return `${value}`;
//   } else {
//     return "";
//   }
// };

// const CustomizedLabel: React.FC<{
//   x?: number;
//   y?: number;
//   stroke?: string;
//   value?: number;
// }> = (props) => {
//   const { x, y, stroke, value } = props;
//   return (
//     <text x={x} y={y} fill={stroke} fontSize={10} textAnchor="middle">
//       {value}
//     </text>
//   );
// };

// const AppLineChart: React.FC<AppLineChartProps> = (props) => {
//   const sortedData = props.dataForLine
//     .slice()
//     .sort((a: any, b: any) => {
//       return new Date(a.name).getTime() - new Date(b.name).getTime();
//     });

//   const arr = sortedData.map((el: any) => ({
//     name: moment(el.name).format('MMM D'),
//     uv: el.uv,
//   }));

//   return (
//     <div style={{ width: "98%" }}>
//       <ResponsiveContainer id={"chart_container"} width="100%" height={400}>
//         <LineChart width={500} height={400} data={arr}>
//           <CartesianGrid strokeDasharray="0 100" />
//           <XAxis dataKey={"name"} tickLine={false} />
//           <YAxis tickLine={false} tickFormatter={customYAxisFormatter} />
//           <Line
//             type="linear"
//             dataKey="uv"
//             stroke="#8caaff"
//             fill="#8caaff"
//             label={<CustomizedLabel />}
//             dot={true}
//           />
//         </LineChart>
//       </ResponsiveContainer>
//     </div>
//   );
// };

// export default AppLineChart;
