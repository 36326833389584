import moment from "moment";
import { getReportData } from "../../helpers/encryption";

export const msalConfig = {
    auth: {
        clientId: getReportData(process.env.REACT_APP_CLI_ID) /*To be changed */,
        authority:
            `https://login.microsoftonline.com/${getReportData(process.env.REACT_APP_TEN_ID)}`,
        redirectUri: window.location.origin + "/assessmentlist",
    },
    cache: {
        cacheLocation: "sessionStorage",
        storeAuthStateInCookie: false,
    },

};



export const isAuth = (accounts: any) => {
    // if (accounts.length && accounts[0]?.idTokenClaims?.exp && accounts[0]?.idTokenClaims?.exp < moment().unix()) return false
    return accounts?.find((_account: any) => _account?.tenantId === getReportData(process.env.REACT_APP_TEN_ID));
};



export const loginRequest = {
    scopes: ["openid", "profile", "User.Read"],
};