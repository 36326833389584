/**
 * PS_RG_1.11 - PS_RG_1.12
 * Import necessary packages
 */
import { useState } from "react";
import {
  DataItem,
  PopupComponentProps,
} from "../../interface/RecommendationPopupModel";

const PopupComponent: React.FC<PopupComponentProps> = ({ data, area }) => {

  /**
   * PS_RG_1.13 
   * state variables creation and create a function to bind the data on the grid
   */
  const [data1, setData1] = useState<DataItem[]>(data);
  const [impactSortOrder, setImpactSortOrder] = useState<string>("asc");
  const [currentImpact, setCurrentImpact] = useState<string>("");
  const [impactAreaSortOrderCa, setImpactAreaSortOrderCa] =
    useState<string>("asc");
  const [impactAreaSortOrderIa, setImpactAreaSortOrderIa] =
    useState<string>("asc");

  /**
   * this function renders the data on the grid
   */
  const bindData = (data1: DataItem[]) => {
    if (data1
      .filter(
        (item: any) =>
          item.description !== null &&
          item.description !== undefined &&
          item.description !== ""
      ).length == 0) {
      return (
        <tr style={{ textAlign: "center", color: "grey" }}>
          <td colSpan={3} className="font-14 font-semibold pt-4 color-grey">
            There are no recommendations to display
          </td>
        </tr>
      )
    }
    else {
      return data1
        .filter(
          (item: any) =>
            item.description !== null &&
            item.description !== undefined &&
            item.description !== ""
        )
        .map((item: any, index: any) => (
          <tr
            style={{
              borderBottom: "1px solid #E7E7E7",
              wordWrap: "break-word",
              whiteSpace: "normal",
              overflowWrap: "break-word",
              hyphens: "auto",
              pageBreakAfter: "auto",
              pageBreakInside: "avoid",
              pageBreakBefore: "auto",
            }}
            key={index}
          >
            <td
              style={{
                width: "38%",
                padding: "1%",
                textAlign: "left",
                verticalAlign: "top",
                fontSize: "12px",
                //fontFamily: '"SFProText-Medium"',
                color: "#242424",
                wordWrap: "break-word",
                whiteSpace: "normal",
                overflowWrap: "break-word",
                hyphens: "auto",
                pageBreakInside: "avoid",
              }}
            >
              {item.description}
            </td>
            {area ? (
              <td
                style={{
                  textAlign: "left",
                  width: "18%",
                  padding: "1%",
                  verticalAlign: "top",
                  fontSize: "12px",
                  //fontFamily: '"SFProText-Medium"',
                  color: "#242424",
                  wordWrap: "break-word",
                  whiteSpace: "normal",
                  overflowWrap: "break-word",
                  pageBreakInside: "avoid",
                }}
              >
                {item.impactResources}
              </td>
            ) : (
              <td
                style={{
                  textAlign: "left",
                  width: "18%",
                  padding: "1%",
                  verticalAlign: "top",
                  fontSize: "12px",
                  //fontFamily: '"SFProText-Medium"',
                  color: "#242424",
                  wordWrap: "break-word",
                  whiteSpace: "normal",
                  overflowWrap: "break-word",
                  pageBreakInside: "avoid",
                }}
              >
                {item.controlAreas}
              </td>
            )}
            <td
              style={{
                textAlign: "center",
                width: "18%",
                padding: "1%",
                verticalAlign: "top",
                fontSize: "12px",
                //fontFamily: '"SFProText-Medium"',
                wordWrap: "break-word",
                whiteSpace: "normal",
                overflowWrap: "break-word",
                pageBreakInside: "avoid",
              }}
            >
              {item.impact == "High" ? (
                <span className="d-inline-block">
                  <span className="severity severity-high-bg">
                    <span className="severity-point severity-high me-2" />
                    {item.impact}
                  </span>
                </span>
              ) : item.impact == "Medium" ? (
                <span className="d-inline-block">
                  <span className="severity severity-medium-bg">
                    <span className="severity-point severity-medium me-2" />
                    {item.impact}
                  </span>
                </span>
              ) : (
                <span className="d-inline-block">
                  <span className="severity severity-low-bg">
                    <span className="severity-point severity-low me-2" />
                    {item.impact}
                  </span>
                </span>
              )}
            </td>
          </tr>
        ));
    }

  };

  /**
   * PS_RG_1.15 - PS_RG_1.23
   * this function sort the Impacts
   */

  let sortImpactData = () => {
    const impactOrder: any = { High: 3, Medium: 2, Low: 1 };
    const sortedData = [...data1].sort((a: any, b: any) => {
      const sortOrder = impactSortOrder === "asc" ? 1 : -1;
      return sortOrder * (impactOrder[b.impact] - impactOrder[a.impact]);
    });

    setData1(sortedData);
    setImpactSortOrder(impactSortOrder === "asc" ? "desc" : "asc");
  };

  /**
   * this sorts the control area
   */
  let sortControlArea = () => {
    const sortedData = [...data1].sort((a: any, b: any) => {
      const sortOrder = impactAreaSortOrderCa === "asc" ? 1 : -1;
      return sortOrder * a.controlAreas.localeCompare(b.controlAreas);
    });

    setData1(sortedData);
    setImpactAreaSortOrderCa(impactAreaSortOrderCa === "asc" ? "desc" : "asc");
  };

  /**
   * This sorts the Impacted areas
   */
  let sortImpactArea = () => {
    const sortedData = [...data1].sort((a: any, b: any) => {
      const sortOrder = impactAreaSortOrderIa == "asc" ? 1 : -1;
      return sortOrder * a.impactResources.localeCompare(b.impactResources);
    });

    setData1(sortedData);
    setImpactAreaSortOrderIa(impactAreaSortOrderIa == "asc" ? "desc" : "asc");
  };

  /**
   * this filters the impact by All, high, medium and low
   */
  const filterDataByImpact = (selectedImpact: string) => {
    if (selectedImpact === "All") {
      setData1(data);
    } else {
      const filteredData = data.filter(
        (item: DataItem) => item.impact === selectedImpact
      );
      setData1(filteredData);
    }
    setCurrentImpact(selectedImpact);
  };

  /**
   * PS_RG_1.25 - PS_RG_1.31
   * This returns the html to be rendered as popup
   */

  return (
    <>
      <div
        className="modal fade"
        id="recommendationsExapn"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        data-bs-target="recommendationsExapn"
        tabIndex={-1}
        aria-hidden="true"
      >
        <div className="modal-dialog modal-xl modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header pb-0 border-0">
              <h5 className="font-semibold font-18 font-semibold d-flex justify-content-between align-items-center">
                Recommendations
              </h5>
              <div className="d-flex align-items-center">
                <div className="d-flex gap-3 align-items-center me-2">
                  <label
                    className="font-14 d-md-block d-none font-regular color-grey text-nowrap"
                    htmlFor="rec-Impact"
                  >
                    Impact
                  </label>
                  <select
                    value={currentImpact}
                    onChange={(e) => filterDataByImpact(e.target.value)}
                    className="shadow-none d-md-block d-none theme-form form-select font-regular color-black font-14 me-3"
                    id="rec-Impact"
                  >
                    <option selected>All</option>
                    <option>High</option>
                    <option>Medium</option>
                    <option>Low</option>
                  </select>
                </div>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                />
              </div>
            </div>
            <div className="modal-body">
              <div className="d-flex gap-3 align-items-center mb-3">
                <label
                  className="font-14 d-md-none d-sm-block font-regular color-grey text-nowrap"
                  htmlFor="Impact-sele"
                >
                  Impact
                </label>
                <select
                  value={currentImpact}
                  onChange={(e) => filterDataByImpact(e.target.value)}
                  className="shadow-none d-md-none d-sm-block theme-form form-select font-regular color-black font-14"
                  id="Impact-sele"
                >
                  <option selected>All</option>
                  <option>High</option>
                  <option>Medium</option>
                  <option>Low</option>
                </select>
              </div>
              <div
                className="table-responsive mb-4 slim-scroll"
                style={{ height: 500 }}
              >
                <table className="table table-borderless themeTable mb-0">
                  <thead className="font-semibold sticky-top">
                    <tr>
                      <th>Description</th>
                      {area ? (
                        <th>
                          Impacted Areas
                          <span
                            className="ms-2 cursor-pointer"
                            onClick={sortImpactArea}
                          >
                            {impactAreaSortOrderIa === "asc" ? " ↑" : " ↓"}
                          </span>
                        </th>
                      ) : (
                        <th>
                          Control Areas
                          <span
                            className="ms-2 cursor-pointer"
                            onClick={sortControlArea}
                          >
                            {impactAreaSortOrderCa === "asc" ? " ↑" : " ↓"}
                          </span>
                        </th>
                      )}
                      <th
                        style={{
                          textAlign: "center",
                        }}
                      >
                        Impact
                        <span
                          className="ms-2 cursor-pointer"
                          onClick={sortImpactData}
                        >
                          {impactSortOrder === "asc" ? " ↑" : " ↓"}
                        </span>
                      </th>
                    </tr>
                  </thead>
                  <tbody className="font-medium">
                    {bindData(data1)}
                  </tbody>
                </table>
              </div>
              <p className="font-12 font-regular color-grey-v3 mb-0">
                No of Rec :{" "}
                <span className="color-black">
                  {" "}
                  {
                    data1.filter(
                      (item: any) =>
                        item.description !== null &&
                        item.description !== undefined &&
                        item.description !== ""
                    ).length
                  }
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PopupComponent;
