import {apiCall} from "../client/apiCall";
import { baseUrl,initiaitveEndpoints } from "../constant/constant";


 /**PS_IA_02-PS_IA_04
    * This function is used to call the getMilestone API
     * milestoneDetails is passed as the parameter as body
     */
export async function getMilestone(body :any) {
    const response = await apiCall(baseUrl+ initiaitveEndpoints.getAllMilestone, 'post',body);
    return response;
}

 /**PS_IA_05-PS_IA_07
     * This function is used to call the deleteMilestone API
     * milestoneId is passed as the parameter as deleteId
     */
export async function deleteMilestone(deleteId: any) {
    const response = await apiCall(baseUrl+ initiaitveEndpoints.deleteMilestone, 'post', deleteId);
    return response;
}

 /**PS_IA_08-PS_IA_10
     * This function is used to call the postInitiative API
     * initiativeDet is passed as the parameter 
     */
export async function postInitiative(initiativeDet: any) {
    const response = await apiCall(baseUrl+ initiaitveEndpoints.postInitiative, 'post', initiativeDet);
    return response;
}

 /**PS_IA_11-PS_IA_13
     * This function is used to cal the getRoles API for 
     * binding the role drop down
     */
export async function getRoles() {
    const response = await apiCall(baseUrl+initiaitveEndpoints.getRoles, 'get');
    return response;
}

 /**PS_IA_14-PS_IA_16
     * This function is used to call the postMilestone API
     * milestoneDet is passed as the parameter
     */
export async function postMilestone(milestoneDet: any) {
    const response = await apiCall(baseUrl+initiaitveEndpoints.postMilestone, 'post',{milestone: milestoneDet});
    return response;
}

 /**PS_IA_20-PS_IA_22
     * This function is used to call the removeInitiative API
     * cancelObj is passed as the parameter 
     */
export async function removeInitiative(cancelObj: any) {
    const response = await apiCall(baseUrl + initiaitveEndpoints.cancelInitiative, 'post', cancelObj);
    return response;
}

 /**PS_IA_17-PS_IA_19
     * This function is used to call the getInitiative API
     * initiativeId is passed as the parameter 
     */
 export async function getInitiative(requestObj: any) {
    const response = await apiCall(baseUrl + initiaitveEndpoints.getInitiative, 'post', requestObj);
    return response;
}

