import React, { useState } from "react";
import { TreeSelect, Select } from "antd";

export default function ConnectorSelectDropdown(props: any) {
  const {
    dataOptions,
    treeDataOptions,
    handleChange,
    handleTreeChange,
    mode,
    dropdownName,
    dataValue,
  } = props;

  if (mode === "treeselect") {
    return (
      <TreeSelect
        style={{ width: "100%" }}
        placeholder="Please select"
        allowClear
        showSearch={false}
        multiple
        value={dataValue}
        treeDefaultExpandAll
        onChange={(e) => {
          handleTreeChange(e, dropdownName);
        }}
        treeData={treeDataOptions}
        treeIcon={true}
        className="font-regular font-14 resize-none"
      />
    );
  } else {
    return (
      <Select
        mode="multiple"
        allowClear
        showSearch={false}
        style={{ width: "100%" }}
        placeholder="Please select"
        onChange={(e) => {
          handleChange(e, dropdownName);
        }}
        options={dataOptions}
        className="font-regular font-14 resize-none"
        value={dataValue}
      />
    );
  }
}
