// PC_MD_1.1, import the required interfaces
import { AppDevConnectorPropsModel } from "../../interface/questionnaireInterface";

/**
 * PC_MD_1.2, Create an exportable function
 * @param props
 * @returns
 */
export default function AppDevConnector(props: AppDevConnectorPropsModel) {
  // PC_MD_1.3, Destructure the props as const
  const {
    activeConnector,
    validateConnectorInput,
    connectorsOnChange,
    setShowConnectorForm,
    sonarCloudProjectkeyOnChange,
    connectorFormData,
    setConnectorFormData,
    connectorFormValidationMsg,
    connectorApiValidationMsg,
    resetValidationMessages,
  } = props;

  // PC_MD_1.4, regex validation
  const emojiRegex =
    /[\u{1F600}-\u{1F64F}\u{1F300}-\u{1F5FF}\u{1F680}-\u{1F6FF}\u{1F700}-\u{1F77F}\u{1F780}-\u{1F7FF}\u{1F800}-\u{1F8FF}\u{1F900}-\u{1F9FF}\u{1FA00}-\u{1FA6F}\u{2600}-\u{26FF}\u{2700}-\u{27BF}'<>]/u;

  // PC_MD_1.5, bind the app dev connector form html
  const bindConnectorsForm = () => {
    // PC_MD_1.6, bind the save and cancel button html
    const bindSaveAndCancel = () => {
      return (
        <div className="d-grid gap-2 d-md-inline-flex justify-content-md-end mb-5 w-100">
          <button
            type="button"
            className="btn btn-dark theme-primary-btn border-0 font-14 font-semibold "
            onClick={() => {
              validateConnectorInput(null);
            }}
          >
            Save
          </button>
          <a
            className="theme-link-btn cursor-pointer  font-semibold text-center order-md-first"
            onClick={() => {
              setConnectorFormData({});
              resetValidationMessages(activeConnector);
              setShowConnectorForm(true);
            }}
          >
            Cancel
          </a>
        </div>
      );
    };
    // PC_MD_1.7, Switch Case to return the active connector form html
    switch (activeConnector) {
      case "Sonar Cloud":
        return (
          <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-4">
            <h3 className="font-18 font-semibold color-black mt-4 mb-4">
              Sonar Cloud Configuration
            </h3>{" "}
            <div className="col-lg-8 col-md-12 col-12 mb-4">
              <div className="row">
                <div className="col-12 col-md-12 col-lg-6">
                  <div className="mb-5">
                    <label
                      htmlFor="Organization Key"
                      className="form-label font-14 font-semibold color-black"
                    >
                      Organization Key
                    </label>{" "}
                    <span className="color-red"> *</span>
                    <input
                      type="text"
                      className="form-control theme-form font-regular font-14"
                      id="Organization Key"
                      name="Organization Key"
                      placeholder="Enter organization key"
                      value={connectorFormData["Organization Key"]}
                      onChange={(e) => {
                        connectorsOnChange(e);
                      }}
                    />
                    <p className="mt-2 mb-0 color-red font-14 font-regular">
                      {connectorFormValidationMsg["Organization Key"]}
                    </p>
                  </div>
                </div>
                <div className="col-12 col-md-12 col-lg-6">
                  <div className="mb-5">
                    <label
                      htmlFor="Access Key"
                      className="form-label font-14 font-semibold color-black"
                    >
                      Access Key
                    </label>
                    <span className="color-red"> *</span>
                    <input
                      type="text"
                      className="form-control theme-form font-regular font-14"
                      id="Access Key"
                      name="Access Key"
                      value={connectorFormData["Access Key"]}
                      placeholder="Enter access key"
                      onChange={(e) => {
                        connectorsOnChange(e);
                      }}
                    />
                    <p className="mt-2 mb-0 color-red font-14 font-regular">
                      {connectorFormValidationMsg["Access Key"]}
                    </p>
                  </div>
                </div>
                <div className="col-12 col-md-12 col-lg-12">
                  <div className="mb-5">
                    <label
                      htmlFor="Project-Key"
                      className="form-label font-14 font-semibold color-black"
                    >
                      Project Key
                    </label>
                    <span className="color-red"> *</span>
                    {/* <input type="text" class="form-control theme-form font-regular font-14"  placeholder="Enter project key"> */}
                    <div className="div-theme-form">
                      {" "}
                      <div className="p-2">
                        {connectorFormData["Project Key"]?.map(
                          (val: string) => {
                            return (
                              <span className="itemsTag">
                                {val}{" "}
                                <button
                                  className="closeIcon btn-close"
                                  onClick={() => {
                                    connectorFormData["Project Key"] =
                                      connectorFormData["Project Key"]?.filter(
                                        (e: string) => e !== val
                                      );
                                    setConnectorFormData({
                                      ...connectorFormData,
                                    });
                                  }}
                                ></button>
                              </span>
                            );
                          }
                        )}
                      </div>
                      <input
                        id="Project Key"
                        name="Project Key"
                        placeholder="Enter project key"
                        className="form-control theme-form font-regular font-14 resize-none"
                        style={{ boxShadow: "none", border: "none" }}
                        onChange={(e: any) => {
                          if (emojiRegex.test(e.target.value)) {
                            e.target.value = "";
                          }
                        }}
                        onKeyDown={(e) => sonarCloudProjectkeyOnChange(e)}
                      />
                    </div>
                    <p className="mt-2 mb-0 color-red font-14 font-regular">
                      {connectorFormValidationMsg["Project Key"]}
                    </p>
                  </div>
                </div>
                <p className="mt-1 mb-0 color-red font-14 font-regular">
                  {connectorApiValidationMsg}
                </p>
                {bindSaveAndCancel()}
              </div>
            </div>
          </div>
        );
      case "Git Repository":
        return (
          <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-4">
            <h3 className="font-18 font-semibold color-black mt-4 mb-4">
              Git Repository Configuration
            </h3>
            <div className="col-lg-8 col-md-12 col-12 mb-4">
              <div className="row">
                <div className="col-12 col-md-12 col-lg-6">
                  <div className="mb-5">
                    <label
                      htmlFor="Repository URL"
                      className="form-label font-14 font-semibold color-black"
                    >
                      Repository URL
                    </label>
                    <span className="color-red"> *</span>
                    <input
                      type="text"
                      className="form-control theme-form font-regular font-14"
                      id="Repository URL"
                      name="Repository URL"
                      placeholder="Enter Repository URL"
                      value={connectorFormData["Repository URL"]}
                      onChange={(e) => {
                        connectorsOnChange(e);
                      }}
                    />
                    <p className="mt-2 mb-0 color-red font-14 font-regular">
                      {connectorFormValidationMsg["Repository URL"]}
                    </p>
                  </div>
                </div>
                <div className="col-12 col-md-12 col-lg-6">
                  <div className="mb-5">
                    <label
                      htmlFor="Authentication API Key"
                      className="form-label font-14 font-semibold color-black"
                    >
                      Authentication API Key
                    </label>
                    <span className="color-red"> *</span>
                    <input
                      type="text"
                      className="form-control theme-form font-regular font-14"
                      id="Authentication API Key"
                      name="Authentication API Key"
                      placeholder="Enter Authentication API Key"
                      value={connectorFormData["Authentication API Key"]}
                      onChange={(e) => {
                        connectorsOnChange(e);
                      }}
                    />
                    <p className="mt-2 mb-0 color-red font-14 font-regular">
                      {connectorFormValidationMsg["Authentication API Key"]}
                    </p>
                  </div>
                </div>
                <div className="col-12 col-md-12 col-lg-6">
                  <div className="mb-5">
                    <label
                      htmlFor="Branch"
                      className="form-label font-14 font-semibold color-black"
                    >
                      Branch
                    </label>
                    <span className="color-red"> *</span>
                    <input
                      type="text"
                      className="form-control theme-form font-regular font-14"
                      id="Branch"
                      name="Branch"
                      placeholder="Enter Branch"
                      value={connectorFormData["Branch"]}
                      onChange={(e) => {
                        connectorsOnChange(e);
                      }}
                    />
                    <p className="mt-2 mb-0 color-red font-14 font-regular">
                      {connectorFormValidationMsg["Branch"]}
                    </p>
                  </div>
                </div>
                {bindSaveAndCancel()}
              </div>
            </div>
          </div>
        );
    }
  };

  // PC_MD_1.9, return the binded connector html
  return <>{bindConnectorsForm()}</>;
}
