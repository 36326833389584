import { apiCall } from "../client/apiCall";
import { baseUrl, assesmentEndpoints } from "../constant/constant";
import { assessmentDataModel, assessmentFilterDataModel, editedAssessmentModel } from "../interface/AssessmentGridModel";
import { postInfraAssessmentTypeInterface, getInfraAssessmentTypeInterface, infraAssessmentTypePayloadInterface, organizationStatusInterface } from "../interface/infraMapModel"
import { organizationDataModel, organizationFilterDataModel } from "../interface/OrganizationGridModel";
/**PS_AA_03-PS_AA_04
    * This function is used to call the getOrganization API
    * organizationId is passed as the parameter
    */
export async function getOrganization(organizationId: any) {
  const response = await apiCall(baseUrl + assesmentEndpoints.getOrganization, 'POST', organizationId);
  return response;
}

/**PS_AA_05-PS_AA_07
 * This function is used to call the getIndustry API
 */
export async function getIndustry() {
  const response = await apiCall(
    baseUrl + assesmentEndpoints.getIndustryDropDown,
    "GET"
  );
  return response;
}

/**PS_AA_09-PS_AA_10
 * This function is used to call the getEmployeeRange API
 */
export async function getEmployeeRange() {
  const response = await apiCall(
    baseUrl + assesmentEndpoints.getEmployeeRange,
    "GET"
  );
  return response;
}

/**PS_AA_12-PS_AA_13
 * This function is used to call the getVerticals API
 */
export async function getVerticals() {
  const response = await apiCall(
    baseUrl + assesmentEndpoints.getVerticalDropDown,
    "GET"
  );
  return response;
}

/**PS_AA_15-PS_AA_16
 * This function is used to post the organization details to the postOrganization API
 * organizationDet is passed as the parameter
 */
export async function postOrganizationDet(organizationDet: any) {
  const response = await apiCall(baseUrl + assesmentEndpoints.postOrganization,
    "POST",
    { organizationInfo: organizationDet }
  );
  return response;
}

/**PS_AA_01-PS_AA_04
 * This function is used to call the getAllOrganization API
 * filterParam is passed as the parameter
 */
export const getAllOrganization = async (filterParams: any) => {
  const response = await apiCall(baseUrl + assesmentEndpoints.getAllOrganization, "POST", filterParams);
  return response;
};

/**PS_AA_14-PS_AA_16
    * This function is used to call the deleteOrganization API
    * delete id is passed as the parameter
    */
export const deleteOrganization = async (id: any) => {
  const response = await apiCall(baseUrl + assesmentEndpoints.deleteOrganization, 'POST', id);
  return response;
};

/**PS_AA_21-PS_AA_22
    * This function is used to call the deleteVerticals API
    * deleteParam is passed as the parameter
    */
export const deleteVerticals = async (deleteParam: any) => {
  const response = await apiCall(baseUrl + assesmentEndpoints.deleteVerticals, 'POST', deleteParam);
  return response;
};

//PS_InfraAssessmentPage_07 - Api call to fetch the infra assessment types
export async function getInfraAssessmentTypes() {
  let response = await apiCall(baseUrl + "/connectors/infraAssessment/getInfraAssessmentType", "GET")
  return response
}
//PS_InfraAssessmentPage_26 - Api call to post infra assessment type
export async function postInfraAssessmentType(payload: postInfraAssessmentTypeInterface) {
  let response = await apiCall(baseUrl + "/connectors/infraAssessment/postInfraAssessmentType", "POST", payload)
  return response
}
//PC_App_01 - Api call to fetch the infra assessment type
export async function getInfraAssessmentType(payload: getInfraAssessmentTypeInterface) {
  let response = await apiCall(baseUrl + "/connectors/infraAssessment/getInfraAssessmentMapping", "POST", payload)
  return response
}

//PC_App_02 - Api call to reset the existing assessment data
export async function infraMapReasses(payload: infraAssessmentTypePayloadInterface) {
  let response = await apiCall(baseUrl + "/assessments/infraAssessment/reassess", "POST", payload)

  return (response)
}

//PC_App_05, PC_App_06 - Api call to post the organization status
export async function postOrganizationStatus(payload: organizationStatusInterface) {
  let response = await apiCall(baseUrl + "/connectors/updateOrganizationVerticalProgress", "POST", payload)
  return (response)
}

/**PS_AA_17-PS_AA_19
 * This function is used to call the deleteRoadmap API
 * organization id is passed as the parameter
 */
export const deleteRoadmap = async (id: any) => {
  //const response = await client(baseUrl + assesmentEndpoints.deleteRoadmap, id, 'POST');
  const response = {
    status: 200,
    data: [],
  };
  return response;
};

//Api function to fetch orgainzation grid data
export const getOrganizationData = async (data: organizationFilterDataModel) => {
  let response = await apiCall(baseUrl + "/assessments/organizationList", "POST", data)
  return (response)
}

export const postOrganization = async (data: organizationDataModel) => {
  let response = await apiCall(baseUrl + "/assessments/addOrganization", "POST", data)
  return (response)
}

export const getDomains = async () => {
  let response = await apiCall(baseUrl + "/assessments/domains", "GET", null)
  return (response)
}

export const getAssessment = async (data: assessmentFilterDataModel) => {
  let response = await apiCall(baseUrl + "/assessments/assessmentList", "POST", data)
  return (response)
}

export const postAssessment = async (data: editedAssessmentModel) => {
  let response = await apiCall(baseUrl + "/assessments/addAssessment", "POST", data)
  return (response)
}

export const getRegions = async () => {
  let response = await apiCall(baseUrl + "/assessments/regions", "GET", null)
  return (response)
}

export const fetchProgress = async (data: any) => {
  let response = await apiCall(baseUrl + "/connectors/trackAiQuestionnarieProgress", "POST", data)
  return (response)
}
export const deleteAssessment = async (data: { organizationId: string, verticalId: string, projectId: string }) => {
  let response = await apiCall(baseUrl + "/assessments/deleteAssessment", "POST", data)
  return (response)
}
