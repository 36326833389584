import { useEffect, useRef, useState } from "react";
import ConnectorSelectDropdown from "../MultiSelectDropdown";
import { getSasToken } from "../../service/ReportApi";
import { read, utils } from "xlsx";
import { encryptStorage, fileNameSlice } from "../../constant/constant";
import { jwtDecode } from "jwt-decode";
import { getConnectorsData } from "../../helpers/encryption";

export default function ServiceNowConnector(props: any) {
  const {
    activeConnector,
    validateConnectorInput,
    connectorsOnChange,
    setShowConnectorForm,
    connectorFormData,
    setConnectorFormData,
    connectorFormValidationMsg,
    connectorApiValidationMsg,
    resetValidationMessages,
    handleMultiSelectChange,
    handleSnTreeSelectChange,
    snSubMenuTreeDataOptions,
    snSubMenuSelectedValues,
    snControlAreaSelectedValues,
    setExcelTemplateData,
    uploadTemplate,
  } = props;

  const options = [
    {
      value: "CSM",
      label: "CSM",
    },
    {
      value: "HRSD",
      title: "HRSD",
    },
    {
      value: "IRM",
      title: "IRM",
    },
    {
      value: "ITAM",
      title: "ITAM",
    },
    {
      value: "ITOM",
      title: "ITOM",
    },
    {
      value: "ITSM",
      title: "ITSM",
    },
    {
      value: "Portal",
      title: "Portal",
    },
    {
      value: "SecOps",
      title: "SecOps",
    },
    {
      value: "SPM",
      title: "SPM",
    },
  ];

  const itamAttachment = useRef(null);
  const environmentAttachment = useRef(null);

  let [fileName, setFileName] = useState({
    itam: "",
    environment: "",
  });
  const [excelFile, setExcelFile] = useState<any>({
    itam: "",
    environment: "",
  });
  const [validationMessage, setValidationMessage] = useState({
    itam: "",
    environment: "",
  });

  const EnvironmentDetailsHeadings = [
    "Created",
    "Recipients",
    "Subject",
    "Type",
    "Notification type",
    "User ID",
    "User",
    "Target",
    "Receive type",
  ];

  const ItamHeadings = [
    "Contract",
    "Asset",
    "Contract Model",
    "Control Area",
    "Control Area Menu",
  ];

  useEffect(() => {
    if (connectorFormData["Environment template"]) {
      let filename =
        fileNameSlice(connectorFormData["Environment template"]) || "";
      fileName.environment = filename?.replace(/%20/g, " ");
    }
    if (connectorFormData["ITAM template"]) {
      let filename = fileNameSlice(connectorFormData["ITAM template"]) || "";
      fileName.itam = filename?.replace(/%20/g, " ");
    }
    setFileName({ ...fileName });
  }, []);

  useEffect(() => {
    if (!snControlAreaSelectedValues.includes("ITAM")) {
      removeFile("itam");
    }
  }, [connectorFormData]);

  /**PS_AN_54
   * This function is used to the remove the files uploaded by the user
   */

  const removeFile = (key: string) => {
    setFileName({ ...fileName, [key]: "" });
    setValidationMessage({ ...validationMessage, [key]: "" });
    if (key === "itam") {
      itamAttachment.current = null;
      connectorFormData["ITAM template"] = "";
    } else {
      environmentAttachment.current = null;
      connectorFormData["Environment template"] = "";
    }
  };

  const readFile = (event: any) => {
    const file = event.target.files?.[0];
    if (file) {
      connectorFormValidationMsg["Environment template"] = "";
      connectorFormValidationMsg["ITAM template"] = "";
      setExcelFile({ ...excelFile, [event.target.id]: file });
      const typeArr = ["vnd.openxmlformats-officedocument.spreadsheetml.sheet"];
      let type = typeArr.includes(file.type.split("/")[1]);
      if (type) {
        let files = file;
        let reader = new FileReader();
        reader.onload = (e) => {
          const wb = read(e?.target?.result);
          const sheets = wb.SheetNames;
          const rows: any = utils.sheet_to_json(wb.Sheets[sheets[0]]);
          let columnTitle = utils.sheet_to_json(wb.Sheets[sheets[0]], {
            header: 1,
          })[0];
          setFileName({
            ...fileName,
            [event.target.id]: event.target.files[0].name,
          });
          setValidationMessage({
            ...validationMessage,
            [event.target.id]: "",
          });
          if (rows.length >= 1) {
            if (
              JSON.stringify(
                event.target.name === "Environment template"
                  ? EnvironmentDetailsHeadings
                  : ""
              ) == JSON.stringify(columnTitle)
            ) {
              let targetCount = 0;
              rows.forEach((data: any) => {
                if (data["Target"]) targetCount++;
              });
              let EnvironmentCalc = (targetCount / rows.length) * 100;
              connectorFormData["environmentData"] = [
                Math.ceil(EnvironmentCalc).toString(),
              ];
            } else if (
              JSON.stringify(
                event.target.name === "ITAM template" ? ItamHeadings : ""
              ) == JSON.stringify(columnTitle)
            ) {
              let targetCount = 0;
              rows.forEach((data: any) => {
                if (data["Contract model"]) targetCount++;
              });
              let ITAMCalc = (targetCount / rows.length) * 100;
              connectorFormData["ITAMData"] = [Math.ceil(ITAMCalc).toString()];
            } else {
              setFileName({ ...fileName, [event.target.id]: "" });
              setValidationMessage({
                ...validationMessage,
                [event.target.id]:
                  "Please upload the file with valid columns and data",
              });
            }
            setConnectorFormData(connectorFormData);
          } else {
            setFileName({ ...fileName, [event.target.id]: "" });
            setValidationMessage({
              ...validationMessage,
              [event.target.id]: "Please upload the file with valid data",
            });
          }
        };
        reader.readAsArrayBuffer(file);
      } else {
        setFileName({ ...fileName, [event.target.id]: "" });
        setValidationMessage({
          ...validationMessage,
          [event.target.id]: "Please upload a XLSX file",
        });
      }
    }
  };

  /**PS_AN_63-PS_AN_68
   * This function is used to download the template file uploaded in the blob
   */
  const downloadTemplate = async (key: string) => {
    try {
      let value = encryptStorage.getItem("jwt")
      let parsedJtk: any = ""
      let storageAccountName: any, containerName: any;
      if (value) {
        parsedJtk = jwtDecode(value)
        storageAccountName = getConnectorsData({ key: parsedJtk.ek, encryptedMessage: process.env.REACT_APP_BLOB_ACCOUNT_NAME! });
        containerName = getConnectorsData({ key: parsedJtk.ek, encryptedMessage: process.env.REACT_APP_BLOB_CONTAINER! })
      }
      const sas = await getSasToken("r");
      if (key === "ITAM") {
        window.open(`https://${storageAccountName}.blob.core.windows.net/${containerName}/Templates/ITAM - Template.xlsx` + `?${sas.data}`);
      } else if (key === "ENV") {
        window.open(`https://${storageAccountName}.blob.core.windows.net/${containerName}/Templates/Environment - Template.xlsx` + `?${sas.data}`);
      }
    } catch (error) {
      console.error("Error occurred while downloading the template.", error);
    }
  };

  const bindConnectorsForm = () => {
    const bindSaveAndCancel = () => {
      return (
        <div className="d-grid gap-2 d-md-inline-flex justify-content-md-end mb-5 w-100">
          <button
            type="button"
            className="btn btn-dark theme-primary-btn border-0 font-14 font-semibold "
            onClick={() => {
              validateConnectorInput(excelFile);
            }}
          >
            Save
          </button>
          <a
            className="theme-link-btn cursor-pointer  font-semibold text-center order-md-first"
            onClick={() => {
              setConnectorFormData({});
              resetValidationMessages(activeConnector);
              setShowConnectorForm(true);
            }}
          >
            Cancel
          </a>
        </div>
      );
    };
    switch (activeConnector) {
      case "ServiceNow":
        return (
          <div className="col-lg-8 col-md-12 col-12">
            <div className="ps-lg-4">
              <h3 className="font-18  font-bold color-black mb-4">
                ServiceNow Configuration
              </h3>
              <div className="row">
                <h2 className="font-16 font-semibold color-black mb-4">
                  Add new connector
                </h2>
                <div className="col-md-6">
                  <div className="mb-5">
                    <label
                      htmlFor="Connector Name"
                      className="form-label font-14 font-semibold color-black"
                    >
                      Connector Name
                    </label>
                    <span className="color-red"> *</span>
                    <input
                      type="text"
                      className="form-control theme-form font-regular font-14"
                      id="Connector Name"
                      name="Connector Name"
                      placeholder="Enter connector name"
                      value={connectorFormData["Connector Name"]}
                      onChange={(e) => {
                        connectorsOnChange(e);
                      }}
                    />
                    <p className="mt-2 mb-0 color-red font-14 font-regular">
                      {connectorFormValidationMsg["Connector Name"]}
                    </p>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="mb-5">
                    <label
                      htmlFor="ServiceNow URL"
                      className="form-label font-14 font-semibold color-black"
                    >
                      ServiceNow URL
                    </label>
                    <span className="color-red"> *</span>
                    <input
                      type="text"
                      className="form-control theme-form font-regular font-14"
                      id="ServiceNow URL"
                      name="ServiceNow URL"
                      placeholder="ServiceNow URL"
                      value={connectorFormData["ServiceNow URL"]}
                      onChange={(e) => {
                        connectorsOnChange(e);
                      }}
                    />
                    <p className="mt-2 mb-0 color-red font-14 font-regular">
                      {connectorFormValidationMsg["ServiceNow URL"]}
                    </p>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="mb-5">
                    <label
                      htmlFor="Username"
                      className="form-label font-14 font-semibold color-black"
                    >
                      Username
                    </label>
                    <span className="color-red"> *</span>
                    <input
                      type="text"
                      className="form-control theme-form font-regular font-14"
                      id="Username"
                      name="Username"
                      placeholder="Enter Username"
                      value={connectorFormData["Username"]}
                      onChange={(e) => {
                        connectorsOnChange(e);
                      }}
                    />
                    <p className="mt-2 mb-0 color-red font-14 font-regular">
                      {connectorFormValidationMsg["Username"]}
                    </p>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="mb-5">
                    <label
                      htmlFor="Password"
                      className="form-label font-14 font-semibold color-black"
                    >
                      Password
                    </label>
                    <span className="color-red"> *</span>
                    <input
                      type="Password"
                      className="form-control theme-form font-regular font-14"
                      id="Password"
                      name="Password"
                      placeholder="Enter Password"
                      value={connectorFormData["Password"]}
                      onChange={(e) => {
                        connectorsOnChange(e);
                      }}
                    />
                    <p className="mt-2 mb-0 color-red font-14 font-regular">
                      {connectorFormValidationMsg["Password"]}
                    </p>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="mb-5">
                    <label
                      htmlFor="Product Suite"
                      className="form-label font-14 font-semibold color-black"
                    >
                      Product Suite
                    </label>
                    <span className="color-red"> *</span>
                    <ConnectorSelectDropdown
                      dataOptions={options}
                      dataValue={
                        connectorFormData["Product Suite"] ||
                        snControlAreaSelectedValues
                      }
                      handleChange={handleMultiSelectChange}
                      handleTreeChange={handleSnTreeSelectChange}
                      mode={"select"}
                      dropdownName={"Product Suite"}
                    />
                    <p className="mt-2 mb-0 color-red font-14 font-regular">
                      {connectorFormValidationMsg["Product Suite"]}
                    </p>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="mb-5">
                    <label
                      htmlFor="Product Suite Subcategories"
                      className="form-label font-14 font-semibold color-black"
                    >
                      Select Subcategories
                    </label>
                    <span className="color-red"> *</span>
                    <ConnectorSelectDropdown
                      treeDataOptions={snSubMenuTreeDataOptions}
                      dataValue={
                        snSubMenuSelectedValues ||
                        connectorFormData["Product Suite Subcategories"]
                      }
                      handleChange={handleMultiSelectChange}
                      handleTreeChange={handleSnTreeSelectChange}
                      mode={"treeselect"}
                      dropdownName={"Product Suite Subcategories"}
                    />
                    <p className="mt-2 mb-0 color-red font-14 font-regular">
                      {
                        connectorFormValidationMsg[
                        "Product Suite Subcategories"
                        ]
                      }
                    </p>
                  </div>
                </div>
                <div
                  className="col-md-6"
                  hidden={!snControlAreaSelectedValues.includes("ITAM")}
                >
                  <p className="font-16 font-bold mt-3">
                    Upload ITAM Data<span className="color-red"> *</span>
                  </p>
                  <p className="font-14 font-bold color-black mb-4">Step 1</p>
                  <div className="d-flex mb-3">
                    <img
                      src="images/download.svg"
                      alt="download"
                      title="download"
                      className="me-2"
                    />
                    <span
                      className="link-blue font-12 font-semibold link-blue cursor-pointer"
                      onClick={() => {
                        downloadTemplate("ITAM");
                      }}
                    >
                      Download Template
                    </span>
                  </div>
                  <p className="form-label font-14 font-regular color-black  mb-4">
                    Download the template
                  </p>
                  <p className="font-14 font-bold color-black mb-4">Step 2</p>
                  <p className="form-label font-14 font-regular color-black  mb-4">
                    Enter valid details on the template
                  </p>
                  <p className="font-14 font-bold color-black mb-3">Step 3</p>
                  <div className="mb-3 d-md-flex align-items-center">
                    <span className="upload-btn-wrapper me-2 d-block ">
                      <button
                        type="button"
                        className="font-14 link-blue font-medium bg-white border-0 shadow-none cursor-pointer"
                      >
                        <img
                          src="images/upload.svg"
                          alt="browse"
                          className="me-2"
                        />
                        Upload Template
                      </button>
                      <input
                        type="file"
                        name="ITAM template"
                        id="itam"
                        ref={itamAttachment}
                        className="cursor-pointer"
                        onChange={(e) => {
                          removeFile("itam");
                          readFile(e);
                        }}
                      />
                    </span>
                  </div>
                  <div className="mb-5">
                    <span className="font-14 font-medium color-grey mt-1   d-block ">
                      {fileName.itam}
                      <span className="cursor-pointer ms-3" title="Cancel">
                        {fileName.itam ? (
                          <img
                            src="images/upload-close.svg"
                            alt="upload-close"
                            onClick={() => {
                              removeFile("itam");
                            }}
                          />
                        ) : (
                          <></>
                        )}
                      </span>
                    </span>
                    <p className="mt-2 mb-0 color-red font-14 font-regular">
                      {connectorFormValidationMsg["ITAM template"]}
                    </p>
                    <p className="mt-2 mb-0 color-red font-14 font-regular">
                      {validationMessage.itam}
                    </p>
                  </div>
                </div>
                <div className="col-md-6">
                  <p className="font-16 font-bold mt-3">
                    Upload Environment Data<span className="color-red"> *</span>
                  </p>
                  <p className="font-14 font-bold color-black mb-4">Step 1</p>
                  <div className="d-flex mb-3">
                    <img
                      src="images/download.svg"
                      alt="download"
                      title="download"
                      className="me-2"
                    />
                    <span
                      className="link-blue font-12 font-semibold link-blue cursor-pointer"
                      onClick={() => {
                        downloadTemplate("ENV");
                      }}
                    >
                      Download Template
                    </span>
                  </div>
                  <p className="form-label font-14 font-regular color-black  mb-4">
                    Download the template
                  </p>
                  <p className="font-14 font-bold color-black mb-4">Step 2</p>
                  <p className="form-label font-14 font-regular color-black  mb-4">
                    Enter valid details on the template
                  </p>
                  <p className="font-14 font-bold color-black mb-3">Step 3</p>
                  <div className="mb-3 d-md-flex align-items-center">
                    <span className="upload-btn-wrapper me-2 d-block ">
                      <button
                        type="button"
                        className="font-14 link-blue font-medium bg-white border-0 shadow-none cursor-pointer"
                      >
                        <img
                          src="images/upload.svg"
                          alt="browse"
                          className="me-2"
                        />
                        Upload Template
                      </button>
                      <input
                        type="file"
                        name="Environment template"
                        id="environment"
                        ref={environmentAttachment}
                        className="cursor-pointer"
                        onChange={(e) => {
                          removeFile("environment");
                          readFile(e);
                        }}
                      />
                    </span>
                  </div>
                  <div className="mb-5">
                    <span className="font-14 font-medium color-grey mt-1   d-block ">
                      {fileName.environment}
                      <span className="cursor-pointer ms-3" title="Cancel">
                        {fileName.environment ? (
                          <img
                            src="images/upload-close.svg"
                            alt="upload-close"
                            onClick={() => {
                              removeFile("environment");
                            }}
                          />
                        ) : (
                          <></>
                        )}
                      </span>
                    </span>
                    <p className="mt-2 mb-0 color-red font-14 font-regular">
                      {connectorFormValidationMsg["Environment template"]}
                    </p>
                    <p className="mt-2 mb-0 color-red font-14 font-regular">
                      {validationMessage.environment}
                    </p>
                  </div>
                </div>
                <p className="mt-1 mb-0 color-red font-14 font-regular">
                  {connectorApiValidationMsg}
                </p>
                {bindSaveAndCancel()}
              </div>
            </div>
          </div>
        );
    }
  };

  return <>{bindConnectorsForm()}</>;
}
