import { useState } from "react";
import { AppDevConnectorPropsModel } from "../../interface/questionnaireInterface";

export default function QAConnector(props: AppDevConnectorPropsModel) {
  const {
    activeConnector,
    validateConnectorInput,
    connectorsOnChange,
    setShowConnectorForm,
    sonarCloudProjectkeyOnChange,
    connectorFormData,
    setConnectorFormData,
    connectorFormValidationMsg,
    connectorApiValidationMsg,
    resetValidationMessages,
  } = props;

  const bindConnectorsForm = () => {
    const bindSaveAndCancel = () => {
      return (
        <div className="d-grid gap-2 d-md-inline-flex justify-content-md-end mb-5 w-100">
          <button
            type="button"
            className="btn btn-dark theme-primary-btn border-0 font-14 font-semibold "
            onClick={() => {
              validateConnectorInput(null);
            }}
          >
            Save
          </button>
          <a
            className="theme-link-btn cursor-pointer  font-semibold text-center order-md-first"
            onClick={() => {
              setConnectorFormData({});
              resetValidationMessages(activeConnector);
              setShowConnectorForm(true);
            }}
          >
            Cancel
          </a>
        </div>
      );
    };
    switch (activeConnector) {
      case "JIRA":
        return (
          <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-4">
            <h3 className="font-18 font-semibold color-black mt-4 mb-4">
              JIRA Cloud Configuration
            </h3>
            <div className="col-lg-8 col-md-12 col-12 mb-4">
              <h3 className="font-17 font-semibold color-black mt-4 mb-4">
                Add new connector
              </h3>
              <div className="row">
                <div className="col-12 col-md-12 col-lg-6">
                  <div className="mb-5">
                    <label
                      htmlFor="Tenant Domain URL"
                      className="form-label font-14 font-semibold color-black"
                    >
                      Tenant Domain URL
                    </label>
                    <span className="color-red"> *</span>
                    <input
                      type="text"
                      className="form-control theme-form font-regular font-14"
                      id="Tenant Domain URL"
                      name="Tenant Domain URL"
                      placeholder="Enter Tenant Domain URL"
                      value={connectorFormData["Tenant Domain URL"]}
                      onChange={(e) => {
                        connectorsOnChange(e);
                      }}
                    />
                    <p className="mt-2 mb-0 color-red font-14 font-regular">
                      {connectorFormValidationMsg["Tenant Domain URL"]}
                    </p>
                  </div>
                </div>
                <div className="col-12 col-md-12 col-lg-6">
                  <div className="mb-5">
                    <label
                      htmlFor="Project Name"
                      className="form-label font-14 font-semibold color-black"
                    >
                      Project Name
                    </label>
                    <span className="color-red"> *</span>
                    <input
                      type="text"
                      className="form-control theme-form font-regular font-14"
                      id="Project Name"
                      name="Project Name"
                      placeholder="Enter Project Name"
                      value={connectorFormData["Project Name"]}
                      onChange={(e) => {
                        connectorsOnChange(e);
                      }}
                    />
                    <p className="mt-2 mb-0 color-red font-14 font-regular">
                      {connectorFormValidationMsg["Project Name"]}
                    </p>
                  </div>
                </div>
                <div className="col-12 col-md-12 col-lg-6">
                  <div className="mb-5">
                    <label
                      htmlFor="Username"
                      className="form-label font-14 font-semibold color-black"
                    >
                      Username
                    </label>
                    <span className="color-red"> *</span>
                    <input
                      type="text"
                      className="form-control theme-form font-regular font-14"
                      id="Username"
                      name="Username"
                      placeholder="Enter Username"
                      value={connectorFormData["Username"]}
                      onChange={(e) => {
                        connectorsOnChange(e);
                      }}
                    />
                    <p className="mt-2 mb-0 color-red font-14 font-regular">
                      {connectorFormValidationMsg["Username"]}
                    </p>
                  </div>
                </div>
                <div className="col-12 col-md-12 col-lg-6">
                  <div className="mb-5">
                    <label
                      htmlFor="Password"
                      className="form-label font-14 font-semibold color-black"
                    >
                      Password
                    </label>
                    <span className="color-red"> *</span>
                    <input
                      type="password"
                      className="form-control theme-form font-regular font-14"
                      id="Password"
                      name="Password"
                      placeholder="Enter Password"
                      value={connectorFormData["Password"]}
                      onChange={(e) => {
                        connectorsOnChange(e);
                      }}
                    />
                    <p className="mt-2 mb-0 color-red font-14 font-regular">
                      {connectorFormValidationMsg["Password"]}
                    </p>
                  </div>
                </div>
                {bindSaveAndCancel()}
              </div>
            </div>
          </div>
        );
    }
  };

  return <>{bindConnectorsForm()}</>;
}
