import React, { useEffect, useState, useRef, MutableRefObject } from 'react'
import { getNavbar, postQuestion } from '../service/AdminApi';
import { questionModel, optionModel, menuModel, toastModel, tempObj } from '../interface/AdminModel';
import { useLocation, useNavigate } from 'react-router-dom'
import { read, utils } from 'xlsx';
import AdminQuestion from './AdminQuestion';
import Loader from './Loader';
import ToastComponent from './ToastComponent';
import { getSasToken } from '../service/ReportApi';
import { useMsal } from "@azure/msal-react";
import { isAuth } from './Login/Auth';
import { encryptStorage } from '../constant/constant';
import { jwtDecode } from 'jwt-decode';
import { getConnectorsData } from '../helpers/encryption';

const AdminNavbar = () => {

    /**PS_AN_05-PS_AF_08
     * object declarations 
     */

    const questionObj: questionModel = {
        questionId: 0,
        question: '',
        isBranch: 0,
        optionType: '',
        option: [],
        branchingOptionId: 0
    };

    const optionObj: optionModel = {
        optionId: 0,
        option: '',
        recommendation: '',
        severity: '',
        score: 0,
        isActive: true
    };

    const toastData: toastModel = {
        toastType: '',
        toastHeaderMessage: '',
        toastBodyMessage: ''
    };

    const menuObj: menuModel = {
        verticalId: '',
        controlAreaId: '',
        controlAreaSubMenuId: ''
    };

    /**PS_AN_09
     * state variables declarations 
     */

    const [responseObj, setResponseObj] = useState<any[]>([]);
    const [currentMenu, setCurrentMenu] = useState<menuModel>(menuObj);
    const [mainArr, setMainArr] = useState<any[]>([])
    const [fileName, setFileName] = useState<string>('');
    const [hidePopup, setHidePopup] = useState<boolean>(true);
    const [hideToast, setHideToast] = useState<boolean>(false);
    const [validationMessage, setValidationMessage] = useState<string>(" ");
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [callComp, setCallComp] = useState<boolean>(false)
    const [render, setRender] = useState<boolean>(false)
    const [callData, setCallData] = useState<boolean>(false);
    const [toastProp, setToastProp] = useState<toastModel>(toastData)
    const attachment: any = useRef(null);
    const ref: any = useRef();
    const nextRefs: any = useRef([]);
    const [ds, setds] = useState(false);
    const location = useLocation();
    const navigate = useNavigate();
    const charRegex = /[-!$%^&*()_+|~={}\[\]:"";<>?,\/]/;
    const fileNameRegex = /[!$%^&*+|~={}\[\]:"";<>?,\/]/
    const { accounts } = useMsal();


    /**PS_AN_11
     * This useEffect is used to call the timeout function for the toast
     * hideToast state variable is passed as the dependency 
     */
    useEffect(() => {
        setTimeout(() => setHideToast(false), 4000);
    }, [hideToast]);

    /**PS_AN_14
         * This useEffect is used to call the getData function    
        */
    useEffect(() => {

        if (!isAuth(accounts)) navigate('/')

        getData();
    }, [render]);


    useEffect(() => {
        if (nextRefs?.current?.length) {
            let offset = Number(ref?.current?.offsetWidth);
            let newOffset = 0;
            for (let i = 0; i < nextRefs?.current?.length; i++) {
                if (nextRefs?.current[i] !== null)
                    newOffset += nextRefs?.current[i]?.offsetWidth;
            }
            if (offset - newOffset < -5) setds(true);
            else setds(false);
        }
    }, [currentMenu]);

    /**PS_AN_15-PS_AN_32
         * This function is used to call the getNavbar API to bind the left navbar datas 
         */
    const getData = async () => {
        try {
            setIsLoading(true)
            const response = await getNavbar();
            if (response?.status !== 200) {
                setIsLoading(false)
                let toastData = {
                    toastType: 'error',
                    toastHeaderMessage: 'Error',
                    toastBodyMessage: 'API failed'
                }
                setToastProp(toastData)
                setHideToast(true);
            } else {
                setIsLoading(false)
                let data = response.data;
                setResponseObj(data);
                const tempMenuObj: menuModel = {
                    verticalId: '',
                    controlAreaId: '',
                    controlAreaSubMenuId: ''
                };
                tempMenuObj.verticalId = data[0].vertical_id;
                tempMenuObj.controlAreaId = data[0].control_areas[0].control_area_id
                tempMenuObj.controlAreaSubMenuId = data[0].control_areas[0].control_area_menu.sort((a: any, b: any) => a.menu_names.localeCompare(b.menu_names))[0].control_area_menu_id;
                setCurrentMenu(tempMenuObj)
                setCallComp(true)
                setIsLoading(false)
            }
        }
        catch (error) {
            setIsLoading(false)
            let toastData = {
                toastType: 'error',
                toastHeaderMessage: 'Error',
                toastBodyMessage: 'API failed'
            }
            setToastProp(toastData)
            setHideToast(true);
        }

    };

    /**PS_AN_38-PS_AN_40
     * This function is used to set the vertical selected by the user to the currentMenu state variable 
     * event is passed as the parameter
     */
    const setVertical = (event: any) => {
        setCurrentMenu({ ...currentMenu, verticalId: event.vertical_id });
    };

    /**PS_AN_41-PS_AN_44
         * This function is used to set the control area selected by the user to the currentMenu state variable 
         * event is passed as the parameter
         */
    const setControlArea = (event: any, verticalId: any) => {
        let tempObj: menuModel = {
            verticalId: verticalId,
            controlAreaId: '',
            controlAreaSubMenuId: ''
        }
        //setCurrentMenu({ ...currentMenu, controlAreaId: event.target.value });
        tempObj.controlAreaId = event.target.value;
        responseObj.forEach((vertical: any) => {
            vertical.control_areas.forEach((controlarea: any) => {
                if (controlarea.control_area_id === event.target.value) {
                    //setCurrentMenu({ ...currentMenu, controlAreaSubMenuId: controlarea.control_area_menu[0].control_area_menu_id });
                    tempObj.controlAreaSubMenuId = controlarea.control_area_menu.sort((a: any, b: any) => a.menu_names.localeCompare(b.menu_names))[0].control_area_menu_id
                }
            })
        })
        setCurrentMenu(tempObj)
    };

    /**PS_AN_45-PS_AN_48
     * This function is used to set the control area menu selected by the user to the currentMenu state variable 
     * event is passed as the parameter
     */
    const setControlAreaMenu = (event: any) => {
        setCurrentMenu({ ...currentMenu, controlAreaSubMenuId: event.target.value });
    };

    /**PS_AN_54
     * This function is used to the remove the files uploaded by the user 
     */
    const removeFile = (errorMessage = "") => {
        setFileName('');
        setValidationMessage(errorMessage);
        attachment.current.value = null;
    };

    /**PS_AN_63-PS_AN_68
     * This function is used to download the template file uploaded in the blob 
     */
    const downloadTemplate = async () => {
        try {
            let value = encryptStorage.getItem("jwt")
            let parsedJtk: any = ""
            let storageAccountName: any;
            let containerName: any;
            if (value) {
                parsedJtk = jwtDecode(value)
                storageAccountName = getConnectorsData({ key: parsedJtk.ek, encryptedMessage: process.env.REACT_APP_BLOB_ACCOUNT_NAME! });
                containerName = getConnectorsData({ key: parsedJtk.ek, encryptedMessage: process.env.REACT_APP_BLOB_CONTAINER! })
            }
            const sas = await getSasToken('r')
            window.open(`https://${storageAccountName}.blob.core.windows.net/${containerName}/Question-Template.xlsx?${sas.data}`);
        } catch (error) {
            setIsLoading(false)
            let toastData = {
                toastType: 'error',
                toastHeaderMessage: 'Error',
                toastBodyMessage: 'API failed'
            }
            setToastProp(toastData)
            setHideToast(true);
        }
    };

    /**PS_AN_70-PS_AN_125
     * This function is used to read the excel file uploaded by the user, validate the file and form the required JSON struct
     * event is passed as the parameter
     */
    let readFile = (e: any) => {
        let mainArr: any = []
        try {
            if (e && !fileNameRegex.test(e.target.files[0].name)) {
                setValidationMessage('');
                const typeArr = ['vnd.openxmlformats-officedocument.spreadsheetml.sheet'];
                let type = typeArr.includes(e.target.files[0].type.split('/')[1]);
                if (type) {
                    setValidationMessage('');
                    const validationHeading = [
                        'QuestionNo',
                        'Question',
                        'Option',
                        'QuestionType',
                        'Score',
                        'Severity',
                        'Recomendations',
                    ];
                    let files = e.target.files;
                    let f = files[0];
                    let userArr = [];
                    let reader = new FileReader();
                    reader.onload = (e) => {
                        const wb = read(e?.target?.result);
                        const sheets = wb.SheetNames;
                        const rows: any = utils.sheet_to_json(wb.Sheets[sheets[0]]);
                        if (rows.length >= 1) {
                            setValidationMessage('');
                            let columnTitle = Object.keys(rows[0]);
                            if (JSON.stringify(validationHeading) == JSON.stringify(columnTitle)) {
                                let arr = []
                                rows.map((value: any, index: number) => {
                                    if (JSON.stringify(validationHeading) != JSON.stringify(Object.keys(value))) {
                                        setFileName('')
                                        setValidationMessage('Please upload the file with valid data')
                                    }
                                    else {
                                        arr.push(value?.QuestionNo)
                                    }
                                });
                                rows.forEach((data: any, index: number) => {
                                    if (mainArr.findIndex((el: any) => el.QuestionNo == data?.QuestionNo) == -1) {
                                        let questionTypeArray: any = [];
                                        let tempObj: tempObj = {
                                            questionId: 0,
                                            QuestionNo: data?.QuestionNo,
                                            question: data?.Question.trim().replace(/\r\n/g, '').replace(/\"/g, '”'),
                                            options: [],
                                            questionType: data?.QuestionType.trim().replace(/\r\n/g, ''),
                                            branchingOptionId: null
                                        }
                                        rows.forEach((opt: any) => {
                                            if (opt.QuestionNo == data.QuestionNo) {
                                                if (opt.Recomendations.trim() == "" || opt.Severity.trim() == "") {
                                                    removeFile('Please upload the file with valid data');
                                                }

                                                if (opt.QuestionType.trim().toUpperCase() !== "RADIO" && opt.QuestionType.trim().toUpperCase() !== "CHECKBOX") {
                                                    removeFile('Please upload the file with valid data');
                                                }

                                                let tempOpt: optionModel = {
                                                    optionId: 0,
                                                    option: opt.Option.trim().replace(/\r\n/g, '').replace(/\"/g, '”'),
                                                    score: Number(opt.Score),
                                                    severity: opt?.Recomendations?.toUpperCase().trim() == "NA" || opt.Severity.toUpperCase().trim() == "NA" ? "" : opt.Severity.trim().replace(/\r\n/g, '').replace(/\"/g, '”'),
                                                    recommendation: opt?.Recomendations?.toUpperCase().trim() == "NA" || opt.Severity.toUpperCase().trim() == "NA" ? "" : opt.Recomendations.trim().replace(/\r\n/g, '').replace(/\"/g, '”'),
                                                    isActive: true
                                                }
                                                questionTypeArray.push(opt.QuestionType.trim().toUpperCase());
                                                tempObj?.options?.push(tempOpt)
                                            }
                                        })

                                        if (tempObj.options.length < 2 || tempObj.question == "" || tempObj.questionType == "" || !questionTypeArray.every((value: any) => value === questionTypeArray[0])) {
                                            removeFile('Please upload the file with valid data');
                                        }
                                        else {
                                            mainArr.push(tempObj)
                                            setFileName(files[0]?.name);
                                        }
                                    }
                                })
                                setMainArr(mainArr)
                            } else {
                                setFileName('');
                                setValidationMessage('Please upload the file with valid data');
                            }
                        } else {
                            setFileName('');
                            setValidationMessage('Please upload the file with valid data');
                        }
                    };
                    reader.readAsArrayBuffer(f);
                } else {
                    setFileName('');
                    setValidationMessage('Please upload the file in .xlsx format');
                }
            }
            else {
                setValidationMessage("Please upload file with valid name")
            }
        } catch (error) {
            //console.log(error.message);
        }
    };

    /**PS_AN_127-PS_AN_138
         * This function is used to call the postQuestion API passing the paramObj as object
         */
    const uploadQuestions = async () => {
        try {
            setIsLoading(true)
            let paramObj = {
                verticalId: currentMenu.verticalId,
                controlAreaId: currentMenu.controlAreaId,
                controlAreaSubMenuId: currentMenu.controlAreaSubMenuId,
                questions: mainArr
            }

            const response = await postQuestion(paramObj);
            if (response?.status !== 200) {
                let toastData = {
                    toastType: 'error',
                    toastHeaderMessage: 'Error',
                    toastBodyMessage: 'API failed'
                }
                setToastProp(toastData)
                setIsLoading(false)
                setHideToast(true);
            } else {
                setIsLoading(false)
                let toastData = {
                    toastType: 'Success',
                    toastHeaderMessage: 'Success',
                    toastBodyMessage: 'Questionnaires have been uploaded successfully.'
                }
                setToastProp(toastData)
                setHidePopup(true);
                setCallData(!callData)
                setCurrentMenu(currentMenu)
                setHideToast(true);
            }

        }
        catch (error) {
            setIsLoading(false)
            let toastData = {
                toastType: 'error',
                toastHeaderMessage: 'Error',
                toastBodyMessage: 'API failed'
            }
            setToastProp(toastData)
            setHideToast(true);
        }
    };

    /**PS_AN_33
         * This function is used to bind the controlArea on the left navbar 
         */
    let bindControlAreas = () => {
        responseObj.forEach((item: any) => {
            item.control_areas.sort((a: any, b: any) => a.control_area_order - b.control_area_order);
        })
        return responseObj.map((vertical: any, i: number) => {
            return (
                <div className="accordion-item border-0">
                    <h2 className="accordion-header border-0" id="headingOne">
                        <button className={`accordion-button custom-accordion-btn border-0 font-14 font-medium ${i == 0 ? '' : 'collapsed'}`} type="button" data-bs-toggle="collapse" data-bs-target={`#target${i}`} value={vertical.vertical_id} aria-expanded={`${i == 0 ? 'true' : 'false'}`} aria-controls="collapseOne" onClick={(e) => {
                            //setVertical(e)
                        }}>
                            {vertical.vertical_name}
                        </button>
                    </h2>
                    <div id={`target${i}`} className={`accordion-collapse border-0 ${i == 0 ? 'collapse show' : 'collapse'} `} aria-labelledby="headingOne" data-bs-parent={`#target${i}`}>
                        <div className="border-0 pe-0">
                            <div className=" align-items-start">
                                <div className="nav flex-column nav-pills custom-v-pills w-100" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                                    {vertical.control_areas.map((controlArea: any) => {
                                        return (
                                            currentMenu.controlAreaId === controlArea.control_area_id ?
                                                <button className="nav-link text-start rounded-0 font-regular font-14 active adjust-height" id="v-pills-sonarCloud-tab" data-bs-toggle="pill" data-bs-target="#v-pills-sonarCloud" type="button" role="tab" aria-controls="v-pills-sonarCloud" aria-selected="true" value={controlArea.control_area_id} onClick={(e) => {
                                                    setVertical(vertical)
                                                    setControlArea(e, vertical.vertical_id);
                                                    ref.current.scrollLeft = 0;
                                                }}>{controlArea.control_area_name?.length > 40

                                                    ? controlArea.control_area_name?.slice(0, 40) + "..."

                                                    : controlArea.control_area_name}</button> :
                                                <button className="nav-link text-start rounded-0 font-regular font-14 adjust-height" id="v-pills-sonarCloud-tab" data-bs-toggle="pill" data-bs-target="#v-pills-sonarCloud" type="button" role="tab" aria-controls="v-pills-sonarCloud" aria-selected="true" value={controlArea.control_area_id} onClick={(e) => {
                                                    setVertical(vertical)
                                                    setControlArea(e, vertical.vertical_id);
                                                    ref.current.scrollLeft = 0;
                                                }}>{controlArea.control_area_name?.length > 40

                                                    ? controlArea.control_area_name?.slice(0, 40) + "..."

                                                    : controlArea.control_area_name}</button>

                                        )
                                    })}

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        })
    }

    /**PS_AF_05-PS_AF_08
     * This function is used to bind the control area menus on the left navbar
     */
    let bindControlAreaMenu = () => {
        return responseObj.map((vertical: any) => {
            return vertical.control_areas.map((controlArea: any) => {
                if (controlArea.control_area_id === currentMenu.controlAreaId) {
                    return controlArea.control_area_menu?.sort((a: any, b: any) => a.menu_names.localeCompare(b.menu_names)).map((controlAreaMenu: any, index: number) => {
                        return (
                            <>

                                {currentMenu.controlAreaSubMenuId === controlAreaMenu.control_area_menu_id || index == 0 ?
                                    <button ref={(el: any) => {
                                        nextRefs.current[index] = el;
                                    }} className="nav-link text-nowrap active" id="nav-storage-tab" data-bs-toggle="tab" data-bs-target="#nav-storage" type="button" role="tab" aria-controls="nav-storage" aria-selected="true" value={controlAreaMenu.control_area_menu_id} onClick={(e) => {
                                        setControlAreaMenu(e)
                                    }}>{controlAreaMenu.menu_names}</button> :
                                    <button ref={(el: any) => {
                                        nextRefs.current[index] = el;
                                    }} className="nav-link text-nowrap" id="nav-storage-tab" data-bs-toggle="tab" data-bs-target="#nav-storage" type="button" role="tab" aria-controls="nav-storage" aria-selected="true" value={controlAreaMenu.control_area_menu_id} onClick={(e) => {
                                        setControlAreaMenu(e)
                                    }}>{controlAreaMenu.menu_names}</button>}

                            </>
                        )
                    })
                }
            })
        })
    }




    return (
        <>
            <>
                <Loader isLoading={isLoading} />
                {hideToast ? <ToastComponent name={toastProp} /> : <></>}
            </>
            <div className="container-fluid" >
                <div className="row justify-content-center" >
                    <div className="px-4">
                        {/* heading content starts here */}
                        <div className="d-flex align-items-center mb-4 mt-5 pt-5">
                            <a ><img src="images/backarrow.svg" alt="back-arrow" title="Back" className='cursor-pointer' onClick={() => { navigate("/homepage") }} /></a>
                            <h2 className="font-22 font-semibold color-black mb-0 ms-3 ">
                                Admin Configurations
                            </h2>
                        </div>
                        {/* heading content ends here */}
                        <div className="row">
                            <div className="col-12 col-md-12 col-lg-3 col-xl-2 pe-0">
                                <h6 className="font-16 font-semibold mt-2 mb-3">Domain and Control Areas</h6>
                                {/* accordions starts here */}
                                <div className="accordion  v-tab-border" id="accordion">
                                    {bindControlAreas()}
                                </div>
                            </div>
                            <div className="col-12 col-md-12 col-lg-9 col-xl-10">
                                <div className="tab-content" id="v-pills-tabContent">
                                    {/* overall summary content starts here */}
                                    <div className="tab-pane fade show active mb-5" id="v-pills-sonarCloud" role="tabpanel">
                                        <div className="d-md-flex d-grid gap-3   align-items-center justify-content-between mb-5">
                                            <h6 className="font-18 font-bold mb-0 ">Manage Questionnaire</h6>
                                            <button data-bs-toggle="modal" data-bs-target="#uploadQuestions" type="button" className="btn btn-dark theme-primary-btn border-0 font-14 font-semibold ms-md-3 ms-0 px-3 table-btn  text-nowrap ">
                                                <span className="me-2"><img src="images/whiteplus.svg" alt="plus" /></span>Upload Questionnaire</button>
                                        </div>
                                        <div className=" Nav-Adpage-scrl">
                                            <div className="tab-content" id="v-pills-tabContent">
                                                <div className="tab-pane   active mb-5" id="v-pills-security" role="tabpanel" style={{ width: "99%" }}>

                                                    <div className="row sticky-top bg-white" style={{ zIndex: "1" }}>
                                                        <div className='d-flex nav-tabs'>
                                                            {ds && (
                                                                <button
                                                                    type="button"
                                                                    className="btn border-0 p-0 shadow-none"
                                                                    onClick={() => {
                                                                        ref.current.scrollLeft -= 200;
                                                                    }}
                                                                >
                                                                    <img
                                                                        src="images/date-left-arrow.svg"
                                                                        alt="date-left-arrow"
                                                                    />
                                                                </button>
                                                            )}
                                                            <div ref={ref} className="nav border-tabs flex-nowrap table-responsive nav-scroll" id="nav-tab" role="tablist">
                                                                {bindControlAreaMenu()}
                                                            </div>
                                                            {ds && (
                                                                <button
                                                                    type="button"
                                                                    className="btn border-0 p-0 shadow-none"
                                                                    onClick={() => {
                                                                        ref.current.scrollLeft += 200;
                                                                    }}
                                                                >
                                                                    <img
                                                                        src="images/date-right-arrow.svg"
                                                                        alt="date-left-arrow"
                                                                    />
                                                                </button>
                                                            )}
                                                        </div>
                                                    </div>
                                                    {callComp ? <AdminQuestion name={currentMenu} data={callData} loader={{ setIsLoading: (param: boolean) => setIsLoading(param) }} toast={{ setHideToast: (param: boolean) => setHideToast(param), setToastProp: (param: toastModel) => setToastProp(param) }} /> : <></>}

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* upload component */}
            <div className="modal fade" id="uploadQuestions" aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1}>
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content p-3">
                        <div className="modal-header pb-0 border-0">
                            <h5 className="font-semibold font-18 font-semibold d-flex justify-content-between align-items-center">
                                Upload Questionnaire</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => { removeFile() }} />
                        </div>
                        <div className="modal-body">
                            <div className="" id="uploadEnvioronment">
                                <p className="font-14 font-bold color-black mb-3">Step 1</p>
                                <div className="mb-4" onClick={() => { downloadTemplate() }}>
                                    <img src="images/download.svg" alt="download" title="download" className="me-2" /><span className="link-blue font-12 font-semibold link-blue pe-4 cursor-pointer">Download Template</span>
                                </div>
                                <p className="form-label font-14 font-regular color-black  mb-4">Download the questionnaire template</p>
                                <p className="font-14 font-bold color-black mb-3">Step 2</p>
                                <p className="form-label font-14 font-regular color-black  mb-4">Fill the questionnaire template with the necessary data.</p>
                                <p className="font-14 font-bold color-black mb-3">Step 3</p>
                                <div className="mb-3 d-md-flex align-items-center cursor-pointer">
                                    <span className="upload-btn-wrapper me-2 d-block cursor-pointer">
                                        <button onClick={() => removeFile()} type="button" className="font-12 link-blue font-semibold bg-white border-0 shadow-none cursor-pointer"><img onClick={() => removeFile()} src="images/upload.svg" alt="upload" className="me-2 cursor-pointer" />Upload Template</button>
                                        <input ref={attachment} type="file" name="myfile" onChange={(e) => {
                                            readFile(e)
                                        }} />
                                    </span>
                                    {fileName ? <span className="font-12 font-medium color-grey mt-1 ms-2  d-block ">{fileName}<span className="cursor-pointer ms-3" onClick={() => { removeFile() }} title="Cancel"><img src="images/upload-close.svg" /></span></span> : ""}
                                </div>
                                <p className="font-14 font-regular color-red">{validationMessage}</p>
                                <p className="form-label font-14 font-regular color-black mb-4">Upload the questionnaire template to add questionnaires.</p>
                                <div className="col-md-12 d-flex justify-content-end mt-4 mb-2">
                                    <a className="theme-link-btn font-14 font-semibold text-center order-md-first cursor-pointer" data-bs-dismiss="modal" onClick={() => { removeFile() }}>Cancel</a>
                                    <button disabled={validationMessage === "" && fileName != "" ? false : true} data-bs-dismiss="modal" type="button" className="btn btn-dark theme-primary-btn-sm border-0 font-semibold px-5" onClick={() => {
                                        removeFile()
                                        uploadQuestions()
                                    }}>Apply</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </>
    );
};

export default AdminNavbar;
