import react, { useState, useEffect, useContext, useRef } from "react";
import TextEditor from "./TextEditor";
import { getRegeneratedSummary } from "../../service/ReportApi";
import {
    editedSessionDataModel,
    sessionDataModel,
    summarySessionsModel,
    wafrFileInfoModel,
} from "../../interface/WafrReportModel";
import UploadTranscript from "./UploadTranscript";
import { upload } from "@testing-library/user-event/dist/upload";
import SessionForm from "./SessionForm";

const ExecutiveSummary = (props: any) => {
    const initialFileInfo: wafrFileInfoModel = {
        fileData: "",
        fileName: "",
        fileType: "",
        base64Transcript: "",
    };
    const initialSessionSummary: editedSessionDataModel = {
        attributeMasterId: "",
        titleAttributeId: "",
        titleName: "",
        description: "",
        descriptionId: "",
        fileName: "",
        fileData: "",
        fileType: "",
        base64Transcript: "",
    };

    const charRegex = /^(?=.*[A-Za-z])[A-Za-z0-9\s]*$/;
    const {
        VMwareRecommendation,
        summaryName,
        summaryTitle,
        summary,
        fileInfo,
        setFileInfo,
        postTranscript,
        postSummary,
        sessions,
        verticalName,
        postSession,
        deleteSession,
        confirmDeletesession,
        setShowDeleteModal,
        showDeleteModal,
        showToasterLoading,
        showToasterPopup,
        showToasterFailedPopup,
        showDeletePopup,
        showSessionToasterPopup,
        organizationName,
        isExecutiveSummary,
        isAzure,
        showRegenerateButton,
        postDirective,
        postOutcome,
        fetchDirective,
        fetchOutcome
    } = props;
    const environmentType = isAzure ? "azure" : "on-premises"
    const [isEditing, setIsEditing] = useState<boolean>(summary?.length === 0);
    const [isUpload, setIsUpload] = useState<boolean>(summary?.length === 0);
    const [isSessionEditing, setIsSessionEditing] = useState<boolean>(false);
    const [sessionData, setSessionData] = useState<sessionDataModel>();
    const [fileData, setFileData] = useState<wafrFileInfoModel>(initialFileInfo);
    const [hideSessionForm, setHideSessionForm] = useState<boolean>(true);
    const [value, setValue] = useState<string>(summary);
    const [isSessionEdit, setIsSessionEdit] = useState<string>("");
    const [titleError, setTitleError] = useState<string>("");
    const [fileError, setFileError] = useState<string>("");
    const attachments = useRef(null);
    const [hideToast, setHideToast] = useState(true);
    const [toastProp, setToastProp] = useState({
        toastType: "",
        toastHeaderMessage: "",
        toastBodyMessage: "",
    });

    const [directive, setDirective] = useState<string>(
        fetchDirective || `Zeb, as the designated migration specialist for ${organizationName}, will spearhead the transition from ${environmentType} infrastructure to Amazon Web Services (AWS), aligning closely with ${organizationName}'s strategic vision and operational objectives. With a meticulous focus on planning, assessment, and execution, Zeb will ensure a seamless migration process, minimizing disruption to business operations while maximizing the benefits of cloud computing. The expertise will guide ${organizationName} through every stage of the journey, from initial evaluation to post-migration support, fostering a culture of innovation and efficiency within the organization.`
    );

    const [outcome, setOutcome] = useState<string>(
        fetchOutcome || `Following the successful migration from ${environmentType} infrastructure to Amazon Web Services (AWS), ${organizationName} experiences a range of transformative outcomes. Firstly, there's a notable improvement in agility and scalability, enabling our IT resources to adapt swiftly to changing business demands without the constraints of physical hardware limitations. This newfound flexibility translates into accelerated innovation cycles, allowing our teams to experiment and deploy new solutions more rapidly. Additionally, the migration to AWS results in cost optimization, with the ability to scale resources up or down based on actual usage, eliminating the need for over-provisioning and reducing operational expenses.`
    );

    const [isDirectiveEditing, setIsDirectiveEditing] = useState<boolean>(false);
    const [isOutcomeEditing, setIsOutcomeEditing] = useState<boolean>(false);


    const shouldShowMandatoryAsterisk = (
        summaryName: string,
        verticalName: string
    ): boolean => {
        if (summaryName === "WAFR Final Report Walkthrough" || summaryName === "MAP Final Report Walkthrough") {
            return false; // No asterisk for "WAFR Final Report Walkthrough"
        }
        if (verticalName === "VMware")
            return false

        if (
            (verticalName === "MDPW"
            ) &&
            (summaryName === "Executive Summary" ||
                summaryName === "Business Overview")
        ) {
            return false; // No asterisk for "Executive Summary" and "Business Overview" when vertical is MDPW
        }

        return true; // Asterisk for all other cases
    };

    const isMandatory = shouldShowMandatoryAsterisk(summaryName, verticalName);

    useEffect(() => {
        setValue(summary);
        setIsEditing(summary?.length === 0);
        setIsUpload(summary?.length === 0);
        if (fetchDirective) setDirective(fetchDirective);
        if (fetchOutcome) setOutcome(fetchOutcome);
    }, [summary, fetchDirective, fetchOutcome]);

    const regex = /<[^>]*>|"/g;

    const onSave = (value: string, summaryType: string) => {
        switch (summaryType) {
            case "Directive":
                onDirectiveSave(value);
                break;
            case "Outcome":
                onOutcomeSave(value);
                break;
            default:
                postSummary(value);
                setIsEditing(false);
                break;
        }
    };

    const onSessionSave = (value: any, data: any) => {
        postSession(value, data);
        setIsSessionEdit("");
    };

    const closeModal = () => {
        setShowDeleteModal(false);
    };

    const handleEditClick = () => {
        setIsEditing(!isEditing);
    };

    const handleDirectiveEditClick = () => {
        setIsDirectiveEditing(!isDirectiveEditing);
    };

    const handleOutcomeEditClick = () => {
        setIsOutcomeEditing(!isOutcomeEditing);
    };

    const onDirectiveSave = (value: string) => {
        setDirective(value);
        setIsDirectiveEditing(false);
        postDirective(value)
    };

    const onOutcomeSave = (value: string) => {
        setOutcome(value);
        setIsOutcomeEditing(false);
        postOutcome(value)
    };

    const handleSessionEditClick = (id: string) => {
        setIsSessionEdit(id);
        setIsSessionEditing(true);
    };

    function escapeJsonString(str: string): string {
        return str
            .replace(/\\/g, "\\\\")
            .replace(/"/g, '\\"')
            .replace(/\n/g, "\\n")
            .replace(/\r/g, "\\r")
            .replace(/\t/g, "\\t")
            .replace(/\f/g, "\\f");
    }

    const bindSessions = () => {
        return sessions?.map((data: sessionDataModel, index: number) => {
            return (
                <>
                    <div className="d-flex justify-content-between align-items-center mt-3 mb-2">
                        <h3 className="font-18 font-semibold color-black mb-0">
                            {`Session ${index + 1}: ${data.titleName}`}
                        </h3>
                        {!(isSessionEdit === data.titleAttributeId && isSessionEditing) && (
                            <div className="d-flex align-items-center">
                                <button
                                    type="button"
                                    className="btn edit-btn custom-btn font-medium"
                                    onClick={() => handleSessionEditClick(data.titleAttributeId)}
                                >
                                    Edit
                                </button>
                                <button
                                    type="button"
                                    className="btn btn-outline-danger custom-btn ms-3 font-medium"
                                    onClick={() => {
                                        deleteSession(data.attributeMasterId);
                                    }}
                                >
                                    Delete
                                </button>
                            </div>
                        )}
                    </div>

                    {isSessionEdit === data.titleAttributeId && isSessionEditing ? (
                        <TextEditor
                            value={data.description}
                            onSave={onSessionSave.bind(null, data)}
                            onCancel={handleSessionEditClick}
                            summaryType={"Session Summary"}
                            verticalName={verticalName}
                        />
                    ) : (
                        <div dangerouslySetInnerHTML={{ __html: data.description }} />
                    )}
                </>
            );
        });
    };
    return (
        <div className="row">
            <div className="col-lg-12 col-md-12 col-12">
                {(summaryName === "WAFR Final Report Walkthrough" || summaryName === "MAP Final Report Walkthrough") && isUpload ? (
                    <UploadTranscript
                        type={summaryName}
                        fileInfo={fileInfo}
                        setFileInfo={setFileInfo}
                        postTranscript={postTranscript}
                        showToasterPopup={showToasterPopup}
                        showToasterFailedPopup={showToasterFailedPopup}
                        hideToast={hideToast}
                        toastProp={toastProp}
                        showToasterLoading={showToasterLoading}
                    />
                ) : (
                    <div className="ps-lg-4">
                        {(verticalName === "MAP" && summaryName === "Executive Summary")  && (
                        <div className="d-flex justify-content-between mt-4">
                            <h3 className="font-18 font-semibold color-black mb-4">
                                {summaryName}
                            </h3>
                        </div>
                        )}
                        {isExecutiveSummary && (
                            <>

                                {/* Directive Section */}
                                <div className="d-flex justify-content-between">
                                    <h3 className="font-18 font-semibold color-black mb-4">
                                        Directive
                                    </h3>
                                    {!isDirectiveEditing && (
                                        <div>
                                            <button
                                                type="button"
                                                className="btn edit-btn custom-btn font-medium px-4"
                                                onClick={handleDirectiveEditClick}
                                            >
                                                Edit
                                            </button>
                                        </div>
                                    )}
                                </div>
                                {isDirectiveEditing ? (
                                    <TextEditor
                                        value={directive}
                                        onSave={(value: any) => onSave(value, "Directive")}
                                        onCancel={handleDirectiveEditClick}
                                        summaryType="Directive"
                                        verticalName={verticalName}
                                        showRegenerateButton={true}
                                    />
                                ) : (
                                    <div dangerouslySetInnerHTML={{ __html: directive }} />
                                )}

                                {/* Outcome Section */}
                                <div className="d-flex justify-content-between mt-4">
                                    <h3 className="font-18 font-semibold color-black mb-4">
                                        Outcome
                                    </h3>
                                    {!isOutcomeEditing && (
                                        <div>
                                            <button
                                                type="button"
                                                className="btn edit-btn custom-btn font-medium px-4"
                                                onClick={handleOutcomeEditClick}
                                            >
                                                Edit
                                            </button>
                                        </div>
                                    )}
                                </div>
                                {isOutcomeEditing ? (
                                    <TextEditor
                                        value={outcome}
                                        onSave={(value: any) => onSave(value, "Outcome")}
                                        onCancel={handleOutcomeEditClick}
                                        summaryType="Outcome"
                                        verticalName={verticalName}
                                        showRegenerateButton={true}
                                    />
                                ) : (
                                    <div dangerouslySetInnerHTML={{ __html: outcome }} />
                                )}
                            </>
                        )}
                        {verticalName === "MAP"? summaryName !== "Executive Summary"  &&
                         (
                        <div className="d-flex justify-content-between mt-4">
                            <h3 className="font-18 font-semibold color-black mb-4">
                                {summaryName}
                                {isMandatory && <span className="mandate"> *</span>}
                            </h3>
                            {isEditing ? (summaryName === "WAFR Final Report Walkthrough" || summaryName === "MAP Final Report Walkthrough") &&
                                !isUpload && (
                                    <div>
                                        <button
                                            type="button"
                                            className="btn btn-dark theme-outline-btn custom-btn font-medium px-4"
                                            onClick={() => {
                                                setIsUpload(true);
                                            }}
                                        >
                                            Reupload
                                        </button>
                                    </div>
                                ) : (
                                <div>
                                    <button
                                        type="button"
                                        className="btn edit-btn custom-btn font-medium px-4"
                                        onClick={() => {
                                            setIsEditing(!isEditing);
                                        }}
                                    >
                                        Edit
                                    </button>
                                </div>
                            )}
                        </div>):(
                        <div className="d-flex justify-content-between mt-4">
                            <h3 className="font-18 font-semibold color-black mb-4">
                                {summaryName}
                                {isMandatory && <span className="mandate"> *</span>}
                            </h3>
                            {isEditing ? (summaryName === "WAFR Final Report Walkthrough" || summaryName === "MAP Final Report Walkthrough") &&
                                !isUpload && (
                                    <div>
                                        <button
                                            type="button"
                                            className="btn btn-dark theme-outline-btn custom-btn font-medium px-4"
                                            onClick={() => {
                                                setIsUpload(true);
                                            }}
                                        >
                                            Reupload
                                        </button>
                                    </div>
                                ) : (
                                <div>
                                    <button
                                        type="button"
                                        className="btn edit-btn custom-btn font-medium px-4"
                                        onClick={() => {
                                            setIsEditing(!isEditing);
                                        }}
                                    >
                                        Edit
                                    </button>
                                </div>
                            )}
                        </div>)}
                        {verticalName === "VMware" &&
                            summaryName === "Recommendation Breakdown" ? (
                            <h3 className="font-18 font-semibold color-black mb-3">
                                {summaryTitle}
                            </h3>
                        ) : null}
                        {isEditing ? (
                            <TextEditor
                                value={value}
                                onSave={onSave}
                                onCancel={handleEditClick}
                                VMwareRecommendation={VMwareRecommendation}
                                summaryType={summaryName}
                                verticalName={verticalName}
                            />
                        ) : (
                            <div dangerouslySetInnerHTML={{ __html: value }} />
                        )}
                        {summaryName === "Executive Summary" && verticalName === "MDPW" && (
                            <>
                                <h3 className="font-18 font-semibold color-black mb-4 mt-2">
                                    Workshop Overview
                                </h3>
                                <p className="font-14 font-regular mb-4">
                                    The workshop comprised targeted sessions covered in a series
                                    of meetings, each aimed at addressing distinct facets of the
                                    modernization process:
                                </p>
                                {bindSessions()}
                                <button
                                    type="button"
                                    className="btn edit-btn px-4 font-medium btn-add-session mt-3"
                                    onClick={() => {
                                        setHideSessionForm(false);
                                    }}
                                >
                                    <img
                                        src="images/add-icon-blue.svg"
                                        alt="plus icon"
                                        className=""
                                    />{" "}
                                    Add Session
                                </button>
                                {hideSessionForm ? (
                                    <></>
                                ) : (
                                    <SessionForm
                                        postSession={postSession}
                                        setHideSessionForm={setHideSessionForm}
                                        sessionData={sessionData}
                                        showDeletePopup={showDeletePopup}
                                    />
                                )}
                            </>
                        )}

                        {showDeleteModal && (
                            <div
                                className="modal fade show"
                                id="delete"
                                tabIndex={-1}
                                aria-hidden="true"
                                data-bs-backdrop="static"
                                data-bs-keyboard="false"
                                style={{ display: "block" }}
                            >
                                <div className="modal-dialog modal-dialog-centered">
                                    <div className="modal-content p-3">
                                        <div className="modal-header pb-0 border-0">
                                            <button
                                                type="button"
                                                className="btn-close cursor-pointer"
                                                onClick={closeModal}
                                                aria-label="Close"
                                            ></button>
                                        </div>

                                        <div className="modal-body">
                                            <div className="w-100 text-center mb-3">
                                                <img src="images/delete-icon.svg" alt="delete" />
                                            </div>

                                            <p className="font-semibold font-24 text-center mb-2 red-400">
                                                Delete Confirmation
                                            </p>

                                            <p className="font-semibold font-16 text-center mb-5">
                                                Are you sure you want to delete?
                                            </p>

                                            <div className="d-grid gap-2 d-md-inline-flex justify-content-md-center mb-2 mb-md-0 w-100">
                                                <a
                                                    className="theme-link-btn font-14 font-semibold text-center order-md-first cursor-pointer px-4"
                                                    onClick={confirmDeletesession}
                                                >
                                                    Yes
                                                </a>

                                                <button
                                                    type="button"
                                                    className="btn btn-danger red-400 btn-lg px-4 text-white font-14 font-semibold"
                                                    onClick={closeModal}
                                                >
                                                    No
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                        {showDeleteModal && (
                            <div className="modal-backdrop fade show"></div>
                        )}
                        {showToasterLoading && (
                            <div className="position-fixed bg-white top-110px start-50 translate-middle w-md-100">
                                <div className="shadow-sm d-flex rounded-3 custom-toast p-3">
                                    <div className="toast-body d-flex align-items-center ">
                                        <div className="d-block me-3">
                                            <img
                                                src="images/loading.gif"
                                                alt="toast-success"
                                                style={{ height: "15px", width: "15px" }}
                                            />
                                        </div>
                                        <div className="d-block">
                                            <span className="font-medium font-16 color-grey">
                                                Saving...
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}

                        {showToasterPopup && (
                            <div className="position-fixed bg-white top-110px start-50 translate-middle w-md-100">
                                <div className="shadow-sm d-flex rounded-3 custom-toast p-3">
                                    <div className="toast-body d-flex align-items-center ">
                                        <div className="d-block me-3">
                                            <img src="images/toast-success.svg" alt="toast-success" />
                                        </div>
                                        <div className="d-block">
                                            <span className="font-medium font-16 color-grey">
                                            {verticalName === 'MAP' && summaryName === 'Executive Summary'
                                                ? 'Saved Successfully'
                                                : `${summaryName} have been generated successfully`}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                        {showSessionToasterPopup && (
                            <div className="position-fixed bg-white top-110px start-50 translate-middle w-md-100">
                                <div className="shadow-sm d-flex rounded-3 custom-toast p-3">
                                    <div className="toast-body d-flex align-items-center ">
                                        <div className="d-block me-3">
                                            <img src="images/toast-success.svg" alt="toast-success" />
                                        </div>
                                        <div className="d-block">
                                            <span className="font-medium font-16 color-grey">
                                                Session has been saved successfully
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}

                        {showToasterFailedPopup && (
                            <div className="position-fixed bg-white top-110px start-50 translate-middle w-md-100">
                                <div className="shadow-sm d-flex rounded-3 custom-toast p-3">
                                    <div className="toast-body d-flex align-items-center ">
                                        <div className="d-block me-3">
                                            <img src="images/fail-icon.svg" alt="toast-fail" />
                                        </div>
                                        <div className="d-block">
                                            <span className="font-medium font-16 color-grey">
                                                Api failed.
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}

                        {showDeletePopup && (
                            <div className="position-fixed bg-white top-110px start-50 translate-middle w-md-100">
                                <div className="shadow-sm d-flex rounded-3 custom-toast p-3">
                                    <div className="toast-body d-flex align-items-center ">
                                        <div className="d-block me-3">
                                            <img src="images/toast-success.svg" alt="toast-success" />
                                        </div>
                                        <div className="d-block">
                                            <span className="font-medium font-16 color-grey">
                                                Session has been deleted successfully.
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                )}
            </div>
        </div>
    );
};

export default ExecutiveSummary;
