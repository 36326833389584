/**
 * PS_RG_1.11 - PS_RG_1.12
 * Import necessary packages
 */
import { useEffect, useState } from "react";
import { migrationStrategyDataInterface, strategySortOrderInferface } from "../../interface/infraMapModel";

const PopupComponent = (props: any) => {

    /**
     * PS_RG_1.13 
     * state variables creation and create a function to bind the data on the grid
     */
    const data = props.data
    const [data1, setData1] = useState<migrationStrategyDataInterface[]>(props.data);
    const [currentImpact, setCurrentImpact] = useState<string>("");
    const [strategySortOrder, setStrategySortOrder] = useState<strategySortOrderInferface>({ name: false, summary: false })

    useEffect(() => {
        setData1(props.data);
      }, [props.data]);
      
    const sortMigrationStrategy = (columnName: string) => {
        let sortedMigrationStrategy: migrationStrategyDataInterface[] = [];
        //PC_MapInfraReportComp_44 - Sort the application name
        if (columnName === 'name') {
            let applicationNames: string[] = []
            data1.forEach((element: any) => {
                if (!applicationNames.includes(element.applicationStrategySummary))
                    applicationNames.push(element.applicationStrategySummary)
            }
            )
            applicationNames.sort()
            if (strategySortOrder.name) {
                applicationNames.reverse();
            }


            applicationNames.forEach((element: string) => {
                const foundItem: migrationStrategyDataInterface[] = data1.filter((migrationStrategy: any, index: number) => migrationStrategy.applicationStrategySummary === element);
                if (foundItem) {
                    sortedMigrationStrategy.push(...foundItem);
                }
            });
            setStrategySortOrder({ ...strategySortOrder, name: !strategySortOrder.name });
        }
        //PC_MapInfraReportComp_45 - Sort the application summary
        else {
            let applicationSummaries: string[] = []
            data1.forEach((element: any) => {
                if (!applicationSummaries.includes(element.applicationStrategySummary))
                    applicationSummaries.push(element.applicationStrategySummary)
            }
            )
            applicationSummaries.sort()
            if (strategySortOrder.summary) {
                applicationSummaries.reverse();
            }


            applicationSummaries.forEach((element: string) => {
                const foundItem: migrationStrategyDataInterface[] = data1.filter((migrationStrategy: any, index: number) => migrationStrategy.applicationStrategySummary === element);
                if (foundItem) {
                    sortedMigrationStrategy.push(...foundItem);
                }
            });
            setStrategySortOrder({ ...strategySortOrder, summary: !strategySortOrder.summary });
        }
        setData1(sortedMigrationStrategy);
    }
    /**
     * this function renders the data on the grid
     */
    const bindData = (data1: migrationStrategyDataInterface[]) => {
        if (data1
            .filter(
                (item: any) =>
                    item.applicationStrategySummary !== null &&
                    item.applicationStrategySummary !== undefined &&
                    item.applicationStrategySummary !== ""
            ).length == 0) {
            return (
                <tr style={{ textAlign: "center", color: "grey" }}>
                    <td colSpan={3} className="font-14 font-semibold pt-4 color-grey">
                        There are no migration strategy to display
                    </td>
                </tr>
            )
        }
        else {
            return data1
                .map((item: any, index: any) => (
                    <tr
                        style={{
                            borderBottom: "1px solid #E7E7E7",
                            wordWrap: "break-word",
                            whiteSpace: "normal",
                            overflowWrap: "break-word",
                            hyphens: "auto",
                            pageBreakAfter: "auto",
                            pageBreakInside: "avoid",
                            pageBreakBefore: "auto",
                        }}
                        key={index}
                    >
                        <td
                            style={{
                                width: "20%",
                                padding: "1%",
                                textAlign: "left",
                                verticalAlign: "top",
                                fontSize: "12px",
                                //fontFamily: '"SFProText-Medium"',
                                color: "#242424",
                                wordWrap: "break-word",
                                whiteSpace: "normal",
                                overflowWrap: "break-word",
                                hyphens: "auto",
                                pageBreakInside: "avoid",
                            }}
                        >
                            {item.applicationName}
                        </td>

                        <td
                            style={{
                                textAlign: "left",
                                width: "20%",
                                padding: "1%",
                                verticalAlign: "top",
                                fontSize: "12px",
                                //fontFamily: '"SFProText-Medium"',
                                color: "#242424",
                                wordWrap: "break-word",
                                whiteSpace: "normal",
                                overflowWrap: "break-word",
                                pageBreakInside: "avoid",
                            }}
                        >
                            {item.applicationStrategy}
                        </td>

                        <td
                            style={{
                                textAlign: "left",
                                width: "60%",
                                padding: "1%",
                                verticalAlign: "top",
                                fontSize: "12px",
                                //fontFamily: '"SFProText-Medium"',
                                wordWrap: "break-word",
                                whiteSpace: "normal",
                                overflowWrap: "break-word",
                                pageBreakInside: "avoid",
                            }}
                        >
                            {item.applicationStrategySummary}
                        </td>
                    </tr>
                ));
        }

    };







    /**
     * this filters the impact by All, high, medium and low
     */
    const filterDataByImpact = (selectedImpact: string) => {
        if (selectedImpact === "All") {
            setData1(data);
        } else {
            const filteredData = data.filter(
                (item: migrationStrategyDataInterface) => item.applicationStrategy === selectedImpact
            );
            setData1(filteredData);
        }
        setCurrentImpact(selectedImpact);
    };

    /**
     * PS_RG_1.25 - PS_RG_1.31
     * This returns the html to be rendered as popup
     */

    return (
        <div
            className="modal fade"
            id="recommendationsExapnMigrationStrategy"
            data-bs-backdrop="static"
            data-bs-keyboard="false"
            data-bs-target="recommendationsExapnMigrationStrategy"
            tabIndex={-1}
            aria-hidden="true"
        >
            <div className="modal-dialog modal-xl modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header pb-0 border-0 pt-4 px-4">
                        <h5 className="font-semibold font-18 font-semibold d-flex justify-content-between align-items-center mb-0">
                            Migration Strategy
                        </h5>
                        <div className="d-flex align-items-center">
                            <div className="d-flex gap-3 align-items-center me-2">
                                <label
                                    className="font-14 d-md-block d-none font-regular color-grey text-nowrap"
                                    htmlFor="rec-Impact"
                                >
                                    7R's Migration Strategy
                                </label>
                                <select
                                    value={currentImpact}
                                    onChange={(e) => filterDataByImpact(e.target.value)}
                                    className="shadow-none d-md-block d-none theme-form form-select font-regular color-black font-14 me-3"
                                    id="rec-Impact"
                                >
                                    <option selected>All</option>
                                    <option>Retain</option>
                                    <option>Replatform</option>
                                    <option>Rehost</option>
                                    <option>Repurchase</option>
                                    <option>Refactor</option>
                                    <option>Retire</option>
                                    <option>Re-Architect</option>
                                </select>
                            </div>
                            <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            />
                        </div>
                    </div>
                    <div className="modal-body p-4">

                        <div
                            className="table-responsive mb-4 slim-scroll"
                            style={{ height: 500 }}
                        >
                            <table className="table table-borderless themeTable mb-0">
                                <thead className="font-semibold sticky-top">
                                    <tr>

                                        <th style={{ width: "20%", padding: "1%" }}>
                                            Application Name
                                            <span
                                                className="ms-2 cursor-pointer"
                                                onClick={() => { sortMigrationStrategy("name") }}
                                            >
                                                {strategySortOrder.name ? " ↓" : " ↑"}
                                            </span>
                                        </th>
                                        <th style={{ width: "20%", padding: "1%" }}>Strategy</th>
                                        <th style={{ width: "60%", padding: "1%" }}>
                                            Summary
                                            {/* <span
                                                className="ms-2 cursor-pointer"
                                                onClick={() => { sortMigrationStrategy("summary") }}
                                            >
                                                {strategySortOrder.summary ? " ↓" : " ↑"}
                                            </span> */}
                                        </th>
                                    </tr>
                                </thead>
                                <tbody className="font-medium">
                                    {bindData(data1)}
                                </tbody>
                            </table>
                        </div>
                        <p className="font-12 font-regular color-grey-v3 mb-0">
                            No of Rec :{" "}
                            <span className="color-black">
                                {data1.length}
                                {
                                    //Show the record count
                                }
                            </span>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PopupComponent;
