const DeletePopup = (props: any) => {
    return (
        <div className="modal fade show" style={{display:"block"}}>
            <div className="modal-dialog modal-dialog-centered" >

                <div className="modal-content p-3">

                    <div className="modal-header pb-0 border-0">

                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => { props.setHideDeletePopup(true) }}></button>

                    </div>

                    <div className="modal-body">

                        <div className="w-100 text-center mb-3"><img src="images/delete-icon.svg" alt="delete" /></div>

                        <p className="font-semibold font-24 text-center mb-2 red-400">{props.popupHeading}</p>

                        <p className="font-semibold font-16 text-center mb-5">{props.popupMessage}</p>

                        <div className="d-grid gap-2 d-md-inline-flex justify-content-md-center mb-2 mb-md-0 w-100">

                            <a className="theme-link-btn font-14 font-semibold text-center order-md-first cursor-pointer px-4"
                                data-bs-dismiss="modal" aria-label="Close" onClick={() => {
                                    props.setHideDeletePopup(true)
                                    props.deleteCall()
                                }}>Yes</a>

                            <button type="button"
                                className="btn btn-danger red-400 btn-lg px-4  text-white font-14 font-semibold "
                                data-bs-dismiss="modal" aria-label="Close" onClick={() => {
                                    props.setHideDeletePopup(true)
                                }}>No</button>

                        </div>

                    </div>

                </div>

            </div>

        </div>


    )

}

export default DeletePopup;