import React, { useEffect, useState } from "react";

const ThreeReportTableComponent: React.FC<any> = ({
  dataForGrid,
  areas,
  overall,
}) => {
  const [data, setData] = useState<any>(dataForGrid);

  const bindData = (data: any) => {
    if (
      data.filter(
        (item: any) =>
          item.description !== null &&
          item.description !== undefined &&
          item.description !== ""
      ).length == 0
    ) {
      return (
          <tr style={{ textAlign: "center", color: "grey" }}>
            <td
              colSpan={3}
              className="font-14 font-semibold pt-4 color-grey spacingForNoRec"
            >
              There are no recommendations to display
            </td>
          </tr>
      );
    }
    if (overall) {
      return data
        ?.filter(
          (item: any) =>
            item.description !== null &&
            item.description !== undefined &&
            item.description !== ""
        )
        .slice(0, 1)
        .map((item: any, index: number) => (
          <>
          <tr style={{ borderBottom: "1px solid #E7E7E7" }} key={index}>
            <td
              style={{
                width: "38%",
                padding: "1%",
                textAlign: "left",
                verticalAlign: "top",
                fontSize: 14,
                fontFamily: '"SFProText-Medium"',
                color: "#242424",
                lineHeight: "1.5",
              }}
            >
              {item.description}
            </td>
            {areas ? (
              <td
                style={{
                  width: "38%",
                  padding: "1%",
                  textAlign: "left",
                  verticalAlign: "top",
                  fontSize: 14,
                  fontFamily: '"SFProText-Medium"',
                  color: "#242424",
                  lineHeight: "1.5",
                }}
              >
                {item.impactResources}
              </td>
            ) : (
              <td
                style={{
                  width: "38%",
                  padding: "1%",
                  textAlign: "left",
                  verticalAlign: "top",
                  fontSize: 14,
                  fontFamily: '"SFProText-Medium"',
                  color: "#242424",
                  lineHeight: "1.5",
                }}
              >
                <ul style={{ listStyle: "none", padding: 0 }}>
                  <li>
                    <span style={{ marginRight: 10 }}>{item.controlAreas}</span>
                  </li>
                </ul>
              </td>
            )}
            <td
              style={{
                textAlign: "left",
                width: "18%",
                padding: "1%",
                verticalAlign: "top",
                fontSize: 14,
                fontFamily: '"SFProText-Medium"',
                lineHeight: "1.5",
              }}
            >
              {item.impact === "High" ? (
                <span
                  style={{
                    backgroundColor: "#FFECEC",
                    borderRadius: 15,
                    padding: "6px 16px",
                    color: "#F88A7F",
                    fontFamily: '"SFProText-Medium"',
                    fontSize: 14,
                  }}
                >
                  <span
                    style={{
                      display: "inline-block",
                      width: 10,
                      height: 10,
                      backgroundColor: "#FFA79D",
                      borderRadius: 10,
                      marginRight: 12,
                    }}
                  ></span>
                  <label>{item.impact}</label>
                </span>
              ) : item.impact === "Medium" ? (
                <span
                  style={{
                    backgroundColor: "#FFF7E2",
                    borderRadius: 15,
                    padding: "6px 16px",
                    color: "#FBC235",
                    fontFamily: '"SFProText-Medium"',
                    fontSize: 14,
                  }}
                >
                  <span
                    style={{
                      display: "inline-block",
                      width: 10,
                      height: 10,
                      backgroundColor: "#FBC235",
                      borderRadius: 10,
                      marginRight: 12,
                    }}
                  ></span>
                  <label>{item.impact}</label>
                </span>
              ) : (
                <span
                  style={{
                    backgroundColor: "#EDFFE9",
                    borderRadius: 15,
                    padding: "6px 16px",
                    color: "#67BA54",
                    fontFamily: '"SFProText-Medium"',
                    fontSize: 14,
                  }}
                >
                  <span
                    style={{
                      display: "inline-block",
                      width: 10,
                      height: 10,
                      backgroundColor: "#67BA54",
                      borderRadius: 10,
                      marginRight: 12,
                    }}
                  ></span>
                  <label>{item.impact}</label>
                </span>
              )}
            </td>
          </tr>
          <tr style={{ color: "grey" }}>
            <td
              colSpan={3}
              className="font-14 font-semibold pt-4 color-grey spacingForNoRec"
            >
              Note: The rest of the recommendations in this control area will be displayed in the overall recommendations.
            </td>
          </tr>
          </>
        ));
    } else {
      return data
        ?.filter(
          (item: any) =>
            item.description !== null &&
            item.description !== undefined &&
            item.description !== ""
        )
        .slice(0, 1)
        .map((item: any, index: number) => (
          <>
          <tr style={{ borderBottom: "1px solid #E7E7E7" }} key={index}>
            <td
              style={{
                width: "38%",
                padding: "1%",
                textAlign: "left",
                verticalAlign: "top",
                fontSize: 14,
                fontFamily: '"SFProText-Medium"',
                color: "#242424",
                lineHeight: "1.5",
              }}
            >
              {item.description}
            </td>
            {areas ? (
              <td
                style={{
                  width: "38%",
                  padding: "1%",
                  textAlign: "left",
                  verticalAlign: "top",
                  fontSize: 14,
                  fontFamily: '"SFProText-Medium"',
                  color: "#242424",
                  lineHeight: "1.5",
                }}
              >
                {item.impactResources}
              </td>
            ) : (
              <td
                style={{
                  width: "38%",
                  padding: "1%",
                  textAlign: "left",
                  verticalAlign: "top",
                  fontSize: 14,
                  fontFamily: '"SFProText-Medium"',
                  color: "#242424",
                  lineHeight: "1.5",
                }}
              >
                <ul style={{ listStyle: "none", padding: 0 }}>
                  <li>
                    {item.impactResources}
                  </li>
                </ul>
              </td>
            )}
            <td
              style={{
                textAlign: "left",
                width: "18%",
                padding: "1%",
                verticalAlign: "top",
                fontSize: 14,
                fontFamily: '"SFProText-Medium"',
                lineHeight: "1.5",
              }}
            >
              {item.impact === "High" ? (
                <span
                  style={{
                    backgroundColor: "#FFECEC",
                    borderRadius: 15,
                    padding: "6px 16px",
                    color: "#F88A7F",
                    fontFamily: '"SFProText-Medium"',
                    fontSize: 14,
                  }}
                >
                  <span
                    style={{
                      display: "inline-block",
                      width: 10,
                      height: 10,
                      backgroundColor: "#FFA79D",
                      borderRadius: 10,
                      marginRight: 12,
                    }}
                  ></span>
                  <label>{item.impact}</label>
                </span>
              ) : item.impact === "Medium" ? (
                <span
                  style={{
                    backgroundColor: "#FFF7E2",
                    borderRadius: 15,
                    padding: "6px 16px",
                    color: "#FBC235",
                    fontFamily: '"SFProText-Medium"',
                    fontSize: 14,
                  }}
                >
                  <span
                    style={{
                      display: "inline-block",
                      width: 10,
                      height: 10,
                      backgroundColor: "#FBC235",
                      borderRadius: 10,
                      marginRight: 12,
                    }}
                  ></span>
                  <label>{item.impact}</label>
                </span>
              ) : (
                <span
                  style={{
                    backgroundColor: "#EDFFE9",
                    borderRadius: 15,
                    padding: "6px 16px",
                    color: "#67BA54",
                    fontFamily: '"SFProText-Medium"',
                    fontSize: 14,
                  }}
                >
                  <span
                    style={{
                      display: "inline-block",
                      width: 10,
                      height: 10,
                      backgroundColor: "#67BA54",
                      borderRadius: 10,
                      marginRight: 12,
                    }}
                  ></span>
                  <label>{item.impact}</label>
                </span>
              )}
            </td>
          </tr>
          <tr style={{ color: "grey" }}>
            <td
              colSpan={3}
              className="font-14 font-semibold pt-4 color-grey spacingForNoRec"
            >
              Note: The rest of the recommendations in this control area will be displayed in the overall recommendations.
            </td>
          </tr>
          </>
        ));
    }
  };

  return (
    <div style={{ width: "100%", float: "left" }}>
      <h2
        style={{
          fontFamily: "SFProText-Medium",
          color: "#000000",
          fontWeight: 500,
          fontSize: 17,
          marginBottom: 16,
        }}
      >
        Top Recommendation
      </h2>
      <table
        style={{
          borderCollapse: "collapse",
          width: "100%",
          marginTop: "2%",
        }}
      >
        <thead style={{ backgroundColor: "#F0F0F0" }}>
          <tr style={{ borderBottom: "1px solid #E7E7E7" }}>
            <th
              style={{
                textAlign: "left",
                color: "#454545",
                fontSize: 14,
                fontFamily: '"SFProText-Medium"',
                width: "45%",
                padding: "1%",
              }}
            >
              Description
            </th>
            <th
              style={{
                textAlign: "left",
                color: "#454545",
                fontSize: 14,
                fontFamily: '"SFProText-Medium"',
                width: "18%",
                padding: "1%",
              }}
            >
              {overall ? "control Area"  : "Impacted Area" }
            </th>
            <th
              style={{
                textAlign: "left",
                color: "#454545",
                fontSize: 14,
                fontFamily: '"SFProText-Medium"',
                width: "18%",
                padding: "1%",
              }}
            >
              Impact
            </th>
          </tr>
        </thead>
        <tbody>{bindData(data)}</tbody>
      </table>
    </div>
  );
};

export default ThreeReportTableComponent;
