import React from 'react';
import { PieChart, Pie, Cell, PieProps } from 'recharts';
import { DataItem, GaugeChartProps } from '../../interface/GaugeChartModel';

const RADIAN = Math.PI / 180;
const cx = 200;
const cy = 130;
const iR = 100;
const oR = 130;

const needle = (
  value: number,
  data: DataItem[],
  cx: number,
  cy: number,
  iR: number,
  oR: number,
  color: string
) => {
  let total = 0;
  data.forEach((entry) => {
    total += entry.value;
  });

  const ang = 180.0 * (1 - value / total);
  const length = (iR + 2 * oR) / 3.2;
  const sin = Math.sin(-RADIAN * ang);
  const cos = Math.cos(-RADIAN * ang);
  const r = 5;
  const x0 = cx + 5;
  const y0 = cy + 5;
  const xba = x0 + r * sin;
  const yba = y0 - r * cos;
  const xbb = x0 - r * sin;
  const ybb = y0 + r * cos;
  const xp = x0 + length * cos;
  const yp = y0 + length * sin;

  return [
    <circle key="circle" cx={x0} cy={y0} r={r} fill={color} stroke="none" />,
    <path key="path" d={`M${xba} ${yba}L${xbb} ${ybb} L${xp} ${yp} L${xba} ${yba}`} fill={color} />,
  ];
};

const ReportGaugeChart: React.FC<any> = (props) => {


  return (
    <PieChart width={400} height={240}>
      <Pie
        dataKey="value"
        startAngle={180}
        endAngle={0}
        data={props.dataValue != 0 ? props.dataValue : 0}
        cx={cx}
        cy={cy}
        innerRadius={iR}
        outerRadius={oR}
        fill="#8884d8"
        stroke="none"
        isAnimationActive={false}
      >
        {props.dataValue.map((entry:any, index:any) => (
          <Cell key={`cell-${index}`} fill={entry.color} />
        ))}
      </Pie>
      {needle(props.Gaugeval, props.dataValue, cx, cy, iR, oR, 'black')}
    </PieChart>
  );
};

export default ReportGaugeChart;
