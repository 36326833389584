import { BlobServiceClient, ContainerClient } from "@azure/storage-blob";
import { getSasToken } from "../service/ReportApi";
import moment from 'moment';
import { v4 as uuid } from 'uuid'
import { getConnectorsData } from "./encryption";
import { encryptStorage } from "../constant/constant";
import { jwtDecode } from "jwt-decode";

const createBlobInContainer = async (containerClient: any, file: any, path: string) => {


  let instance = moment().format("DDHHMMmmYYYYss");

  instance += uuid() + "-iiiii-";

  let defaultPath = '';

  if (!path) {

    path = defaultPath;

  }

  let pdfHtmlPath = "pdfHtmls"

  // create blobClient for container

  const blobClient = containerClient.getBlockBlobClient(file?.name ? `${path}/` + instance + file.name : `${pdfHtmlPath}/` + "input.html");


  // const blobClient = containerClient.getBlockBlobClient(file?.name ? file.name : "input.html");

  const options = { blobHTTPHeaders: { blobContentType: file.type } };

  let res = await blobClient.uploadBrowserData(file, options);

  if (res._response.status == 201) {
    return res._response.request.url.split("?")[0];
  }
};

let value = encryptStorage.getItem("jwt")
let parsedJtk: any = ""
let storageAccountName: any;
let containerName: any;
if (value) {
  parsedJtk = jwtDecode(value)
  storageAccountName = getConnectorsData({ key: parsedJtk.ek, encryptedMessage: process.env.REACT_APP_BLOB_ACCOUNT_NAME! });
  containerName = getConnectorsData({ key: parsedJtk.ek, encryptedMessage: process.env.REACT_APP_BLOB_CONTAINER! })
}

const uploadFileToBlob1 = async (file: any, path: string = "") => {
  let value = encryptStorage.getItem("jwt")
  let parsedJtk: any = ""
  let storageAccountName: any;
  let containerName: any;
  if (value) {
    parsedJtk = jwtDecode(value)
    storageAccountName = getConnectorsData({ key: parsedJtk.ek, encryptedMessage: process.env.REACT_APP_BLOB_ACCOUNT_NAME! });
    containerName = getConnectorsData({ key: parsedJtk.ek, encryptedMessage: process.env.REACT_APP_BLOB_CONTAINER! })
  }
  if (!file) return [];

  let sasToken = await getSasToken();
  let sas = sasToken.data;
  const blobService = new BlobServiceClient(
    `https://${storageAccountName}.blob.core.windows.net/?${sas}`
  );

  // get Container - full public read access
  const containerClient = blobService.getContainerClient(containerName!);

  let loc = await createBlobInContainer(containerClient, file, path);
  return loc

};

export default uploadFileToBlob1;
