import React, { SVGProps, useEffect, useState } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  LabelList,
  Tooltip,
  ResponsiveContainer
} from "recharts";


type CustomYAxisTickProps = {
  x: number;
  y: number;
  payload: {
    value: string; // This is the truncated name
  };
  fullServerName: string; // Add this prop for the full server name
};

const CustomYAxisTick = ({ x, y, payload, fullServerName }: CustomYAxisTickProps) => {
  return (
    <g transform={`translate(${x},${y})`}>
      <text
        x={0}
        y={0}
        dy={16}
        textAnchor="end"
        fill="#666"
        fontSize={10}
        fontWeight="bold"
      >
        {payload.value}
        <title>{fullServerName}</title> {/* Use fullServerName for the tooltip */}
      </text>
    </g>
  );
};

const ReportMapHorizontalBarChart : React.FC<any> = (props: any) => {

  const data = props?.barChartData?.map((element: any, index: number) => {
    return {
      id: index, // Add an identifier
      fullServerName: element.serverName,
      name: element.serverName.length > 4 ? element.serverName.slice(0, 3) + "..." : element.serverName,
      value: element.utilization,
    };
  });


  return (
    <ResponsiveContainer width="70%" height={370}>
      <BarChart width={500} height={300} data={data} layout="vertical">
        <XAxis type="number" dataKey="value" tickLine={false} axisLine={{ stroke: '#bbb', strokeWidth: 0.5 }} label={{ value: props.xAxisLabel, position: 'insideBottom', offset: -2, style: { fontSize: 14, fontWeight: 'bold', fill: '#222', fontFamily: "SFProText-Medium" }, }} style={{ fontSize: 11, fontWeight: 'bold', fill: '#777', fontFamily: "SFProText-Medium" }} />
        <YAxis type="category" dataKey="name"  tickLine={false} axisLine={{ stroke: '#bbb', strokeWidth: 0.5 }} label={{ value: 'Server Name', position: 'outsideTop', dx: -29, style: { fontSize: 14, fontWeight: 'bold', fill: '#222', fontFamily: "SFProText-Medium" }, angle: -90 }} style={{ fontSize: 10, fontWeight: 'bold', fill: '#777', fontFamily: "SFProText-Medium" }}
          tick={(tickProps) => (
            <CustomYAxisTick
              {...tickProps}
              fullServerName={data.find((d: { id: any; }) => d.id === tickProps.index)?.fullServerName || ''}
              
            />
          )} />
        <Bar dataKey="value" fill="#8caaff" barSize={30} >
          <LabelList dataKey="value" position="right" />
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  );
};

export default ReportMapHorizontalBarChart ;
