export default function ErrorPage(){


return(
<div>
  <div className="vh-100 w-100 d-flex align-items-center justify-content-center">
    <img src="images/404error.svg" />
  </div>
</div>

)
}