import CryptoJS from "crypto-js";
import { EncryptOptions, DecryptOptions } from "../interface/EncryptionModel";
import { encryptStorage } from "../constant/constant";
import { jwtDecode } from "jwt-decode";

export function getAdminData({ key, data }: any): string {
  const keyWordArray = CryptoJS.enc.Utf8.parse(key);
  const iv = CryptoJS.lib.WordArray.random(16);
  const plaintext = JSON.stringify(data);
  const encrypted = CryptoJS.AES.encrypt(plaintext, keyWordArray, {
    iv: iv,
    mode: CryptoJS.mode.CFB,
    padding: CryptoJS.pad.NoPadding,
  });
  const encryptedMessage = iv
    .concat(encrypted.ciphertext)
    .toString(CryptoJS.enc.Base64);
  return encryptedMessage;
}

export function getConnectorsData({ key, encryptedMessage }: DecryptOptions): string {
  const keyWordArray = CryptoJS.enc.Utf8.parse(key);
  const encryptedMessageWordArray = CryptoJS.enc.Base64.parse(encryptedMessage);
  const iv = encryptedMessageWordArray.clone();
  iv.sigBytes = 16;
  encryptedMessageWordArray.words.splice(0, 4);
  encryptedMessageWordArray.sigBytes -= 16;

  const cipherParams = CryptoJS.lib.CipherParams.create({
    ciphertext: encryptedMessageWordArray,
    key: keyWordArray,
  });

  const decrypted = CryptoJS.AES.decrypt(cipherParams, keyWordArray, {
    iv: iv,
    mode: CryptoJS.mode.CFB,
    padding: CryptoJS.pad.NoPadding,
  });

  const decryptedPlaintext = decrypted.toString(CryptoJS.enc.Utf8);
  const decryptedObject = JSON.parse(decryptedPlaintext);
  return decryptedObject;
}

export function getAssessmentData(i:any) {
  let s:any=[]
  let p:any=[]
  i.split("").forEach((c:any,n:any)=>{
      if(i.charCodeAt(n)<=99){
          p.push(n)
          s.push(i.charCodeAt(n).toString()+0)
      }
      else s.push(i.charCodeAt(n).toString())
  })
    const es = (btoa(s.concat('') + p)+btoa(s.length)+(btoa(s.length).length))
    return es.toString();
  }
  
  export function getReportData(es:any){
      let pi=[]
      let rs=""
      let al:any= ""
      for(let i=es.length-1;i>=0;i--){
         if(!isNaN(es.split("")[i])){
          al+=es.split("")[i]
         }
         else{
             break
         }
      }
      al=al.split('').sort().join('')
      let pl:any
      pl=es.slice(((es.length-1)-al),es.length)
      let dp=""
      for(let i=0;i<pl.length;i++){
          if(isNaN(pl.split("")[i])){
            dp+=pl.split("")[i]
          }
          else{
              break;
          }
      }
      dp=atob(dp)
      es=es.split(pl)[0]
      es=atob(es).split(',')
      let ea=es.slice(0,dp)
      pi =es.slice(dp,es.length)
      for(let i=0;i<ea.length;i++){
          if(ea[i].length>3){
            ea[i]=ea[i].split('').slice(0,3).join('')
          }
      }
      pi.forEach((e:any)=>{
        ea[e]=ea[e]?.slice(0,2)
    })
    ea.forEach((e:any)=>{
        rs+=String.fromCharCode(e)
    })
    return rs;  
  }
  
  