/**PS_AF_05-PS_AF_08
     * This function is used to read the excel file uploaded by the user, validate the file and form the required JSON struct
     * event is passed as the parameter
     */
import {apiCall} from '../client/apiCall';
import { baseUrl, AdminEndpoints, assesmentEndpoints } from '../constant/constant';



/**PS_AN_03
     * This API is used to get the navbar data from the backend
     */
export const getNavbar = async () => {
  try {
    const response = await apiCall(baseUrl+AdminEndpoints.getNavbar, "GET");
    return response
  } catch (error) {
    console.error(error);
  }
}

/**PS_AN_06-PS_AN_07
     * This API is used to post the question to the backend
     * mainArr is the question array which is passed as the parameter
     */
export const postQuestion = async (mainArr: any) => {
  try {
    const response = await apiCall(baseUrl+AdminEndpoints.postQuestions, "POST", mainArr);
    return response
  } catch (error) {
    console.error(error);
  }
}

/**PS_AA_02-PS_AA_04
     * This API is used to get the questions from the backend
     * currentMenu is the state variable which contains the selected controlArea, controlAreaSubMenu is passed as the parameter
     */
export async function getQuestions(currentMenu:any) {
    const response = await apiCall(baseUrl+AdminEndpoints.getQuestions, 'POST',currentMenu);
    return response;
}

/**PS_AA_05-PS_AA_07
     * This API is used to update the question
     * editArr is the array which contains the edited questions is passed as the parameter
     */
export async function updateQuestions(editArr :any) {
    const response = await apiCall(baseUrl+AdminEndpoints.updateQuestions, "POST",editArr);
    return response;
}

/**PS_AA_08-PS_AA_10
     * This API is used to delete the selected question
     * deleteId is the object which contains the id of the question is passed as the parameter
     */
export async function deleteQuestions(deleteId:any) {
    const response = await apiCall(baseUrl+AdminEndpoints.deleteQuestions,'POST',deleteId);
    return response;
}


