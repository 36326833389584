import axios from "axios";
import { encryptStorage } from "../constant/constant";
import { getConnectorsData, getAdminData } from '../helpers/encryption';
import { jwtDecode } from "jwt-decode";

export const apiCall = async (url: string, method: string, data: any = null, callTo: string = "Dev") => {
 
  let value: any = ""
  if (url.split("/")[3] == "auth") {
    value = encryptStorage.getItem("access-token")
  }
  else {
    value = encryptStorage.getItem("jwt")
  }


  let parsedJtk: any = jwtDecode(value)
  let connectionConfig : any
  if(callTo ==="Dev"){
   connectionConfig = {
    method,
    url,
    data: {
      data: getAdminData({ key: url.split("/")[3] == "auth" ? parsedJtk?.appid.substring(0, 32) : parsedJtk?.ek, data: JSON.parse(JSON.stringify(data)) })
    },
    headers: {
      'Content-Type': 'application/json',
      //'Username': encryptStorage.getItem('userDetails')?.userName?encryptStorage.getItem('userDetails').userName:"",
      'Authorization': `Bearer ${encryptStorage.getItem("jwt")}`
    }
  };
}
  else{
    connectionConfig = {
      method,
      url,
      data: data,
      headers: {
        'Content-Type': 'application/json',
      }
    };
  }


  try {
    let apiResponse = await axios(connectionConfig);
    const response = callTo === "Dev"?getConnectorsData({ key: url.split("/")[3] == "auth" ? parsedJtk?.appid.substring(0, 32) : parsedJtk?.ek, encryptedMessage: apiResponse?.data }): apiResponse?.data ;
    apiResponse.data = response;
    return apiResponse;

  } catch (error: any) {
    console.error("API call failed:", error, getConnectorsData({ key: url.split("/")[3] == "auth" ? parsedJtk?.appid.substring(0, 32) : parsedJtk?.ek, encryptedMessage: error.response?.data }));
    throw error;
  }
};

export const apiCall2 = (url: string, method: string, data: any = null, type:string ="") => {
  let value: any = ""
  if (url.split("/")[3] == "auth") {
    value = encryptStorage.getItem("access-token")
  }
  else {
    value = encryptStorage.getItem("jwt")
  }
  let parsedJtk: any = jwtDecode(value)
  let connectionConfig = {
    method,
    url,
    data: type=== "encrpty" ? data  : 
    {
      data: getAdminData({ key: url.split("/")[3] == "auth" ? parsedJtk?.appid.substring(0, 32) : parsedJtk?.ek, data: JSON.parse(JSON.stringify(data)) })
    },
    headers: {
      'Content-Type': 'application/json',
      //'Username': encryptStorage.getItem('userDetails')?.userName?encryptStorage.getItem('userDetails').userName:"",
      'Authorization': `Bearer ${encryptStorage.getItem("jwt")}`
    }
  };

  return axios(connectionConfig)
  .then(response => {
    return response.data;
  })
  .catch(error => {
    console.error("API call failed:", error);
    if (error.response?.data) {
      console.error("Decrypted error data:", getConnectorsData({ key: url.split("/")[3] == "auth" ? parsedJtk?.appid.substring(0, 32) : parsedJtk?.ek, encryptedMessage: error.response.data }));
    }

    return Promise.reject({
      message: "Auto questionnaire failed, but connector was added successfully.",
      originalError: error
    });
  });
};