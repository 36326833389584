import react, { useState, useEffect, useContext } from "react"
import { employeeRangeModel, industryModel, organizationDataModel, organizationFilterDataModel, sortOrderModel } from "../interface/OrganizationGridModel"
import { toastModel } from "../interface/InitiativeModel"
import { deleteOrganization, getEmployeeRange, getIndustry, getOrganizationData, postOrganization } from "../service/AssesmentApi"
import OrganizationForm from "./OrganizationForm"
import Loader from "./Loader"
import { getSasToken } from "../service/ReportApi"
import { UserDetails } from "../constant/constant"
import ToastComponent from "./ToastComponent"
import { useNavigate } from "react-router-dom"
import DeletePopup from "./DeletePopup"
import { toastStateModel } from "../interface/AssessmentGridModel"

const OrganizationGrid = (props: any) => {


    //Initial filter data
    const initialOrganizationData: organizationDataModel = {
        organizationId: "",
        organizationName: "",
        organizationLogo: "",
        websiteUrl: "",
        industryTypeId: "",
        industryName: "",
        industryType: "",
        employeeRangeId: "",
        numberOfEmployees: "",
        revenue: "",
    }
    const initialFilterData: organizationFilterDataModel = {
        search: "",
        industryTypeId: "",
        employeeRangeId: "",
        revenue: "",
    }
    const initialSortData: sortOrderModel = {
        organizationName: "asc",
        industryType: "asc",
        revenue: "asc",
        numberOfEmployees: "asc",
    }
    const initialToastState: toastStateModel = {
        hideToast: true,
        type: "error",
        message: "Api Fail"
    }

    //Initialize the state varibales
    const [organizationData, setOrgainzationData] = useState<organizationDataModel[]>([])
    const [filterData, setFilterData] = useState<organizationFilterDataModel>(initialFilterData)
    const [filterParams, setFilterParams] = useState<organizationFilterDataModel>(initialFilterData)
    const [industry, setIndustry] = useState<industryModel[]>([])
    const [employeeRange, setEmployeeRange] = useState<employeeRangeModel[]>([])
    const [editedOrganization, setEditedOrganization] = useState<organizationDataModel>(initialOrganizationData)
    const [sortOrder, setSortOrder] = useState<sortOrderModel>(initialSortData)
    const [sasToken, setSasToken] = useState<string>('')
    const [deleteId, setDeleteId] = useState<string>("")
    const [hideDeletePopup, setHideDeletePopup] = useState<boolean>(true)
    const [hideFilter, setHideFilter] = useState<boolean>(true)
    const [hideForm, setHideForm] = useState<boolean>(true)
    const [toastState, setToastState] = useState<toastStateModel>(initialToastState)
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const navigate = useNavigate()

    //useEffect to fetch organization details
    useEffect(() => {
        fetchOrganizationData()
    }, [filterParams])

    //Function to fecth industry, employee range and organization data
    const fetchOrganizationData = async () => {
        try {
            setIsLoading(true)
            //Api call to fetch industry type
            const industryResponse = await getIndustry()
            if (industryResponse.status === 200) {
                setIndustry(industryResponse.data)
            }
            else {
                setIsLoading(false)
                setToastState({ hideToast: false, type: 'error', message: 'Api Failed' })
            }
            const rangeResponse = await getEmployeeRange()
            if (rangeResponse.status === 200) {
                setEmployeeRange(rangeResponse.data)
            }
            else {
                setIsLoading(false)
                setToastState({ hideToast: false, type: 'error', message: 'Api Failed' })
            }
            const orgResponse = await getOrganizationData(filterParams)

            if (orgResponse.status === 200) {            
                setOrgainzationData(orgResponse.data)
                const sas = await getSasToken('r')
                setSasToken(sas?.data)
                setIsLoading(false)
            }
            else {
                setIsLoading(false)
                setToastState({ hideToast: false, type: 'error', message: 'Api Failed' })
            }

        }
        catch (error) {
            setIsLoading(false)
            setToastState({ hideToast: false, type: 'error', message: 'Api Failed' })
        }
    }
    //Function to post organization
    const addOrganization = async (data: organizationDataModel) => {
        setIsLoading(true)
        setHideForm(true)
        try {
            const response = await postOrganization(data)
            if (response.status === 200) {
                await fetchOrganizationData()
                if (data?.organizationId)
                    setToastState({ hideToast: false, type: 'success', message: 'Organization has been edited successfully' })
                else
                    setToastState({ hideToast: false, type: 'success', message: 'Organization has been added successfully' })
                showToast()
            }
            else {
                setIsLoading(false)
                setToastState({ hideToast: false, type: 'error', message: 'Api Failed' })
            }
        }
        catch (error) {
            setIsLoading(false)
            setToastState({ hideToast: false, type: 'error', message: 'Api Failed' })
        }
    }

    //Function to delete the organization
    const deleteOrganizationData = async () => {
        try {
            setIsLoading(true)
            const response = await deleteOrganization({ "organizationId": deleteId });
            if (response.status === 200) {
                await fetchOrganizationData()
                setToastState({ hideToast: false, type: 'success', message: 'Organization has been deleted successfully' })

                showToast()
            }
            else {
                setIsLoading(false)
                setToastState({ hideToast: false, type: 'error', message: 'Api Failed' })
            }
        }
        catch (error) {
            setIsLoading(false)
            setToastState({ hideToast: false, type: 'error', message: 'Api Failed' })
        }
    }

    //Function to show tiast popup
    const showToast = () => {
        setTimeout(() => {
            setToastState({ hideToast: true, type: 'error', message: 'Api Failed' })
        }, 4000)
    }

    //Function to handle the onChange event on filter data
    const onChangeFilter = (id: string, value: string) => {
        setFilterData({ ...filterData, [id]: value })
        if (id === "search" && value.length === 0)
            setFilterParams({ ...filterParams, "search": value.trim() })
    }

    //Function to handle the sort
    const onClickSort = (columnName: string) => {
        const sortId: keyof sortOrderModel = columnName as keyof sortOrderModel
        const id: keyof organizationDataModel = columnName as keyof organizationDataModel
        const sortedData = JSON.parse(JSON.stringify(organizationData))

        sortedData?.sort((a: organizationDataModel, b: organizationDataModel) => {
            const order = sortOrder[sortId] === "asc" ? 1 : -1

            // Check if the values are numeric
            if (!isNaN(Number(a[id])) && !isNaN(Number(b[id]))) {
                // Sort numerically
                return order * (Number(a[id]) - Number(b[id]))
            } else {
                // Sort alphabetically
                return order * String(a[id]).localeCompare(String(b[id]))
            }
        })
        setOrgainzationData(sortedData)
    }

    //Function to bind the industry
    const bindIndustry = () => {
        return (
            industry?.map((data: industryModel) => {
                return (
                    <option value={data.industryTypeId} key={data.industryTypeId}>{data.industryName}</option>
                )
            })
        )
    }

    //Function to bind the employee range
    const bindEmployeeRange = () => {
        return (
            employeeRange?.map((data: employeeRangeModel) => {
                return (
                    <option value={data.employeeRangeId} key={data.employeeRangeId}>{data.employeeRange}</option>
                )
            })
        )
    }

    //Function to bind the grid data
    const bindOrganizationData = () => {
        if (!organizationData || organizationData?.length === 0) {
            return (
                <tr>
                    <td colSpan={5}>
                        <div className="row text-center">
                            <img src="images/no-records-found.svg" width={"300"} height={"300"} />
                            <p className="font-16 font-medium color-grey-v3 my-5">
                                No Records Found
                            </p>
                        </div>
                    </td>

                </tr>

            )
        }
        return (
            organizationData?.map((data: organizationDataModel, index: number) => {

                return (
                    <tr key={data.organizationId}>
                        <td>
                            <span className="d-flex align-items-center text-decoration-underline">
                                <span className="me-2">
                                    <img src={`${data.organizationLogo}?${sasToken}`} alt={data.organizationName} className="organization-img" />
                                </span>
                                <a href="#" className="link-blue" onClick={(event: any) => {
                                    event.preventDefault();
                                    navigate("/manageassessment", { state: { organizationId: data.organizationId, organizationName: data.organizationName } })
                                }}>{data.organizationName}</a></span>
                        </td>
                        <td>{data.industryName ? data.industryName:data.industryType}</td>
                        <td>{data.numberOfEmployees}</td>
                        <td className="text-end pe-5">{"$" + `${parseFloat(data.revenue)}M`}</td>
                        <td className="text-center">
                            <span className="d-flex justify-content-center gap-1">
                                <button className="btn action-btn d-flex align-items-center justify-content-center" onClick={() => {
                                    setEditedOrganization(data)
                                    setHideForm(false)
                                }}>
                                    <img src="images/editIcon.svg" alt="editIcon" title="Edit" /></button>
                                {props?.userData?.role === "Admin" ?
                                    <button className="btn action-btn d-flex align-items-center justify-content-center" onClick={() => {
                                        setDeleteId(data.organizationId);
                                        setHideDeletePopup(false)
                                    }}>
                                        <img src="images/deleteIcon.svg" alt="deleteIcon" title="Delete" />
                                    </button> :
                                    <></>
                                }
                            </span>
                        </td>
                    </tr>
                )
            })
        )
    }
    return (
        <>
            {
                isLoading ? <Loader isLoading={isLoading} /> :

                    <>
                        <div className="container-fluid">
                            <div className="row justify-content-center">
                                <div className="px-4">
                                    <div className="d-block d-md-flex flex-wrap justify-content-between align-items-center  mb-4 mt-5 pt-5">
                                        <h2 className="font-22 font-semibold color-black ">
                                            Manage Organization
                                        </h2>
                                        <div className="d-block d-md-flex">
                                            <div className="w-md-100 d-flex mb-3 mb-md-0">
                                                {/*search starts here*/}
                                                <span className="position-relative d-flex align-items-center me-4 w-100">
                                                    <input type="text" className="form-control theme-form font-regular font-14 top-0 bottom-0 py-2 " id="search" placeholder="Search" value={filterData.search} onChange={(event: any) => {
                                                        setHideFilter(true)
                                                        onChangeFilter("search", event.target.value);
                                                    }} onKeyDown={(event: any) => {
                                                        if (event.key === 'Enter') {
                                                            setFilterParams({ ...filterData, search: filterData.search.trim() })
                                                        }
                                                    }} />
                                                    <span className="position-absolute search-icon"><img src="images/search-icon.svg" alt="search-icon" onClick={() => { setFilterParams({ ...filterData, search: filterData.search.trim() }) }} /></span>
                                                </span>
                                                {/*search ends here*/}
                                                <div className="btn-group">
                                                    <button type="button" className="btn btn-secondary btn-white rounded-2 table-btn" aria-expanded="false" onClick={() => { setHideFilter(false) }}>
                                                        <img src="images/adv-fliter.svg" alt="adv-fliter" />
                                                    </button>
                                                    {hideFilter ? <></> :
                                                        <div className="dropdown-menu dropdown-menu-end adv-dropdown p-4 adv-filter show" data-popper-placement="bottom-end" style={{ position: 'absolute', inset: '0px auto auto 0px', margin: '0px', transform: 'translate(-442px, 42px)' }}>
                                                            <div className="row">
                                                                <div className="d-flex justify-content-between align-items-center mb-3">
                                                                    <h6 className="font-18 font-bold color-black">Advanced Filter</h6>
                                                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => { setHideFilter(true) }} />
                                                                </div>
                                                                <div className="col-sm-12 col-md-6 mb-3">
                                                                    <label htmlFor="industry" className="form-label font-14 font-semibold color-black">Industry</label>
                                                                    <select id="industry" className="theme-form form-select custom-form font-regular font-14" value={filterData.industryTypeId} onChange={(event: any) => { onChangeFilter("industryTypeId", event.target.value) }}>
                                                                        <option selected disabled value="">Select Industry</option>
                                                                        {bindIndustry()}
                                                                    </select>
                                                                </div>
                                                                <div className="col-sm-12 col-md-6 mb-3">
                                                                    <label htmlFor="employee" className="form-label font-14 font-semibold color-black">Employee Range</label>
                                                                    <select id="employee" className="theme-form form-select custom-form font-regular font-14" value={filterData.employeeRangeId} onChange={(event: any) => { onChangeFilter("employeeRangeId", event.target.value) }}>
                                                                        <option selected disabled value="">Select employee range</option>
                                                                        {bindEmployeeRange()}
                                                                    </select>
                                                                </div>
                                                                <div className="col-sm-12 col-md-6 mb-3">
                                                                    <label htmlFor="Revenue" className="form-label font-14 font-semibold color-black">Revenue</label>
                                                                    <select id="Revenue" className="theme-form form-select custom-form font-regular font-14" value={filterData.revenue} onChange={(event: any) => { onChangeFilter("revenue", event.target.value) }}>
                                                                        <option selected disabled value="">Select revenue</option>
                                                                        <option value={"0.01-0.1"}>0.01 - 0.1</option>
                                                                        <option value={"0.01-1"}>0.1 - 1</option>
                                                                        <option value={"1-10"}>1 - 10</option>
                                                                        <option value={"11-50"}>11 - 50</option>
                                                                        <option value={"51-100"}>51 - 100</option>
                                                                        <option value={"101-500"}>101 - 500</option>
                                                                        <option value={"500-1000000000000"}>{`> 500`}</option>
                                                                    </select>
                                                                </div>
                                                                <div className="col-md-12 d-flex justify-content-end mt-4">
                                                                    <a className="theme-link-btn font-14 font-semibold text-center order-md-first cursor-pointer"
                                                                        onClick={() => {
                                                                            setFilterData({ ...filterData, industryTypeId: "", revenue: "", employeeRangeId: "" });
                                                                            setFilterParams({ search: filterParams.search, industryTypeId: "", revenue: "", employeeRangeId: "" })
                                                                            setHideFilter(true)
                                                                        }}>Clear</a>
                                                                    <button type="button" className="btn btn-dark theme-primary-btn border-0 font-semibold "
                                                                        onClick={() => {
                                                                            setHideFilter(true)
                                                                            setFilterParams({ ...filterData, search: filterParams.search })
                                                                        }}
                                                                    >Apply</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                            <button type="button" className="btn btn-dark theme-primary-btn border-0 font-14 font-semibold ms-md-3 ms-0 table-btn w-auto text-nowrap w-md-100" onClick={() => {
                                                setEditedOrganization(initialOrganizationData)
                                                setHideFilter(true)
                                                setHideForm(false)
                                            }} >
                                                <span className="me-2"><img src="images/whiteplus.svg" alt="plus" /></span>Add Organization</button>
                                        </div>
                                    </div>
                                    {/* table starts here */}
                                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                        <div className="table-responsive mb-4">
                                            <table className="table table-bordered themeTable ">
                                                <thead className="font-semibold ">
                                                    <tr>
                                                        <th>Organization Name <span className="ms-2 cursor-pointer" onClick={() => { onClickSort("organizationName"); setSortOrder({ ...sortOrder, organizationName: sortOrder.organizationName === "asc" ? "desc" : "asc" }) }}>{sortOrder.organizationName === "asc" ?
                                                            <img src="images/sortarrow.svg" alt="sortarrow" title="Sort" /> :
                                                            <img src="images/sortarrowup.svg" alt="sortarrow" title="Sort" />
                                                        }</span></th>
                                                        <th>Industry {organizationData?.length > 0 && (<span className="ms-2 cursor-pointer" onClick={() => { onClickSort("industryType"); setSortOrder({ ...sortOrder, industryType: sortOrder.industryType === "asc" ? "desc" : "asc" }) }}>{sortOrder.industryType === "asc" ?
                                                            <img src="images/sortarrow.svg" alt="sortarrow" title="Sort" /> :
                                                            <img src="images/sortarrowup.svg" alt="sortarrow" title="Sort" />
                                                        }</span>)}</th>
                                                        <th># Employees {organizationData?.length > 0 && (<span className="ms-2 cursor-pointer" onClick={() => { onClickSort("numberOfEmployees"); setSortOrder({ ...sortOrder, numberOfEmployees: sortOrder.numberOfEmployees === "asc" ? "desc" : "asc" }) }}>{sortOrder.numberOfEmployees === "asc" ?
                                                            <img src="images/sortarrow.svg" alt="sortarrow" title="Sort" /> :
                                                            <img src="images/sortarrowup.svg" alt="sortarrow" title="Sort" />
                                                        }</span>)}</th>
                                                        <th className="text-end pe-5">Revenue {organizationData?.length > 0 && (<span className="ms-2 cursor-pointer" onClick={() => { onClickSort("revenue"); setSortOrder({ ...sortOrder, revenue: sortOrder.revenue === "asc" ? "desc" : "asc" }) }}>{sortOrder.revenue === "asc" ?
                                                            <img src="images/sortarrow.svg" alt="sortarrow" title="Sort" /> :
                                                            <img src="images/sortarrowup.svg" alt="sortarrow" title="Sort" />
                                                        }</span>)}</th>
                                                        <th className="text-center">Actions</th>

                                                    </tr>
                                                </thead>
                                                <tbody className="font-medium">
                                                    {bindOrganizationData()}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    {/* first tab ends here */}
                                    <div className="tab-pane fade" id="pills-inprogress" role="tabpanel">InProgress</div>
                                    <div className="tab-pane fade" id="pills-completed" role="tabpanel">Completed</div>
                                </div>
                                {/* tabs ends here */}
                            </div>
                        </div>

                    </>
            }
            {
                !toastState?.hideToast ? <div className="position-fixed bg-white top-110px start-50 translate-middle w-md-100">
                    <div className="shadow-sm d-flex rounded-3 custom-toast p-3">
                        <div className="toast-body d-flex align-items-center ">
                            <div className="d-block me-3">
                            <img src={toastState?.type?.toLowerCase() === 'success' ? "images/toast-success.svg" : "images/fail-icon.svg"} alt={toastState?.type}  />
                            </div>
                            <div className="d-block">
                                <span className="font-medium font-16 color-grey">
                                    {toastState?.message}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                    :
                    <></>

            }

            {hideDeletePopup ?
                <></>
                :
                <DeletePopup
                    popupType='Delete'
                    popupHeading='Delete Confirmation'
                    popupMessage='Are you sure you want to delete this Organization?'
                    setHideDeletePopup={setHideDeletePopup}
                    deleteCall={deleteOrganizationData}
                />}
            {hideForm ? <></> : <OrganizationForm setHideForm={setHideForm} editedData={editedOrganization}
                industry={industry} employeeRange={employeeRange}
                postOrganization={addOrganization}
            />}
            {!hideDeletePopup || !hideForm ? <div className="modal-backdrop fade show"></div> : <></>

            }

        </>
    )

}

export default OrganizationGrid
