import React, { useState, useEffect } from 'react';
import { CreateProfileInput, ProfileQuestionUpdate } from '../interface/CreateProfileAndWorkloadModel';
import CreateProfile from './CreateProfile';
import CreateWorkload from './CreateWorkload';
import { getConnectedConnectors, getProfileAndWorkLoad, postConnectors, postProfileAndWorkload } from '../service/QuestionnaireApi';

export default function WellArchitectConnector(props: any) {

    const { isLoading, setShowToasterFailedPopup, setShowToasterLoading, profileDetails, setShowToasterPopup, setShowToaster, showToaster, workLoadDetails, setProfileDetails, setWorkLoadDetails,fetchProfileAndWorkLoad } = props
    const { organizationId, verticalId, projectId, connectorId, activeConnectorId } = props?.projectDetails;
    const [showGrid, setShowGrid] = useState<boolean>(workLoadDetails?.workLoadName?.length?true:false)
    const [selectedPage, setSelectedPage] = useState<number>(1)
    const [profiles, setProfile] = useState<any>(profileDetails)
    const [workloads, setWorkload] = useState<any>(workLoadDetails)
    const [apiError, setApiError] = useState<any>(false);
    const [errorMessage, setErrorMessage] = useState("");
    // useEffect(() => {
    //     getProfileAndWorkLoadDetails()
    // }, []);

    // const getProfileAndWorkLoadDetails = async () => {
    //     try {
    //         isLoading(true);
    //         const req = {
    //             "organizationId": organizationId,
    //             "verticalId": verticalId,
    //             "projectId": projectId,
    //             "connectorId": connectorId
    //         };
    //         const response = await getProfileAndWorkLoad(req);
    //         if (response.status !== 200) {
    //             isLoading(false);
    //             return;
    //         }
    //         setProfileDetails(response?.data?.profile)
    //         setWorkLoadDetails(response?.data?.workLoad)
    //         isLoading(false);
    //     }
    //     catch (error) {
    //         isLoading(false);
    //         setShowToasterFailedPopup(true);
    //         // console.log(error)
    //     }
    //     finally {
    //         isLoading(false);
    //         setTimeout(() => {
    //             setShowToasterFailedPopup(false);
    //         }, 4000);
    //     }
    // }

    useEffect(() => {
        setProfile(profileDetails)
        setWorkload({...workLoadDetails, awsRegions: workLoadDetails?.awsRegions||""})
    }, [profileDetails, workLoadDetails])


    const transformQuestions = (questions: any) => {
        return questions.map((question: any) => ({
            QuestionId: question.questionId,
            SelectedChoiceIds: question.selectedOptionIds
        }));
    };

    const invokeToaster = () => {
        setShowToasterPopup(true);
        setTimeout(() => {
            setShowToasterPopup(false);
        }, 4000);
    };

    const handleSave = async () => {
        if (showToaster) setShowToaster(false);
        setShowToasterLoading(true);

        const profileData = {
            ProfileArn: profiles.profileArn,
            ProfileName: profiles.profileName,
            ProfileDescription: profiles.profileDescription,
            ProfileQuestions: transformQuestions(profiles.profileQuestions)
        };

        const workloadData = {
            WorkloadId: workloads.workLoadId,
            WorkloadName: workloads.workLoadName,
            Description: workloads.workLoadDescription,
            Environment: workloads.environment,
            ArchitecturalDesign: workloads.architecturalDesign,
            ReviewOwner: workloads.reviewOwnerName,
            IndustryType: workloads.industryType,
            Lenses: workloads.lenses,
            AwsRegions: [workloads.awsRegions]
        };

        const request = {
            organizationId,
            verticalId,
            projectId,
            connectorId,
            profileDetails: profileData,
            workLoadDetails: workloadData
        };

        try {
            let postProfileAndWorkloadResponse = await postProfileAndWorkload(request);
            if (postProfileAndWorkloadResponse.status === 200) {
                
                const profileArn = postProfileAndWorkloadResponse?.data?.createdProfileDetails?.ProfileArn ??
                    postProfileAndWorkloadResponse?.data?.updatedProfileDetails?.Profile?.ProfileArn;

                const workLoadId = postProfileAndWorkloadResponse.data?.createdWorkloadDetails?.WorkloadId ??
                    postProfileAndWorkloadResponse?.data?.updatedWorkloadDetails?.Workload?.WorkloadId;

                if (profileArn) {
                    setProfileDetails({ ...profiles, ["profileArn"]: profileArn });
                    setProfile({ ...profiles, ["profileArn"]: profileArn });
                }

                if (workLoadId) {
                    setWorkLoadDetails({ ...workloads, "workLoadId": workLoadId });
                    setWorkload({ ...workloads, "workLoadId": workLoadId });
                }

                await updateConnectorData(profileArn, workLoadId);
                invokeToaster();
                setShowGrid(true)
                setSelectedPage(1) 
                

            }
        } catch (error: any) {
            if (error.response) {
                if (error.response.status === 409) {
                    setErrorMessage("A profile with this name already exists");
                } else if (error.response.status === 410) {
                    setErrorMessage("A Workload with this name already exists");
                } else {
                    setErrorMessage("An unknown error occurred");
                }
            } else {
                setErrorMessage("Error setting up the request");
            }
            setApiError(true);
        } finally {
            setShowToasterLoading(false);
            setTimeout(() => {
                setShowToasterFailedPopup(false);
                setApiError(false)

                if (showToaster) setShowToaster(true);
            }, 4000);
        }
    };

    const updateConnectorData = async (profileArn: any, workLoad: any) => {
        const dataParams = {
            organizationId,
            connectorId: activeConnectorId,
            verticalId,
            projectId,
        };

        try {
            const connectorApiData = await getConnectedConnectors(dataParams);
            const awsWellArchitectData = {
                id: {
                    organizationId,
                    connectorId: activeConnectorId,
                    connectorName: "Well-Architected",
                    projectId,
                    verticalId,
                    attributeId: connectorApiData[0]?.attributeId ?? "",
                    verticalName: "WAFR"
                },
                data: [
                    { key: "Workload Id", value: [workLoad] },
                    { key: "Profile ARN", value: [profileArn] }
                ],
                userName: "Dhilipan"
            };

            const connectorsApiData = await postConnectors(awsWellArchitectData);
        } catch (error) {
            throw error;
        }
    };


    return (
        showGrid ?
        <>
        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-4">
        <h3 className="font-18 font-semibold color-black mt-4 mb-4">
        AWS Well-Architected Configurations
        </h3>
            <div
              className="table-responsive mb-4 border border-1 rounded p-2 "
            >
              <table className="table table-borderless mb-0">
                <thead className="font-semibold "></thead>
                <tbody className="font-regular">
                  <tr className="text-nowrap connector-table">
                    <td className="text-start text-nowrap">
                      <img src="images/aws-wafr-logo.svg" width="27" height="27" alt="logo" className="me-2" />
                      <span className="font-14 font-semibold color-black-v2 me-3">
                        {/* Accounts Management */}
                        AWS Well-Architected{" "}
                      </span>
                    </td>
                    <td className="d-flex justify-content-end">
   
                        <button
                          type="button"
                          className="btn edit-btn custom-btn font-medium px-4"
                          onClick={() => {
                            setShowGrid(false)
                          }}
                        >
                          Edit
                        </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
      </div>
      </>
      :
        <>
            <div className="col-12 col-md-12 col-lg-9 col-xl-10 Nav-page-scrl">
                <div className="row">
                    <div className="tab-content" id="v-pills-tabContent">
                        <div
                            className="tab-pane fade show active mb-5"
                            id="Well-Architected"
                            role="tabpanel"
                        >
                            <div className="row">
                                <div className="d-flex justify-content-center w-100 mb-4 pb-3 border-bottom">
                                    <div className="wizard-conatiner d-flex justify-content-between" onClick={() => { setSelectedPage(1) }}>
                                        <div className="wiz-step cursor-pointer active">
                                            <span className="wiz-count cursor-pointe font-semibold font-14 me-2 " >1</span>
                                            <span className="font-semibold d-none d-sm-inline-block" >
                                                Create Profile
                                            </span>
                                        </div>
                                        <div className={`wiz-step  ${selectedPage === 2 ? "active" : ""}`}>
                                            <span className="wiz-count font-semibold font-14 me-2" >2</span>
                                            <span className="font-semibold d-none d-sm-inline-block">
                                                Create Workload
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                {selectedPage === 1 ? <CreateProfile profileDetails={profiles} setProfile={setProfile} setProfileDetails={setProfileDetails} questions={profiles?.profileQuestions} setSelectedPage={setSelectedPage} projectDetails={props.projectIds} setIsLoading={isLoading} /> :
                                    <CreateWorkload setWorkLoadDetails={setWorkLoadDetails} workLoadDetails={workLoadDetails} workloads={workloads} setWorkload={setWorkload} handleSave={handleSave} setSelectedPage={setSelectedPage} projectDetails={props?.projectDetails} setIsLoading={isLoading} setApiError={setApiError} />}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {apiError && (
                <div
                    className="position-fixed" style={{ top: '15%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: 1050, maxWidth: '500px', width: 'auto' }}>
                    <div className="bg-white shadow-sm rounded-3 p-3">
                        <div className="d-flex align-items-center">
                            <div className="me-3">
                                <img src="images/fail-icon.svg" alt="toast-fail" />
                            </div>
                            <div style={{ wordWrap: 'break-word' }}>
                                <span className="font-medium font-16 color-grey">
                                    {errorMessage}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    )
}