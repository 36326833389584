import React, { useEffect, useState } from 'react';
import { profileOptionsModel } from '../interface/questionnaireInterface';

const CreateProfile = (props: any) => {
    const { profileDetails, setProfileDetails, questions, setSelectedPage, setProfile, projectDetails } = props;
    const [isDisable, setIsDisable] = useState<boolean>(true)
    const [validationMsg, setValidationMsg] = useState({
        profileName: "",
        profileDescription: ""
    });
    const [maxSelectionsError, setMaxSelectionsError] = useState("");
    const regexPattern = /^[a-zA-Z]([a-zA-Z0-9-_\. ]{0,15})*$/;

    useEffect(() => {
        const isNameValid = profileDetails?.profileName?.trim().length !== 0 && validationMsg.profileName === "";
        const isDescriptionValid = profileDetails?.profileDescription?.trim().length !== 0 && validationMsg.profileDescription === "";
        const isAllQuestionAnswered = profileDetails?.profileQuestions?.every((data: any) => data?.selectedOptionIds?.length > 0)
        setIsDisable(!(isNameValid && isDescriptionValid && isAllQuestionAnswered));
    }, [profileDetails, validationMsg]);

    const bindOptions = (question: any, options: any) => {
        if (Array.isArray(options)) {
            return options.map((option: any) => {
                return (
                    <div className="form-check ms-3" key={option.optionId}>
                        <input
                            className={`form-check-input theme-${question.questionType === "checkbox" ? "check" : "radio"} label-bold`}
                            type={question.questionType}
                            name={question.questionId}
                            id={option.optionId}
                            checked={question.selectedOptionIds?.includes(option.optionId)}
                            onChange={(event: any) => {
                                const selectedIds = question.selectedOptionIds || [];
                                const optionId = event.target.id;

                                if (question.questionType === "checkbox") {
                                    if (selectedIds.includes(optionId)) {
                                        // Removing a selection
                                        setProfile((prevState: any) => ({
                                            ...prevState,
                                            profileQuestions: prevState.profileQuestions.map((q: any) =>
                                                q.questionId === question.questionId
                                                    ? { ...q, selectedOptionIds: selectedIds.filter((id: any) => id !== optionId) }
                                                    : q
                                            )
                                        }));
                                        setMaxSelectionsError("");
                                    } else {
                                        // Adding a selection
                                        if (selectedIds.length < 3) {
                                            setProfile((prevState: any) => ({
                                                ...prevState,
                                                profileQuestions: prevState.profileQuestions.map((q: any) =>
                                                    q.questionId === question.questionId
                                                        ? { ...q, selectedOptionIds: [...selectedIds, optionId] }
                                                        : q
                                                )
                                            }));
                                            setMaxSelectionsError("");
                                        } else {
                                            setMaxSelectionsError("Please choose up to three options.");
                                            event.preventDefault();
                                        }
                                    }
                                } else {
                                    // For radio buttons, set the selected option
                                    setProfile((prevState: any) => ({
                                        ...prevState,
                                        profileQuestions: prevState.profileQuestions.map((q: any) =>
                                            q.questionId === question.questionId
                                                ? { ...q, selectedOptionIds: [optionId] }
                                                : q
                                        )
                                    }));
                                }
                            }}
                        />
                        <label className="form-check-label mb-2 ms-2 font-14 font-regular" htmlFor={option.optionId}>
                            {option.option}
                        </label>
                    </div>
                );
            });
        } else {
            return <></>;
        }
    };

    const bindQuestions = () => {
        if (Array.isArray(questions)) {
            return questions.map((question: any, index: number) => {
                return (
                    <div className="mb-4 ps-lg-4" key={question.questionId}>
                        <p className="font-16 font-medium">
                            {`${index + 1}. ${question.question}`}
                        </p>
                        {bindOptions(question, question.options)}
                        {question.questionType === "checkbox" && maxSelectionsError && (
                            <p className="color-red mt-2">{maxSelectionsError}</p>
                        )}
                    </div>
                );
            });
        } else {
            return <></>;
        }
    };



    return (
        <>
            <div className="col-lg-8 col-md-12 col-12">
                <div className="ps-lg-4">
                    <h3 className="font-18 font-bold mt-3 mb-5">Create Profile</h3>
                    <div className="row">
                        <div className="col-md-6 mb-5">
                            <label className="form-label font-14 font-semibold" htmlFor="acessKey">Name<span className="color-red ms-1">*</span>
                                <img src="images/info.svg" data-bs-toggle="tooltip" data-bs-placement="right" title="A unique identifier for the profile." className="ms-1" /></label>
                            <input
                                type="text"
                                id="accessKey"
                                className="form-control custom-form"
                                placeholder="Name"
                                value={profileDetails?.profileName}
                                onChange={(event: any) => {
                                    const value = event.target.value;
                                    setProfile((prevState: any) => ({
                                        ...prevState,
                                        profileName: value
                                    }));
                                    if (value === "") {
                                        setValidationMsg(prev => ({ ...prev, profileName: "Please enter name" }));
                                    } else if (!regexPattern.test(value)) {
                                        setValidationMsg(prev => ({ ...prev, profileName: "Enter a valid name" }));
                                    } else {
                                        setValidationMsg(prev => ({ ...prev, profileName: "" }));
                                    }
                                }}
                            />
                            <span className='color-red'>{validationMsg.profileName}</span>
                        </div>
                        <div className="col-md-12 mb-5">
                            <label className="form-label font-14 font-semibold">
                                Description<span
                                    className="color-red ms-1">*</span><img src="images/info.svg" data-bs-toggle="tooltip"
                                        data-bs-placement="right" title="A brief description of the profile to document its scope and intended purpose." className="ms-1" alt="Work Load Name" />
                            </label>
                            <textarea
                                className="border rounded-1 custom-form ps-4 pe-5 "
                                style={{ height: '150px' }}
                                spellCheck="false"
                                value={profileDetails?.profileDescription}
                                placeholder="Enter Description"
                                onChange={(event: any) => {
                                    const value = event.target.value;
                                    setProfile((prevState: any) => ({
                                        ...prevState,
                                        profileDescription: value
                                    }));
                                    if (value === "") {
                                        setValidationMsg(prev => ({ ...prev, profileDescription: "Please enter description" }));
                                    } else {
                                        setValidationMsg(prev => ({ ...prev, profileDescription: "" }));
                                    }
                                }}
                            />
                            <span className='color-red'>{validationMsg.profileDescription}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-12">
                <h3 className="font-18 font-bold mt-3 mb-5 border-bottom pb-3 ps-lg-4">
                    Profile Questions
                </h3>
                {bindQuestions()}
            </div>

            <div className="col-lg-8 col-md-12 col-12">
                <div className="modal-footer px-4 border-0 justify-content-end pb-4 gap-1 ">
                    <a
                        className="theme-link-btn font-14 font-semibold text-center order-md-first cursor-pointer"
                        data-bs-dismiss="modal"
                        onClick={() => {
                            setProfile((prevState: any) => ({
                                ...prevState,
                                profileName: "",
                                profileDescription: ""
                            }));
                            setProfile((prevState: any) => ({
                                ...prevState,
                                profileQuestions: prevState.profileQuestions.map((q: any) => {
                                    return { ...q, selectedOptionIds: [] }
                                }
                                )
                            }));
                        }}
                    >
                        Clear
                    </a>
                    <button
                        type="button"
                        className="btn btn-dark theme-primary-btn border-0 font-semibold"
                        onClick={() => {
                            setSelectedPage(2);
                        }}
                        disabled={isDisable}
                    >
                        Next
                    </button>
                </div>
            </div>
        </>
    );
};

export default CreateProfile;