export default function Loader(props: any) {
  return (
    <div className="overlay" hidden={!props.isLoading}>
      <div className="position-absolute top-50 start-50 translate-middle">
        <div
          className="spinner-border Loader text-dark align-center "
          role="status"
        >
          <span className="visually-hidden" />
        </div>
        <p className="loading-text mt-3">Loading..</p>
      </div>
    </div>
  );
}
