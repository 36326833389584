import { HTMLAttributes, useEffect, useState } from "react";
import { MobilityConnectorPropsModel } from "../../interface/questionnaireInterface";

import ConnectorSelectDropdown from "../MultiSelectDropdown";

export default function MobilityConnector(props: MobilityConnectorPropsModel) {
  const {
    activeConnector,
    validateConnectorInput,
    connectorsOnChange,
    setShowConnectorForm,
    handleMultiSelectChange,
    handleSnTreeSelectChange,
    CrashMonitoringDropdown,
    LogMonitoringDropdown,
    AnalyticsMonitoringDropdown,
    connectorFormData,
    setConnectorFormData,
    setMobilityActiveTab,
    mobilityActiveTab,
    connectorFormValidationMsg,
    connectorApiValidationMsg,
    resetValidationMessages,
    handleDeleteClick,
    connectorGridData,
  } = props;

  const mobilitySourceControlDropdown = [
    {
      label: "Git Lab",
      value: "Git",
    },
    {
      label: "Git Hub",
      value: "Git",
    },
    {
      label: "Bit Bucket",
      value: "Git",
    },
    {
      label: "TFS",
      value: "Azure",
    },
  ];

  const bindMobilityCrashMonitoringCredsFields = () => {
    try {
      const bindArray: any = connectorFormData["Crash Monitoring Tool"];
      return (
        <>
          {/* Crash Monitoring Tools Start*/}
          <p
            className={`font-18 font-bold mb-4 ${
              connectorFormData[
                "Do we have access to the Crash Monitoring Tools?"
              ] === "Yes" && bindArray?.length
                ? ""
                : "hideActionNeeded"
            }`}
          >
            Add Credentials for the Crash Monitoring Tools
          </p>
          {/**  Add User name and password */}

          {bindArray?.length != 0
            ? bindArray?.map((crashTools: string, index: number) => {
                return (
                  <>
                    <p
                      className={`font-15 font-bold ${
                        connectorFormData[
                          "Do we have access to the Crash Monitoring Tools?"
                        ] === "Yes"
                          ? ""
                          : "hideActionNeeded"
                      }`}
                    >
                      {crashTools}
                    </p>
                    <div
                      key={index}
                      className={`col-md-6 ${
                        connectorFormData[
                          "Do we have access to the Crash Monitoring Tools?"
                        ] === "Yes"
                          ? ""
                          : "hideActionNeeded"
                      }`}
                    >
                      <div className="mb-4">
                        <label
                          htmlFor={`Crash Monitoring Tool-${crashTools}-Username`}
                          className="form-label font-14 font-semibold color-black"
                        >
                          Username
                        </label>
                        <span className="color-red"> *</span>
                        <input
                          type="text"
                          className="form-control theme-form font-regular font-14"
                          id={`Crash Monitoring Tool-${crashTools}-Username`}
                          name={`Crash Monitoring Tool-${crashTools}-Username`}
                          placeholder="Enter Username"
                          value={
                            connectorFormData[
                              `Crash Monitoring Tool-${crashTools}-Username`
                            ]
                          }
                          defaultValue={
                            connectorFormData[
                              `Crash Monitoring Tool-${crashTools}-Username`
                            ]
                          }
                          onChange={(e) => {
                            connectorsOnChange(e);
                          }}
                        />

                        <p className="mt-2 mb-0 color-red font-14 font-regular">
                          {connectorFormValidationMsg[
                            `Crash Monitoring Tool-${crashTools}-Username`
                          ]?.replace(
                            `Crash Monitoring Tool-${crashTools}-Username`,
                            `${crashTools} Username`
                          )}
                        </p>
                      </div>
                    </div>
                    <div
                      className={`col-md-6 ${
                        connectorFormData[
                          "Do we have access to the Crash Monitoring Tools?"
                        ] === "Yes"
                          ? ""
                          : "hideActionNeeded"
                      }`}
                    >
                      <div className="mb-4">
                        <label
                          htmlFor={`Crash Monitoring Tool-${crashTools}-Password`}
                          className="form-label font-14 font-semibold color-black"
                        >
                          Password
                        </label>
                        <span className="color-red"> *</span>
                        <input
                          type="password"
                          className="form-control theme-form font-regular font-14"
                          id={`Crash Monitoring Tool-${crashTools}-Password`}
                          name={`Crash Monitoring Tool-${crashTools}-Password`}
                          placeholder="Enter Password"
                          value={
                            connectorFormData[
                              `Crash Monitoring Tool-${crashTools}-Password`
                            ]
                          }
                          defaultValue={
                            connectorFormData[
                              `Crash Monitoring Tool-${crashTools}-Password`
                            ]
                          }
                          onChange={(e) => {
                            connectorsOnChange(e);
                          }}
                        />
                        <p className="mt-2 mb-0 color-red font-14 font-regular">
                          {connectorFormValidationMsg[
                            `Crash Monitoring Tool-${crashTools}-Password`
                          ]?.replace(
                            `Crash Monitoring Tool-${crashTools}-Password`,
                            `${crashTools} Password`
                          )}
                        </p>
                      </div>
                    </div>
                  </>
                );
              })
            : null}

          {/* Crash Monitoring Tools END*/}
        </>
      );
    } catch (error) {
      // console.log(error, "Error");
    }
  };

  const bindMobilityLogMonitoringCredsFields = () => {
    try {
      const bindArray: any = connectorFormData["Log Monitoring Tool"];
      return (
        <>
          {/* Log Monitoring Tools Start*/}
          <p
            className={`font-18 font-bold mb-4 ${
              connectorFormData[
                "Do we have access to the Log Monitoring Tools?"
              ] === "Yes" && bindArray?.length
                ? ""
                : "hideActionNeeded"
            }`}
          >
            Add Credentials for the Log Monitoring Tools
          </p>
          {/**  Add User name and password */}

          {bindArray?.length !== 0
            ? bindArray?.map((crashTools: string, index: number) => {
                return (
                  <>
                    <p
                      className={`font-15 font-bold ${
                        connectorFormData[
                          "Do we have access to the Log Monitoring Tools?"
                        ] === "Yes"
                          ? ""
                          : "hideActionNeeded"
                      }`}
                    >
                      {crashTools}
                    </p>
                    <div
                      key={index}
                      className={`col-md-6 ${
                        connectorFormData[
                          "Do we have access to the Log Monitoring Tools?"
                        ] === "Yes"
                          ? ""
                          : "hideActionNeeded"
                      }`}
                    >
                      <div className="mb-4">
                        <label
                          htmlFor={`Crash Monitoring Tool-${crashTools}-Username`}
                          className="form-label font-14 font-semibold color-black"
                        >
                          Username
                        </label>
                        <span className="color-red"> *</span>
                        <input
                          type="text"
                          className="form-control theme-form font-regular font-14"
                          id={`Log Monitoring Tool-${crashTools}-Username`}
                          name={`Log Monitoring Tool-${crashTools}-Username`}
                          placeholder="Enter Username"
                          defaultValue={
                            connectorFormData[
                              `Log Monitoring Tool-${crashTools}-Username`
                            ]
                          }
                          value={
                            connectorFormData[
                              `Log Monitoring Tool-${crashTools}-Username`
                            ]
                          }
                          onChange={(e) => {
                            connectorsOnChange(e);
                          }}
                        />
                        <p className="mt-2 mb-0 color-red font-14 font-regular">
                          {connectorFormValidationMsg[
                            `Log Monitoring Tool-${crashTools}-Username`
                          ]?.replace(
                            `Log Monitoring Tool-${crashTools}-Username`,
                            `${crashTools} Username`
                          )}
                        </p>
                      </div>
                    </div>
                    <div
                      className={`col-md-6 ${
                        connectorFormData[
                          "Do we have access to the Log Monitoring Tools?"
                        ] === "Yes"
                          ? ""
                          : "hideActionNeeded"
                      }`}
                    >
                      <div className="mb-4">
                        <label
                          htmlFor={`Log Monitoring Tool-${crashTools}-Password`}
                          className="form-label font-14 font-semibold color-black"
                        >
                          Password
                        </label>
                        <span className="color-red"> *</span>
                        <input
                          type="password"
                          className="form-control theme-form font-regular font-14"
                          id={`Log Monitoring Tool-${crashTools}-Password`}
                          name={`Log Monitoring Tool-${crashTools}-Password`}
                          placeholder="Enter Password"
                          defaultValue={
                            connectorFormData[
                              `Log Monitoring Tool-${crashTools}-Password`
                            ]
                          }
                          value={
                            connectorFormData[
                              `Log Monitoring Tool-${crashTools}-Password`
                            ]
                          }
                          onChange={(e) => {
                            connectorsOnChange(e);
                          }}
                        />

                        <p className="mt-2 mb-0 color-red font-14 font-regular">
                          {connectorFormValidationMsg[
                            `Log Monitoring Tool-${crashTools}-Password`
                          ]?.replace(
                            `Log Monitoring Tool-${crashTools}-Password`,
                            `${crashTools} Password`
                          )}
                        </p>
                      </div>
                    </div>
                  </>
                );
              })
            : null}

          {/* Log Monitoring Tools END*/}
        </>
      );
    } catch (error) {
      // console.log(error, "Error");
    }
  };

  const bindMobilityAnalyticsCredsFields = () => {
    try {
      const bindArray: any = connectorFormData["Analytics Tool"];
      return (
        <>
          {/* Analytics Tools Start*/}
          <p
            className={`font-18 font-bold mb-4 ${
              connectorFormData["Do we have access to the Analytics Tools?"] ===
                "Yes" && bindArray?.length
                ? ""
                : "hideActionNeeded"
            }`}
          >
            Add Credentials for the Analytics Tools
          </p>
          {/**  Add User name and password */}

          {bindArray?.length != 0
            ? bindArray?.map((crashTools: string, index: number) => {
                return (
                  <>
                    <p
                      className={`font-15 font-bold ${
                        connectorFormData[
                          "Do we have access to the Analytics Tools?"
                        ] === "Yes"
                          ? ""
                          : "hideActionNeeded"
                      }`}
                    >
                      {crashTools}
                    </p>
                    <div
                      key={index}
                      className={`col-md-6 ${
                        connectorFormData[
                          "Do we have access to the Analytics Tools?"
                        ] === "Yes"
                          ? ""
                          : "hideActionNeeded"
                      }`}
                    >
                      <div className="mb-4">
                        <label
                          htmlFor={`Analytics Tool-${crashTools}-Username`}
                          className="form-label font-14 font-semibold color-black"
                        >
                          Username
                        </label>
                        <span className="color-red"> *</span>

                        <input
                          type="text"
                          className="form-control theme-form font-regular font-14"
                          id={`Analytics Tool-${crashTools}-Username`}
                          name={`Analytics Tool-${crashTools}-Username`}
                          placeholder="Enter Username"
                          defaultValue={
                            connectorFormData[
                              `Analytics Tool-${crashTools}-Username`
                            ]
                          }
                          value={
                            connectorFormData[
                              `Analytics Tool-${crashTools}-Username`
                            ]
                          }
                          onChange={(e) => {
                            connectorsOnChange(e);
                          }}
                        />
                        <p className="mt-2 mb-0 color-red font-14 font-regular">
                          {connectorFormValidationMsg[
                            `Analytics Tool-${crashTools}-Username`
                          ]?.replace(
                            `Analytics Tool-${crashTools}-Username`,
                            `${crashTools} Username`
                          )}
                        </p>
                      </div>
                    </div>
                    <div
                      className={`col-md-6 ${
                        connectorFormData[
                          "Do we have access to the Analytics Tools?"
                        ] === "Yes"
                          ? ""
                          : "hideActionNeeded"
                      }`}
                    >
                      <div className="mb-4">
                        <label
                          htmlFor={`Analytics Tool-${crashTools}-Password`}
                          className="form-label font-14 font-semibold color-black"
                        >
                          Password
                        </label>
                        <span className="color-red"> *</span>

                        <input
                          type="password"
                          className="form-control theme-form font-regular font-14"
                          id={`Analytics Tool-${crashTools}-Password`}
                          name={`Analytics Tool-${crashTools}-Password`}
                          placeholder="Enter Password"
                          defaultValue={
                            connectorFormData[
                              `Analytics Tool-${crashTools}-Password`
                            ]
                          }
                          value={
                            connectorFormData[
                              `Analytics Tool-${crashTools}-Password`
                            ]
                          }
                          onChange={(e) => {
                            connectorsOnChange(e);
                          }}
                        />

                        <p className="mt-2 mb-0 color-red font-14 font-regular">
                          {connectorFormValidationMsg[
                            `Analytics Tool-${crashTools}-Password`
                          ]?.replace(
                            `Analytics Tool-${crashTools}-Password`,
                            `${crashTools} Password`
                          )}
                        </p>
                      </div>
                    </div>
                  </>
                );
              })
            : null}

          {/* Analytics Tools END*/}
        </>
      );
    } catch (error) {
      // console.log(error, "Error");
    }
  };

  const bindConnectorsForm = () => {
    const bindSaveAndCancel = () => {
      return (
        <div className="d-grid gap-2 d-md-inline-flex justify-content-md-end mb-5 w-100">
          <button
            type="button"
            className="btn btn-dark theme-primary-btn border-0 font-14 font-semibold "
            onClick={() => {
              validateConnectorInput(null);
            }}
          >
            Save
          </button>
          <a
            className="theme-link-btn cursor-pointer  font-semibold text-center order-md-first"
            onClick={() => {
              if (activeConnector === "Access Controls") {
                handleDeleteClick(connectorGridData[0]?.attributeId);
                resetValidationMessages(activeConnector);
                setConnectorFormData({});
              } else {
                resetValidationMessages(activeConnector);
                setConnectorFormData({});
                setShowConnectorForm(true);
              }
            }}
          >
            {activeConnector !== "Access Controls" ? "Cancel" : "Clear"}
          </a>
        </div>
      );
    };
    switch (activeConnector) {
      // Mobility
      case "Source Code":
        return (
          <div className="col-lg-8 col-md-12 col-12">
            <div className="row mt-4">
              <h6 className="font-18 font-bold mb-5">Add new connector</h6>
              <div className="col-md-6">
                <div className="mb-4">
                  <label className="form-label font-14 font-semibold color-black d-flex">
                    Source Control System
                    <span className="color-red px-1"> *</span>
                  </label>

                  <div className="form-check form-check-inline mt-2">
                    <input
                      className="form-check-input theme-radio label-bold"
                      type="radio"
                      name="Source Control System"
                      id="Git"
                      value="Git"
                      checked={
                        connectorFormData["Source Control System"] === "Git"
                      }
                      onChange={(e) => {
                        connectorsOnChange(e);
                      }}
                    />

                    <label
                      className="form-check-label font-regular font-14 color-black"
                      htmlFor="Git"
                    >
                      Git
                    </label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input theme-radio label-bold"
                      type="radio"
                      name="Source Control System"
                      id="Azure"
                      value="Azure"
                      checked={
                        connectorFormData["Source Control System"] === "Azure"
                      }
                      onChange={(e) => {
                        connectorsOnChange(e);
                      }}
                    />
                    <label
                      className="form-check-label font-regular font-14 color-black"
                      htmlFor="Azure"
                    >
                      Azure
                    </label>
                  </div>
                  <p className="mt-2 mb-0 color-red font-14 font-regular">
                    {connectorFormValidationMsg["Source Control System"]}
                  </p>
                </div>
              </div>
              <div className="col-md-6">
                <div className="mb-4">
                  <label className="form-label font-14 font-semibold color-black">
                    Source Control Tool
                  </label>
                  <span className="color-red"> *</span>
                  <select
                    className="theme-form form-select font-regular color-black font-14"
                    id="Source Control Tool"
                    name="Source Control Tool"
                    defaultValue={connectorFormData["Source Control Tool"]}
                    onChange={(e) => {
                      connectorsOnChange(e);
                    }}
                  >
                    {mobilitySourceControlDropdown.map((dd, ind: number) => {
                      if (
                        dd.value === connectorFormData["Source Control System"]
                      )
                        return (
                          <option key={ind} defaultValue={dd.label}>
                            {dd.label}
                          </option>
                        );
                    })}
                  </select>
                  <p className="mt-2 mb-0 color-red font-14 font-regular">
                    {connectorFormValidationMsg["Source Control Tool"]}
                  </p>
                </div>
              </div>
              <div className="col-md-6">
                <div className="mb-4">
                  <label
                    htmlFor="Source Control"
                    className="form-label font-14 font-semibold color-black"
                  >
                    Source Code
                  </label>
                  <span className="color-red"> *</span>
                  <input
                    type="text"
                    className="form-control theme-form font-regular font-14"
                    id="Source Code"
                    name="Source Code"
                    placeholder="Enter Source Code Url"
                    value={connectorFormData["Source Code"]}
                    defaultValue={connectorFormData["Source Code"]}
                    onChange={(e) => {
                      connectorsOnChange(e);
                    }}
                  />
                  <p className="mt-2 mb-0 color-red font-14 font-regular">
                    {connectorFormValidationMsg["Source Code"]}
                  </p>
                </div>
              </div>
              <div className={`col-md-6`}>
                <div className="mb-5">
                  <label
                    htmlFor="Access Token"
                    className="form-label font-14 font-semibold color-black"
                  >
                    Access Token
                  </label>
                  <span className="color-red"> *</span>
                  <input
                    type="text"
                    className="form-control theme-form font-regular font-14"
                    id="Access Token"
                    name="Access Token"
                    placeholder="Enter Access Token"
                    value={connectorFormData["Access Token"]}
                    defaultValue={connectorFormData["Access Token"]}
                    onChange={(e) => {
                      connectorsOnChange(e);
                    }}
                  />
                  <p className="mt-2 mb-0 color-red font-14 font-regular">
                    {connectorFormValidationMsg["Access Token"]}
                  </p>
                </div>
              </div>
              <p className="mt-1 mb-0 color-red font-14 font-regular">
                {connectorApiValidationMsg}
              </p>
              {bindSaveAndCancel()}
            </div>
          </div>
        );
      case "Access Controls":
        return (
          <div>
            <div
              className="nav nav-tabs border-tabs px-2 flex-nowrap table-responsive nav-scroll"
              role="tablist"
            >
              <button
                className={`nav-link text-nowrap active`}
                id="nav-appStore-tab"
                data-bs-toggle="tab"
                data-bs-target="#nav-appStore"
                type="button"
                role="tab"
                aria-controls="nav-appStore"
                aria-selected="true"
                onClick={() => {
                  setMobilityActiveTab(true);
                }}
              >
                Application Store
              </button>
              <button
                className={`nav-link text-nowrap`}
                id="nav-crashLog-tab"
                data-bs-toggle="tab"
                data-bs-target="#nav-crashLog"
                type="button"
                role="tab"
                aria-controls="nav-crashLog"
                aria-selected="false"
                onClick={() => {
                  setMobilityActiveTab(false);
                }}
              >
                Crash &amp; Log Monitoring System
              </button>
            </div>
            <div className="tab-content" id="nav-tabContent">
              {/* app store starts  */}
              <div
                className="tab-pane fade show active pt-5"
                id="nav-appStore"
                role="tabpanel"
              >
                <div className="row ">
                  <div className="col-lg-8 col-md-12 col-12">
                    <div className="row">
                      <h6 className="font-18 font-bold mb-4">Play Store</h6>
                      <div className="col-md-6">
                        <div className="mb-4">
                          <label
                            htmlFor="Play Store URL"
                            className="form-label font-14 font-semibold color-black"
                          >
                            Play Store URL
                          </label>
                          <span className="color-red"> *</span>
                          <input
                            type="text"
                            className="form-control theme-form font-regular font-14"
                            id="Play Store URL"
                            name="Play Store URL"
                            placeholder="Enter Play Store URL"
                            defaultValue={connectorFormData["Play Store URL"]}
                            value={connectorFormData["Play Store URL"]}
                            onChange={(e) => {
                              connectorsOnChange(e);
                            }}
                          />
                          <p className="mt-2 mb-0 color-red font-14 font-regular">
                            {connectorFormValidationMsg["Play Store URL"]}
                          </p>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="mb-4">
                          <label className="form-label font-14 font-semibold color-black d-flex">
                            Manager Access{" "}
                            <span className="color-red px-1"> *</span>
                          </label>
                          <div className="form-check form-check-inline mt-2">
                            <input
                              className="form-check-input theme-radio label-bold"
                              type="radio"
                              name="Play Store Manager Access"
                              id="Play Store Available"
                              value="Available"
                              checked={
                                connectorFormData[
                                  "Play Store Manager Access"
                                ] === "Available"
                              }
                              onChange={(e) => {
                                connectorsOnChange(e);
                              }}
                            />
                            <label
                              className="form-check-label font-regular font-14 color-black"
                              htmlFor="Play Store Available"
                            >
                              Available
                            </label>
                          </div>
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input theme-radio label-bold"
                              type="radio"
                              name="Play Store Manager Access"
                              id="Play Store Not Available"
                              value="Not Available"
                              checked={
                                connectorFormData[
                                  "Play Store Manager Access"
                                ] === "Not Available"
                              }
                              onChange={(e) => {
                                connectorsOnChange(e);
                              }}
                            />
                            <label
                              className="form-check-label font-regular font-14 color-black"
                              htmlFor="Play Store Not Available"
                            >
                              Not Available
                            </label>
                          </div>
                          <p className="mt-2 mb-0 color-red font-14 font-regular">
                            {
                              connectorFormValidationMsg[
                                "Play Store Manager Access"
                              ]
                            }
                          </p>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="mb-5">
                          <label
                            htmlFor="Google Play API key"
                            className="form-label font-14 font-semibold color-black"
                          >
                            Google Play API key
                          </label>
                          <span className="color-red"> *</span>
                          <input
                            type="text"
                            className="form-control theme-form font-regular font-14"
                            id="Google Play API key"
                            name="Google Play API key"
                            placeholder="Enter Google Play API key"
                            defaultValue={
                              connectorFormData["Google Play API key"]
                            }
                            value={connectorFormData["Google Play API key"]}
                            onChange={(e) => {
                              connectorsOnChange(e);
                            }}
                          />
                          <p className="mt-2 mb-0 color-red font-14 font-regular">
                            {connectorFormValidationMsg["Google Play API key"]}
                          </p>
                        </div>
                      </div>
                      <h6 className="font-18 font-bold mb-4">App Store</h6>
                      <div className="col-md-6">
                        <div className="mb-4">
                          <label
                            htmlFor="App Store URL"
                            className="form-label font-14 font-semibold color-black"
                          >
                            App Store URL
                          </label>
                          <span className="color-red"> *</span>
                          <input
                            type="text"
                            className="form-control theme-form font-regular font-14"
                            id="App Store URL"
                            name="App Store URL"
                            placeholder="Enter App Store URL"
                            defaultValue={connectorFormData["App Store URL"]}
                            value={connectorFormData["App Store URL"]}
                            onChange={(e) => {
                              connectorsOnChange(e);
                            }}
                          />
                          <p className="mt-2 mb-0 color-red font-14 font-regular">
                            {connectorFormValidationMsg["App Store URL"]}
                          </p>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="mb-4">
                          <label className="form-label font-14 font-semibold color-black d-flex">
                            Manager Access{" "}
                            <span className="color-red px-1"> *</span>
                          </label>
                          <div className="form-check form-check-inline mt-2">
                            <input
                              className="form-check-input theme-radio label-bold"
                              type="radio"
                              name="App Store Manager Access"
                              id="App Store Available"
                              value="Available"
                              checked={
                                connectorFormData[
                                  "App Store Manager Access"
                                ] === "Available"
                              }
                              onChange={(e) => {
                                connectorsOnChange(e);
                              }}
                            />
                            <label
                              className="form-check-label font-regular font-14 color-black"
                              htmlFor="App Store Available"
                            >
                              Available
                            </label>
                          </div>
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input theme-radio label-bold"
                              type="radio"
                              name="App Store Manager Access"
                              id="App Store Not Available"
                              value="Not Available"
                              checked={
                                connectorFormData[
                                  "App Store Manager Access"
                                ] === "Not Available"
                              }
                              onChange={(e) => {
                                connectorsOnChange(e);
                              }}
                            />
                            <label
                              className="form-check-label font-regular font-14 color-black"
                              htmlFor="App Store Not Available"
                            >
                              Not Available
                            </label>
                          </div>
                          <p className="mt-2 mb-0 color-red font-14 font-regular">
                            {
                              connectorFormValidationMsg[
                                "App Store Manager Access"
                              ]
                            }
                          </p>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="mb-5">
                          <label
                            htmlFor="App Store Connect API key"
                            className="form-label font-14 font-semibold color-black"
                          >
                            App Store Connect API key
                          </label>
                          <span className="color-red"> *</span>
                          <input
                            type="text"
                            className="form-control theme-form font-regular font-14"
                            id="App Store Connect API key"
                            name="App Store Connect API key"
                            placeholder="Enter App Store Connect API key"
                            defaultValue={
                              connectorFormData["App Store Connect API key"]
                            }
                            value={
                              connectorFormData["App Store Connect API key"]
                            }
                            onChange={(e) => {
                              connectorsOnChange(e);
                            }}
                          />
                          <p className="mt-2 mb-0 color-red font-14 font-regular">
                            {
                              connectorFormValidationMsg[
                                "App Store Connect API key"
                              ]
                            }
                          </p>
                        </div>
                      </div>
                      {bindSaveAndCancel()}
                    </div>
                  </div>
                </div>
              </div>
              {/* app store ends  */}

              {/* crash log starts here */}
              <div
                className="tab-pane fade show pt-5"
                id="nav-crashLog"
                role="tabpanel"
              >
                <div className="row">
                  <div className="col-lg-8 col-md-12 col-12">
                    <div className="row">
                      <h6 className="font-20 font-bold mb-4">
                        Crash Monitoring System
                      </h6>
                      <div className="col-md-6">
                        <div className="mb-4">
                          <label className="form-label font-14 font-semibold color-black d-flex">
                            System integrated with Crash Monitoring System?
                            <span className="color-red px-1"> *</span>
                          </label>
                          <div className="form-check form-check-inline mt-2">
                            <input
                              className="form-check-input theme-radio label-bold"
                              type="radio"
                              name="System integrated with Crash Monitoring System?"
                              id="System integrated with Crash Monitoring System Yes"
                              value="Yes"
                              checked={
                                connectorFormData[
                                  "System integrated with Crash Monitoring System?"
                                ] === "Yes"
                              }
                              onChange={(e) => {
                                connectorsOnChange(e);
                              }}
                            />

                            <label
                              className="form-check-label font-regular font-14 color-black"
                              htmlFor="System integrated with Crash Monitoring System Yes"
                            >
                              Yes
                            </label>
                          </div>
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input theme-radio label-bold"
                              type="radio"
                              name="System integrated with Crash Monitoring System?"
                              id="System integrated with Crash Monitoring System No"
                              value="No"
                              checked={
                                connectorFormData[
                                  "System integrated with Crash Monitoring System?"
                                ] === "No"
                              }
                              onChange={(e) => {
                                connectorsOnChange(e);
                              }}
                            />
                            <label
                              className="form-check-label font-regular font-14 color-black"
                              htmlFor="System integrated with Crash Monitoring System No"
                            >
                              No
                            </label>
                          </div>
                          <p className="mt-2 mb-0 color-red font-14 font-regular">
                            {
                              connectorFormValidationMsg[
                                "System integrated with Crash Monitoring System?"
                              ]
                            }
                          </p>
                        </div>
                      </div>
                      <div
                        className={`col-md-6 ${
                          connectorFormData[
                            "System integrated with Crash Monitoring System?"
                          ] === "Yes"
                            ? ""
                            : "hideActionNeeded"
                        }`}
                      >
                        <div className="mb-4">
                          <label
                            htmlFor="Crash Monitoring Tool"
                            className="form-label font-14 font-semibold color-black"
                          >
                            Crash Monitoring Tool
                          </label>
                          <span className="color-red"> *</span>
                          <ConnectorSelectDropdown
                            dataOptions={CrashMonitoringDropdown}
                            dataValue={
                              connectorFormData["Crash Monitoring Tool"] === ""
                                ? []
                                : connectorFormData["Crash Monitoring Tool"]
                            }
                            handleChange={handleMultiSelectChange}
                            handleTreeChange={handleSnTreeSelectChange}
                            mode={"select"}
                            dropdownName={"Crash Monitoring Tool"}
                          />
                          <p className="mt-2 mb-0 color-red font-14 font-regular">
                            {
                              connectorFormValidationMsg[
                                "Crash Monitoring Tool"
                              ]
                            }
                          </p>
                        </div>
                      </div>
                      <div
                        className={`col-md-6 ${
                          connectorFormData[
                            "System integrated with Crash Monitoring System?"
                          ] === "Yes"
                            ? ""
                            : "hideActionNeeded"
                        }`}
                      >
                        <div className="mb-5">
                          <label className="form-label font-14 font-semibold color-black d-flex">
                            Do we have access to the Crash Monitoring Tools?
                            <span className="color-red px-1"> *</span>
                          </label>
                          <div className="form-check form-check-inline mt-2">
                            <input
                              className="form-check-input theme-radio label-bold"
                              type="radio"
                              name="Do we have access to the Crash Monitoring Tools?"
                              id="Do we have access to the Crash Monitoring Tools Yes"
                              value="Yes"
                              checked={
                                connectorFormData[
                                  "Do we have access to the Crash Monitoring Tools?"
                                ] === "Yes"
                              }
                              onChange={(e) => {
                                connectorsOnChange(e);
                              }}
                            />
                            <label
                              className="form-check-label font-regular font-14 color-black"
                              htmlFor="Do we have access to the Crash Monitoring Tools Yes"
                            >
                              Yes
                            </label>
                          </div>
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input theme-radio label-bold"
                              type="radio"
                              name="Do we have access to the Crash Monitoring Tools?"
                              id="Do we have access to the Crash Monitoring Tools No"
                              value="No"
                              checked={
                                connectorFormData[
                                  "Do we have access to the Crash Monitoring Tools?"
                                ] === "No"
                              }
                              onChange={(e) => {
                                connectorsOnChange(e);
                              }}
                            />
                            <label
                              className="form-check-label font-regular font-14 color-black"
                              htmlFor="Do we have access to the Crash Monitoring Tools No"
                            >
                              No
                            </label>
                          </div>
                          <p className="mt-2 mb-0 color-red font-14 font-regular">
                            {
                              connectorFormValidationMsg[
                                "Do we have access to the Crash Monitoring Tools?"
                              ]
                            }
                          </p>
                        </div>
                      </div>
                      {bindMobilityCrashMonitoringCredsFields()}

                      {/* Log Monitoring System Start*/}
                      <h6 className="font-20 font-bold mb-4">
                        Log Monitoring System
                      </h6>
                      <div className="col-md-6">
                        <div className="mb-4">
                          <label className="form-label font-14 font-semibold color-black d-flex">
                            System integrated with Log Monitoring System?
                            <span className="color-red px-1"> *</span>
                          </label>
                          <div className="form-check form-check-inline mt-2">
                            <input
                              className="form-check-input theme-radio label-bold"
                              type="radio"
                              name="System integrated with Log Monitoring System?"
                              id="System integrated with Log Monitoring System Yes"
                              value="Yes"
                              checked={
                                connectorFormData[
                                  "System integrated with Log Monitoring System?"
                                ] === "Yes"
                              }
                              onChange={(e) => {
                                connectorsOnChange(e);
                              }}
                            />
                            <label
                              className="form-check-label font-regular font-14 color-black"
                              htmlFor="System integrated with Log Monitoring System Yes"
                            >
                              Yes
                            </label>
                          </div>
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input theme-radio label-bold"
                              type="radio"
                              name="System integrated with Log Monitoring System?"
                              id="System integrated with Log Monitoring System No"
                              value="No"
                              checked={
                                connectorFormData[
                                  "System integrated with Log Monitoring System?"
                                ] === "No"
                              }
                              onChange={(e) => {
                                connectorsOnChange(e);
                              }}
                            />
                            <label
                              className="form-check-label font-regular font-14 color-black"
                              htmlFor="System integrated with Log Monitoring System No"
                            >
                              No
                            </label>
                          </div>
                          <p className="mt-2 mb-0 color-red font-14 font-regular">
                            {
                              connectorFormValidationMsg[
                                "System integrated with Log Monitoring System?"
                              ]
                            }
                          </p>
                        </div>
                      </div>
                      <div
                        className={`col-md-6 ${
                          connectorFormData[
                            "System integrated with Log Monitoring System?"
                          ] === "Yes"
                            ? ""
                            : "hideActionNeeded"
                        }`}
                      >
                        <div className="mb-4">
                          <label
                            htmlFor="Log Monitoring Tool"
                            className="form-label font-14 font-semibold color-black"
                          >
                            Log Monitoring Tool
                          </label>
                          <span className="color-red"> *</span>
                          <ConnectorSelectDropdown
                            dataOptions={LogMonitoringDropdown}
                            dataValue={connectorFormData["Log Monitoring Tool"]}
                            handleChange={handleMultiSelectChange}
                            handleTreeChange={handleSnTreeSelectChange}
                            mode={"select"}
                            dropdownName={"Log Monitoring Tool"}
                          />
                          <p className="mt-2 mb-0 color-red font-14 font-regular">
                            {connectorFormValidationMsg["Log Monitoring Tool"]}
                          </p>
                        </div>
                      </div>
                      <div
                        className={`col-md-6 ${
                          connectorFormData[
                            "System integrated with Log Monitoring System?"
                          ] === "Yes"
                            ? ""
                            : "hideActionNeeded"
                        }`}
                      >
                        <div className="mb-5">
                          <label className="form-label font-14 font-semibold color-black d-flex">
                            Do we have access to the Log Monitoring Tools?
                            <span className="color-red px-1"> *</span>
                          </label>
                          <div className="form-check form-check-inline mt-2">
                            <input
                              className="form-check-input theme-radio label-bold"
                              type="radio"
                              name="Do we have access to the Log Monitoring Tools?"
                              id="Do we have access to the Log Monitoring Tools Yes"
                              value="Yes"
                              checked={
                                connectorFormData[
                                  "Do we have access to the Log Monitoring Tools?"
                                ] === "Yes"
                              }
                              onChange={(e) => {
                                connectorsOnChange(e);
                              }}
                            />
                            <label
                              className="form-check-label font-regular font-14 color-black"
                              htmlFor="Do we have access to the Log Monitoring Tools Yes"
                            >
                              Yes
                            </label>
                          </div>
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input theme-radio label-bold"
                              type="radio"
                              name="Do we have access to the Log Monitoring Tools?"
                              id="Do we have access to the Log Monitoring Tools No"
                              value="No"
                              checked={
                                connectorFormData[
                                  "Do we have access to the Log Monitoring Tools?"
                                ] === "No"
                              }
                              onChange={(e) => {
                                connectorsOnChange(e);
                              }}
                            />
                            <label
                              className="form-check-label font-regular font-14 color-black"
                              htmlFor="Do we have access to the Log Monitoring Tools No"
                            >
                              No
                            </label>
                          </div>
                          <p className="mt-2 mb-0 color-red font-14 font-regular">
                            {
                              connectorFormValidationMsg[
                                "Do we have access to the Log Monitoring Tools?"
                              ]
                            }
                          </p>
                        </div>
                      </div>

                      {bindMobilityLogMonitoringCredsFields()}

                      <h6 className="font-20 font-bold mb-4">Analytics</h6>
                      <div className="col-md-6">
                        <div className="mb-4">
                          <label className="form-label font-14 font-semibold color-black d-flex">
                            System integrated with Analytics System?
                            <span className="color-red px-1"> *</span>
                          </label>
                          <div className="form-check form-check-inline mt-2">
                            <input
                              className="form-check-input theme-radio label-bold"
                              type="radio"
                              name="System integrated with Analytics System?"
                              id="System integrated with Analytics System Yes"
                              value="Yes"
                              checked={
                                connectorFormData[
                                  "System integrated with Analytics System?"
                                ] === "Yes"
                              }
                              onChange={(e) => {
                                connectorsOnChange(e);
                              }}
                            />
                            <label
                              className="form-check-label font-regular font-14 color-black"
                              htmlFor="System integrated with Analytics System Yes"
                            >
                              Yes
                            </label>
                          </div>
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input theme-radio label-bold"
                              type="radio"
                              name="System integrated with Analytics System?"
                              id="System integrated with Analytics System No"
                              value="No"
                              checked={
                                connectorFormData[
                                  "System integrated with Analytics System?"
                                ] === "No"
                              }
                              onChange={(e) => {
                                connectorsOnChange(e);
                              }}
                            />
                            <label
                              className="form-check-label font-regular font-14 color-black"
                              htmlFor="System integrated with Analytics System No"
                            >
                              No
                            </label>
                          </div>
                          <p className="mt-2 mb-0 color-red font-14 font-regular">
                            {
                              connectorFormValidationMsg[
                                "System integrated with Analytics System?"
                              ]
                            }
                          </p>
                        </div>
                      </div>
                      <div
                        className={`col-md-6 ${
                          connectorFormData[
                            "System integrated with Analytics System?"
                          ] === "Yes"
                            ? ""
                            : "hideActionNeeded"
                        }`}
                      >
                        <div className="mb-4">
                          <label
                            htmlFor="Analytics Tool"
                            className="form-label font-14 font-semibold color-black"
                          >
                            Analytics Tool
                          </label>
                          <span className="color-red"> *</span>
                          <ConnectorSelectDropdown
                            dataOptions={AnalyticsMonitoringDropdown}
                            dataValue={connectorFormData["Analytics Tool"]}
                            handleChange={handleMultiSelectChange}
                            handleTreeChange={handleSnTreeSelectChange}
                            mode={"select"}
                            dropdownName={"Analytics Tool"}
                          />
                          <p className="mt-2 mb-0 color-red font-14 font-regular">
                            {connectorFormValidationMsg["Analytics Tool"]}
                          </p>
                        </div>
                      </div>
                      <div
                        className={`col-md-6 ${
                          connectorFormData[
                            "System integrated with Analytics System?"
                          ] === "Yes"
                            ? ""
                            : "hideActionNeeded"
                        }`}
                      >
                        <div className="mb-5">
                          <label className="form-label font-14 font-semibold color-black d-flex">
                            Do we have access to the Analytics Tools?
                            <span className="color-red px-1"> *</span>
                          </label>
                          <div className="form-check form-check-inline mt-2">
                            <input
                              className="form-check-input theme-radio label-bold"
                              type="radio"
                              name="Do we have access to the Analytics Tools?"
                              id="Do we have access to the Analytics Tools Yes"
                              value="Yes"
                              checked={
                                connectorFormData[
                                  "Do we have access to the Analytics Tools?"
                                ] === "Yes"
                              }
                              onChange={(e) => {
                                connectorsOnChange(e);
                              }}
                            />
                            <label
                              className="form-check-label font-regular font-14 color-black"
                              htmlFor="Do we have access to the Analytics Tools Yes"
                            >
                              Yes
                            </label>
                          </div>
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input theme-radio label-bold"
                              type="radio"
                              name="Do we have access to the Analytics Tools?"
                              id="Do we have access to the Analytics Tools No"
                              value="No"
                              checked={
                                connectorFormData[
                                  "Do we have access to the Analytics Tools?"
                                ] === "No"
                              }
                              onChange={(e) => {
                                connectorsOnChange(e);
                              }}
                            />
                            <label
                              className="form-check-label font-regular font-14 color-black"
                              htmlFor="Do we have access to the Analytics Tools No"
                            >
                              No
                            </label>
                          </div>
                          <p className="mt-2 mb-0 color-red font-14 font-regular">
                            {
                              connectorFormValidationMsg[
                                "Do we have access to the Analytics Tools?"
                              ]
                            }
                          </p>
                        </div>
                      </div>
                      {bindMobilityAnalyticsCredsFields()}
                      {bindSaveAndCancel()}
                    </div>
                  </div>
                </div>
              </div>
              {/* crash log ends here */}
            </div>
          </div>
        );
    }
  };

  return <>{bindConnectorsForm()}</>;
}
