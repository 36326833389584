import react, { useState, useEffect, useContext } from "react"
import { assessmentDataModel, assessmentFilterDataModel, assessmentSortOrderModel, deleteDataModel, domainsModel, editedAssessmentModel, selectedControlAreasModel, toastStateModel, verticalModel } from "../interface/AssessmentGridModel"
import { toastModel } from "../interface/InitiativeModel"
import { sortOrderModel } from "../interface/OrganizationGridModel"
import { useLocation, useNavigate } from "react-router-dom"
import { getAssessmentData } from "../helpers/encryption"
import { deleteAssessment, getAssessment, getDomains, postAssessment } from "../service/AssesmentApi"
import ToastComponent from "./ToastComponent"
import Loader from "./Loader"
import moment from "moment"
import AssessmentForm from "./AssessmentForm"
import DeletePopup from "./DeletePopup"
import { postReassessProject } from "../service/QuestionnaireApi"

const AssessmentGrid = () => {

    const location = useLocation()
    const { organizationId, organizationName } = location.state
    //Initial data
    const initialFilterData: assessmentFilterDataModel = {
        organizationId: organizationId,
        verticalId: "",
        search: "",
        assssmentType: "",
        domainVerticalId: "",
        status: "",
    }

    const initialEditedAssessment: editedAssessmentModel = {
        organizationId: organizationId,
        domainId: "",
        verticalId: "",
        projectId: "",
        projectName: "",
        isEdit: false,
    }

    const initialDeleteData: deleteDataModel = {
        organizationId: "",
        verticalId: "",
        projectId: "",
    }
    const initialSortData: assessmentSortOrderModel = {
        domainVertical: "asc",
        controlAreaCount: "asc",
        controlAreaName: "asc",
    }
    const toastData: toastModel = {
        toastType: '',
        toastHeaderMessage: '',
        toastBodyMessage: 'Saved Successfully',
    }
    const initialToastState: toastStateModel = {
        hideToast: true,
        type: "error",
        message: "Api Fail"
    }




    //Initializing state variables
    const [assessmentData, setAssessmentData] = useState<assessmentDataModel[]>([])
    const [editedAssessment, setEditedAssessment] = useState<editedAssessmentModel>(initialEditedAssessment)
    const [filterData, setFilterData] = useState<assessmentFilterDataModel>(initialFilterData)
    const [filterParams, setFilterParams] = useState<assessmentFilterDataModel>(initialFilterData)
    const [domains, setDomains] = useState<domainsModel[]>([])
    const [verticals, setVerticals] = useState<verticalModel[]>([])
    const [sortOrder, setSortOrder] = useState<assessmentSortOrderModel>(initialSortData)
    const [selectedControlAreas, setSelectedControlAreas] = useState<selectedControlAreasModel[]>([])
    const [deleteData, setDeleteData] = useState<deleteDataModel>(initialDeleteData)
    const [hideDeletePopup, setHideDeletePopup] = useState<boolean>(true)
    const [hideFilter, setHideFilter] = useState<boolean>(true)
    const [hideForm, setHideForm] = useState<boolean>(true)
    const [hideControlAreasPopup, setHideControlAreasPopup] = useState<boolean>(true)
    const [toastState, setToastState] = useState<toastStateModel>(initialToastState)
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const navigate = useNavigate()



    //useEffect to fect domains
    useEffect(() => {
        fetchDomains()
    }, [])
    //useEffect to fetch organization details
    useEffect(() => {
        fetchAssessmentData()
    }, [filterParams])

    useEffect(() => {
        const handleBeforeUnload = (e: any) => {
            e.preventDefault(); // Prevent the page from reloading
            e.returnValue =
                "You may have unsaved changes. Are you sure you want to reload or leave?"; // Some browsers require a return value
        };

        window.onbeforeunload = function () {
            return "You may have unsaved changes. Are you sure you want to reload or leave?";
        };
        window.addEventListener("beforeunload", handleBeforeUnload);
    }, []);


    //Function to fecth domains
    const fetchDomains = async () => {
        try {
            setIsLoading(true)
            const response = await getDomains()
            if (response.status === 200) {
                setDomains(response.data)
            }
            else {
                setIsLoading(false)
                setToastState({ hideToast: false, type: 'error', message: 'Api Failed' })
            }
        }
        catch (error) {
            setIsLoading(false)
            setToastState({ hideToast: false, type: 'error', message: 'Api Failed' })
        }
    }


    //Function to fetch assessment datails
    const fetchAssessmentData = async () => {
        try {
            setIsLoading(true)
            const response = await getAssessment(filterParams)
            if (response.status === 200) {
                setIsLoading(false)
                setAssessmentData(response.data)
            }
            else {
                setIsLoading(false)
                setToastState({ hideToast: false, type: 'error', message: 'Api Failed' })
            }
        }
        catch (error) {
            setIsLoading(false)
            setToastState({ hideToast: false, type: 'error', message: 'Api Failed' })
        }
    }

    //Function to post assessments
    const addAssessment = async (data: editedAssessmentModel) => {
        setIsLoading(true)
        setHideForm(true)
        try {
            const response = await postAssessment(data)
            if (response.status === 200) {
                await fetchAssessmentData()
                if (data?.projectId)
                    setToastState({ hideToast: false, type: 'success', message: 'Assessment has been edited successfully' })
                else
                    setToastState({ hideToast: false, type: 'success', message: 'Assessment has been added successfully' })
                showToast()
            }
            else {
                setIsLoading(false)
                setToastState({ hideToast: false, type: 'error', message: 'Api Failed' })
            }
        }
        catch (error) {
            setIsLoading(false)
            setToastState({ hideToast: false, type: 'error', message: 'Api Failed' })
        }
    }

    //Function to delete the assessment 
    const deleteAssessmentData = async () => {
        try {
            setIsLoading(true)
            const response = await deleteAssessment({ "organizationId": organizationId, "verticalId": deleteData.verticalId, "projectId": deleteData.projectId });
            if (response.status === 200) {
                await fetchAssessmentData()
                setToastState({ hideToast: false, type: 'success', message: 'Assessment has been deleted successfully' })
                showToast()
            }
            else {
                setIsLoading(false)
                setToastState({ hideToast: false, type: 'error', message: 'Api Failed' })
            }
        }
        catch (error) {
            setIsLoading(false)
            setToastState({ hideToast: false, type: 'error', message: 'Api Failed' })
        }
    }

    const reassessProject = async (data: assessmentDataModel) => {
        try {
            let response = await postReassessProject({
                organizationId: organizationId,
                verticalId: data.assessmentTypeId,
                projectId: data.assessmentId,
            });
            if (response.status == 200) {
                if (data.assessmentType === "MAP") {
                    navigate("/infraassessment", {
                        state: {
                            organizationId: organizationId,
                            organizationName: organizationName,
                            projectId: data.assessmentId,
                            projectName: data.assessmentName,
                            verticalId: data.assessmentTypeId,
                            verticalName: data.assessmentType,
                            mode: "assess",
                        },
                    });
                } else {
                    navigate("/assessment", {
                        state: {
                            organizationId: organizationId,
                            organizationName: organizationName,
                            projectId: data.assessmentId,
                            projectName: data.assessmentName,
                            verticalId: data.assessmentTypeId,
                            verticalName: data.assessmentType,
                            mode: "assess",
                        },
                    });
                }
            } else {
                setIsLoading(false);
                setToastState({
                    hideToast: false,
                    type: "error",
                    message: "Api Failed",
                });
            }
        } catch (error) {
            setIsLoading(false);
            setToastState({ hideToast: false, type: "error", message: "Api Failed" });
        }
    };


    //Function to handle the onChange event on filter data
    const onChangeFilter = (id: string, value: string) => {
        setFilterData({ ...filterData, [id]: value })
        if (id === "search" && value.length === 0)
            setFilterParams({ ...filterParams, "search": value.trim() })
        else if (id === "domainVerticalId") {
            setFilterData({ ...filterData, [id]: value, verticalId: "" })
            setVerticals(domains.find((data: domainsModel) => {
                return data.domainId === value
            })?.verticals || [])

        }
    }

    //Function to handle the sort
    const onClickSort = (columnName: string) => {
        const sortId: keyof assessmentSortOrderModel = columnName as keyof assessmentSortOrderModel
        if (columnName === "controlAreaName") {
            const id: keyof selectedControlAreasModel = columnName as keyof selectedControlAreasModel
            const sortedData = JSON.parse(JSON.stringify(selectedControlAreas))
            sortedData?.sort((a: selectedControlAreasModel, b: selectedControlAreasModel) => {
                const order = sortOrder[sortId] === "asc" ? 1 : -1
                return order * a[id].localeCompare(b[id])
            })
            setSelectedControlAreas(sortedData)
        }
        else {
            const id: keyof assessmentDataModel = columnName as keyof assessmentDataModel
            const sortedData = JSON.parse(JSON.stringify(assessmentData))
            sortedData?.sort((a: assessmentDataModel, b: assessmentDataModel) => {
                const order = sortOrder[sortId] === "asc" ? 1 : -1
                if (id === "controlAreaDetails")
                    return;
                return order * a[id].localeCompare(b[id])
            })
            setAssessmentData(sortedData)
        }
    }


    //Function to show tiast popup
    const showToast = () => {
        setTimeout(() => {
            setToastState({ hideToast: true, type: 'error', message: 'Api Failed' })
        }, 4000)
    }

    //Function to bind the assessment type
    const bindVerticals = () => {
        return (
            verticals?.map((data: verticalModel) => {
                return (
                    <option value={data.verticalId} key={data.verticalId}>{data.verticalName}</option>
                )
            })
        )
    }

    //Function to bind the domains
    const bindDomains = () => {
        return (
            domains?.map((data: domainsModel) => {
                return (
                    <option value={data.domainId} key={data.domainName}>{data.domainName}</option>
                )
            })
        )
    }

    //Function to bind the actions
    const bindAction = (data: assessmentDataModel) => {
        switch (data.status) {
            case "Not Started":
                return (
                    <>
                        <span className="d-flex align-items-center gap-2 list p-2"
                            onClick={() => {
                                if (data.assessmentType === "MAP") {
                                    navigate("/infraassessment", {
                                        state: {
                                            organizationId: organizationId,
                                            organizationName: organizationName,
                                            projectId: data.assessmentId,
                                            projectName: data.assessmentName,
                                            verticalId: data.assessmentTypeId,
                                            verticalName: data.assessmentType,
                                            mode: "assess",
                                        },
                                    });
                                } else {
                                    navigate("/assessment", {
                                        state: {
                                            organizationId: organizationId,
                                            organizationName: organizationName,
                                            projectId: data.assessmentId,
                                            projectName: data.assessmentName,
                                            verticalId: data.assessmentTypeId,
                                            verticalName: data.assessmentType,
                                            mode: "assess",
                                        },
                                    });
                                }
                            }}
                        >
                            Start assessment
                        </span>
                        <span className="d-flex align-items-center gap-2 list p-2" onClick={() => {
                            setDeleteData({ organizationId: organizationId, verticalId: data.assessmentTypeId, projectId: data.assessmentId });
                            setHideDeletePopup(false)
                        }}>Delete</span>
                        <span className="d-flex align-items-center gap-2 list p-2" onClick={() => {
                            setEditedAssessment({
                                organizationId: location.state?.organizationId,
                                domainId: data.domainVerticalId,
                                verticalId: data.assessmentTypeId,
                                projectId: data.assessmentId,
                                projectName: data.assessmentName,
                                isEdit: true
                            });
                            setHideForm(false);
                        }}>Rename assessment</span>
                    </>)
            case "In Progress":
                return (
                    <>
                        <span className="d-flex align-items-center gap-2 list p-2"
                            onClick={() => {
                                if (data.assessmentType === "MAP") {
                                    navigate("/infraassessment", {
                                        state: {
                                            organizationId: organizationId,
                                            organizationName: organizationName,
                                            projectId: data.assessmentId,
                                            projectName: data.assessmentName,
                                            verticalId: data.assessmentTypeId,
                                            verticalName: data.assessmentType,
                                            mode: "Edit",
                                        },
                                    });
                                } else {
                                    navigate("/assessment", { state: { organizationId: organizationId, organizationName: organizationName, verticalId: data.assessmentTypeId, verticalName: data.assessmentType, projectId: data.assessmentId, projectName: data.assessmentName, mode: "Edit" } })
                                }
                            }}>Resume assessment</span>
                        <span className="d-flex align-items-center gap-2 list p-2" onClick={() => {
                            setEditedAssessment({
                                organizationId: location.state?.organizationId,
                                domainId: data.domainVerticalId,
                                verticalId: data.assessmentTypeId,
                                projectId: data.assessmentId,
                                projectName: data.assessmentName,
                                isEdit: true
                            });
                            setHideForm(false);
                        }}>Rename assessment</span>

                    </>)

            case "Completed":
                return (
                    <>
                        {(data.assessmentType === "WAFR" || data.assessmentType === "MAP") && (
                            <span className="d-flex align-items-center gap-2 list p-2" onClick={() => {
                                const route = data.assessmentType.toLowerCase() === "map" ? "/infraassessment" : "/assessment";
                                navigate(route, {
                                    state: {
                                        organizationId: organizationId,
                                        organizationName: organizationName,
                                        verticalId: data.assessmentTypeId,
                                        verticalName: data.assessmentType,
                                        projectId: data.assessmentId,
                                        projectName: data.assessmentName,
                                        mode: "Edit"
                                    }
                                });
                            }}>Edit assessment</span>
                        )}
                        {data.assessmentType !== "WAFR" && (
                            <span className="d-flex align-items-center gap-2 list p-2" onClick={() => {
                                reassessProject(data)
                            }}>Reassess</span>
                        )}
                        <span className="d-flex align-items-center gap-2 list p-2" onClick={() => {
                            navigate("/reports", { state: { organizationId: organizationId, organizationName: organizationName, verticalId: data.assessmentTypeId, verticalName: data.assessmentType, projectId: data.assessmentId, projectName: data.assessmentName, isViewReport: true } })
                        }}>View report</span>
                        <span className="d-flex align-items-center gap-2 list p-2" onClick={() => {
                            setEditedAssessment({
                                organizationId: organizationId,
                                domainId: data.domainVerticalId,
                                verticalId: data.assessmentTypeId,
                                projectId: data.assessmentId,
                                projectName: data.assessmentName,
                                isEdit: true
                            });
                            setHideForm(false);
                        }}>Rename assessment</span>
                    </>)

        }
    }
    //Funtion to bind the control areas
    const bindControlAreas = () => {
        const controlAreaData = new Map();

        selectedControlAreas?.forEach(({ controlAreaId, controlAreaName, proficiencyIndex, impact }) => {
            const existingData = controlAreaData.get(controlAreaId);
            if (existingData) {
                if (proficiencyIndex) {
                    existingData.proficiencyIndices.push(parseFloat(proficiencyIndex));
                    existingData.count++;
                }
            } else {
                controlAreaData.set(controlAreaId, {
                    controlAreaName,
                    proficiencyIndices: proficiencyIndex ? [parseFloat(proficiencyIndex)] : [],
                    impact: impact || "",
                    count: proficiencyIndex ? 1 : 0,
                });
            }
        });

        return Array.from(controlAreaData, ([, data]) => (
            <tr key={data.controlAreaId}>
                <td>{data.controlAreaName}</td>
                <td>{
                    data.count > 0
                        ? Math.round(data.proficiencyIndices.reduce((acc: number, val: number) => acc + val, 0) / data.count) + '%'
                        : data.impact || "-"
                }</td>
            </tr>
        ));
    };

    const countUniqueControlAreas = (controlAreaDetails: selectedControlAreasModel[]) => {
        const uniqueControlAreas = new Set(controlAreaDetails.map(area => area.controlAreaId));
        return uniqueControlAreas.size;
    }

    //Function to bind the assessments
    const bindAssessments = () => {
        if (!assessmentData?.length) {
            return (
                <td colSpan={7}>
                    <div className="row text-center">
                        <img src="images/no-records-found.svg" width={"300"} height={"300"} />
                        <p className="font-16 font-medium color-grey-v3 my-5">
                            No Records Found
                        </p>
                    </div>
                </td>
            )
        }

        return (
            assessmentData?.map((data: assessmentDataModel) => {
                const uniqueControlAreaCount = countUniqueControlAreas(data.controlAreaDetails);
                return (
                    <tr key={data.assessmentId}>
                        <td><span className="assessmen-data">{data.domainVertical}</span></td>
                        <td><span className="assessmen-data">{data.assessmentName}</span></td>
                        <td><span className="assessmen-data">{data.assessmentType}</span></td>
                        <td>
                            <a href="" className="text-decoration-underline link-blue me-2" onClick={(event: any) => {
                                event.preventDefault();
                                setSelectedControlAreas(data.controlAreaDetails)
                                setHideControlAreasPopup(false)
                            }}>{uniqueControlAreaCount}</a>
                        </td>
                        <td>{moment(data.lastAssessedOn?.split("T")?.at(0), 'YYYY-MM-DD').format('MMM D, YYYY')}</td>
                        <td className="text-center"><span className={`${data.status === "Not Started" ? "grey" : data.status === "In Progress" ? "orange" : "green"}-badge`}>{data.status}</span></td>
                        <td className="text-center">
                            <span className="action-drop-down">
                                <button data-bs-toggle="dropdown" data-bs-auto-close="true" className="kebab-icon"><img src="images/keb-menu-icon.svg" alt="keb-menu-icon" /></button>
                                <span className="dropdown-menu font-regular font-14 px-3 py-0" data-popper-placement="bottom-end" style={{ position: 'absolute', inset: '0px auto auto 0px', margin: '0px', transform: 'translate(1217px, 103px)' }}>
                                    {bindAction(data)}
                                </span>
                            </span>
                        </td>
                    </tr>
                )
            })
        )

    }


    return (
        <>
            {
                isLoading ? <Loader isLoading={isLoading} /> :
                    <>
                        <div className="container-fluid">
                            <div className="row justify-content-center">
                                <div className="px-4">
                                    <ol className="breadcrumb  mt-5 pt-5 font-12 font-semibold  mb-4">
                                        <li className="breadcrumb-item inactive"><a href="#" onClick={(e) => { e.preventDefault(); navigate("/homepage"); }} >Organization</a></li>
                                        <li className="breadcrumb-item active" aria-current="page"> {`${location.state.organizationName}'s Assessment`}</li>
                                    </ol>
                                    <div className="d-block d-md-flex flex-wrap justify-content-between align-items-center mb-4">
                                        <div className="d-block d-md-flex flex-wrap justify-content-between align-items-center  mb-4">
                                            <div className="d-flex align-items-center mb-4 mb-md-0">
                                                <a href=""><img src="images/backarrow.svg" alt="back-arrow" title="Back" onClick={(event: any) => {
                                                    event.preventDefault();
                                                    navigate("/homepage")
                                                }} /></a>
                                                <h2 className="font-22 font-semibold color-black mb-0 ms-3 ">
                                                    {`${location.state.organizationName}'s Assessment`}
                                                </h2>
                                            </div>
                                        </div>
                                        <div className="d-block d-md-flex">

                                            <div className="w-md-100 d-flex mb-3 mb-md-0">

                                                <span className="position-relative d-flex align-items-center me-4 w-100" >
                                                    <input type="text" className="form-control theme-form font-regular font-14 top-0 bottom-0 py-2 " id="search" placeholder="Search" value={filterData.search} onChange={(event: any) => { onChangeFilter("search", event.target.value) }} onKeyDown={(event: any) => {
                                                        setHideFilter(true)
                                                        if (event.key === 'Enter') {
                                                            setFilterParams({ ...filterData, search: filterData.search.trim() })
                                                        }
                                                    }} />
                                                    <span className="position-absolute search-icon" ><img src="images/search-icon.svg" alt="search-icon" onClick={() => { setFilterParams({ ...filterData, search: filterData.search.trim() }) }} /></span>

                                                </span>

                                                <div className="btn-group">
                                                    <button type="button" className="btn btn-secondary btn-white rounded-2 table-btn" onClick={() => { setHideFilter(false) }}>
                                                        <img src="images/adv-fliter.svg" alt="adv-fliter" />
                                                    </button>
                                                    {hideFilter ? <></> :
                                                        <div className="dropdown-menu dropdown-menu-end adv-dropdown p-4 adv-filter show" data-popper-placement="bottom-end" style={{ position: 'absolute', inset: '0px auto auto 0px', margin: '0px', transform: 'translate(-442px, 42px)' }}>
                                                            <div className="row">
                                                                <div className="d-flex justify-content-between align-items-center mb-3">
                                                                    <h6 className="font-bold color-black">Advanced Filter</h6>
                                                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => { setHideFilter(true) }} />
                                                                </div>
                                                                <div className="col-sm-12 col-md-6 mb-3">
                                                                    <label htmlFor="industry" className="form-label font-14 font-semibold color-black">Practice</label>
                                                                    <select id="industry" className="theme-form form-select font-regular custom-form font-14" value={filterData.domainVerticalId} onChange={(event: any) => { onChangeFilter("domainVerticalId", event.target.value) }}>
                                                                        <option selected disabled value={""}>Select</option>
                                                                        {bindDomains()}
                                                                    </select>
                                                                </div>


                                                                <div className="col-sm-12 col-md-6 mb-3">
                                                                    <label htmlFor="employee" className="form-label font-14 font-semibold color-black">Assessment Type</label>
                                                                    <select id="employee" className="theme-form form-select font-regular custom-form font-14" value={verticals?.length <= 1 ? "" : filterData.verticalId} onChange={(event: any) => { onChangeFilter("verticalId", event.target.value) }} disabled={verticals?.length <= 1}>
                                                                        <option selected disabled value={""} >Select assessment</option>
                                                                        {bindVerticals()}
                                                                    </select>
                                                                </div>

                                                                <div className="col-sm-12 col-md-6 mb-3">
                                                                    <label htmlFor="Revenue" className="form-label font-14 font-semibold color-black" >Status</label>
                                                                    <select id="Revenue" className="theme-form form-select font-regular custom-form font-14" value={filterData.status} onChange={(event: any) => { onChangeFilter("status", event.target.value) }}>
                                                                        <option selected disabled value={""}>Select status</option>
                                                                        <option value="Not Started">Not Started</option>
                                                                        <option value="In Progress">In Progress</option>
                                                                        <option value="Completed">Completed</option>
                                                                    </select>
                                                                </div>
                                                                <div className="col-md-12 d-flex justify-content-end mt-4">
                                                                    <a className="theme-link-btn font-14 font-semibold text-center order-md-first cursor-pointer" onClick={() => {
                                                                        setFilterData({ ...filterData, domainVerticalId: "", verticalId: "", status: "" });
                                                                        setFilterParams({ ...filterParams, search: filterParams.search, domainVerticalId: "", verticalId: "", status: "" })
                                                                        setHideFilter(true)
                                                                    }}>Clear</a>
                                                                    <button type="button" className="btn btn-dark theme-primary-btn border-0 font-semibold "
                                                                        onClick={() => {
                                                                            setHideFilter(true)
                                                                            setFilterParams({ ...filterData, search: filterParams.search })
                                                                        }}>Apply</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    }
                                                </div>
                                            </div>

                                            <button type="button" className="btn btn-dark theme-primary-btn border-0 font-14 font-semibold ms-md-3 ms-0 table-btn w-auto text-nowrap w-md-100" onClick={() => {
                                                setEditedAssessment(initialEditedAssessment);
                                                setHideForm(false)
                                            }}>

                                                <span className="me-2"><img src="images/whiteplus.svg" alt="plus" /></span>Start New Assessment</button>
                                        </div>
                                    </div>
                                    {/* table starts here */}
                                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">

                                        <div className="table-responsive mb-4">
                                            <table className="table table-bordered themeTable text-nowrap">
                                                <thead className="font-semibold ">
                                                    <tr>
                                                        <th>Practise  {assessmentData?.length > 0 && (<span className="ms-2 cursor-pointer" onClick={() => { onClickSort("domainVertical"); setSortOrder({ ...sortOrder, domainVertical: sortOrder.domainVertical === "asc" ? "desc" : "asc" }) }}>{sortOrder.domainVertical === "asc" ?
                                                            <img src="images/sortarrow.svg" alt="sortarrow" title="Sort" /> :
                                                            <img src="images/sortarrowup.svg" alt="sortarrow" title="Sort" />
                                                        }</span>)}</th>
                                                        <th>Assessment Name</th>
                                                        <th>Assessment Type</th>
                                                        <th>Control Area  {assessmentData?.length > 0 && (<span className="ms-2 cursor-pointer" onClick={() => { onClickSort("controlAreaCount"); setSortOrder({ ...sortOrder, controlAreaCount: sortOrder.controlAreaCount === "asc" ? "desc" : "asc" }) }}>{sortOrder.controlAreaCount === "asc" ?
                                                            <img src="images/sortarrow.svg" alt="sortarrow" title="Sort" /> :
                                                            <img src="images/sortarrowup.svg" alt="sortarrow" title="Sort" />
                                                        }</span>)}</th>
                                                        <th>Last Assessed on</th>
                                                        <th className="text-center">Status</th>
                                                        <th className="text-center">Actions</th>
                                                    </tr>
                                                </thead>
                                                <tbody className="font-medium">
                                                    {bindAssessments()}
                                                </tbody>
                                            </table>
                                        </div>

                                    </div>
                                    {/* first tab ends here */}
                                    <div className="tab-pane fade" id="pills-inprogress" role="tabpanel">InProgress</div>
                                    <div className="tab-pane fade" id="pills-completed" role="tabpanel">Completed</div>
                                </div>
                                {/* tabs ends here */}
                            </div>
                        </div>
                        <Loader isLoading={isLoading} />
                        {/* {hideForm ? <></> : <AssessmentForm setHideForm={setHideForm} editedData={editedAssessment}
                domains={domains} 
            />} */}
                    </>
            }
            {hideForm ? <></> : <AssessmentForm setHideForm={setHideForm} editedData={editedAssessment}
                domains={domains} addAssessment={addAssessment}
            />}
            {hideControlAreasPopup ? <></> :

                <div className="modal fade show" style={{ display: "block" }}>
                    <div className="modal-dialog modal-dialog-centered" style={{ position: "absolute", top: "5%", left: "35%" }}>
                        <div className="modal-content custom-popup w-650">
                            <div className="modal-header border-0 justify-content-between align-items-center position-relative px-4 pt-4 pb-2">
                                <h2 className="modal-title mb-0 font-20 font-semibold primary-textcolor" id="accept-promptLabel">
                                    Control Area</h2>
                                <button type="button" className="btn" data-bs-dismiss="modal" aria-label="Close" onClick={() => { setHideControlAreasPopup(true) }}><img src="images/popup-close.svg" alt="Close" /></button>
                            </div>
                            <div className="modal-body border-0 px-4" style={bindControlAreas().length > 7 ? { maxHeight: 'calc(7 * 70px)', overflowY: 'auto' } : {}}>
                                <div className="table-responsive">
                                    <table className="table themeTable no-border">
                                        <thead className="font-semibold font-12">
                                            <tr>
                                                <th>Control Areas<span className="ms-2 cursor-pointer" onClick={() => { onClickSort("controlAreaName"); setSortOrder({ ...sortOrder, controlAreaName: sortOrder.controlAreaName === "asc" ? "desc" : "asc" }) }}>{sortOrder.controlAreaName === "asc" ?
                                                    <img src="images/sortarrow.svg" alt="sortarrow" title="Sort" /> :
                                                    <img src="images/sortarrowup.svg" alt="sortarrow" title="Sort" />
                                                }</span></th>
                                                <th>Proficiency Index / Severity Level</th>
                                            </tr>
                                        </thead>
                                        <tbody className="font-semibold font-12">
                                            {bindControlAreas()}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            }
            {
                !toastState?.hideToast ? <div className="position-fixed bg-white top-110px start-50 translate-middle w-md-100">
                    <div className="shadow-sm d-flex rounded-3 custom-toast p-3">
                        <div className="toast-body d-flex align-items-center ">
                            <div className="d-block me-3">
                                <img src={`images/toast-${toastState?.type?.toLowerCase()}.svg`} alt="toast-success" />
                            </div>
                            <div className="d-block">
                                <span className="font-medium font-16 color-grey">
                                    {toastState?.message}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                    :
                    <></>

            }
            {hideDeletePopup ?
                <></>
                :
                <DeletePopup
                    popupType='Delete'
                    popupHeading='Delete Confirmation'
                    popupMessage='Are you sure you want to delete this Assessment?'
                    setHideDeletePopup={setHideDeletePopup}
                    deleteCall={deleteAssessmentData}
                />}

            {!hideDeletePopup || !hideForm || !hideControlAreasPopup ? <div className="modal-backdrop fade show"></div> : <></>}
        </>

    )

}


export default AssessmentGrid;