import React, { useState, useEffect } from "react"
import { useNavigate, useLocation } from "react-router-dom";
import Loader from './Loader';
import ToastComponent from './ToastComponent';
import { filterModel, popupModel, toastModel, responseModel, editModel } from '../interface/AssesmentModel';
import { infraAssessmentTypesInterface } from "../interface/infraMapModel"
import { getInfraAssessmentTypes, postInfraAssessmentType } from "../service/AssesmentApi"
import BreadCrumbs from "./breadCrumb";
const InfrastructureAssessmentPage = () => {
  let infraAssessmentType = ""
  let infraAssessmentTypeId: string | null;
  const toastData: toastModel = {
    toastType: '',
    toastHeaderMessage: '',
    toastBodyMessage: '',
  };

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [toastProp, setToastProp] = useState<toastModel>(toastData)
  const [infraAssessments, setInfraAssessments] = useState<infraAssessmentTypesInterface[]>([])
  const [hideToast, setHideToast] = useState<boolean>(true);
  const location = useLocation()
  const navigation = useNavigate()
  const breadCrumbs =
    [
      { title: "Assessments", path: "/homepage", isActive: true },
      { title: location.state.organizationName, path: "", isActive: true },
      { title: location.state.verticalName, path: "", isActive: false },
    ]


  //PS_InfraAssessmentPage_04 - Api call to fetch infra assessment types
  useEffect(() => {
    infraAssessmentTypes()
  }, [])

  useEffect(() => {
    const handleBeforeUnload = (e: any) => {
      e.preventDefault(); // Prevent the page from reloading
      e.returnValue =
        "You may have unsaved changes. Are you sure you want to reload or leave?"; // Some browsers require a return value
    };

    window.onbeforeunload = function () {
      return "You may have unsaved changes. Are you sure you want to reload or leave?";
    };
    window.addEventListener("beforeunload", handleBeforeUnload);
  }, []);

  //PS_InfraAssessmentPage_05 - Function to fetch infra assessment types
  const infraAssessmentTypes = async () => {
    try {
      setIsLoading(true)
      const response = await getInfraAssessmentTypes();
      if (response.status === 200) {
        setIsLoading(false);
        setInfraAssessments(response.data);
      }
      else {
        setIsLoading(false)

        let toastData = {

          toastType: 'error',

          toastHeaderMessage: 'Error',

          toastBodyMessage: 'Api Failed'

        }

        setToastProp(toastData)

        setHideToast(false);
      }
    }
    catch (error) {
      setIsLoading(false)

      let toastData = {

        toastType: 'error',

        toastHeaderMessage: 'Error',

        toastBodyMessage: 'Api Failed'

      }

      setToastProp(toastData)

      setHideToast(false);
    }
  }
  //PS_InfraAssessmentPage_25 - Function to submit the infra assessment type
  const submitInfraAssessmentType = async (infraAssessmentType: string) => {
    const foundAssessment = infraAssessments.find((element: infraAssessmentTypesInterface) => element.assessmentName === infraAssessmentType);
    infraAssessmentTypeId = foundAssessment?.infraAssessmentTypeId || null;
    if (!infraAssessmentTypeId) {
      return;
    }

    try {
      setIsLoading(true)
      const response = await postInfraAssessmentType({ organizationId: location.state.organizationId, verticalId: location.state.verticalId, infraAssessmentTypeId: infraAssessmentTypeId })
      if (response.status === 200) {
        switch (infraAssessmentType) {
          case "MAP Assessment":
            navigation("/infraassessment", { state: { organizationId: location.state.organizationId, organizationName: location.state.organizationName, verticalId: location.state.verticalId, verticalName: location.state.verticalName, infraAssessmentTypeName: infraAssessmentType, infraAssessmentTypeId: infraAssessmentTypeId } })
            break
          default:
            navigation("/assessment", { state: { organizationId: location.state.organizationId, organizationName: location.state.organizationName, verticalId: location.state.verticalId, verticalName: location.state.verticalName, mode: location.state.mode } })
        }
      }
      else {
        setIsLoading(false)

        let toastData = {

          toastType: 'error',

          toastHeaderMessage: 'Error',

          toastBodyMessage: 'Api Failed'

        }

        setToastProp(toastData)

        setHideToast(false);
      }
    }
    catch (error) {
      setIsLoading(false)

      let toastData = {

        toastType: 'error',

        toastHeaderMessage: 'Error',

        toastBodyMessage: 'Api Failed'

      }

      setToastProp(toastData)

      setHideToast(false);

    }
  }
  //PS_InfraAssessmentPage_44 - Bind the infra assessments
  const bindInfraAssessment = () => {
    return (infraAssessments.map((element: infraAssessmentTypesInterface) => {
      return (
        <div className="col-md-6 col-sm-12" id={element.assessmentName} onClick={() => submitInfraAssessmentType(element.assessmentName)}>
          <div className="p-4 text-center h-100 assess-cust-card border rounded-3 cursor-pointer" >
            {element.assessmentName === "MAP Assessment" ? <img src="images/map-assessment-icon.svg" alt="map-assessment-icon" /> : <img src="images/infra-assessment-icon.svg" alt="infra-assessment-icon" />}
            <p className="font-semibold font-18 color-black my-3" >{element.assessmentName}</p>
            <p className="font-regular font-16 color-grey-v3">{element.assessmentDescription}</p>
          </div>
        </div>
      )
    }))
  }

  return (
    <>
      <div>
        {/* top header starts here */}

        {/* top header ends here */}
        <div className="container-fluid" style={{ marginTop: "105px" }}>
          <div className="row justify-content-center">
            <div className="px-4">
              {/* breadcrumb starts here */}
              <BreadCrumbs data={breadCrumbs} />
              {/* breadcrumb ends here */}
              {/* heading content starts here */}
              <div className="d-flex align-items-center mb-5">
                <a href="#" onClick={() => navigation("/homepage")}><img src="images/backarrow.svg" alt="back-arrow" title="Back" /></a>
                <h2 className="font-22 font-semibold color-black mb-0 ms-3 ">
                  Infrastructure Assessment
                </h2>
              </div>
              {/* heading content ends here */}
              <div className="row justify-content-center h-100 align-items-center">
                <div className="col-sm-12 col-md-12 col-lg-7">
                  <div className="row">
                    {/* Assessment type tab starts here */}
                    {bindInfraAssessment()}
                    {/* Assessment type tab ends here*/}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Loader isLoading={isLoading} />
      {hideToast ? <></> :
        <ToastComponent name={toastProp} />
      }
    </>
  );
}

export default InfrastructureAssessmentPage