import React, { useEffect } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  ResponsiveContainer,
  LabelList,
} from "recharts";
import * as _ from "lodash";
import {
  DataItem,
  CustomYAxisLabelInterface,
} from "../../interface/HorizontalBarChartModel";

const ReportHorizontalBarChart: React.FC<any> = (props) => {
  let unique: any = _.uniqWith(props.dataNameValue, _.isEqual);

  

  // const CustomYAxisLabel: any = (label: any) => {
  //   const { x, y, payload } = label;
  //   const truncatedValue =
  //     payload.value.length > 15
  //       ? payload.value.slice(0, 15) + "..."
  //       : payload.value;

  //   return (
  //     <g transform={`translate(${x},${y})`}>
  //       <text x={-140} y={0} dy={6} textAnchor="start">
  //         {truncatedValue}
  //       </text>
  //     </g>
  //   );
  // };

  const CustomYAxisLabel: any = (label: any) => {
    const { x, y, payload } = label;
    const textContent = payload.value;

    return (
      <g transform={`translate(${x},${y})`}>
        <foreignObject x={-140} y={-7} width={170} height={50}>
          <div
            style={{
              overflowWrap: "break-word",
              wordWrap: "break-word",
              width: "80%",
              height: " 100%",
              fontSize: props?.dataNameValue?.length <= 6 ? 10 : 8,
            }}
          >
            {textContent}
          </div>
        </foreignObject>
      </g>
    );
  };

  return (
    <>
      <div>
        <ResponsiveContainer width="70%" height={340}>
          <BarChart
            data={unique}
            layout="vertical"
            margin={{ top: 10, right: 40, left: 120, bottom: 10 }}
          >
            <CartesianGrid horizontal={false} vertical={false} />
            <XAxis
              type="number"
              tickLine={false}
              domain={[0, 100]}
              ticks={[0, 20, 40, 60, 80, 100]}
            />
            <YAxis
              type="category"
              dataKey="name"
              tickLine={false}
              tick={CustomYAxisLabel}
            />
            <Bar
              dataKey="value"
              fill="#8caaff"
              barSize={
                props?.dataNameValue?.length <= 3
                  ? 40
                  : props?.dataNameValue?.length <= 5
                  ? 30
                  : props?.dataNameValue?.length <= 8
                  ? 20
                  : 15
              }
            >
              <LabelList dataKey="value" position="right" />
            </Bar>
          </BarChart>
        </ResponsiveContainer>
      </div>
    </>
  );
};

export default ReportHorizontalBarChart;
