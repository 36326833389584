import React, { useEffect, useLayoutEffect, useState } from "react"
import MapHorizondialBarChart from "./reportComponents/MapHorizondialBarChart"
import MapLineChart from "./reportComponents/MapLineChart"
import { azureInventoryModel, phaseData, weekDataState } from "../interface/infraMapModel"
import moment, { duration } from "moment"
import { getSasToken } from "../service/ReportApi"
import { encryptStorage } from "../constant/constant"
import { jwtDecode } from "jwt-decode"
import { getConnectorsData } from "../helpers/encryption"
import { PricingModel } from "../interface/CostBreakdownModel"
import ReportMapHorizontalBarChart from "./downloadReportComponents/ReportMapHorizontalBarChart"

const InfrastructureMapDownloadReport = (props: any) => {

  const reportData = props.reportData
  const migrationPlanData = props.reportData.migrationPlan
  let applicationPageCount = 0
  const [minDateAndRoundValue, setMinDateAndRoundValue] = useState<weekDataState>({ minDate: new Date(), weekNumber: 1 });
  const [weeks, setWeeks] = useState<string[]>([]);
  const [sasToken, setSasToken] = useState<any>();
  const environmentType = reportData.isAzure ? "azure" : "on-premises"

  const calculateApplicationPageNumber = () => {
    applicationPageCount =
      reportData.discovery.length + 5
    return applicationPageCount
  }


  const sasTokenGeneration = async () => {

    const sas = await getSasToken("r");
    setSasToken(`${sas.data}`)
    return `${sas.data}`
  }
  useLayoutEffect(() => {
    sasTokenGeneration()
  }, []);

  useEffect(() => {
    const fetchSasToken = async () => {
      try {
        const sas = await getSasToken('r');
        setSasToken(sas?.data);
      } catch (error) {
        console.error("Error fetching SAS token:", error);
      }
    };

    fetchSasToken();
  }, []);
  const generateImageUrl = (blobUrl: any) => {
    if (!blobUrl || !sasToken) return '';
    return `${blobUrl}?${sasToken}`;
  };


  useEffect(() => {
    const weekData = updateWeeks();
    setMinDateAndRoundValue(weekData);
  }, [migrationPlanData]);

  const bindApplicationDetails = () => {
    return (
      reportData.discovery.map((element: any, index: number) => {
        return (
          <div key={index} style={{ width: '100%', float: 'left', pageBreakInside: 'avoid', breakBefore: 'page' }} className="new-page">
            <div style={{ width: '98%', float: 'left', justifyContent: 'center', padding: '2% 1%', backgroundColor: '#F5F5F5' }}>
              <h5 style={{ color: "#050505", margin: 0, fontSize: 22, fontWeight: 600, textAlign: "center", wordSpacing: 8, fontFamily: "SFProText-Medium" }}> Portfolio Discovery </h5>
            </div>
            <div style={{ width: '100%', float: 'left', backgroundColor: '#FFF', margin: "1% 0%" }}>
              {/* Main header style */}
              <h4 style={{ fontFamily: 'SFProText-Medium', color: '#000', fontWeight: 'bold', fontSize: '21px', margin: '3% 0% 2% 0%' }}>Application Summary:</h4>
              <p style={{ fontFamily: 'SFProText-Medium', color: '#2E2E2E', fontWeight: 400, fontSize: '18px', margin: '3% 0% 2% 0%', lineHeight: '1.2' }}>
                <div dangerouslySetInnerHTML={{ __html: element.applicationSummary }} />
              </p>
              
              <h4 style={{ fontFamily: 'SFProText-Medium', color: '#000', fontWeight: 'bold', fontSize: '21px', margin: '3% 0% 2% 0%' }}>Application Architecture:</h4>{
                element?.applicationArchitUrl ?
                  <img src={generateImageUrl(element?.applicationArchitUrl)} alt="Application Architecture" style={{ height: '550px', width: '100%' }} /> :
                  <div style={{ width: "100%", textAlign: "center" }}>
                    <img src={"https://avaeuszebpulsestgdev.blob.core.windows.net/avazebpulse/pdfImages/image-notuploded.svg?" + sasToken} alt="image-notuploded" style={{ margin: '3% 0%' }} />
                    <p style={{ marginTop: '3px', marginBottom: '3px', fontSize: '16px', fontWeight: 500, color: 'grey' }}>Application Architecture has not been uploaded</p>
                  </div>
              }
            </div>
          </div >
        )
      })
    )
  }

  const bindRecommendedArchitecture = () => {

    return (
      <div style={{ width: '100%', float: 'left' }}>
        <h4 style={{ fontFamily: 'SFProText-Medium', color: '#000', fontWeight: 'bold', fontSize: '21px', margin: '3% 0% 2% 0%' }}>Recommended Architecture:</h4>
        {reportData.migrationStrategy.recommendedArchitBlodUrl ?
          <img src={generateImageUrl(reportData?.migrationStrategy.recommendedArchitBlodUrl)} alt="Recommended Architecture" style={{ height: '550px', width: '100%' }} />
          :
          <div style={{ width: "100%", textAlign: "center" }}>
            <img src={"https://avaeuszebpulsestgdev.blob.core.windows.net/avazebpulse/pdfImages/image-notuploded.svg?" + sasToken} alt="Default Architecture" style={{ margin: '3% 0%' }} />
            <p style={{ marginTop: '3px', marginBottom: '3px', fontSize: '16px', fontWeight: 500, color: 'grey', textAlign: 'center' }}>Recommended Architecture has not been uploaded</p>
          </div>

        }
      </div>
    )

  }
  const bindApplicationStrategy = () => {
    return (reportData.migrationStrategy.applicationsStrategy.map((element: any) => {
      return <tr style={{ borderBottom: '1px solid #E7E7E7' }}>
        <td style={{ width: '18%', padding: '1%', textAlign: 'left', verticalAlign: 'top', fontSize: '14px', fontFamily: '"SFProText-Medium"', color: '#242424', lineHeight: '1.5' }}>
          {element.applicationName}
        </td>
        <td style={{ width: '18%', padding: '1%', textAlign: 'left', verticalAlign: 'top', fontSize: '14px', fontFamily: '"SFProText-Medium"', color: '#197CD6', lineHeight: '1.5' }}>
          {element.applicationStrategy}
        </td>
        <td style={{ width: '58%', borderRight: '1px solid #E7E7E7', padding: '1%', textAlign: 'left', verticalAlign: 'top', fontSize: '14px', fontFamily: '"SFProText-Medium"', color: '#242424', lineHeight: '1.5' }}>
          {element.applicationStrategySummary}
        </td>
      </tr>
    }))
  }

  const bindAzureInventoryDetails = () => {
    let value = encryptStorage.getItem("jwt")
    let parsedJtk: any = ""
    let storageAccountName: any, containerName: any;
    if (value) {
      //PS-133 Retrieve JWT from browser storage for authorization purposes.    
      parsedJtk = jwtDecode(value)
      storageAccountName = getConnectorsData({ key: parsedJtk.ek, encryptedMessage: process.env.REACT_APP_BLOB_ACCOUNT_NAME! });
      containerName = getConnectorsData({ key: parsedJtk.ek, encryptedMessage: process.env.REACT_APP_BLOB_CONTAINER! })
    }
    return (
      reportData.azureInventory?.serviceCount?.map((data: azureInventoryModel) => {
        return (
          <div style={{ width: '31.33%', float: 'left', padding: '0 1% 2% 1%' }}>
            <div style={{ backgroundColor: '#FFFFFF', boxShadow: '0px 0px 4px 2px #00000014', borderRadius: '0.5rem', padding: '1rem', overflow: 'hidden' }}>
              <span style={{ float: 'left' }}>
                <img src={`https://${storageAccountName}.blob.core.windows.net/${containerName}/Inventory Images/aws-${data.serviceName?.replaceAll(" ", "")?.toLowerCase()}.svg?${sasToken}`} alt="aws-ec2-icon" style={{ verticalAlign: 'middle', marginRight: '0.75rem' }} />
                <span style={{ fontFamily: 'Arial', fontSize: '14px', fontWeight: 500, verticalAlign: 'middle' }}>{data.serviceName}</span>
              </span>
              <span style={{ fontFamily: 'Arial', float: 'right', fontSize: '20px', fontWeight: 600, verticalAlign: 'middle', lineHeight: '38px' }}>{data.serviceCount}</span>
            </div>
          </div>
        )
      })
    )
  }

  const bindInventoryDetails = () => {
    return (
      <div style={{ width: '100%', float: 'left', margin: '1% 0%' }}>
        {/* Main header style */}
        <div style={{ width: '48%', float: 'left', margin: '3% 0% 1% 0%', paddingRight: '2%' }}>
          <div style={{ padding: '2%  4%', width: '94%' }}>
            <h5 style={{ fontFamily: 'SFProText-Medium', color: '#000', fontWeight: 'bold', fontSize: '21px', margin: '3% 0% 2% 0%' }}>Inventory:</h5>
            <div style={{ width: '100%', float: 'left', height: '350px' }}>
              <div style={{ width: '80%', float: 'left', marginBottom: '9%', marginTop: '4%' }}>
                <div style={{ float: 'left', color: '#343434', fontFamily: 'SFProText-Medium', fontSize: '20px', fontWeight: 500 }}>
                  Total instances count
                </div>
                <div style={{ float: 'right', color: '#000', fontFamily: 'SFProText-Medium', fontSize: '23px', fontWeight: 'bold' }}>
                  {reportData.inventory.instancesCount}
                </div>
              </div>
              <div style={{ width: '80%', float: 'left', marginBottom: '9%' }}>
                <div style={{ float: 'left', color: '#343434', fontFamily: 'SFProText-Medium', fontSize: '20px', fontWeight: 500 }}>
                  Windows server count
                </div>
                <div style={{ float: 'right', color: '#000', fontFamily: 'SFProText-Medium', fontSize: '23px', fontWeight: 'bold' }}>
                  {reportData.inventory.windowsServerCount}
                </div>
              </div>
              <div style={{ width: '80%', float: 'left', marginBottom: '9%' }}>
                <div style={{ float: 'left', color: '#343434', fontFamily: 'SFProText-Medium', fontSize: '20px', fontWeight: 500 }}>
                  Linux server count
                </div>
                <div style={{ float: 'right', color: '#000', fontFamily: 'SFProText-Medium', fontSize: '23px', fontWeight: 'bold' }}>
                  {reportData.inventory.linuxServerCount}
                </div>
              </div>
              <div style={{ width: '80%', float: 'left', marginBottom: '9%' }}>
                <div style={{ float: 'left', color: '#343434', fontFamily: 'SFProText-Medium', fontSize: '20px', fontWeight: 500 }}>
                  Windows Desktops count
                </div>
                <div style={{ float: 'right', color: '#000', fontFamily: 'SFProText-Medium', fontSize: '23px', fontWeight: 'bold' }}>
                  {reportData.inventory.windowsDesktopsCount}
                </div>
              </div>
              <div style={{ width: '80%', float: 'left', marginBottom: '9%' }}>
                <div style={{ float: 'left', color: '#343434', fontFamily: 'SFProText-Medium', fontSize: '20px', fontWeight: 500 }}>
                  Server not patched
                </div>
                <div style={{ float: 'right', color: '#000', fontFamily: 'SFProText-Medium', fontSize: '23px', fontWeight: 'bold' }}>
                  {reportData.inventory.serverNotPatched}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div style={{ width: '48%', float: 'left', margin: '3% 0% 1% 0%', paddingLeft: '2%' }}>
          <div style={{ border: '1px solid #E3E3E3', borderRadius: '6px', padding: '2%  4%', width: '90%' }}>
            <h5 style={{ fontFamily: 'SFProText-Medium', color: '#000', fontWeight: 'bold', fontSize: '21px', margin: '3% 0% 2% 0%' }}>Percentage Memory Used:</h5>
            <ReportMapHorizontalBarChart barChartData={reportData.inventory.maxMemoryUsed} xAxisLabel="Utilization Percentage" />
          </div>
        </div>
        <div style={{ width: '48%', float: 'left', margin: '1% 0%', paddingRight: '2%' }}>
          <div style={{ border: '1px solid #E3E3E3', borderRadius: '6px', marginTop: '100px', padding: '2% 4%', width: '90%' }}>
            <h5 style={{ fontFamily: 'SFProText-Medium', color: '#000', fontWeight: 'bold', fontSize: '21px', margin: '3% 0% 2% 0%' }}>CPU Load:</h5>
            <ReportMapHorizontalBarChart barChartData={reportData.inventory.maxCpuLoad} xAxisLabel="Load Percentage" />
          </div>
        </div>
        <div style={{ width: '48%', float: 'left', margin: '1% 0%', paddingLeft: '2%' }}>
          <div style={{ border: '1px solid #E3E3E3', borderRadius: '6px', marginTop: '100px', padding: '2%  4%', width: '90%' }}>
            <h5 style={{ fontFamily: 'SFProText-Medium', color: '#000', fontWeight: 'bold', fontSize: '21px', margin: '3% 0% 2% 0%' }}>Disk Size:</h5>
            <ReportMapHorizontalBarChart barChartData={reportData.inventory.maxDiskSize} xAxisLabel="Disk Utilization" />
          </div>
        </div>
      </div>
    )
  }

  const BindCostBreakdown = () => {
    let costBreakdown = reportData.costBreakdownDetails;
    const [data, setData] = useState<PricingModel[]>([]);

    useEffect(() => {
      setData(costBreakdown || []);
    }, [costBreakdown]);

    const sortPricingModel = (a: PricingModel, b: PricingModel) => {
      const order = ["On Demand", "Saving Plans", "Reserved Instances"];
      return order.indexOf(a.pricingModel) - order.indexOf(b.pricingModel);
    }

    const renderTable = (pricingModel: PricingModel) => {
      return pricingModel.paymentOptions.map((paymentOption) => {
        const setDuration = new Set(
          paymentOption.categoryDetails.map((detail) => detail.duration)
        );
        const durations = Array.from(setDuration).sort((a, b) => {
          const aYear = parseInt(a.split(" ")[0], 10);
          const bYear = parseInt(b.split(" ")[0], 10);
          return isNaN(aYear) || isNaN(bYear) ? 0 : aYear - bYear;
        });

        const MapCategories = ["Compute", "Storage", "Network and Monitoring"];

        const getCategoryDetail = (category: string, duration: string) => {
          return (
            paymentOption.categoryDetails.find(
              (detail) =>
                detail.category === category && detail.duration === duration
            ) || { category, duration, cost: "0" }
          );
        };

        const calculateTotal = (duration: string) => {
          return MapCategories.reduce((total, category) => {
            const detail = getCategoryDetail(category, duration);
            return total + parseFloat(detail.cost || "0");
          }, 0);
        };

        return (
          <div key={paymentOption.paymentOption}>
            <h3 style={{ marginTop: "36px", fontSize: "20px", marginBottom: "1rem", fontFamily: "SFProText-Semibold" }}>{pricingModel.pricingModel} {paymentOption.paymentOption ? "- " + paymentOption.paymentOption : ""}</h3>
            <div style={{ width: "max-content", minWidth: "100%" }}>
              <table style={{ borderTop: "1px", borderLeft: "1px", borderRight: "1px", borderBottom: "1px", borderWidth: "0 2px", borderColor: "gray", width: "fit-content" }}>
                <thead style={{ fontFamily: "SFProText-Medium" }}>
                  <tr>
                    <th style={{ color: "#454545", fontSize: "14px", padding: "14px 40px", backgroundColor: "#F0F0F0", whiteSpace: "nowrap", fontWeight: "unset" }}>Category</th>
                    {durations.map((duration) => (
                      <th key={duration} style={{ color: "#454545", fontSize: "14px", padding: "14px 40px", backgroundColor: "#F0F0F0", whiteSpace: "nowrap", fontWeight: "unset" }}>{duration}</th>
                    ))}
                  </tr>
                </thead>
                <tbody style={{ fontFamily: "SFProText-Regular", padding: "14px 40px" }}>
                  {MapCategories.map((category) => (
                    <tr key={category}>
                      <td style={{ padding: "14px 40px" }}>{category}</td>
                      {durations.map((duration) => {
                        const detail = getCategoryDetail(category, duration);
                        return (
                          <td key={duration} style={{ textAlign: "right", padding: "14px 40px" }}>
                            {/* $ {parseFloat(detail.cost).toFixed(2)} */}$
                            {parseFloat(detail.cost).toLocaleString("en-US", {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })}
                          </td>
                        );
                      })}
                    </tr>

                  ))}
                  <tr>
                    <td style={{ padding: "14px 40px" }}>Total</td>
                    {durations.map((duration) => (
                      <td
                        key={duration}
                        //   className="text-end font-semibold"
                        style={{
                          textAlign: "right",
                          fontFamily: "SFProText-Semibold",
                          padding: "14px 40px",
                        }}
                      >
                        {/* $ {calculateTotal(duration).toFixed(2)} */}$
                        {calculateTotal(duration).toLocaleString("en-US", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                      </td>
                    ))}
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        );
      });
    };

    const renderContent = () => {
      return data.sort(sortPricingModel).map((pricingModel) => {
        return (
          <div key={pricingModel.pricingModel}>
            {renderTable(pricingModel)}
          </div>
        );
      });
    };

    return (
      <div>
        <h5 style={{ fontSize: "18px", fontFamily: "SFProText-Semibold", color: "#000000" }}>AWS Cost</h5>
        <div style={{ flex: "0 0 auto", width: "100%", marginTop: "1px", marginBottom: "1.5px" }}>{renderContent()}</div>
      </div>
    )
  }

  const extractDatesFromProcesses = (data: any) => {
    let datesArray: string[] = [];

    data?.forEach((phase: any) => {
      phase.processTimeLine?.forEach((process: any) => {
        let startDate = moment(process.startWeek);
        let endDate = moment(process.endWeek);
        datesArray.push(startDate.format("YYYY-MM-DD"));
        datesArray.push(endDate.format("YYYY-MM-DD"));
      });
    });
    return datesArray;
  };

  const updateWeeks = () => {
    const allDates = extractDatesFromProcesses(migrationPlanData);

    const uniqueDates = Array.from(new Set(allDates));
    const dateObjects: Date[] = uniqueDates?.map((date) => new Date(date));

    let minDate = new Date(dateObjects[0]);
    let maxDate = new Date(dateObjects[0]);

    dateObjects.forEach((date) => {
      if (date < minDate) minDate = new Date(date);
      if (date > maxDate) maxDate = new Date(date);
    });

    let weeks: string[] = [];
    let currentDate: Date = new Date(minDate);
    const millisecondsPerWeek = 1000 * 60 * 60 * 24 * 7; // Milliseconds in a week
    // const weeksBetween = Math.ceil((maxDate.getTime() - minDate.getTime()) / millisecondsPerWeek);
    const weeksBetween = getWeekIndex(maxDate, minDate);

    let roundedIncrement;

    if (weeksBetween % 4 != 0) {
      roundedIncrement = Math.ceil(weeksBetween / 4);
    } else {
      roundedIncrement = Math.round(weeksBetween / 4);
    }

    let weekNumber: number;
    if (roundedIncrement == 0) {
      roundedIncrement = 1;
      weekNumber = roundedIncrement;
    } else {
      weekNumber = roundedIncrement;
    }

    for (let i = 1; i <= 4; i++) {
      weeks.push(`week ${weekNumber}`);
      currentDate.setDate(currentDate.getDate() + 7);
      weekNumber += roundedIncrement;
    }

    setWeeks(weeks);
    return { minDate: minDate, weekNumber: roundedIncrement };
  };



  const differenceInDays = (dateA: Date, dateB: Date): number => {
    const timeDifference = dateA.getTime() - dateB.getTime();
    return Math.abs(Math.round(timeDifference / (1000 * 3600 * 24)));
  };

  const getWeekIndex = (date: Date, minDate: Date): number => {
    const diffDays = differenceInDays(date, minDate);
    const diffWeeks = Math.ceil(diffDays / 6);
    return diffWeeks > 0 ? diffWeeks : 1;
  };

  // PS_MigrationPlan_70 -  PS_MigrationPlan_76
  const getWeekRange = (
    startDate: Date,
    endDate: Date,
    process: any,
    minDate: Date,
    weekNumber: number
  ): any => {
    const startWeekIndex = getWeekIndex(startDate, minDate);
    const endWeekIndex = getWeekIndex(endDate, minDate);

    if (startWeekIndex !== -1 && endWeekIndex !== -1) {
      const totalBlocks = 4;
      const weeksPerBlock = weekNumber;

      const startBlock = Math.floor((startWeekIndex - 1) / weeksPerBlock);
      const endBlock = Math.floor((endWeekIndex - 1) / weeksPerBlock);

      const marginLeft = (startBlock / totalBlocks) * 100;
      const marginRight = ((totalBlocks - endBlock - 1) / totalBlocks) * 100;

      if (startWeekIndex === endWeekIndex) {
        return {
          weekRange: `Week ${startWeekIndex}`,
          marginLeft: `${marginLeft}%`,
          marginRight: `${marginRight}%`,
        };
      } else {
        return {
          weekRange: `Week ${startWeekIndex} - Week ${endWeekIndex}`,
          marginLeft: `${marginLeft}%`,
          marginRight: `${marginRight}%`,
        };
      }
    } else {
      return {};
    }
  };


  const handleBindingWeekRange = (process: any, index: any) => {
    const startDate = new Date(process.startWeek);
    const endDate = new Date(process.endWeek);

    const weekData = getWeekRange(
      startDate,
      endDate,
      process,
      minDateAndRoundValue.minDate,
      minDateAndRoundValue.weekNumber
    );

    return (
      <td
        colSpan={4}
        style={{
          width: "58%",
          borderRight: "1px solid #E7E7E7",
          padding: "1%",
          textAlign: "left",
          verticalAlign: "top",
          fontSize: "10px",
          fontFamily: '"Roboto"',
          fontWeight: 600,
          color: "#242424",
          lineHeight: "1.5",
        }}
      >
        <div
          style={{
            borderLeft: "4px solid #0F7FAF",
            borderRadius: "5px",
            backgroundColor: "#F1FBFF",
            padding: "10px 16px",
            marginLeft: weekData.marginLeft,
            marginRight: weekData.marginRight,
          }}
        >
          <span>{weekData.weekRange}</span>
        </div>
      </td>
    );
  };


  return (
    <>
      <div style={{ width: '100%' }}>
        <table style={{ width: '100%' }}>
          <tbody>
            <tr>
              <td>
                {/*Reports page starts here */}
                <div style={{ width: '100%', float: 'left', margin: '1% 0%', pageBreakInside: 'avoid' }} className="new-page">
                  {/*Reports Header starts here */}
                  <div style={{ width: '98%', justifyContent: 'center', padding: '1.5% 1%', backgroundColor: '#F5F5F5' }}>
                    <h5 style={{ color: '#050505', fontFamily: 'Arial', fontSize: '20px', fontWeight: 600, margin: '0px', textAlign: 'center', wordSpacing: '4px' }}>Reports</h5>
                  </div>
                  {/*Reports Header  ends here */}
                  <div style={{ width: '100%', float: 'left', margin: '1% 0%' }}>
                    {/* Main header style */}
                    {/* Page title style */}
                    <div style={{ width: '100%', float: 'left' }}>
                      <label style={{ fontFamily: 'Arial', color: '#2E2E2E', fontWeight: 600, fontSize: '13px', margin: '1% 0%', float: 'left' }}>
                        {reportData.menus[0].menuName}
                      </label>
                      <label style={{ fontFamily: 'SFProText-Medium', color: '#000', fontWeight: 500, fontSize: '19px', margin: '0px 0px 16px 0px', float: 'right' }}>

                      </label>

                    </div>
                    {/* Page title style */}
                    <div style={{ width: '100%', float: 'left' }}>
                      <label style={{ fontFamily: 'Arial', color: '#2E2E2E', fontWeight: 600, fontSize: '13px', margin: '1% 0%', float: 'left' }}>
                        {reportData.menus[1].menuName}
                      </label>
                      <label style={{ fontFamily: 'SFProText-Medium', color: '#000', fontWeight: 500, fontSize: '19px', margin: '0px 0px 16px 0px', float: 'right' }}>

                      </label>
                    </div>

                    {/* Page title style */}
                    <div style={{ width: '100%', float: 'left' }}>
                      <label style={{ fontFamily: 'Arial', color: '#2E2E2E', fontWeight: 600, fontSize: '13px', margin: '1% 0%', float: 'left' }}>
                        {reportData.menus[2].menuName}
                      </label>
                      <label style={{ fontFamily: 'SFProText-Medium', color: '#000', fontWeight: 500, fontSize: '19px', margin: '0px 0px 16px 0px', float: 'right' }}>

                      </label>
                    </div>

                    {/* Page title style */}
                    <div style={{ width: '100%', float: 'left' }}>
                      <label style={{ fontFamily: 'Arial', color: '#2E2E2E', fontWeight: 600, fontSize: '13px', margin: '1% 0%', float: 'left' }}>
                        {reportData.menus[3].menuName}
                      </label>
                      <label style={{ fontFamily: 'SFProText-Medium', color: '#000', fontWeight: 500, fontSize: '19px', margin: '0px 0px 16px 0px', float: 'right' }}>

                      </label>
                    </div>

                    {/* Page title style */}
                    <div style={{ width: '100%', float: 'left' }}>
                      <label style={{ fontFamily: 'Arial', color: '#2E2E2E', fontWeight: 600, fontSize: '13px', margin: '1% 0%', float: 'left' }}>
                        {reportData.menus[4].menuName}
                      </label>
                      <label style={{ fontFamily: 'SFProText-Medium', color: '#000', fontWeight: 500, fontSize: '19px', margin: '0px 0px 16px 0px', float: 'right' }}>

                      </label>
                    </div>

                    {/* Page title style */}
                    <div style={{ width: '100%', float: 'left' }}>
                      <label style={{ fontFamily: 'Arial', color: '#2E2E2E', fontWeight: 600, fontSize: '13px', margin: '1% 0%', float: 'left' }}>
                        {reportData.menus[5].menuName}
                      </label>
                      <label style={{ fontFamily: 'SFProText-Medium', color: '#000', fontWeight: 500, fontSize: '19px', margin: '0px 0px 16px 0px', float: 'right' }}>

                      </label>
                    </div>

                    {/* Page title style */}
                    <div style={{ width: '100%', float: 'left' }}>
                      <label style={{ fontFamily: 'Arial', color: '#2E2E2E', fontWeight: 600, fontSize: '13px', margin: '1% 0%', float: 'left' }}>
                        {reportData.menus[6].menuName}
                      </label>
                      <label style={{ fontFamily: 'SFProText-Medium', color: '#000', fontWeight: 500, fontSize: '19px', margin: '0px 0px 16px 0px', float: 'right' }}>

                      </label>
                    </div>

                    {/* Page title style */}
                    <div style={{ width: '100%', float: 'left' }}>
                      <label style={{ fontFamily: 'Arial', color: '#2E2E2E', fontWeight: 600, fontSize: '13px', margin: '1% 0%', float: 'left' }}>
                        {reportData.menus[7].menuName}
                      </label>
                      <label style={{ fontFamily: 'SFProText-Medium', color: '#000', fontWeight: 500, fontSize: '19px', margin: '0px 0px 16px 0px', float: 'right' }}>

                      </label>
                    </div>

                    {/* Page title style */}
                    <div style={{ width: '100%', float: 'left' }}>
                      <label style={{ fontFamily: 'Arial', color: '#2E2E2E', fontWeight: 600, fontSize: '13px', margin: '1% 0%', float: 'left' }}>
                        {reportData.menus[8].menuName}
                      </label>
                      <label style={{ fontFamily: 'SFProText-Medium', color: '#000', fontWeight: 500, fontSize: '19px', margin: '0px 0px 16px 0px', float: 'right' }}>

                      </label>
                    </div>

                  </div>
                </div>
                {/*Reports page ends here */}

                {/* Executive Summary page starts here */}
                <div style={{ width: '100%', float: 'left', margin: '1% 0%', pageBreakInside: 'avoid' }} className="new-page">
                  {/*Executive Summary Header starts here */}
                  <div style={{ width: '98%', justifyContent: 'center', padding: '1.5% 1%', backgroundColor: '#F5F5F5' }}>
                    <h5 style={{ color: '#050505', fontFamily: 'Arial', fontSize: '20px', fontWeight: 600, margin: '0px', textAlign: 'center', wordSpacing: '4px' }}> Executive Summary</h5>
                  </div>
                  {/*Executive Summary Header  ends here */}
                  <div style={{ width: '98%', float: 'left', margin: '1% 0%' }}>
                    {/* Main header style */}
                    <h4 style={{ fontFamily: 'SFProText-Medium', color: '#000', fontWeight: 'bold', fontSize: '21px', margin: '3% 0% 2% 0%' }}>Directive:</h4>
                    <p style={{ fontFamily: 'SFProText-Medium', color: '#2E2E2E', fontWeight: 400, fontSize: '19px', margin: '3% 0% 2% 0%', lineHeight: '1.2' }}>
                      <div dangerouslySetInnerHTML={{ __html: reportData.infraSummary.find((item: any) => item.attributeName === "Directive")?.attributeValue || "Zeb, as the designated migration specialist for " + reportData.organizationName + ", will spearhead the transition from " + environmentType + " infrastructure to Amazon Web Services (AWS), aligning closely with " + reportData.organizationName + " strategic vision and operational objectives. With a meticulous focus on planning, assessment, and execution, Zeb will ensure a seamless migration process, minimizing disruption to business operations while maximizing the benefits of cloud computing. The expertise will guide " + reportData.organizationName + " through every stage of the journey, from initial evaluation to post-migration support, fostering a culture of innovation and efficiency within the organization. " }} />
                    </p>
                    <h4 style={{ fontFamily: 'SFProText-Medium', color: '#000', fontWeight: 'bold', fontSize: '21px', margin: '3% 0% 2% 0%' }}>Outcome:</h4>
                    <p style={{ fontFamily: 'SFProText-Medium', color: '#2E2E2E', fontWeight: 400, fontSize: '19px', margin: '3% 0% 2% 0%', lineHeight: '1.2' }}>
                      <div dangerouslySetInnerHTML={{ __html: reportData.infraSummary.find((item: any) => item.attributeName === "Outcome")?.attributeValue || "Following the successful migration from " + environmentType + " infrastructure to Amazon Web Services (AWS), " + reportData.organizationName + " experiences a range of transformative outcomes. Firstly, there's a notable improvement in agility and scalability, enabling our IT resources to adapt swiftly to changing business demands without the constraints of physical hardware limitations. This newfound flexibility translates into accelerated innovation cycles, allowing our teams to experiment and deploy new solutions more rapidly. Additionally, the migration to AWS results in cost optimization, with the ability to scale resources up or down based on actual usage, eliminating the need for over-provisioning and reducing operational expenses" }} />
                    </p>

                  </div>
                </div>
                {/* Executive Summary page ends here */}
                {/* Business Overview page starts here */}
                <div style={{ width: '100%', float: 'left', margin: '0% 0%', pageBreakInside: 'avoid', breakBefore: 'page' }} className="new-page">
                  {/* Business Overview Header starts here */}
                  <div style={{ width: '98%', justifyContent: 'center', padding: '1.5% 1%', backgroundColor: '#F5F5F5' }}>
                    <h5 style={{ color: '#050505', fontFamily: 'Arial', fontSize: '20px', fontWeight: 600, margin: '0px', textAlign: 'center', wordSpacing: '4px' }}> Business Overview</h5>
                  </div>
                  {/*Business Overview Header  ends here */}
                  <div style={{ width: '98%', float: 'left', margin: '1% 0%' }}>
                    {/* Main header style */}
                    <p style={{ fontFamily: 'Arial', color: '#000', fontWeight: 300, fontSize: '11px', margin: '3% 0% 2% 0%', lineHeight: '20px' }}>
                      <div dangerouslySetInnerHTML={{ __html: reportData.infraSummary.find((item: any) => item.attributeName === "Business Overview")?.attributeValue || "" }} />
                    </p>
                  </div>
                </div>
                {/* Business Overview page ends here */}
                {/* Inventory page starts here */}
                <div style={{ width: '100%', float: 'left', margin: '0% 0%', pageBreakInside: 'avoid', breakBefore: 'page' }} >
                  {/* Conditional rendering based on isAzure and isAzureAndOnPremise */}
                  {(reportData.isAzure && !reportData.isAzureAndOnPremise) && (
                    <div className="new-page">
                      {/*Azure Inventory Header starts here */}
                      <div style={{ width: '98%', justifyContent: 'center', padding: '2% 1%', backgroundColor: '#F5F5F5', marginBottom: '20px' }}>
                        <h5 style={{ color: "#050505", margin: 0, fontSize: 22, fontWeight: 600, textAlign: "center", wordSpacing: 8, fontFamily: "SFProText-Medium" }}> Inventory </h5>
                      </div>
                      {/*Azure Inventory Header ends here */}
                      {bindAzureInventoryDetails()}
                    </div>
                  )}

                  {(!reportData.isAzure && !reportData.isAzureAndOnPremise) && (
                    <div className="new-page">
                      {/*On-Premise Inventory Header starts here */}
                      <div style={{ width: '98%', justifyContent: 'center', padding: '2% 1%', backgroundColor: '#F5F5F5' }}>
                        <h5 style={{ color: "#050505", margin: 0, fontSize: 22, fontWeight: 600, textAlign: "center", wordSpacing: 8, fontFamily: "SFProText-Medium" }}> Inventory </h5>
                      </div>
                      {/*On-Premise Inventory Header ends here */}
                      {bindInventoryDetails()}
                    </div>
                  )}

                  {(reportData.isAzure && reportData.isAzureAndOnPremise) && (
                    <div>
                      <div className="new-page">
                        {/*Azure Inventory Header starts here */}
                        <div style={{ width: '98%', justifyContent: 'center', padding: '2% 1%', backgroundColor: '#F5F5F5', marginBottom: '20px' }}>
                          <h5 style={{ color: "#050505", margin: 0, fontSize: 22, fontWeight: 600, textAlign: "center", wordSpacing: 8, fontFamily: "SFProText-Medium" }}> Inventory </h5>
                        </div>
                        {/*Azure Inventory Header ends here */}
                        {bindAzureInventoryDetails()}
                      </div>
                      <div style={{ marginTop: '40px' }} className="new-page">
                        {/*On-Premise Inventory Header starts here */}
                        <div style={{ width: '100%', float: 'left', pageBreakInside: 'avoid' }}>
                          <div style={{ width: '98%', justifyContent: 'center', padding: '2% 1%', backgroundColor: '#F5F5F5' }}>
                            <h5 style={{ color: "#050505", fontSize: 22, margin: 0, fontWeight: 600, textAlign: "center", wordSpacing: 8, fontFamily: "SFProText-Medium" }}> Inventory </h5>
                          </div>
                        </div>
                        {/*On-Premise Inventory Header ends here */}
                        {bindInventoryDetails()}
                      </div>
                    </div>
                  )}
                </div>
                {/* Inventory page ends here */}
                {/* Inventory page ends here */}
                {/* Portfolio Discovery page starts here */}
                {/*Portfolio Discovery Header starts here */}
                {/*Portfolio Discovery Header  ends here */}
                {bindApplicationDetails()}

                {/* Portfolio Discovery page ends here */}
                {/* Cloud Adoption Framework page starts here */}
                <div style={{ width: '100%', float: 'left', pageBreakInside: 'avoid', breakBefore: 'page' }} className="new-page">
                  {/*Cloud Adoption Framework Header starts here */}
                  <div style={{ width: '98%', justifyContent: 'center', padding: '2% 1%', backgroundColor: '#F5F5F5' }}>
                    <h5 style={{ color: "#050505", fontSize: 22, margin: 0, fontWeight: 600, textAlign: "center", wordSpacing: 8, fontFamily: "SFProText-Medium" }}> Cloud Adoption Framework </h5>
                  </div>
                  {/*Cloud Adoption Framework Header  ends here */}
                  <div style={{ width: '92%', float: 'left', margin: '10% 0%', border: '1px solid #E3E3E3', borderRadius: '6px', padding: '2% 4%' }}>
                    <h5 style={{ fontFamily: 'SFProText-Medium', color: '#000', fontWeight: 'bold', fontSize: '21px', margin: '3% 0% 2% 0%' }}>Score Card:</h5>
                    <MapLineChart lineChartData={reportData.caf} />
                  </div>
                </div>
                {/* Cloud Adoption Framework page ends here */}
                {/* Migration Strategy page starts here */}
                <div style={{ width: '100%', float: 'left', padding: "60px 0px", pageBreakInside: 'avoid', }} className="new-page">
                  {/*Migration Strategy Header starts here */}
                  <div style={{ width: '98%', justifyContent: 'center', padding: '2% 1%', backgroundColor: '#F5F5F5' }}>
                    <h5 style={{ color: '#050505', fontFamily: 'Arial', fontSize: '20px', fontWeight: 600, margin: '0px', textAlign: 'center', wordSpacing: '4px' }}>Migration Strategy</h5>
                  </div>
                  {/*Migration Strategy Header  ends here */}
                  <div style={{ width: '92%', float: 'left', margin: '6% 0% 0% 0%', border: '1px solid #E3E3E3', borderRadius: '6px', padding: '2% 3%' }}>
                    <h5 style={{ color: '#050505', fontFamily: 'Arial', fontSize: '18px', fontWeight: 600, margin: '0px', textAlign: 'left', wordSpacing: '4px' }}>Migration Strategy Table:</h5>
                    <table style={{ borderCollapse: 'collapse', width: '100%', marginTop: '2%' }}>
                      <thead style={{ backgroundColor: '#F0F0F0' }}>
                        <tr style={{ borderBottom: '1px solid #E7E7E7' }}>
                          <th style={{ width: '18%', textAlign: 'left', color: '#454545', fontSize: '16px', fontFamily: '"Roboto"', padding: '1%' }}>Application</th>
                          <th style={{ width: '18%', textAlign: 'left', color: '#454545', fontSize: '16px', fontFamily: '"Roboto"', padding: '1%' }}>7R</th>
                          <th style={{ width: '58%', borderRight: '1px solid #E7E7E7', textAlign: 'left', color: '#454545', fontSize: '16px', fontFamily: '"Roboto"', padding: '1%' }}>Summary</th>
                        </tr>
                      </thead>
                      <tbody>
                        {bindApplicationStrategy()}
                      </tbody>
                    </table>
                  </div>
                  {bindRecommendedArchitecture()}
                </div>
                {/*Cost Breakdown page starts here */}
                <div style={{ width: '100%', float: 'left', pageBreakInside: 'avoid', breakBefore: 'page' }} className="new-page">
                  {/*Cost Breakdown Header starts here */}
                  <div style={{ width: '98%', justifyContent: 'center', padding: '2% 1%', backgroundColor: '#F5F5F5' }}>
                    <h5 style={{ color: "#050505", margin: 0, fontSize: 22, fontWeight: 600, textAlign: "center", wordSpacing: 8, fontFamily: "SFProText-Medium" }}> Cost Breakdown </h5>
                  </div>
                  {/*Cost Breakdown Header  ends here */}
                  <div style={{ width: "98%", float: "left", margin: "1% 0%" }}>
                    {/* Main header style */}
                    <p
                      style={{
                        fontFamily: "Arial",
                        color: "#000",
                        fontWeight: 500,
                        fontSize: "11px",
                        margin: "3% 0% 2% 0%",
                        lineHeight: "20px",
                      }}
                    >
                      {BindCostBreakdown()}
                    </p>
                  </div>
                </div>
                {/*Cost Breakdown page ends here*/}
                {/* Timeline page starts here */}
                <div style={{ width: '100%', float: 'left', pageBreakInside: 'avoid', breakBefore: 'page' }} className="new-page">
                  {/*Timeline Header starts here */}
                  <div style={{ width: '98%', justifyContent: 'center', padding: '2% 1%', backgroundColor: '#F5F5F5' }}>
                    <h5 style={{ color: "#050505", margin: 0, fontSize: 22, fontWeight: 600, textAlign: "center", wordSpacing: 8, fontFamily: "SFProText-Medium" }}>Timeline</h5>
                  </div>
                  {/*Timeline Header  ends here */}
                  <div style={{ width: '96%', float: 'left', padding: '2%' }}>
                    {/* <h5 style="color: #000; font-family: SFProText-Medium ; font-size: 13px; font-weight: bold; margin-top:0px;">Migration Strategy Table</h5> */}
                    <table style={{ borderCollapse: 'collapse', width: '100%', marginTop: '16px%' }}>
                      <thead style={{ backgroundColor: '#F0F0F0' }}>
                        {<tr style={{ borderBottom: '1px solid #E7E7E7' }}>
                          <th style={{ width: '18%', textAlign: 'left', color: '#454545', fontSize: '11px', fontFamily: '"Roboto"', padding: '1%' }}>
                            Phase
                          </th>
                          <th style={{ width: '18%', textAlign: 'left', color: '#454545', fontSize: '11px', fontFamily: '"Roboto"', padding: '1%' }}>
                            Process
                          </th>
                          {weeks?.map((week, index, processWeek) => (
                            <th key={index} style={{ width: '13%', textAlign: 'left', color: '#454545', fontSize: '11px', fontFamily: '"Roboto"', padding: '1%', borderRight: processWeek.length - 1 === index ? '1px solid #E7E7E7' : '' }}>{week}</th>
                          ))}
                        </tr>}
                      </thead>
                      <tbody>
                        {migrationPlanData &&
                          migrationPlanData?.map((phaseData: any, phaseIndex: number) =>
                            phaseData?.processTimeLine?.map(
                              (process: any, processIndex: number) => (
                                <tr
                                  key={`${phaseIndex}-${processIndex}`}
                                  style={{ borderBottom: "1px solid #E7E7E7" }}
                                >
                                  {processIndex === 0 && (
                                    <td
                                      rowSpan={phaseData.processTimeLine.length}
                                      style={{
                                        width: "18%",
                                        padding: "1%",
                                        textAlign: "left",
                                        border: "1px solid #E7E7E7",
                                        verticalAlign: "top",
                                        fontSize: "10px",
                                        fontFamily: '"Roboto"',
                                        color: "#242424",
                                        lineHeight: "1.5",
                                      }}
                                    >
                                      {phaseData.phaseName}
                                    </td>
                                  )}

                                  <td
                                    style={{
                                      width: "18%",
                                      padding: "1%",
                                      textAlign: "left",
                                      border: "1px solid #E7E7E7",
                                      verticalAlign: "top",
                                      fontSize: "10px",
                                      fontFamily: '"Roboto"',
                                      color: "#242424",
                                      lineHeight: "1.5",
                                    }}
                                  >
                                    {process.processName}
                                  </td>

                                  {/* Assuming handleBindingWeekRange is a function that returns a valid JSX component or HTML element */}
                                  {handleBindingWeekRange(process, processIndex)}
                                </tr>
                              )
                            )
                          )}
                      </tbody>
                    </table>
                  </div>
                </div>
                {/* MAP Final Report Walkthrough page starts here */}
                <div style={{ width: '100%', float: 'left', pageBreakInside: 'avoid', breakBefore: 'page' }} className="new-page">
                  {/* MAP Final Report Walkthrough Header starts here */}
                  <div style={{ width: '98%', justifyContent: 'center', padding: '2% 1%', backgroundColor: '#F5F5F5' }}>
                    <h5 style={{ color: "#050505", margin: 0, fontSize: 22, fontWeight: 600, textAlign: "center", wordSpacing: 8, fontFamily: "SFProText-Medium" }}>MAP Final Report Walkthrough</h5>
                  </div>
                  {/*MAP Final Report Walkthrough Header  ends here */}
                  <div style={{ width: '100%', float: 'left', margin: '1% 0% 85%' }}>
                    {/* Main header style */}
                    <p style={{ fontFamily: 'SFProText-Medium', color: '#2E2E2E', fontWeight: 400, fontSize: '19px', margin: '3% 0% 2% 0%', lineHeight: '20px' }}>
                      <div dangerouslySetInnerHTML={{ __html: reportData.infraSummary.find((item: any) => item.attributeName === "Final Walkthrough")?.attributeValue || "" }} />
                    </p>
                  </div>
                </div>
                {/* MAP Final Report Walkthrough page ends here */}
              </td></tr>
          </tbody></table>
      </div>
    </>)
}
export default InfrastructureMapDownloadReport