/**
 * PS_SQ_02 - PS_RMP_04
 */
import { useState, useEffect, useRef, MutableRefObject } from 'react';
import { useNavigate, useLocation } from "react-router-dom"
import { MilestonesEntity, RoadMapDetailsEntity, RoadMapModel, ScoreDetails } from '../interface/roadMapInterface';
import { getRoadMapData } from '../service/roadMapApi'
import moment from 'moment';
import { toastModel } from '../interface/InitiativeModel';
import Loader from './Loader';
import ToastComponent from './ToastComponent';
import { useMsal } from "@azure/msal-react";
import { isAuth } from './Login/Auth';
import BreadCrumbs from './breadCrumb';

const RoadMap = () => {

    const { accounts } = useMsal();


    /**
     * PS_RMP_05 - PS_RMP_10
     * Declaration of variables and assigning values to it
     */
    const { state } = useLocation();

    const [isLoading, setisLoading] = useState<boolean>(false)

    const navKey = state?.controlArea;

    const [months, setMonths] = useState<string[]>([]);

    const [monthScroll, setMonthScroll] = useState<string[]>([]);

    const [scrollIndex, setScrollIndex] = useState<number>(0);

    const [roadMapData, setRoadMapData] = useState<RoadMapModel[]>([]);

    const textRef = useRef() as MutableRefObject<HTMLDivElement>;

    const scrollRef = useRef() as MutableRefObject<HTMLDivElement>;

    const scrollRefInitiative = useRef() as MutableRefObject<HTMLDivElement>;

    const [tabView, settabView] = useState<string>(navKey == 'Capacity Analysis' ? 'Transformation' : 'initiative');

    const [navView, setnavView] = useState<string>(navKey == 'Capacity Analysis' ? 'capacity' : navKey == 'Initiative Estimation' ? 'initiative' : 'sequence');

    const [sortable, setsortable] = useState({
        name: 'asc',
        startDate: 'asc',
        endDate: 'asc'
    })

    const score: ScoreDetails = {
        Transformation: 0,
        RTE: 0
    }

    const [scoreDetails, setscoreDetails] = useState<ScoreDetails>(score);

    const [render, setRender] = useState<Boolean>(false)

    const toastData: toastModel =
    {
        toastType: '',
        toastHeaderMessage: '',
        toastBodyMessage: ''
    };

    const [toast, setToast] = useState<toastModel>(toastData);

    const [hideToast, setHideToast] = useState<boolean>(true);

    const [hideMonthTab, setHideMonthTab] = useState<boolean>(true);

    const organizationId = state?.organizationId

    const organizationName = state?.organizationName

    const verticalId = state?.verticalId

    const verticalName = state?.verticalName

    const navigate = useNavigate();

    const [breadCrumbData, setBreadCrumbData] = useState([

        { title: "Assessments", path: "/homepage", isActive: true },

        { title: organizationName, path: "", isActive: true },

        { title: "Roadmap Planning", path: "", isActive: false },

    ]);
    /**
     * PS_RMP_11
     * First useEffect function is used for the toast
     * The second useEffect function is used to call getData function
     */
    useEffect(() => {
        setTimeout(() => {
            setHideToast(true);
        }, 4000);
    }, [hideToast]);


    useEffect(() => {
        if (!isAuth(accounts)) navigate('/')
        getData()
    }, []);

    /**
     * PS_RMP_12 - PS_RMP_33
     * The getData() function is declared and getRoadMapData is called
     * The response is then set to the state variables and the minimum start date
     * maximum end date is retrived using reduce function which is then passed to 
     * getMonthsBetweenDates to get the months between them.
     */
    async function getData() {
        try {
            let startDate: string = "", endDate: string = "";
            let obj = {
                organizationId: organizationId
            }

            setisLoading(true);
            const response = await getRoadMapData(obj)

            if (response.status !== 200) {
                setToast({
                    toastType: 'error',
                    toastHeaderMessage: 'Error',
                    toastBodyMessage: 'API failed'
                });
                setHideToast(false);
                setisLoading(false);
            }
            else {
                setisLoading(false);
                setRoadMapData(response.data.roadMapDetails)

                setHideToast(true);

                setscoreDetails({ ...scoreDetails, Transformation: parseInt(response.data.transformationScore), RTE: parseInt(response.data.rteScore) });


                const roadMapDetails = response?.data?.roadMapDetails || [];

                if (roadMapDetails.length) {


                    startDate = roadMapDetails.reduce((minStartDate: string, el: RoadMapDetailsEntity) => {
                        return el.startDate < minStartDate ? el.startDate : minStartDate;
                    }, roadMapDetails[0].startDate);

                    endDate = roadMapDetails.reduce((maxEndDate: string, el: RoadMapDetailsEntity) => {
                        return el.endDate > maxEndDate ? el.endDate : maxEndDate;
                    }, roadMapDetails[0].endDate);

                    const monthData: string[] = getMonthsBetweenDates(startDate, endDate);

                    setMonths(monthData)

                    setMonthScroll(extractValuesWithPattern(monthData));

                }

                setHideMonthTab(response.data.roadMapDetails ? true : false)
            }
        } catch (e) {
            // console.log(e, "Enytered catch");
            setToast({
                toastType: 'error',
                toastHeaderMessage: 'Error',
                toastBodyMessage: 'API failed'
            });
            setHideToast(false);
            setisLoading(false);
        }
    }


    /**
     * PS_RMP_22 - PS_RMP_28
     * The getMonthsBetweenDates function is used to retrive the list of months
     * between the provided start date and end date.
     * @param startDate 
     * @param endDate 
     * @returns 
     */
    function getMonthsBetweenDates(startDate: string, endDate: string) {
        const months = [];

        let currentDate = new Date(startDate);
        let lastDateMonth = (moment(new Date(endDate), 'MMM YYYY').endOf('month').format('YYYY-MM-DD'))

        const lastDate = new Date(lastDateMonth);

        while (currentDate <= lastDate) {
            const monthName = currentDate.toLocaleString('default', { month: 'long' });
            const year = currentDate.getFullYear();

            months.push(`${monthName.substr(0, 3)} ${year}`);

            let monthendDate = (moment(new Date(currentDate), 'MMM YYYY').endOf('month').format('YYYY-MM-DD'))
            let formatDate = new Date(monthendDate)
            let reducedDate = currentDate
            let formatcurrentDate = moment(currentDate).format('YYYY-MM-DD')
            let formatendDate = moment(formatDate).format('YYYY-MM-DD')
            if (formatcurrentDate === formatendDate) {
                let date = moment(currentDate).subtract(1, 'days').format('YYYY-MM-DD')
                reducedDate = new Date(date)
            }
            reducedDate.setMonth(reducedDate.getMonth() + 1);
            currentDate = reducedDate;
        }
        return months;
    }

    /**
     * PS_RMP_30 - PS_RMP_33
     * The extractValuesWithPattern function is used to seperate the 
     * total months into a pattern of six months
     * @param arr 
     * @returns 
     */
    function extractValuesWithPattern(arr: string[]) {
        const extractedValues = []; let j = 0;
        // if (arr.length != 6) 

        for (let i = 0; i < arr.length; i += 5) {

            (arr[i + 5] || arr[i + j]) && extractedValues.push(`${arr[i + j]} - ${!arr[i + 5] ? arr[arr.length - 1] : arr[i + 5 + j]}`);

            j++;
        }
        // else extractedValues.push(`${arr[0]}-${arr[5]}`)


        return extractedValues;
    }


    return (<>
        <Loader isLoading={isLoading}></Loader>
        {!hideToast ? <ToastComponent name={toast}></ToastComponent> : <></>}

        <div className="container-fluid">
            <div className="row justify-content-center">
                <div className="px-4">
                    {/* breadcrumb starts here */}
                    <div className="mt-5 pt-5 font-12 font-semibold">
                        <BreadCrumbs data={breadCrumbData}></BreadCrumbs>
                    </div>
                    {/* breadcrumb ends here */}
                    {/* heading content starts here */}
                    <div className="d-block d-md-flex flex-wrap justify-content-between align-items-center  mb-5">
                        <div className="d-flex align-items-center mb-3 mb-md-0">
                            <a style={{ 'cursor': 'pointer' }} onClick={() => { navigate('/homepage') }}>
                                <img src="images/backarrow.svg" alt="back-arrow" title="Back" />
                            </a>
                            <h2 className="font-22 font-semibold color-black mb-0 ms-3 ">
                                Roadmap Planning
                            </h2>
                        </div>
                        {hideMonthTab ?
                            <div className="d-block d-md-flex">
                                {
                                    navView !== 'capacity' && <div className="d-flex align-items-center me-0 me-md-5 mb-3 mb-md-0 justify-content-center">
                                        <button onClick={() => {
                                            if (scrollIndex !== 0) {
                                                setScrollIndex(scrollIndex => scrollIndex - 1);
                                                tabView == 'initiative' ? scrollRefInitiative.current.scrollLeft -= 900 : scrollRef.current.scrollLeft -= 900
                                            }
                                        }}
                                            type="button" className="bg-white border-0 p-0 shadow-none">
                                            <img
                                                src="images/forward-arrow.svg"
                                                alt="date-left-arrow"
                                                className="cal-date-arrow"
                                            />
                                        </button>
                                        <label className="font-12 font-medium color-black mx-3">
                                            {`${monthScroll[scrollIndex]}`}
                                        </label>
                                        <button onClick={() => {

                                            if (scrollIndex < monthScroll.length - 1) {
                                                setScrollIndex(scrollIndex => scrollIndex + 1);
                                                tabView == 'initiative' ? scrollRefInitiative.current.scrollLeft += 900 : scrollRef.current.scrollLeft += 900
                                            }
                                        }}
                                            type="button" className="bg-white border-0 p-0 shadow-none">
                                            <img
                                                src="images/backward-arrow.svg"
                                                alt="date-right-arrow"
                                                className="cal-date-arrow"
                                            />
                                        </button>
                                    </div>

                                }

                                {/* score card starts */}
                                {navView === 'capacity' &&
                                    <span className="d-flex align-items-center me-0 py-2 px-3 font-14 font-medium me-md-3 mb-3 mb-md-0 justify-content-center blue-bg-100 rounded-2">
                                        {`${tabView} Score : `}<span className="font-bold blue-700 ps-2"> {`${tabView}` == 'Transformation' ? scoreDetails.Transformation : scoreDetails.RTE}%</span>
                                    </span>
                                }
                                {/* score card ends */}
                                <div>
                                    <ul className="nav nav-pills white-tab rounded-2" id="pills-tab">
                                        <li className="nav-item w-md-50" role="presentation">
                                            <button
                                                className={` ${tabView === 'Transformation' || tabView === 'initiative' ? 'active' : ''} nav-link white-tab-list font-12 font-semibold w-100`}
                                                id="pills-initiative-tab"
                                                onClick={() => {

                                                    if (navView === 'capacity') settabView('Transformation')
                                                    else { settabView('initiative'); }

                                                }}
                                            >
                                                {` ${navView === 'capacity' ? 'Transformation' : 'Initiative View'}  `}
                                            </button>
                                        </li>
                                        <li className="nav-item w-md-50" role="presentation">
                                            <button
                                                className={` ${tabView === 'RTE' || tabView === 'team' ? 'active' : ''} nav-link white-tab-list font-12 font-semibold w-100`}
                                                id="pills-team-tab"
                                                onClick={() => {

                                                    if (navView === 'capacity') settabView('RTE')
                                                    else settabView('team')

                                                }}
                                            >
                                                {` ${navView === 'capacity' ? 'RTE' : 'Team View'}  `}
                                            </button>
                                        </li>
                                    </ul>
                                </div>
                            </div> :
                            <div>
                                <button className="btn btn-dark theme-primary-btn border-0 font-14 font-semibold ms-md-3 ms-0 table-btn w-auto text-nowrap w-md-100"
                                    onClick={() => { navigate('/newinitiative', { state: { organizationId: organizationId, verticalId: verticalId, verticalName: verticalName, organizationName: organizationName } }) }}
                                >New Initiative</button>
                            </div>
                        }

                    </div>
                    {/* heading content ends here */}
                    <div className="row">
                        <div className="col-12 col-md-12 col-lg-3 col-xl-2 ">
                            <div className=" v-tab-border left-nav-brd">
                                <h6 className="font-16 font-semibold mb-lg-4 mb-2 ps-0 ps-lg-3">
                                    Control Areas
                                </h6>
                                <div className="d-lg-flex d-none align-items-start">
                                    <div
                                        className="nav flex-column nav-pills custom-v-pills w-100"
                                        id="v-pills-tab"
                                        role="tablist"
                                        aria-orientation="vertical"
                                    >
                                        <button
                                            className={` ${navView === 'initiative' ? 'active' : ''} nav-link text-start rounded-0 font-regular font-14`}
                                            onClick={() => {
                                                setnavView('initiative');
                                                settabView('initiative');
                                            }}
                                        >
                                            Initiative Estimation
                                        </button>
                                        {/* need to provide a index id instead of id in data bs target */}
                                        <button
                                            className={` ${navView === 'capacity' ? 'active' : ''} nav-link text-start rounded-0 font-regular font-14`}
                                            onClick={() => {
                                                setnavView('capacity');
                                                settabView('Transformation');
                                            }}
                                        >
                                            Capacity Analysis
                                        </button>
                                        <button
                                            className={` ${navView === 'sequence' ? 'active' : ''} nav-link text-start rounded-0 font-regular font-14`}
                                            onClick={() => {
                                                setnavView('sequence');
                                                settabView('initiative');
                                            }}
                                        >
                                            Sequencing
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <select className="theme-form form-select font-regular font-14 d-lg-none d-block mb-3">
                                <option>Initiative Estimation</option>
                                <option>Capacity Analysis</option>
                                <option>Sequencing</option>
                            </select>
                        </div>
                        <div className="col-12 col-md-12 col-lg-9 col-xl-10 Nav-page-scrl">
                            <div className="tab-content" id="v-pills-tabContent">
                                {/* Initiative Estimation content starts here */}
                                <InitiativeEstimation tabView={tabView} navView={navView} roadMapData={roadMapData} months={months} textRef={textRef} scrollRef={scrollRef} scrollRefInitiative={scrollRefInitiative} organization_id={organizationId} organization_name={organizationName} vertical_id={verticalId} vertical_name={verticalName} getMonthBetweenDates={getMonthsBetweenDates} />
                                {/* Initiative Estimation content ends here */}
                                {/* Capacity Analysis content starts here */}
                                <CapacityAnalysis tabView={tabView} navView={navView} roadMapData={roadMapData} sortable={sortable} setsortable={setsortable} setRender={setRender} />
                                {/* Capacity Analysis content ends here */}
                                {/* sequencing starts here */}
                                <div
                                    className="tab-pane"
                                    id="v-pills-sequencing"
                                    role="tabpanel"
                                ></div>
                                {/* sequencing ends here */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </>)
}

export default RoadMap

/**
 * PS_RMP_35 - PS_RMP_41
 * The initiativeEstimation function provides the mapping of the datas in the
 * initiative or sequence view
 */
const InitiativeEstimation = ({ tabView, navView, roadMapData, scrollRef, months, textRef, scrollRefInitiative, organization_id, vertical_id, vertical_name, organization_name, getMonthBetweenDates }: any) => {

    const navigate = useNavigate();

    return (<>
        <div
            className={`${((navView === 'initiative' || navView === 'sequence')) ? 'show active' : ''} tab-pane fade mb-5`}
            id="v-pills-estimation"
            role="tabpanel"
        >
            <div className="tab-content" id="pills-tabContent">
                {/* TeamView tab */}
                <div
                    className={`${tabView === 'team' ? 'show active' : ''}tab-pane fade mb-5`}
                    id="pills-initiative"
                    role="tabpanel"
                >
                    <div ref={scrollRef} className="table-responsive calender-scrl d-none d-md-block">
                        {roadMapData ?
                            <table className="table table-borderless themeTable calender-tbl mb-0">
                                <thead className="font-semibold ">
                                    <tr>
                                        <th className="border-bottom ps-4 table-sticky-th" style={{ minWidth: '200px' }}>
                                            Initiatives
                                        </th>
                                        {
                                            months?.map((month: string, i: number) => <th key={i + Math.random()} className="border-bottom ps-4">{month}</th>)
                                        }
                                    </tr>
                                </thead>
                                <tbody className="font-medium">

                                    {
                                        roadMapData?.map((initiativeData: RoadMapDetailsEntity, i: number) => {
                                            return (
                                                <tr>
                                                    <td className="ps-4 text-wrap table-sticky-td" style={{ minWidth: '200px' }}>
                                                        <span className="d-flex align-items-center text-nowrap">
                                                            {
                                                                tabView === 'team' && <span className="d-block">
                                                                    <img
                                                                        src="images/accordion-arrow.svg"
                                                                        className="cursor-pointer accordion-arrow collapsed me-2"
                                                                        data-bs-toggle="collapse"
                                                                        data-bs-target={`#crmDev${i}`}
                                                                        aria-expanded="false"
                                                                        aria-controls="crmDev"
                                                                    />
                                                                </span>
                                                            }

                                                            {
                                                                navView === 'initiative' || tabView === 'team' ?
                                                                    initiativeData.initiativeName : <a className="link-blue text-nowrap">{initiativeData.initiativeName}</a>
                                                            }
                                                            <span className={`${initiativeData.initiativeType === 'RTE' ? 'indigo-300' : 'link-blue'}  ms-2 p-1 blue-bg-100 font-12 rounded-3`}>
                                                                {initiativeData.initiativeType}
                                                            </span>
                                                        </span>
                                                        <span className="collapse" id={`crmDev${i}`}>
                                                            <span className="ps-2 mt-4 d-block">
                                                                {
                                                                    initiativeData?.resourceDetails?.map(({ roleName, resources }: any) => {
                                                                        return <span className="d-block ps-3 mb-4 ">
                                                                            {`${roleName}(${resources})`}
                                                                        </span>
                                                                    })
                                                                }
                                                                <span className="d-block p-3 ps-1 grey-bg-100">
                                                                    <span className="d-flex justify-content-between ps-4 mb-3">
                                                                        Planned Hours
                                                                        <span>{initiativeData.plannedHours}</span>
                                                                    </span>
                                                                    <span className="d-flex justify-content-between ps-4 ">
                                                                        Development Capacity <span>{initiativeData.developmentCapacity}</span>
                                                                    </span>
                                                                </span>
                                                            </span>
                                                        </span>
                                                    </td>
                                                    <td
                                                        colSpan={((navView === 'initiative' || navView === 'sequence') && tabView !== 'team') ? months.length + 2 : months.length}
                                                        className="my-2 px-0 py-3 position-relative "
                                                    >

                                                        <span className="d-flex justify-content-between td-head-brd">

                                                            {months?.map((month: any) => {

                                                                return <span className="cal-tbl-brd" />
                                                            })}

                                                            <span className="cal-tbl-brd" />

                                                        </span>
                                                        <span>
                                                            {
                                                                initiativeData?.milestones?.map((milestone: MilestonesEntity, i: number) => {


                                                                    //PS_RMP_38 - PS_RMP_40
                                                                    //The getMileStoneCss function provides the marginLeft, marginRight values
                                                                    //for the milestone along with the total width of the milestone. 
                                                                    function getMileStoneCss({ milestoneStart, milestoneEnd }: any) {

                                                                        let totalPixel = months.length < 6 ? Number(900 / months.length) : 150

                                                                        const marginLeft = Number(Math.abs(
                                                                            moment(moment(months[0], 'MMM YYYY').startOf('month').format('YYYY-MM-DD'), 'YYYY-MM-DD')
                                                                                .startOf('day')
                                                                                .diff(moment(milestoneStart, 'YYYY-MM-DD').startOf('day'), 'days')
                                                                        )) * (totalPixel / 30);

                                                                        const daysDiff = Number(Math.abs(
                                                                            moment(milestoneStart, 'YYYY-MM-DD')
                                                                                .startOf('day')
                                                                                .diff(moment(milestoneEnd, 'YYYY-MM-DD').startOf('day'), 'days')
                                                                        ));
                                                                        let compactDays = 0
                                                                        // if (daysDiff > 30) {
                                                                        let totalMonths = getMonthBetweenDates(milestoneStart, milestoneEnd)
                                                                        let lastDate: any = []
                                                                        for (let i = 0; i < totalMonths.length; i++) {
                                                                            let date = (moment(totalMonths[i]).endOf('month')).format('DD')
                                                                            lastDate.push(date)
                                                                        }
                                                                        let daysToAdd = 0
                                                                        let daysToSub = 0

                                                                        for (let i = 0; i < lastDate.length; i++) {
                                                                            if (lastDate[i] == '31') daysToSub += 1
                                                                            if (lastDate[i] == '28') daysToAdd += 2
                                                                            if (lastDate[i] == '29') daysToAdd += 1
                                                                        }

                                                                        compactDays = daysDiff + daysToAdd - daysToSub
                                                                        // } else {
                                                                        //     daysDiff === 0 ? compactDays = 1 : compactDays = daysDiff
                                                                        // }

                                                                        const pixCalc = compactDays * (totalPixel / 30);

                                                                        const marginRight = (Number(months.length * totalPixel) - (pixCalc + marginLeft));


                                                                        let returnObj: any = {
                                                                            style: { marginLeft: marginLeft + 'px', marginRight: marginRight + 'px' },
                                                                            width: pixCalc + 'px'
                                                                        }
                                                                        return returnObj

                                                                    }

                                                                    return <span style={getMileStoneCss(milestone).style} className={`d-block text-nowrap text-start font-13  cal-label-sty-noPointer mb-3 ${milestone.riskSeverity === 'Low' ? 'cal-blue-label' : 'cal-red-label'}`}>

                                                                        <TextWithEllipsis text={milestone.milestoneName} boxWidth={getMileStoneCss(milestone).width} />
                                                                    </span>
                                                                })
                                                            }

                                                        </span>
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }

                                    <tr className="border-0">

                                        {months?.map((month: string, i: number) => {

                                            return <td key={month + i} />
                                        })}
                                        <td />

                                    </tr>
                                </tbody>
                            </table> : <>
                                <p>Sorry you don't have any Initiative assessment. Please click on <b>"New initiative"</b> to add with a milestones.</p>
                            </>}
                    </div>
                </div>

                {/* Initiative View tab */}
                <div
                    className={`${tabView === 'initiative' ? 'show active' : ''}tab-pane fade mb-5`}
                    id="pills-team"
                    role="tabpanel"
                >
                    <div ref={scrollRefInitiative} className="table-responsive calender-scrl d-none d-md-block">
                        {roadMapData ?
                            <table className="table table-borderless themeTable calender-tbl mb-0">
                                <thead className="font-semibold ">
                                    <tr>
                                        <th className="border-bottom ps-4 table-sticky-th" style={{ minWidth: '200px' }}>
                                            Initiatives
                                        </th>
                                        <th className="border-bottom ps-4 table-sticky-sec-th" style={{ left: '180px' }}>
                                            Risk Severity Level
                                        </th>
                                        {
                                            months?.map((month: string, i: number) => <th key={i + Math.random()} className="border-bottom ps-4">{month}</th>)
                                        }
                                    </tr>
                                </thead>
                                <tbody className="font-medium">

                                    {
                                        roadMapData?.map((initiativeData: RoadMapDetailsEntity, i: number) => {
                                            let high = 0
                                            initiativeData?.milestones?.map((milestones: MilestonesEntity) => {
                                                if (milestones.riskSeverity != 'Low') high = 1
                                            })
                                            return (<>

                                                <tr key={i}>
                                                    <td className="ps-4 text-wrap table-sticky-td tbl-brd-right" style={{ minWidth: '200px' }}>
                                                        <a
                                                            onClick={() => {
                                                                if (navView === 'sequence' && high === 1) navigate("/sequencing", { state: { initiativeId: initiativeData.initiativeId, organizationId: organization_id, verticalId: vertical_id, verticalName: vertical_name, organizationName: organization_name } })
                                                                else if (navView === 'initiative') navigate('/newinitiative', { state: { initiativeId: initiativeData.initiativeId, organizationId: organization_id, verticalId: vertical_id, verticalName: vertical_name, organizationName: organization_name, path: 'RoadmapPlanning' } })
                                                            }}
                                                            className={`${navView === 'sequence' && initiativeData.riskSeverity != 'Low' ? 'link-blue' : ''} text-nowrap`} style={(navView === 'sequence' && high === 1) || (navView === 'initiative') ? { cursor: 'pointer' } : { textDecoration: 'none', color: 'black' }}>
                                                            {initiativeData.initiativeName}
                                                        </a>
                                                        <span className={`${initiativeData.initiativeType === 'RTE' ? 'indigo-300' : 'link-blue'} ms-2 p-1 transformation-batch-bg font-12 rounded-3`}>
                                                            {initiativeData.initiativeType}
                                                        </span>
                                                    </td>
                                                    <td className="text-center table-sticky-sec-td tbl-brd-right" style={{ left: '180px' }}>
                                                        <span className="d-inline-block">
                                                            <span className={`${initiativeData.riskSeverity === 'High' ? 'severity-high-bg' : initiativeData.riskSeverity === 'Medium' ? 'severity-medium-bg' : 'severity-low-bg'} severity`}>
                                                                <span className={`${initiativeData.riskSeverity === 'High' ? 'severity-high' : initiativeData.riskSeverity === 'Medium' ? 'severity-medium' : 'severity-low'} severity-point  me-2 `} />
                                                                {initiativeData.riskSeverity}
                                                            </span>
                                                        </span>
                                                    </td>
                                                    <td
                                                        colSpan={months.length}
                                                        className="my-2 px-0 py-3 position-relative "
                                                    >
                                                        <span className="d-flex justify-content-between td-head-brd">
                                                            {months?.map((month: any) => {

                                                                return <span className="cal-tbl-brd" />
                                                            })}
                                                            <span className="cal-tbl-brd" />

                                                        </span>
                                                        <span>
                                                            {
                                                                initiativeData?.milestones?.map((milestone: MilestonesEntity, i: number) => {

                                                                    //PS_RMP_38 - PS_RMP_40
                                                                    //The getMileStoneCss function provides the marginLeft, marginRight values
                                                                    //for the milestone along with the total width of the milestone. 
                                                                    function getMileStoneCss({ milestoneStart, milestoneEnd }: any) {

                                                                        let totalPixel = months.length < 6 ? Number(900 / months.length) : 150

                                                                        const marginLeft = Number(Math.abs(
                                                                            moment(moment(months[0], 'MMM YYYY').startOf('month').format('YYYY-MM-DD'), 'YYYY-MM-DD')
                                                                                .startOf('day')
                                                                                .diff(moment(milestoneStart, 'YYYY-MM-DD').startOf('day'), 'days')
                                                                        )) * (totalPixel / 30);

                                                                        const daysDiff = Number(Math.abs(
                                                                            moment(milestoneStart, 'YYYY-MM-DD')
                                                                                .startOf('day')
                                                                                .diff(moment(milestoneEnd, 'YYYY-MM-DD').startOf('day'), 'days')
                                                                        ));

                                                                        let compactDays = 0
                                                                        // if (daysDiff > 30){
                                                                        let totalMonths = getMonthBetweenDates(milestoneStart, milestoneEnd)
                                                                        let lastDate: any = []
                                                                        for (let i = 0; i < totalMonths.length; i++) {
                                                                            let date = (moment(totalMonths[i]).endOf('month')).format('DD')
                                                                            lastDate.push(date)
                                                                        }
                                                                        let daysToAdd = 0
                                                                        let daysToSub = 0

                                                                        for (let i = 0; i < lastDate.length; i++) {
                                                                            if (lastDate[i] == '31') daysToSub += 1
                                                                            if (lastDate[i] == '28') daysToAdd += 2
                                                                            if (lastDate[i] == '29') daysToAdd += 1
                                                                        }

                                                                        compactDays = daysDiff + daysToAdd - daysToSub
                                                                        // }else{
                                                                        //     daysDiff === 0 ? compactDays = 1 : compactDays = daysDiff
                                                                        // }
                                                                        const pixCalc = Math.abs(compactDays) * (totalPixel / 30);
                                                                        let lastmothdate = moment(months[months.length - 1]).endOf('month').format('YYYY-MM-DD');
                                                                        let milestoneEndDate = moment(milestoneEnd).format('YYYY-MM-DD')

                                                                        let marginRight
                                                                        if (lastmothdate === milestoneEndDate) {
                                                                            marginRight = 0
                                                                        } else {
                                                                            marginRight = (Number(months.length * totalPixel) - (pixCalc + marginLeft));
                                                                        }

                                                                        // const marginRight = (Number(months.length * totalPixel) - (pixCalc + marginLeft));

                                                                        let returnObj: any = {
                                                                            style: { marginLeft: marginLeft + 'px', marginRight: marginRight + 'px' },
                                                                            width: pixCalc + 'px'
                                                                        }
                                                                        return returnObj
                                                                    }


                                                                    return (
                                                                        <p style={getMileStoneCss(milestone).style} className={` text-nowrap text-start font-13  cal-label-sty-noPointer mb-3 ${milestone.riskSeverity === 'Low' ? 'cal-blue-label' : 'cal-red-label'}`}>
                                                                            {/* {text.substr(0, Math.floor(text.length / 2))} */}
                                                                            {/* <canvas ref={textRef} style={{ display: 'none' }} /> */}
                                                                            <TextWithEllipsis text={milestone.milestoneName} boxWidth={getMileStoneCss(milestone).width} />
                                                                        </p>)
                                                                })
                                                            }

                                                        </span>
                                                    </td>
                                                </tr>



                                            </>)

                                        })
                                    }

                                    <tr className="border-0">
                                        {months?.map((month: string, i: number) => {

                                            return <td key={month + i} />
                                        })}
                                        <td />
                                        <td />
                                    </tr>
                                </tbody>
                            </table> : <>
                                <p>Sorry you don't have any Initiative assessment. Please click on <b>"New initiative"</b> to add with a milestones.</p>
                            </>}
                    </div>
                </div>
            </div>
        </div>
    </>)
}

/**
 * PS_RMP_46 - PS_RMP_48
 * The CapacityAnalysis function provides the mapping of the datas in the 
 * capacityAnalysis view
 */
const CapacityAnalysis = ({ tabView, navView, roadMapData, sortable, setsortable, setRender }: any) => {


    return (<>

        <div
            className={`${navView === 'capacity' ? 'show active' : ''} tab-pane fade`}
            id="v-pills-analysis" role="tabpanel">

            {/* transfromation table starts here */}


            {
                (tabView === 'Transformation' || tabView === 'RTE') && <div className="table-responsive mb-4">
                    {roadMapData && !roadMapData.find((initiativeData: RoadMapDetailsEntity) => initiativeData.initiativeType === tabView) ? <div className="tab-pane mb-5" id="v-pills-costopt" role="tabpanel">

                        <div className=" text-center">

                            <p className="font-16 font-medium color-grey-v3 my-5">Sorry you don't have any data in {tabView}</p>

                        </div>

                    </div> : roadMapData ?
                        <table className="table table-bordered themeTable ">
                            <thead className="font-semibold ">
                                <tr>
                                    <th className="ps-5">Initiatives</th>
                                    <th className="text-end">Planned Hours</th>
                                    <th className="text-end">Development Capacity</th>
                                    <th className="text-center">Risk Severity Level</th>
                                </tr>
                            </thead>
                            <tbody className="font-medium">

                                {
                                    roadMapData?.map((initiativeData: RoadMapDetailsEntity, i: number) => {

                                        return initiativeData.initiativeType === tabView && <>
                                            <tr>
                                                <td>
                                                    <span className="d-flex align-items-center">
                                                        <span>
                                                            <img
                                                                src="images/accordion-arrow.svg"
                                                                className="cursor-pointer accordion-arrow collapsed me-2"
                                                                data-bs-toggle="collapse"
                                                                data-bs-target={"#crmDev" + i}
                                                                aria-expanded="false"
                                                                aria-controls="collapseExample"
                                                            />
                                                        </span>
                                                        {initiativeData.initiativeName}
                                                    </span>
                                                </td>
                                                <td className="text-end">{initiativeData.plannedHours}</td>
                                                <td className="text-end">{initiativeData.developmentCapacity}</td>
                                                <td className="text-center">
                                                    <span className="d-inline-block">
                                                        <span className={`${initiativeData.riskSeverity === 'High' ? 'severity-high-bg' : initiativeData.riskSeverity === 'Medium' ? 'severity-medium-bg' : 'severity-low-bg'} severity`}>
                                                            <span className={`${initiativeData.riskSeverity === 'High' ? 'severity-high' : initiativeData.riskSeverity === 'Medium' ? 'severity-medium' : 'severity-low'} severity-point  me-2`} />
                                                            {initiativeData.riskSeverity}
                                                        </span>
                                                    </span>
                                                </td>
                                            </tr>
                                            <tr className="accordion-container">
                                                <td colSpan={12} className="collapse" id={"crmDev" + i}>
                                                    {/* first level accordion starts  */}
                                                    <table className="table table-bordered themeTable ">
                                                        <thead className="font-semibold ">
                                                            <tr>
                                                                <th>
                                                                    Milestone
                                                                    <span className="ms-2 cursor-pointer">
                                                                        <img onClick={() => {

                                                                            initiativeData?.milestones?.sort((a, b) => {
                                                                                const sortOrder = (sortable.name === 'asc') ? 1 : -1;
                                                                                return sortOrder * a.milestoneName.localeCompare(b.milestoneName);
                                                                            });


                                                                            setsortable((prev: any) => ({ ...prev, name: `${prev.name === 'asc' ? 'desc' : 'asc'}` }))

                                                                            setRender((prev: any) => !prev)
                                                                        }}
                                                                            src={`${sortable.name === 'asc' ? "/images/sortarrow.svg" : "/images/sortarrowup.svg"}`}
                                                                            alt="sortarrow"
                                                                            title="Sort"
                                                                        />
                                                                    </span>
                                                                </th>
                                                                <th>
                                                                    Start Date
                                                                    <span className="ms-2 cursor-pointer">
                                                                        <img onClick={() => {

                                                                            initiativeData?.milestones?.sort((a, b) => sortable.startDate === 'desc' ? new Date(b.milestoneStart).getTime() - new Date(a.milestoneStart).getTime() : new Date(a.milestoneStart).getTime() - new Date(b.milestoneStart).getTime());

                                                                            setsortable((prev: any) => ({ ...prev, startDate: `${prev.startDate === 'asc' ? 'desc' : 'asc'}` }))
                                                                        }}
                                                                            src={`${sortable.startDate === 'asc' ? "/images/sortarrow.svg" : "/images/sortarrowup.svg"}`}
                                                                            alt="sortarrow"
                                                                            title="Sort"
                                                                        />
                                                                    </span>
                                                                </th>
                                                                <th>
                                                                    End Date
                                                                    <span className="ms-2 cursor-pointer">
                                                                        <img onClick={() => {

                                                                            initiativeData?.milestones?.sort((a, b) => sortable.endDate === 'desc' ? new Date(b.milestoneEnd).getTime() - new Date(a.milestoneEnd).getTime() : new Date(a.milestoneEnd).getTime() - new Date(b.milestoneEnd).getTime());

                                                                            setsortable((prev: any) => ({ ...prev, endDate: `${prev.endDate === 'asc' ? 'desc' : 'asc'}` }))
                                                                        }}
                                                                            src={`${sortable.endDate === 'asc' ? "/images/sortarrow.svg" : "/images/sortarrowup.svg"}`}
                                                                            alt="sortarrow"
                                                                            title="Sort"
                                                                        />
                                                                    </span>
                                                                </th>
                                                                <th className="text-end">Planned Hours </th>
                                                                <th className="text-end">Development Capacity</th>
                                                                <th className="text-center">Risk Severity</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody className="font-medium">

                                                            {
                                                                initiativeData?.milestones?.map((milestone) => {

                                                                    return <>

                                                                        <tr>
                                                                            <td>
                                                                                <a style={{ textDecoration: 'none', color: 'blue' }} title={`${milestone.milestoneName}`}>
                                                                                    {milestone.milestoneName.length > 20 ? milestone.milestoneName.substr(0, 20) + '...' : milestone.milestoneName}
                                                                                </a>
                                                                            </td>
                                                                            <td>{moment(milestone.milestoneStart).format('MMMM DD, YYYY')}</td>
                                                                            <td>{moment(milestone.milestoneEnd).format('MMMM DD, YYYY')}</td>
                                                                            <td className="text-end">{milestone.plannedHours}</td>
                                                                            <td className="text-end">{milestone.developmentCapacity}</td>
                                                                            <td className="text-center">
                                                                                <span className="d-inline-block">
                                                                                    <span className={`${milestone.riskSeverity === 'High' ? 'severity-high-bg' : milestone.riskSeverity === 'Medium' ? 'severity-medium-bg' : 'severity-low-bg'} severity`}>
                                                                                        <span className={`${milestone.riskSeverity === 'High' ? 'severity-high' : milestone.riskSeverity === 'Medium' ? 'severity-medium' : 'severity-low'} severity-point  me-2`} />
                                                                                        {milestone.riskSeverity}
                                                                                    </span>
                                                                                </span>
                                                                            </td>
                                                                        </tr>

                                                                    </>
                                                                })
                                                            }
                                                        </tbody>
                                                    </table>
                                                    {/* first level accordion ends */}
                                                </td>
                                            </tr>

                                        </>
                                    })
                                }


                            </tbody>
                        </table> : <>
                            <p>Sorry you don't have any Initiative assessment. Please click on <b>"New initiative"</b> to add with a milestones.</p>
                        </>}
                </div>
            }


            {/* transfromation table ends here */}

        </div>
    </>)
}

/**
 * PS_RMP_56 - PS_RMP_70
 * The TextWithEllipsis function is used to handle the overlapping of the milestone name
 * within the milestone box. 
 */
const TextWithEllipsis = ({ text, boxWidth }: any) => {
    const boxRef = useRef() as MutableRefObject<HTMLDivElement>;
    const [truncatedText, setTruncatedText] = useState<string>(text);

    useEffect(() => {
        const boxElement = boxRef.current;
        if (boxElement) {
            if (boxElement.scrollWidth > boxElement.clientWidth) {
                const availableWidth = boxElement.clientWidth;
                const textContent = text;
                let start = 0;
                let end = textContent.length;
                let mid;

                while (start < end) {
                    mid = Math.floor((start + end) / 2);
                    const truncatedText = textContent.slice(0, mid);


                    if (boxElement.scrollWidth <= availableWidth) {
                        start = mid + 1;
                        setTruncatedText(truncatedText + '...');
                    } else {
                        end = mid;
                    }
                }
            }
        }
    }, [text, boxWidth]);

    return (
        <div className="text-box" title={text} style={{ width: boxWidth }} ref={boxRef}>
            {truncatedText}
        </div>
    );
}