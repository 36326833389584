/**
 * PS_SQ_02 - PS_RMP_04
 */
import { useState, useEffect, useRef, MutableRefObject } from 'react';
import { getInitiativeData } from "../service/roadMapApi";
import { useLocation, useNavigate } from 'react-router-dom';
import {
  MilestonesEntity,
  ResourceDetailsEntity,
  TimelineRecommendationEntity
} from '../interface/roadMapInterface';
import moment from 'moment';
import MilestoneForm from './MilestoneForm';
import { milestoneDetailsModel, toastModel } from '../interface/InitiativeModel';
import Loader from './Loader';
import ToastComponent from './ToastComponent';
import { useMsal } from "@azure/msal-react";
import { isAuth } from './Login/Auth';
import BreadCrumbs from './breadCrumb';

/**
 * PS_SQ_05 - PS_SQ_10
 * Declaration of variables and assigning values to it
 */
const Sequence = () => {

  const { state } = useLocation();

  const [isLoading, setisLoading] = useState<boolean>(false)

  const [initiativeDetails, setinitiativeDetils] = useState<any>([]);

  const organization_id = state?.organizationId;
  const organizationName = state?.organizationName;
  const initiative_id = state?.initiativeId;
  const verticalId = state?.verticalId;
  const verticalName = state?.verticalName;

  const [orgData, setOrgData] = useState({
    organization_id: state?.organizationId,
    organizationName: state?.organizationName,
    initiative_id: state?.initiativeId,
    verticalId: state?.verticalId,
    verticalName: state?.verticalName
  })

  const { accounts } = useMsal();

  const [currentTimeline, setcurrentTimeline] = useState<TimelineRecommendationEntity>();

  const [resourceRecommendation, setresourceRecommendation] = useState<TimelineRecommendationEntity>();

  const [timelineRecommendation, settimelineRecommmendation] = useState<TimelineRecommendationEntity>();

  const [currentTimelineMonths, setcurrentTimelineMonths] = useState<string[]>([]);

  const [resourceRecommendationMonths, setresourceRecommendationMonths] = useState<string[]>([]);

  const [timelineRecommendationMonths, settimelineRecommendationMonths] = useState<string[]>([]);

  const [milestoneId, setMilestoneId] = useState<any>('')

  const [flag, setFlag] = useState<Boolean>(false)

  const [render, setRender] = useState<boolean>(false)


  const InitiativeParam = {
    organizationId: organization_id,
    initiativeId: initiative_id
  }

  const toastData: toastModel =
  {
    toastType: '',
    toastHeaderMessage: '',
    toastBodyMessage: ''
  };

  const [toast, setToast] = useState<toastModel>(toastData);

  const [hideToast, setHideToast] = useState<boolean>(true);

  const navigate = useNavigate();

  const [breadCrumbData, setBreadCrumbData] = useState([

    { title: "Assessments", path: "/homepage", isActive: true },

    { title: organizationName, path: "", isActive: true },

    { title: "Roadmap Planning", path: "/roadmapplanning", isActive: true, state: { state: { initiativeId: orgData.initiative_id, organizationId: orgData.organization_id, verticalId: orgData.verticalId, verticalName: orgData.verticalName, organizationName: orgData.organizationName } } },

    { title: "Sequencing", path: "", isActive: false },

  ]);


  /**
   * PS_SQ_11
   * First useEffect function is used for the toast
   * Within the useEffect function call the getData()
   */
  useEffect(() => {
    setTimeout(() => {
      setHideToast(true);
    }, 4000);
  }, [hideToast]);

  useEffect(() => {

    if (!isAuth(accounts)) navigate('/')

    getData();

  }, [render, flag])




  /**
   * PS_SQ_12 - PS_SQ_21
   * The getData function calls the getInitiativeData function
   * The response is set to the state variable and set the currentTimeline, resourceRecommendation and
   * timelineRecommendation to the respective state variables
   * To get the months between the dates call the startDateEndDate function and pass the respective keys and
   * store it in the respective state variables.
   */
  async function getData() {
    try {
      setisLoading(true);
      const response = await getInitiativeData(InitiativeParam);

      if (response.status != 200) {
        setToast({
          toastType: 'error',
          toastHeaderMessage: 'Error',
          toastBodyMessage: 'API failed'
        });
        setHideToast(false);
        setisLoading(false);

      } else {


        setHideToast(true);
        setinitiativeDetils(response?.data);
        setcurrentTimeline(response?.data?.currentTimeline);
        setresourceRecommendation(response?.data?.resourceRecommendations);
        settimelineRecommmendation(response?.data?.timelineRecommendations);

        setcurrentTimelineMonths(startDateEndDate(response?.data?.currentTimeline));
        setresourceRecommendationMonths(startDateEndDate(response?.data?.resourceRecommendations));
        settimelineRecommendationMonths(startDateEndDate(response?.data?.timelineRecommendations));

        setisLoading(false)
      }

    } catch (e) {
      // console.log(e, "error in catch");
      setToast({
        toastType: 'error',
        toastHeaderMessage: 'Error',
        toastBodyMessage: 'API failed'
      });
      setHideToast(false);
      setisLoading(false);
    }
  }

  /**
   * PS_SQ_23 - PS_SQ_26
   * The startDateEndDate function is used to retrive the minimum start date
   * and the maximum end date.
   */
  function startDateEndDate(initiativeData: TimelineRecommendationEntity) {

    let startDate: string = "", endDate: string = "";

    let initiativeDetails = initiativeData.milestones || [];



    if (initiativeDetails.length) {
      startDate = initiativeDetails.reduce((minStartDate: string, el: MilestonesEntity) => {
        return el.milestoneStart < minStartDate ? el.milestoneStart : minStartDate;
      }, initiativeDetails[0].milestoneStart);

      endDate = initiativeDetails.reduce((maxEndDate: string, el: MilestonesEntity) => {
        return el.milestoneEnd > maxEndDate ? el.milestoneEnd : maxEndDate;
      }, initiativeDetails[0].milestoneEnd);
    }
    let monthData: string[] = getMonthsBetweenDates(startDate, endDate);
    return monthData;
  }

  /**
   * PS_SQ_27 - PS_SQ_33
   * The getMonthsBetweenDates function is used to get the list of months
   * between the provided start date and the end date.
   */
  function getMonthsBetweenDates(startDate: string, endDate: string) {
    const months = [];

    let currentDate = new Date(startDate);
    let lastDateMonth = (moment(new Date(endDate), 'MMM YYYY').endOf('month').format('YYYY-MM-DD'))

    const lastDate = new Date(lastDateMonth);

    while (currentDate <= lastDate) {
        const monthName = currentDate.toLocaleString('default', { month: 'long' });
        const year = currentDate.getFullYear();

        months.push(`${monthName.substr(0, 3)} ${year}`);

        let monthendDate = (moment(new Date(currentDate), 'MMM YYYY').endOf('month').format('YYYY-MM-DD'))
        let formatDate = new Date(monthendDate)
        let reducedDate = currentDate
        let formatcurrentDate = moment(currentDate).format('YYYY-MM-DD')
        let formatendDate = moment(formatDate).format('YYYY-MM-DD')
        if (formatcurrentDate === formatendDate) {
            let date = moment(currentDate).subtract(1, 'days').format('YYYY-MM-DD')
            reducedDate = new Date(date)
        }
        reducedDate.setMonth(reducedDate.getMonth() + 1);
        currentDate = reducedDate;
    }
    return months;
}



  return (
    <>
      <>
        <Loader isLoading={isLoading}></Loader>
        {!hideToast ? <ToastComponent name={toast}></ToastComponent> : <></>}
      </>
      {/* top header starts here */}

      {/* top header ends here */}
      <div className="container-fluid">
        <div className="row justify-content-center">
          <div className="px-4">
            {/* breadcrumb starts here */}
            <div className="mt-5 pt-5 font-12 font-semibold">
              <BreadCrumbs data={breadCrumbData}></BreadCrumbs>
            </div>
            {/* breadcrumb ends here */}
            {/* heading content starts here */}
            <div className="d-flex  align-items-center  mb-5">
              <a style={{ 'cursor': 'pointer' }} onClick={() => { navigate('/roadmapplanning', { state: { initiativeId: orgData.initiative_id, organizationId: orgData.organization_id, verticalId: orgData.verticalId, verticalName: orgData.verticalName, organizationName: orgData.organizationName } }) }}>
                <img src="images/backarrow.svg" alt="back-arrow" title="Back" />
              </a>
              <h2 className="font-22 font-semibold color-black mb-0 ms-3 ">
                {initiativeDetails.initiativeName}
              </h2>
            </div>
            {/* heading content ends here */}
            <div className="row">
              <div className="d-flex flex-wrap justify-content-between align-items-center mb-lg-4 mb-2">
                <h6 className="font-16 font-semibold ">Current Timeline</h6>
                <span className="d-flex align-items-center py-2 px-3 font-14 font-medium mb-3 mb-md-0 justify-content-center  rounded-2 grey-bg-100">
                  <span className="d-none d-sm-flex">Risk Severity :</span>
                  <span className={`${initiativeDetails.riskSeverity === 'High' ? 'red-500' : initiativeDetails.riskSeverity === 'Medium' ? 'orange-500' : 'green-500'} font-bold  ps-2 red-500`}>{initiativeDetails.riskSeverity}</span>
                </span>
              </div>
              <div className="col-md-12 mb-4">
                <div className="table-responsive calender-scrl d-none d-md-block">
                  <table className="table table-borderless themeTable calender-tbl mb-0">
                    <thead className="font-semibold ">
                      <tr>
                        <th className="border-bottom ps-4 table-sticky-th">
                          Project Team
                        </th>
                        {
                          currentTimelineMonths?.map((month: string, i: number) => <th key={i + Math.random()} className="border-bottom ps-4">{month}</th>)
                        }
                      </tr>
                    </thead>
                    <tbody className="font-medium">
                      <tr>
                        <td className="ps-4 text-wrap table-sticky-td">
                          <ul className="mb-0 list-unstyled p-0">
                            {currentTimeline?.resourceDetails?.map((resourceDetails: ResourceDetailsEntity) =>
                              <li className="mt-2 mb-4">{resourceDetails.roleName} ({resourceDetails.resources})</li>
                            )}
                          </ul>
                        </td>
                        <td
                          colSpan={currentTimelineMonths.length}
                          className="my-2 px-0 py-3 position-relative "
                        >
                          <span className="d-flex justify-content-between td-head-brd">


                            {currentTimelineMonths?.map((month: any) => {

                              return <span className="cal-tbl-brd" />
                            })}

                            <span className="cal-tbl-brd" />
                          </span>
                          <span>
                            {currentTimeline?.milestones?.map((milestone: MilestonesEntity, i: number) => {


                              /**
                               * PS_SQ_37 - PS_SQ_41
                               * The getMileStoneCss function provides the marginLeft, marginRight values
                               * for the milestone along with the total width of the milestone.
                               */
                              function getMileStoneCss({ milestoneStart, milestoneEnd }: any) {

                                let totalPixel = currentTimelineMonths.length < 9 ? Number(1350 / currentTimelineMonths.length) : 150


                                const marginLeft = Number(Math.abs(
                                  moment(moment(currentTimelineMonths[0], 'MMM YYYY').startOf('month').format('YYYY-MM-DD'), 'YYYY-MM-DD')
                                    .startOf('day')
                                    .diff(moment(milestoneStart, 'YYYY-MM-DD').startOf('day'), 'days')
                                )) * ((totalPixel / 30));


                                const daysDiff = Number(Math.abs(
                                  moment(milestoneStart, 'YYYY-MM-DD')
                                    .startOf('day')
                                    .diff(moment(milestoneEnd, 'YYYY-MM-DD').startOf('day'), 'days')
                                ));


                                let compactDays = 0
                                let totalMonths = getMonthsBetweenDates(milestoneStart, milestoneEnd)
                                let lastDate: any = []
                                for (let i = 0; i < totalMonths.length; i++) {
                                  let date = (moment(totalMonths[i]).endOf('month')).format('DD')
                                  lastDate.push(date)
                                }
                                // if (lastDate.length > 1) {
                                let daysToAdd = 0
                                let daysToSub = 0

                                for (let i = 0; i < lastDate.length; i++) {
                                  if (lastDate[i] == '31') daysToSub += 1
                                  if (lastDate[i] == '28') daysToAdd += 2
                                  if (lastDate[i] == '29') daysToAdd += 1
                                }

                                compactDays = daysDiff + daysToAdd - daysToSub

                                // }else{
                                //   let daysToAdd = 0
                                //   let daysToSub = 0
                                //   let endDate = moment(milestoneEnd).format('DD')
                                //   if (endDate == '31') daysToSub += 1
                                //   if (endDate == '28') daysToAdd += 2
                                //   if (endDate == '29') daysToAdd += 1

                                //   compactDays = Math.abs(daysDiff + daysToAdd - daysToSub)
                                // }



                                compactDays <= 0 ? compactDays = 0 : compactDays = compactDays

                                const pixCalc = compactDays * (totalPixel / 30);

                                let lastmothdate = moment(currentTimelineMonths[currentTimelineMonths.length - 1]).endOf('month').format('YYYY-MM-DD');
                                let milestoneEndDate = moment(milestoneEnd).format('YYYY-MM-DD')
                                let marginRight
                                if (lastmothdate === milestoneEndDate) {
                                  marginRight = 0
                                } else {
                                  marginRight = (Number(currentTimelineMonths.length * totalPixel) - (pixCalc + marginLeft));
                                }
                                let returnObj: any = {
                                  style: { marginLeft: marginLeft + 'px', marginRight: marginRight + 'px' },
                                  width: pixCalc + 'px'
                                }
                                return returnObj
                              }
                              /**  
                               * PS_SQ_74 
                               * The milestoneId and the flag value is assigned to the state variables while clicking on the milestone bar
                               */
                              return <p style={getMileStoneCss(milestone).style} onClick={() => {
                                setMilestoneId(milestone.milestoneId)
                                setFlag(true);
                              }} className={` text-nowrap text-start font-13  cal-label-sty mb-3 ${milestone.riskSeverity === 'Low' ? 'cal-blue-label' : 'cal-red-label' }`}
                              >
                                <TextWithEllipsis text={milestone.milestoneName} boxWidth={getMileStoneCss(milestone).width} />
                              </p>
                            })}
                          </span>
                        </td>
                      </tr>
                      <tr className="border-0">
                        {currentTimelineMonths?.map((month: string, i: number) => {
                          return <td key={month + i} />
                        })}
                        <td />
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="col-md-12 mb-4">
                <p className="py-3 px-4 blue-bg-100 mb-0 font-16 font-semibold">
                  <span>
                    <img
                      src="images/accordion-arrow.svg"
                      alt="accordion-arrow"
                      className="cursor-pointer accordion-arrow collapsed me-2"
                      data-bs-toggle="collapse"
                      data-bs-target="#resourceRecommendations"
                      aria-expanded="false"
                    />
                  </span>
                  Resource Recommendations
                </p>
                <div className="collapse mb-4" id="resourceRecommendations">
                  <div className="table-responsive calender-scrl d-none d-md-block">
                    <table className="table table-borderless themeTable calender-tbl mb-0">
                      <thead className="font-semibold ">
                        <tr>
                          <th className="border-bottom ps-4 table-sticky-th">
                            Project Team
                          </th>
                          {
                            resourceRecommendationMonths?.map((month: string, i: number) => <th key={i + Math.random()} className="border-bottom ps-4">{month}</th>)
                          }
                        </tr>
                      </thead>
                      <tbody className="font-medium">
                        <tr>
                          <td className="ps-4 text-wrap table-sticky-td">
                            <ul className="mb-0 list-unstyled p-0">
                              {resourceRecommendation?.resourceDetails?.map((resourceDetails: ResourceDetailsEntity) =>
                                <li className="mt-2 mb-4">{resourceDetails.roleName} ({resourceDetails.resources})</li>
                              )}
                            </ul>
                          </td>
                          <td
                            colSpan={resourceRecommendationMonths?.length}
                            className="my-2 px-0 py-3 position-relative "
                          >
                            <span className="d-flex justify-content-between td-head-brd">
                              {resourceRecommendationMonths?.map((month: any) => {

                                return <span className="cal-tbl-brd" />
                              })}
                              <span className="cal-tbl-brd" />
                            </span>
                            <span>
                              {resourceRecommendation?.milestones?.map((milestone: MilestonesEntity, i: number) => {


                                /**
                                 * PS_SQ_44 - PS_SQ_48
                                 * The getMileStoneCss function provides the marginLeft, marginRight values
                                 * for the milestone along with the total width of the milestone.
                                 */
                                function getMileStoneCss({ milestoneStart, milestoneEnd }: any) {

                                  let totalPixel = resourceRecommendationMonths?.length < 9 ? Number(1350 / resourceRecommendationMonths?.length) : 150

                                  const marginLeft = Number(Math.abs(

                                    moment(moment(resourceRecommendationMonths[0], 'MMM YYYY').startOf('month').format('YYYY-MM-DD'), 'YYYY-MM-DD')
                                      .startOf('day')
                                      .diff(moment(milestoneStart, 'YYYY-MM-DD').startOf('day'), 'days')
                                  )) * (totalPixel / 30);


                                  const daysDiff = Number(Math.abs(
                                    moment(milestoneStart, 'YYYY-MM-DD')
                                      .startOf('day')
                                      .diff(moment(milestoneEnd, 'YYYY-MM-DD').startOf('day'), 'days')
                                  ));
                                  let compactDays = 0

                                  let totalMonths = getMonthsBetweenDates(milestoneStart, milestoneEnd)
                                  let lastDate: any = []
                                  for (let i = 0; i < totalMonths.length; i++) {
                                    let date = (moment(totalMonths[i]).endOf('month')).format('DD')
                                    lastDate.push(date)
                                  }
                                  let daysToAdd = 0
                                  let daysToSub = 0

                                  for (let i = 0; i < lastDate.length; i++) {
                                    if (lastDate[i] == '31') daysToSub += 1
                                    if (lastDate[i] == '28') daysToAdd += 2
                                    if (lastDate[i] == '29') daysToAdd += 1
                                  }

                                  compactDays = daysDiff + daysToAdd - daysToSub


                                  const pixCalc = compactDays * (totalPixel / 30);


                                  const marginRight = (Number(resourceRecommendationMonths?.length * totalPixel) - (pixCalc + marginLeft));

                                  let returnObj: any = {
                                    style: { marginLeft: marginLeft + 'px', marginRight: marginRight + 'px' },
                                    width: pixCalc + 'px'
                                  }
                                  return returnObj;
                                }

                                return <span style={getMileStoneCss(milestone).style}
                                  className={`d-block text-nowrap text-start font-13  cal-label-sty-noPointer mb-3 cal-blue-label`}>

                                  <TextWithEllipsis text={milestone.milestoneName} boxWidth={getMileStoneCss(milestone).width} />
                                </span>
                              })}
                            </span>
                          </td>
                        </tr>
                        <tr className="border-0">
                          {resourceRecommendationMonths?.map((month: string, i: number) => {

                            return <td key={month + i} />
                          })}
                          <td />
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div className="col-md-12 mb-4">
                <p className="py-3 px-4 blue-bg-100 mb-0 font-16 font-semibold">
                  <span>
                    <img
                      src="images/accordion-arrow.svg"
                      alt="accordion-arrow"
                      className="cursor-pointer accordion-arrow collapsed me-2"
                      data-bs-toggle="collapse"
                      data-bs-target="#timeRecommendations"
                      aria-expanded="false"
                    />
                  </span>
                  Timeline Recommendations
                </p>
                <div className="collapse mb-4" id="timeRecommendations">
                  <div className="table-responsive calender-scrl d-none d-md-block">
                    <table className="table table-borderless themeTable calender-tbl mb-0">
                      <thead className="font-semibold ">
                        <tr>
                          <th className="border-bottom ps-4 table-sticky-th">
                            Project Team
                          </th>
                          {
                            timelineRecommendationMonths?.map((month: string, i: number) => <th key={i + Math.random()} className="border-bottom ps-4">{month}</th>)
                          }
                        </tr>
                      </thead>
                      <tbody className="font-medium">
                        <tr>
                          <td className="ps-4 text-wrap table-sticky-td">
                            <ul className="mb-0 list-unstyled p-0">
                              {timelineRecommendation?.resourceDetails?.map((resourceDetails: ResourceDetailsEntity) =>
                                <li className="mt-2 mb-4">{resourceDetails.roleName} ({resourceDetails.resources})</li>
                              )}

                            </ul>
                          </td>
                          <td
                            colSpan={timelineRecommendationMonths.length}
                            className="my-2 px-0 py-3 position-relative "
                          >
                            <span className="d-flex justify-content-between td-head-brd">
                              {timelineRecommendationMonths?.map((month: any) => {

                                return <span className="cal-tbl-brd" />
                              })}
                              <span className="cal-tbl-brd" />
                            </span>
                            <span>
                              {timelineRecommendation?.milestones?.map((milestone: MilestonesEntity, i: number) => {


                                /**
                                 * PS_SQ_51 - PS_SQ_55
                                 * The getMileStoneCss function provides the marginLeft, marginRight values
                                 * for the milestone along with the total width of the milestone.
                                 */
                                function getMileStoneCss({ milestoneStart, milestoneEnd }: any) {


                                  let totalPixel = timelineRecommendationMonths.length < 9 ? Number(1350 / timelineRecommendationMonths.length) : 150


                                  const marginLeft = Number(Math.abs(
                                    moment(moment(timelineRecommendationMonths[0], 'MMM YYYY').startOf('month').format('YYYY-MM-DD'), 'YYYY-MM-DD')
                                      .startOf('day')
                                      .diff(moment(milestoneStart, 'YYYY-MM-DD').startOf('day'), 'days')
                                  )) * (totalPixel / 30);

                                  const daysDiff = Number(Math.abs(
                                    moment(milestoneStart, 'YYYY-MM-DD')
                                      .startOf('day')
                                      .diff(moment(milestoneEnd, 'YYYY-MM-DD').startOf('day'), 'days')
                                  ));

                                  let compactDays = 0
                                  let totalMonths = getMonthsBetweenDates(milestoneStart, milestoneEnd)
                                  let lastDate: any = []
                                  for (let i = 0; i < totalMonths.length; i++) {
                                    let date = (moment(totalMonths[i]).endOf('month')).format('DD')
                                    lastDate.push(date)
                                  }
                                  let daysToAdd = 0
                                  let daysToSub = 0


                                  for (let i = 0; i < lastDate.length; i++) {
                                    if (lastDate[i] == '31') daysToSub += 1
                                    if (lastDate[i] == '28') daysToAdd += 2
                                    if (lastDate[i] == '29') daysToAdd += 1
                                  }
                                  compactDays = daysDiff + daysToAdd - daysToSub

                                  const pixCalc = compactDays * (totalPixel / 30);

                                  const marginRight = (Number(timelineRecommendationMonths.length * totalPixel) - (pixCalc + marginLeft));

                                  let returnObj: any = {
                                    style: { marginLeft: marginLeft + 'px', marginRight: marginRight + 'px' },
                                    width: pixCalc + 'px'
                                  }

                                  return returnObj;
                                }

                                return <span style={getMileStoneCss(milestone).style}
                                  className={`d-block text-nowrap text-start font-13  cal-label-sty-noPointer mb-3 cal-blue-label`}>

                                  <TextWithEllipsis text={milestone.milestoneName} boxWidth={getMileStoneCss(milestone).width} />
                                </span>
                              })}
                            </span>
                          </td>
                        </tr>
                        <tr className="border-0">
                          {timelineRecommendationMonths?.map((month: string, i: number) => {

                            return <td key={month + i} />
                          })}
                          <td />
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/**
           * PS_SQ_75 
           * The milestoneForm component is called based on the trueness of the flag condition
           * and the milestoneId, value of flag and its function is passed as the props
           */}
          {flag && <MilestoneForm flag={flag} name={{
            setFlag: (param: boolean) => setFlag(param),
            setRender: (param: boolean) => setRender(param),
            milestoneId: milestoneId,
            InitiativeId: initiative_id,
            organizationName: organizationName,
            organizationId: organization_id,
            verticalId: verticalId,
            verticalName: verticalName,
            setIsLoading: (param: boolean) => setisLoading(param),
            isLoading: isLoading
          }} />}
        </div>
      </div>
      {/* This makes the background blur when the form is visible*/}
      {flag && <div className="modal-backdrop fade show"></div>}
    </>
  )
}

export default Sequence;

/** 
 * PS_SQ_57 - PS_SQ_71
 * The TextWithEllipsis function is used to handle the overlapping of the milestone name
 * within the milestone box.
*/
const TextWithEllipsis = ({ text, boxWidth }: any) => {
  const boxRef = useRef() as MutableRefObject<HTMLDivElement>;
  const [truncatedText, setTruncatedText] = useState<string>('');

  useEffect(() => {
    const boxElement = boxRef.current;

    if (boxElement) {
      if (boxElement.scrollWidth > boxElement.clientWidth) {
        const availableWidth = boxElement.clientWidth;
        const textContent = text;
        let start = 0;
        let end = textContent.length;
        let mid;

        while (start < end) {
          mid = Math.floor((start + end) / 2);
          const truncatedText = textContent.slice(0, mid);

          if (boxElement.scrollWidth <= availableWidth) {
            start = mid + 1;
            setTruncatedText(truncatedText + '...');
          } else {
            end = mid;
          }
        }
      }
      else setTruncatedText(text)
    }
  }, [text, boxWidth]);

  return (
    <div className="text-box" title={text} style={{ width: boxWidth }} ref={boxRef}>
      {truncatedText}
    </div>
  );
}